<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="showName" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container">
        <ng-container *ngFor="let divisionId of divisionIds">
          <app-division-chart [divisionId]="divisionId" [idConcurso]="idConcurso" [showName]="showName"></app-division-chart>
        </ng-container>
      </div>
    </div>
  </div>
</div>
