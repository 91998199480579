import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class MantenimientoService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getInformacionBinomio(idConcurso,idBinomio,idResponsable,idCaballo): Observable<any>{
    const params = JSON.stringify({ id_concurso: idConcurso, id_binomio: idBinomio, id_responsable: idResponsable, id_caballo: idCaballo });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}binomios/conceptos_binomio/`, params, {headers: headers});
  }

  public getCaballosJSON(): Observable<any> {
    return this.http.get("./../assets/json/caballosFEM.json");
  }

  public getJinetesJSON(): Observable<any> {
    return this.http.get("./../assets/json/jinetesFEM.json");
  }

  public updateClubes(siglas, nombre): Observable<any>{
    const params = JSON.stringify({ siglas: siglas, fullname: nombre });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}mantenimiento/clubs/`, params, {headers: headers});
  }
}
