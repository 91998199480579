import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service';
import { ConcursoService } from '../services/concurso.service';

@Component({
  selector: 'app-formato-inscripciones',
  templateUrl: './formato-inscripciones.component.html',
  styleUrls: ['./formato-inscripciones.component.css']
})
export class FormatoInscripcionesComponent implements OnInit {
  private ids: any;
  private idConcurso: string|false;
  public show: any;
  public solicitudes: any[];
  public entriesDue: string;
  public logoClub: string;
  public nombreConcurso: string;
  public entryAmount: number;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService, private inscripcionesRemotasService: InscripcionesRemotasService, private concursoService: ConcursoService) {
    this.ids = this.route.snapshot.params.ids.split(',');
    this.idConcurso = this.authService.validarConcurso();
    this.show = {};
    this.entriesDue = '';
    this.logoClub = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
		this.entryAmount = 350;
    this.solicitudes = [];
  }

  ngOnInit() {
    Promise.all([this.concursoService.getConcurso(this.idConcurso).toPromise().then(r => { this.show = r.concurso; return r; }), this.inscripcionesRemotasService.getFormatosSolicitudesPendientes(this.ids, this.idConcurso).toPromise().then(r => { this.solicitudes = r.solicitudes; return r; } )]).then(() => {
      this.entriesDue = this.show.entriesDue;
      this.logoClub = this.show.logoClub;
      this.entryAmount = this.show.entryAmount;
      setTimeout(() => { window.print(); });
      window.onafterprint = () => {
        setTimeout(() => { window.close(); });
      };
    });
  }


  public numerosPruebas(pruebas: any[]): string{
		return pruebas.map(p => p.numero).join(', ');
	}

  public totalCargos(cargos): number{
		if(cargos){
			return cargos.reduce((sum, cargo) => {
				return sum + cargo.total;
			}, 0);
		}

		return 0;
	}
}
