<ng-container *ngFor="let class$ of classes">
    <ng-container *ngIf="(class$ | async) as class">
        <div class="row">
            <div class="col">
                <img style="max-height: 90px;" [src]="class.info.header" onerror="this.style.display='none'">
            </div>
            <div class="col text-center">
                <h3>Class Sheet</h3>
                <p>{{ nombreConcurso }}<br><b>{{ class.info.numero }}</b> {{ class.info.nombre }}</p>
                <p *ngIf="class.info.description">{{ class.info.description }}</p>
                <p>{{class.info.pista}}</p>
            </div>
            <div class="col text-right">
                <img style="max-height: 90px;" [src]="class.info.sponsor" onerror="this.style.display='none'">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="mb-0"><b>Date:</b> {{ class.info.fecha | date : 'd MMMM yyyy' | titlecase }}</p>
                <p class="mb-0"><b>Article / Table:</b> {{ class.info.modalidad }}</p>
                <p class="mb-0" *ngIf="class.info.premio > 0"><b>Prize:</b> {{ class.info.premio | currency }}</p>
            </div>
            <div class="col">
                <p class="mb-0"><b>Designer:</b> {{ class.info.designer }}</p>
                <p class="mb-0"><b>Level:</b>
                    <span [ngSwitch]="class.info.ambito">
                        <span *ngSwitchCase="1"> Regional</span>
                        <span *ngSwitchCase="2"> National</span>
                        <span *ngSwitchCase="3"> CSI</span>
                        <span *ngSwitchCase="4"> CSI-Amateur</span>
                        <span *ngSwitchCase="5"> FEI Ranking</span>
                        <span *ngSwitchDefault> {{ class.info.ambito }}</span>
                    </span>
                </p>
            </div>
            <div class="col">
                <p class="mb-0"><b>Judge:</b> {{ class.info.juez }}</p>
                <p class="mb-0"><b>Height:</b> {{ class.info.altura }}</p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>
                                <p class="m-0">Entry</p>
                              </th>
                              <th>
                                <p class="m-0">Horse</p>
                              </th>
                              <th>
                                <p class="m-0">Rider</p>
                              </th>
                              <th>
                                <p class="m-0">Owner</p>
                              </th>
                              <th>
                                <p class="m-0">Trainer</p>
                              </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let binomio of class.binomios" [ngClass]="{'scratched': binomio.scratched}">
                            <td>{{ binomio.cucarda }}</td>
                            <td>
                                <p class="m-0">{{ binomio.caballo }}
                                    <span class="badge badge-secondary-lighten"
                                    style="vertical-align:text-top">{{binomio.heightCategory}}</span>
                                </p>
                                <p class="m-0">
                                    <small>
                                        <span *ngIf="mostrarInfoCaballo == 'true'">{{ infoCaballo(binomio) }}</span>
                                    </small>
                                </p>
                            </td>
                            <td><span *ngIf="mostrarPaisJinete == 'true' && binomio.country != '-'">({{ binomio.country }}) </span>{{ binomio.nombreJinete }} {{ binomio.apellidosJinete }} <span class="badge badge-info badge-pill" style="font-size: .575rem;" *ngIf="binomio.categoria">{{ binomio.categoria.substring(0, 2) }}</span></td>
                            <td>{{ binomio.owner }}</td>
                            <td>{{ binomio.trainer }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <img [src]="class.info.footer" style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'">
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                Powered by <img src="assets/images/logo.png" style="width: 15px;"> EquestrianDigital
            </div>
        </div>
        <div class="page-break"></div>
    </ng-container>
</ng-container>
