import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class ClubService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getClubs(): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}clubs/`, {headers: headers});
  }

  public addClub(club): Observable<any>{
    const params = JSON.stringify(club);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}clubs/add/`, params, {headers: headers});
  }

  public updateClub(club): Observable<any>{
    const params = JSON.stringify(club);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}clubs/update/`, params, {headers: headers});
  }

  public getLogo(concurso): Observable<any>{
    const params = JSON.stringify({ concurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}clubs/getLogo/`, params, {headers: headers});
  }

  public guardarLogo(idClub, logo_url): Observable<any>{
    const params = JSON.stringify({ idClub, logo_url });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}clubs/guardarLogo/`, params, { headers: headers });
  }
}
