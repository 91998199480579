<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <!-- Modal de confirmacion para borrar prueba -->
        <div class="modal fade" id="modal-eliminar-prueba" tabindex="-1" role="dialog"
          aria-labelledby="eliminarPruebaLabel" style="display: none;" aria-hidden="true">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header px-2 py-1">
                <h4 class="modal-title" id="eliminarPruebaLabel">Delete class</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
              </div>
              <div class="modal-body p-2">
                Are you sure to delete this class?
              </div>
              <div class="modal-footer px-2 py-1">
                <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary btn-sm" (click)="deleteClass()">Accept</button>
              </div>
            </div>
          </div>
        </div>
        <!-- FIN Modal de confirmacion para borrar prueba -->

        <div class="row m-print-0 w-100">
          <div class="d-flex text-center align-items-center w-100">
            <div style="flex: 1;">
              <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='unset'">
            </div>
            <div style="flex: 1;">
              <h3><span>Division</span></h3>
              <p>{{ nombreConcurso }}</p>
              <p><b>{{ form.value.name }}</b></p>
            </div>
            <div style="flex: 1;">
              <img [src]="header2" style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='unset'">
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="card">
              <div class="card-body p-2">
                <div class="row d-none d-print-flex">
                  <div class="col-6">
                    <p><b>Division Number: </b>{{ form.value.code }}</p>
                    <p><b>Cost: </b>{{ form.value.cost | currency }}</p>
                    <p><b>Section Code: </b>{{ form.value.section_code | currency }}</p>
                  </div>
                  <div class="col-6">
                    <p><b>Type: </b>{{ form.value.type | titlecase }}</p>
                    <p><b>Charge by: </b>{{ form.value.charged | titlecase }}</p>
                  </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="updateDivision()" class="needs-validation d-print-none"
                  no-validate>
                  <div class="row">
                    <div class="col-6 mb-3 d-print-none">
                      <a href="javascript:;" [routerLink]="['/divisions']">
                        <button type="button" class="btn btn-primary">Back to Divisions</button>
                      </a>
                    </div>
                    <br>
                    <div class="form-row col-12">
                      <div class="col-2">
                        <div class="form-group">
                          <label>Division Number</label>
                          <input type="text" class="form-control" formControlName="code" required>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label>Name</label>
                          <input type="text" class="form-control" formControlName="name" required>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <label>Type</label>
                          <select class="form-control" formControlName="type">
                            <option value="hunters">Hunters</option>
                            <option value="jumpers">Jumpers</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <label>Cost</label>
                          <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                            <span class="input-group-addon input-group-prepend"><span
                                class="input-group-text">$</span></span>
                            <input type="number" class="form-control" formControlName="cost" min="0" required>
                          </div>

                        </div>
                      </div>
                      <!--div class="col-4">
                                                <div class="form-group">
                                                    <label>Discount</label>
                                                    <input type="number" class="form-control" formControlName="discount">
                                                </div>
                                            </div-->

                      <div class="col-3">
                        <div class="form-group">
                          <label>Charge by</label>
                          <select class="form-control" formControlName="charged">
                            <option value="class-fees">Class Fees</option>
                            <option value="division-fee">Division Fee</option>
                            <option value="division-discount">Division Discount</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <label>Section Code</label>
                          <input type="text" class="form-control" formControlName="section_code">
                        </div>
                      </div>
                      <div class="col-4 mb-1 text-right d-print-none" style="line-height: 6em;">

                        <button type="submit" class="btn btn-success">Update Division</button>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <ul class="list-group">
                        <li class="list-group-item py-1 px-2 justify-content-between d-flex" *ngFor="let prueba of pruebas; let i = index;">
                          <span><b>{{ prueba.numero }}</b> {{ prueba.nombre }}</span>
                          <span>{{ prueba.costo | currency:'USD':'symbol':'0.0-0' }}</span>
                          <span>{{ prueba.premio | currency:'USD':'symbol':'0.0-0' }}</span>
                          <span [ngClass]="{'visible': prueba.sponsorImage}" style="opacity: 0;"><i class="mdi mdi-image"></i><small>Sponsor Banner</small></span>
                          <span [ngClass]="{'visible': prueba.croquis}" style="opacity: 0;"><i class="mdi mdi-image"></i><small>Course Design</small></span>
                          <span class="d-print-none">
                            <a href="javascript:;" (click)="duplicateClass(prueba.ipc, prueba.numero)" tabindex="-1" title="Duplicate Class"><i class="mdi mdi-content-duplicate mr-3"></i></a>
                            <a href="javascript:;" (click)="editClass(prueba.ipc)" tabindex="-1" title="Edit Class"><i class="mdi mdi-pencil mr-3"></i></a>
                            <a href="javascript:;" (click)="modalRemoveClass(prueba.ipc)" tabindex="-1" title="Delete Class"><i class="mdi mdi-close"></i></a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-12 d-none d-print-block text-center">
                    <img [src]="footer" style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'"
                      onload="this.style.display='unset'">
                  </div>
                  <p class="d-none d-print-block pl-2 mt-3">
                    Powered by <img src="assets/images/logo.png" style="width: 15px;">
                    EquestrianDigital;
                  </p>
                </div>
                <div class="row d-print-none">
                  <div class="col-12">
                    <div class="form-group mb-0">
                      <button type="button" class="btn btn-info" (click)="mostrarAgregarPrueba()"
                        *ngIf="!mostrarFormularioPruebas;else cancelarFormularioPrueba">+ New
                        class (ctrl + q) </button>
                      <ng-template #cancelarFormularioPrueba>
                        <button type="button" class="btn btn-secondary"
                          (click)="mostrarFormularioPruebas = false;">Cancel</button>
                      </ng-template>
                    </div>
                    <form [formGroup]="formPrueba" class="needs-validation mt-2" (ngSubmit)="crearPrueba()" no-validate
                      [hidden]="!mostrarFormularioPruebas">
                      <div class="row">
                        <div class="col-2">
                          <div class="form-group">
                            <label>Number</label>
                            <input type="text" class="form-control" formControlName="numero" name="numero" required>
                          </div>
                        </div>
                        <div class="col-5">
                          <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" formControlName="nombre" required>
                          </div>
                        </div>
                        <div class="col-5">
                          <div class="form-group">
                            <label>Article / Table</label>
                            <select class="form-control" formControlName="modalidad" required>
                              <option disabled value=''>Select an Article/Table</option>
                              <option *ngFor="let modalidad of modalidades" [value]="modalidad.id">{{ modalidad.nombre
                                }} ({{
                                modalidad.clave }})</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label>Description</label>
                            <textarea class="form-control" style="resize: none;"
                              formControlName="description"></textarea>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="form-group">
                            <label>Height(s)</label>
                            <input type="text" class="form-control" formControlName="altura">
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="form-group">
                            <label>Height Unit</label>
                            <select class="form-control" formControlName="height_unit">
                              <option value="M">M</option>
                              <option value="E">E</option>
                              <option value="A">A</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group">
                            <label>Ring</label>
                            <select class="form-control" formControlName="pista" (change)="cambioPista()">
                              <option value=""></option>
                              <option [value]="pista" *ngFor="let pista of pistas">{{ pista }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="form-group">
                            <label>Prize</label>
                            <input type="number" min="0" class="form-control" formControlName="premio">
                          </div>
                        </div>
                        <!--div class="col-4">
                                                    <div class="form-group">
                                                        <label>Date</label>
                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div-->
                        <ng-container *ngIf="!reining">
                          <div class="col-3">
                            <div class="form-group">
                              <label>USEF Section code</label>
                              <input type="text" class="form-control" formControlName="usef_section">
                            </div>
                          </div>
                        </ng-container>
                        <!--div class="col-4">
                                                    <div class="form-group">
                                                        <label>Judge</label>
                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div-->
                        <div class="col-2">
                          <div class="form-group">
                            <label>Cost</label>
                            <input type="number" min="0" class="form-control" formControlName="costo">
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label>Prize Money Distribution</label>
                            <select class="form-control" formControlName="prize_distribution">
                              <option value=""></option>
                              <option [value]="distribucion.id" *ngFor="let distribucion of distribuciones">{{
                                distribucion.nombre }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label>Charges</label>
                            <div class="input-group">
                              <select class="form-control" name="conceptoSeleccionado"
                                [(ngModel)]="conceptoSeleccionado" [ngModelOptions]="{standalone: true}">
                                <option value="">-- Select a charge --</option>
                                <option [value]="concepto.id" *ngFor="let concepto of filtroConceptosSinAgregar()">
                                  {{ concepto.nombre }}</option>
                              </select>
                              <div class="input-group-append">
                                <button class="btn btn-info" type="button" (click)="agregarCargo()">Add</button>
                              </div>
                            </div>
                            <p class="mb-0 mt-2">
                              <span style="font-size: .75rem;" class="badge badge-primary badge-pill mr-1"
                                *ngFor="let cargo of cargos">
                                {{ cargo.name }}
                                <a href="javascript:void(0)" class="text-white" (click)="removeCharge(cargo.id)"><i
                                    class="mdi mdi-close"></i>
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label>Judge</label>
                            <select class="form-control" formControlName="juez">
                              <option value="" disabled>-- Select a Judge --</option>
                              <option *ngFor="let juez of jueces" value="{{juez.id}}">{{ juez.nombre }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label>Designer</label>
                            <input type="text" class="form-control" formControlName="designer" [matAutocomplete]="autocompleteDessigner" (keyup)="getPersonas($event, 'trainer')">
                            <mat-autocomplete #autocompleteDessigner="matAutocomplete" [displayWith]="displayPersona.bind(this)">
                              <mat-option *ngFor="let designer of personas" [value]="designer.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em">
                                <img src="{{designer.flag}}" style="height:.9em" />
                                <span id="dessignerName" style="font-weight: bold;">{{ designer.fullname||'-' }}</span>
                                <div>
                                  <span id="USEF">
                                    <img src="/assets/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">
                                    {{ designer.usef }}
                                  </span>
                                  <span *ngIf="designer.fei" id="FEI">
                                    <img src="/assets/images/icons/fei.svg" alt="FEI: " style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                                    {{ designer.fei }}
                                  </span>
                                </div>
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <button class="btn btn-outline-alternate" onclick="$('#sponsor_banner').click()"
                              type="button" style="margin-top: 30px;"><i class="dripicons-pin"></i>
                              Sponsor banner
                            </button>
                            <input class="d-none" accept="image/jpeg" type="file" id="sponsor_banner"
                              (change)="imagenPatrocinador($event)">
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <button class="btn btn-outline-alternate" onclick="$('#course_design').click()"
                              type="button" style="margin-top: 30px;"><i class="dripicons-map"></i> Course
                              design</button>
                            <input class="d-none" type="file" id="course_design" accept=".jpg,.jpeg,.png"
                              (change)="archivoCroquis($event)">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <button type="submit" class="btn btn-success">Save Class</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
