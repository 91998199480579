import { Pipe, PipeTransform } from '@angular/core';
import { traducciones } from '../traducciones/traducciones';

@Pipe({
    name: 'traduccion'
})

export class TraduccionPipe implements PipeTransform {
    transform(value: any, args: any[]): any {
        const baseUrl = window.location.hostname;
        if(traducciones[value] && baseUrl == "app.equestrian.digital"){ // localhost, app.equestrian.digital
            return traducciones[value];
        } 

        return value;
    }
}