<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
            <div class="container-fluid">
                <div class="row margin-top-35 justify-content-center">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3 mb-2 mb-sm-0">
                                        <ul class="list-group">
                                            <li class="list-group-item d-flex px-2 py-1" [ngClass]="{'active': paymentSystem == 'chase'}" (click)="paymentSystem = 'chase'">
                                                <img src="assets/images/icons/chase.png" class="my-auto mr-1" style="max-height: .875rem; max-width: .875rem;">
                                                <div class="my-auto">Chase</div>
                                            </li>
                                            <li class="list-group-item d-flex px-2 py-1" [ngClass]="{'active': paymentSystem == 'stripe'}" (click)="paymentSystem = 'stripe'">
                                                <img src="assets/images/icons/stripe_icon.png" class="my-auto mr-1" style="max-height: .875rem; max-width: .875rem;">
                                                <div class="my-auto">Stripe</div>
                                            </li>
                                            <li class="list-group-item d-flex px-2 py-1" [ngClass]="{'active': paymentSystem == 'square'}" (click)="paymentSystem = 'square'">
                                                <img src="assets/images/icons/square.png" class="my-auto mr-1" style="max-height: .875rem; max-width: .875rem;">
                                                <div class="my-auto">Square</div>
                                            </li>
                                        </ul>    
                                    </div>                            
                                    <div class="col-sm-9 px-0">
                                        <div class="tab-content" [ngSwitch]="paymentSystem">
                                            <div class="tab-pane fade active show" *ngSwitchCase="'chase'">
                                                <form class="form-horizontal" [formGroup]="chaseForm" (submit)="saveChaseConfig()">
                                                    <div class="row mb-3">
                                                        <label class="col-3 col-form-label">Public Key</label>
                                                        <div class="col-9">
                                                            <div class="input-group">
                                                                <input formControlName="public_key" [type]="show_chase_public_key ? 'text' : 'password'" class="form-control"  placeholder="Public Key" required>
                                                                <div class="input-group-append">
                                                                    <button type="button" class="btn btn-light" (click)="show_chase_public_key = !show_chase_public_key">
                                                                        <i class="mdi mdi-eye-off-outline" [ngClass]="{'mdi-eye-outline': show_chase_public_key}"></i> 
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <label class="col-3 col-form-label">Secret Key</label>
                                                        <div class="col-9">
                                                            <div class="input-group">
                                                                <input formControlName="secret_key" [type]="show_chase_secret_key ? 'text' : 'password'" class="form-control" placeholder="Secret Key" required>
                                                                <div class="input-group-append">
                                                                    <button type="button" class="btn btn-light" (click)="show_chase_secret_key = !show_chase_secret_key">
                                                                        <i class="mdi mdi-eye-off-outline" [ngClass]="{'mdi-eye-outline': show_chase_secret_key}"></i> 
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr class="my-4">
                                                    <div class="row mb-3">
                                                        <label class="col-3 col-form-label">User</label>
                                                        <div class="col-9">
                                                            <input formControlName="user"  type="text" class="form-control" placeholder="User" required>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <label class="col-3 col-form-label">Password</label>
                                                        <div class="col-9">
                                                            <div class="input-group">
                                                                <input formControlName="password" [type]="show_chase_password ? 'text' : 'password'" class="form-control" placeholder="Password" required>
                                                                <div class="input-group-append">
                                                                    <button type="button" class="btn btn-light" (click)="show_chase_password = !show_chase_password">
                                                                        <i class="mdi mdi-eye-off-outline" [ngClass]="{'mdi-eye-outline': show_chase_password}"></i> 
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <label class="col-3 col-form-label">Merchant ID</label>
                                                        <div class="col-9">
                                                            <input formControlName="merchant_id" type="number" class="form-control" placeholder="Merchant ID" required>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3 justify-content-end">
                                                        <label class="col-3 col-form-label">Active</label>
                                                        <div class="col-9 d-flex">
                                                            <input formControlName="active" type="checkbox" class="form-check-input" id="active-chase" checked data-switch="success"/>
                                                            <label class="form-check-label my-auto" for="active-chase" [attr.data-on-label]="'Yes'" data-off-label="No"></label>
                                                        </div>
                                                    </div>
                                                    <div class="justify-content-end row">
                                                        <div class="col-9">
                                                            <button type="submit" class="btn btn-info">Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <pre>
                                                    {{ chaseForm.value | json }}
                                                </pre>
                                            </div>
                                            <div class="tab-pane fade active show" *ngSwitchCase="'stripe'">
                                              ... stripe
                                            </div>
                                            <div class="tab-pane fade active show" *ngSwitchCase="'square'">
                                                ... square
                                            </div>
                                            <!--div *ngSwitchDefault>output2</div-->
                                        </div> <!-- end tab-content-->
                                    </div> <!-- end col-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>