<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid p-print-0">
                <div class="row mt-4 m-print-0 justify-content-center">
                    <div class="col-10 col-print-12">
                        <div class="row">
                            <div class="card w-100">
                                <div class="card-body p-print-0">
                                    <div class="col-12 d-print-none">
                                        <div class="row">
                                            <div class="form-group mb-0">
                                                <select class="form-control" (change)="cambioPrueba($event)">
                                                    <option value="" selected disabled>Select a Class</option>
                                                    <option *ngFor="let prueba of pruebas" [value]="prueba.ipc">{{ prueba.numero }} - {{ prueba.nombre }}</option>
                                                </select>
                                                
                                            </div>
                                            <div class="form-group mb-0 ml-auto" *ngIf="ipc && resultados.length > 0">
                                                <button *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2') && privilegios >= 250" class="btn btn-light mr-2" (click)="exportarXML()">
                                                    <i class="mdi mdi-sync mr-1"></i>
                                                <span>FEI Results..</span>
                                            </button>
                                                <button class="btn btn-light mr-2" (click)="exportarExcel()">
                                                    <i class="mdi mdi-file-excel-box mr-1"></i>
                                                    <span>Excel</span>
                                                </button>
                                                <button class="btn btn-light mr-2" onclick="print()">
                                                        <i class="mdi mdi-printer mr-1"></i>
                                                    <span>Print</span>
                                                </button>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-print-none mt-4" *ngIf="resultados.length > 0 && prueba.categorias.length > 1">
                                        <div class="row">
                                            <button class="btn btn-outline-secondary mr-2" *ngFor="let categoria of prueba.categorias" [ngClass]="{'active': categoria == categoriaSeleccionada}" (click)="seleccionarCategoria(categoria)">{{ categoria }}</button>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12 mt-4 m-print-0" *ngIf="resultados.length > 0">
                                        <div class="row border">
                                            <div class="d-flex text-center align-items-center w-100">
                                                <div style="flex: 1;">
                                                    <img src="/assets/img/clubs/{{prueba.siglasClub}}.png?1" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='initial'">
                                                </div>
                                                <div style="flex: 1;">
                                                    <h3>Class Results</h3>
                                                    <p>{{ nombreConcurso }}
                                                    <br><b>{{ prueba.numero }}</b> {{ prueba.nombre }}</p>
                                                </div>
                                                <div style="flex: 1;">
                                                    <img [src]="prueba.sponsorImage ? prueba.sponsorImage : prueba.header" style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='initial'">
                                                </div>
                                            </div>
                                            <div class="d-flex w-100">
                                                <p class="p-3" style="flex: 1;">
                                                    <b>Date Printed:</b> {{ fecha | date : 'dd MMMM yyyy' | titlecase }}
                                                    <br><b>Designer:</b> {{ prueba.designer }}
                                                    <br *ngIf="prueba.premio > 0"><b *ngIf="prueba.premio > 0">Prize:</b> <span *ngIf="prueba.premio > 0">{{ prueba.premio | currency }}</span>
                                                    <br><b>Judge:</b> {{ prueba.nombreJuez }}
                                                </p>
                                                <p class="p-3" style="flex: 1;">
                                                    <b>Class / Article:</b> {{ prueba.nombreModalidad }}
                                                    <br><b>Level:</b>
                                                    <span *ngIf="prueba.ambito == '1'">Regional</span>
                                                    <span *ngIf="prueba.ambito == '2'">National</span>
                                                    <span *ngIf="prueba.ambito == '3'">CSI</span>
                                                    <span *ngIf="prueba.ambito == '4'">Young Horses</span>
                                                    <span *ngIf="prueba.ambito == '5'">FEI Ranking</span>
                                                    <span *ngIf="prueba.ambito == '6'">CSAm-A</span>
                                                    <br><b>Height:</b> {{ prueba.altura }}
                                                    <br *ngIf="prueba.categorias.length > 1"><b *ngIf="prueba.categorias.length > 1">Category: </b> <span *ngIf="prueba.categorias.length > 1">{{ categoriaSeleccionada }}</span>
                                                </p>
                                            </div>
                                            <table class="table table-striped table-fw-widget table-hover small-print">
                                                <thead>
                                                    <tr>
                                                        <th>Rank</th>
                                                        <th>Back #</th>
                                                        <!--th *ngIf="prueba.ambito == '1' || prueba.ambito == '2'">ID</th-->
                                                        <th *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">FEI</th>
                                                        <th>Horse</th>
                                                        <!--th *ngIf="prueba.ambito == '1' || prueba.ambito == '2'">ID</th-->
                                                        <th *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">FEI</th>
                                                        <th>Rider</th>
                                                        <!--th *ngIf="prueba.ambito == '1' || prueba.ambito == '2'">Team</th-->
                                                        <th *ngIf="prueba.ambito == '3' || prueba.ambito == '4' || prueba.ambito == '5' || prueba.ambito == '6'">Country</th>
                                                        
                                                        <th>Order</th>
                                                        <th>F | T</th>
                                                        <th *ngIf="fases == '2'">F | T</th>
                                                        <th *ngIf="prueba.modalidad == '8'">Result</th><!-- 239 -->
                                                        <th *ngIf="prueba.premio > 0">Prize</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngFor="let resultado of filtroOverall(); let i = index;">
                                                        <tr>
                                                            <td>{{ prueba.overall && categoriaSeleccionada != 'Overall' ? i+1 :  resultado.posicion }}</td>
                                                            <td>{{ resultado.cucarda }}</td>
                                                            <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.reg_caballo }}</td>
                                                            <td>{{ resultado.caballo }}</td>
                                                            <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.reg_jinete }}</td>
                                                            <td>{{ resultado.nombre_jinete }} {{ resultado.apellido_jinete }}</td>
                                                            <!--td *ngIf="prueba.ambito == '1' || prueba.ambito == '2'">{{ resultado.club | uppercase }}</td-->
                                                            <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.pais |uppercase }}</td>
                                                            
                                                            <td>{{ resultado.entrada }}</td>
                                                            <td *ngIf="prueba.modalidad != '8'">{{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.faltas1 + ' .| ' + resultado.tiempo1 : resultado.status1) : '' }}</td>
                                                            <td *ngIf="fases == '2'">{{ resultado.calificado2 == '1' ? (resultado.status2 == '0' ? resultado.faltas2 + ' .| ' + resultado.tiempo2 : resultado.status2) : '' }}</td>
                                                            <td *ngIf="prueba.modalidad == '8'">{{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.faltas1 + ' + ' + resultado.tiempo1 : resultado.status1) : '' }}</td>
                                                            <td *ngIf="prueba.modalidad == '8'">{{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? formatoSuma239(resultado.faltas1 + resultado.tiempo1) : resultado.status1) : '' }}</td>
                                                            <td *ngIf="prueba.premio > 0">{{ resultado.premio | currency }}</td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                            <div class="col-12 mt-4" *ngIf="resultados.length > 0">
                                                <span class="label-total" *ngIf="totalResultados > 0"><b>Total entries:</b> {{ totalResultados }}</span>
                                            </div>
                                            <div class="col-12 text-center">
                                                <img [src]="prueba.footer" style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'">
                                            </div>

                                            <p class="d-none d-print-block pl-2 mt-3">
                                                Powered by <img src="assets/images/logo.png" style="width: 15px;"> EquestrianDigital
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
