
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class EmailService extends Api {
  constructor(private http: HttpClient) {
    super();
  }

  public send(subject: string, content: string): Observable<any>{
    const params = JSON.stringify({ subject, content });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}email/send/`, params, {headers: headers});
  }
}
