import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckService  extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public save(checkData: any): Observable<any>{
    const params = JSON.stringify({ checkData });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}checks/save/`, params, {headers: headers});
  }

  public getChecks(idConcurso: string, entry?: string, trainer?: string): Observable<any>{
    const params = JSON.stringify({ idConcurso, entry, trainer });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}checks/`, params, {headers: headers});
  }
}
