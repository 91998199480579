import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConcursoService } from '../services/concurso.service';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.css']
})
export class PrintLayoutComponent implements OnInit {
  public idConcurso: string;
  public nombreReporte: string;
  public concurso: Promise<any>;

  constructor(private route: ActivatedRoute, private concursoService: ConcursoService) {
    this.idConcurso = this.route.snapshot.params['idConcurso'];
    this.nombreReporte = this.route.snapshot.params['nombreReporte'];
  }

  ngOnInit() {
    this.concurso = this.getConcurso();
  }

  getConcurso(): Promise<any>{
    return this.concursoService.getConcurso(this.idConcurso).toPromise();
  }
}
