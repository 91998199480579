<div class="row justify-content-center">
    <div class="col-6 mt-4">
        <div class="card">
            <div class="card-body">
                <h1>{{ prueba.numero }} - {{ prueba.nombre }}</h1>
                <h1>{{ en_pista.nombreJinete }} {{ en_pista.apellidosJinete }}</h1>
                <h1>{{ en_pista.cucarda }} - {{ en_pista.caballo }}</h1>
            </div>
        </div>
    </div>
</div>
