import { Component, OnInit, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { EntriesService } from './../../services/entries.service';
import { AuthService } from './../../services/auth.service';

@Component({
  selector: 'app-settle-credits',
  templateUrl: './settle-credits.component.html',
  styleUrls: ['./settle-credits.component.css']
})
export class SettleCreditsComponent implements OnInit, OnChanges{
  @Input() private entries?: any[];
  @Output() public onError: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSuccess: EventEmitter<any> = new EventEmitter<any>();
  public entriesWithCredits: any[];
  public entriesInTrainerAccount: any[];
  public entrySelected: string = '';
  public loading: boolean = false;
  public idConcurso: string | boolean;
  private idUsuario: string;
  public get availableCredits(): number {
    if(!this.entrySelected) return 0;
    const entry = this.entriesWithCredits.find(e => e.entry === this.entrySelected)||{};
    return Math.abs(entry.saldo||0);
  }
  public get usedCredits(): number {
    return this.entriesInTrainerAccount.reduce((acc, entry) => acc + entry.creditAssigned, 0);
  }

  constructor(private entriesService: EntriesService, private authService: AuthService) {
    this.entriesWithCredits = [];
    this.entriesInTrainerAccount = [];
    this.entrySelected = '';
    this.idConcurso = this.authService.validarConcurso();
    this.idUsuario = '';
  }

  ngOnInit(){
    this.entriesWithCredits = this.entries?.filter((entry: any) => entry.saldo < 0);
    this.entriesInTrainerAccount = this.entries?.filter((entry: any) => entry.saldo >= 0).map(e => {
      e.creditAssigned = 0;
      return e;
    });
    const token = this.authService.getAuthorizationToken();
    const sessionData = this.authService.getSessionData(token);
    this.idUsuario = String(sessionData['idUsuario']);
  }

  ngOnChanges(changes: SimpleChanges){
    console.log('ngOnChanges', changes);
  }

  public balanceCredit(entry: any): number {
    return (entry.saldo||0) - (entry.creditAssigned||0);
  }

  public async assignCredit(){
    const entries = this.entriesInTrainerAccount.filter(e => e.creditAssigned > 0).map(e => ({
      entry: e.entry,
      creditAssigned: e.creditAssigned,
      reference: `Settle credit received from entry ${this.entrySelected}.`
    }));
    if(!this.entrySelected || this.availableCredits <= 0 || this.usedCredits <= 0 || this.usedCredits > this.availableCredits || entries.length == 0) return;
    this.loading = true;
    const entrySelected = {
      entry: this.entrySelected,
      creditsUsed : this.usedCredits,
      reference: `Settle credits entries ${entries.map(e => e.entry).join(', ')}.`
    };
    const response: any = await this.entriesService.assignCredit(entrySelected, entries, this.idConcurso, this.idUsuario);
    if(response.error){
      this.loading = false;
      this.onError.emit(response.message||'');
      return;
    }

    this.loading = false;
    this.onSuccess.emit(response.message);
  }
}
