import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CaballoService } from '../services/caballo.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-merge-horses-manual',
  templateUrl: './merge-horses-manual.component.html',
  styleUrls: ['./merge-horses-manual.component.css']
})
export class MergeHorsesManualComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public horses: any[];
  public form: UntypedFormGroup;

  constructor(private router: Router, private authService: AuthService, private caballoService: CaballoService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.horses = [];
    this.form = new UntypedFormGroup({
      horse1: new UntypedFormControl('', [Validators.required]),
      horse2: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    $('#loader').hide();
  }

  public findHorses(e): void{
    const searchValue: string = e.target.value;
    if(searchValue.length < 3) return;

    this.caballoService.getCaballosFiltrados(searchValue, this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.horses = response.caballos;
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It has not been possible to query the horses, please try again.', 'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  public displayHorse(idCaballo): string{
    const horse = this.horses.find(p => p.id_caballo == idCaballo);

    return horse ? horse.fullname : idCaballo;
  }

  public filtroCaballos(): any[]{
    let horses = this.horses;
    if(this.form.value.horse1) horses = horses.filter(c => c.id_caballo != this.form.value.horse1);
    if(this.form.value.horse2) horses = horses.filter(c => c.id_caballo != this.form.value.horse2);

    return horses;
  }

  public mergeHorsesManual(): void{
    if(this.form.valid){
      this.form.disable();
      let horses = this.form.value;
      horses.idConcurso = this.idConcurso;
      this.caballoService.mergeHorsesManual(horses).subscribe(
        response => {
          console.log(response);
          if(!response.error){
            this.form.setValue({
              horse1: '',
              horse2: ''
            });
            $('form').removeClass('was-validated');
            this.horses = [];
            this.form.enable();
            $.NotificationApp.send("Success", "The merge has been successfully.", 'bottom-right', '#0acf97', 'success');
          } else{
            this.form.enable();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          this.form.enable();
          console.log(error);
          $.NotificationApp.send("Error", 'It has not been possible to merge these horses, please try again.', 'bottom-right', '#fa5c7c', 'error');
        }
      );
    }
  }
}
