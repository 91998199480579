import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service';
import { ResultadoService } from '../services/resultado.service';
import { ConcursoService } from '../services/concurso.service';
import { StripeService } from '../services/stripe.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-inscripcion-pendiente',
  templateUrl: './inscripcion-pendiente.component.html',
  styleUrls: ['./inscripcion-pendiente.component.css']
})
export class InscripcionPendienteComponent implements OnInit {
  public token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public idConcurso: string | boolean;
  public idSolicitud: string;
  public solicitud: any;
  public formPago: FormGroup;
  public formStable: FormGroup;
  public procesandoPago: boolean;
  public idUsuario: number;
  public logoClub: string;
  public entriesDue: string;
  public entryAmount: number;
  public taxId: string;
  public emergencyContact: FormGroup;
  public paymentDetails: any;
  public horse: any;
  public riders: any[];
  public owner: any;
  public trainer: any;
  public payee: any;

  public reining: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private inscripcionesRemotasService: InscripcionesRemotasService,
    private concursoService: ConcursoService,
    private resultadoService: ResultadoService,
    private sanitizer: DomSanitizer,
    private stripeService: StripeService
  ) {
    this.token = '';
    this.paymentDetails = {};
    this.sessionData = {};
    this.privilegios = 0;
    this.idConcurso = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.idSolicitud = '';
    this.solicitud = {};
    this.formPago = new FormGroup({
      notas: new FormControl('', [])
    });
    this.procesandoPago = false;
    this.idUsuario = 0;
    this.logoClub = '';
    this.formStable = new FormGroup({
      stableWith: new FormControl('', []),
      arrivalDate: new FormControl('', [])
    });
    this.entriesDue = '';
    this.entryAmount = 350;
    this.taxId = '';
    this.emergencyContact = new FormGroup({
      name: new FormControl('', []),
      phone: new FormControl('', []),
    });
    this.horse = {};
    this.riders = [];
    this.owner = {};
    this.trainer = {};
    this.payee = {};
    this.reining = sessionStorage.getItem('reining') == '1';
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
      this.idUsuario = this.sessionData.idUsuario;
    }
    //Ingate: 50
    if (this.authService.validarConcurso() && this.privilegios >= 50) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.route.params.subscribe(params => {
      this.idSolicitud = params['idSolicitud'];
    });
    this.getSolicitudPendiente();
    this.getConcurso();
  }

  public async getSolicitudPendiente() {
    const { data, error } = await firstValueFrom(this.inscripcionesRemotasService.getSolicitudPendiente(this.idSolicitud)).catch(error => ({ error }));
    if(error) {
      console.log(error);
      $.NotificationApp.send("ERROR!", "It was not possible to query the request.", 'bottom-right', '#fa5c7c', 'error', 5000);
      return;
    }

  this.solicitud = data;
  this.paymentDetails = await firstValueFrom(this.stripeService.getPaymentDetails(this.solicitud.pago.notas, this.idConcurso)).catch(error => {
    console.log(error);
    $.NotificationApp.send("ERROR!", "It was not possible to query the payment details.", 'bottom-right', '#fa5c7c', 'error', 5000);
    return error;
  });
  this.riders = (this.solicitud.jinetes_adicionales||[]).map(j => ({
    name: `${j.riderName||''} ${j.riderLastName||''}`,
    usef: j.riderUsef||(j.riderNrha||''),
    fei: j.riderFei||'',
    phone: j.riderPhone||'',
    email: j.riderEmail||'',
    address: `${j.riderAddress||''}. ${j.riderCity||''}, ${j.riderState||''} ${j.riderZip||''}`,
    signatureRider: this.sanitizer.bypassSecurityTrustHtml(this.getSignatureImage(j.signatureRider||(this.solicitud.signature_trainer||'')))
  }));
  this.horse = {
    name: this.solicitud.caballo.horseName||'',
    usef: this.solicitud.caballo.horseUsef||(this.solicitud.caballo.horseNrha||''),
    fei: this.solicitud.caballo.horseFei||'',
    number: this.solicitud.caballo.cucarda||''
  };
  this.owner = {
    name: `${this.solicitud.propietario.ownerName||''} ${this.solicitud.propietario.ownerLastName||''}`,
    usef: this.solicitud.propietario.ownerUsef||(this.solicitud.propietario.ownerNrha||''),
    phone: this.solicitud.propietario.ownerPhone||'',
    email: this.solicitud.propietario.ownerEmail||'',
    address: `${this.solicitud.propietario.ownerAddress||''}. ${this.solicitud.propietario.ownerCity||''}, ${this.solicitud.propietario.ownerState||''} ${this.solicitud.propietario.ownerZip||''}`,
    signatureOwner: this.sanitizer.bypassSecurityTrustHtml(this.getSignatureImage(this.solicitud.propietario.signatureOwner||(this.solicitud.signature_owner||'')))
  };
  this.trainer = {
    name: `${this.solicitud.entrenador.trainerName||''} ${this.solicitud.entrenador.trainerLastname||''}`,
    usef: this.solicitud.entrenador.trainerUsef||(this.solicitud.entrenador.trainerNrha||''),
    phone: this.solicitud.entrenador.trainerPhone||'',
    email: this.solicitud.entrenador.trainerEmail||'',
    address: `${this.solicitud.entrenador.trainerAddress||''}`,
    signatureTrainer: this.sanitizer.bypassSecurityTrustHtml(this.getSignatureImage(this.solicitud.entrenador.signatureTrainer||(this.solicitud.signature_trainer||'')))
  };
  this.payee = {
    name: `${this.solicitud.responsable.payeeName||''} ${this.solicitud.responsable.payeeLastName||''}`,
    address: this.solicitud.responsable.payeeAddress||'',
    usef: this.solicitud.responsable.payeeUsef||(this.solicitud.responsable.payeeNrha||''),
    phone: this.solicitud.responsable.payeePhone||'',
    email: this.solicitud.responsable.payeeEmail||''
  };
  this.formStable.setValue({
    stableWith: this.solicitud.otros.stableWith||'',
    arrivalDate: this.solicitud.otros.arrivalDate||''
  });
  this.taxId = this.solicitud.tax_id;
  this.emergencyContact.setValue({
    name: this.solicitud.emergency_contact.name||'',
    phone: this.solicitud.emergency_contact.phone||''
  });
  this.solicitud.cargos = (this.solicitud.cargos || []).map(c => ({...c,
    nombre: c.nombre||c.name,
    monto: c.monto||c.amount,
    qty: c.qty||c.quantity||0,
    total: c.total,
  }));
  this.solicitud.pago.metodo = this.solicitud.pago.metodo == 'tarjeta' ? 'card' : this.solicitud.pago.metodo;

  }

  public getSignatureImage(signature: string): string {
    if(signature && signature.includes('xmlns') && signature.includes('svg')){
      const parser = new DOMParser();
      const doc: any = parser.parseFromString(signature, "image/svg+xml");
      const svgElement = doc.documentElement;
      svgElement.setAttribute("viewBox",  '0 0 100 150');
      svgElement.setAttribute("width",  '100%');
      svgElement.setAttribute("height",  '150');
      return svgElement.outerHTML;
    }
    return '';
  }

  public getConcurso() {
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.entriesDue = response.concurso.entriesDue;
          this.logoClub = response.concurso.logoClub;
          this.entryAmount = response.concurso.entryAmount;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        $.NotificationApp.send("Error", 'The show data could not be consulted', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  /*public pagarConCheque() {
    this.procesandoPago = true;
    const pago = {
      pagado: true,
      metodo: 'cheque',
      notas: this.formPago.value.notas,
      trasactionId: '',
      recibidoPor: this.idUsuario
    };
    const estatus = 1;
    /*
      0: Sin pagar sin aceptar
      1: Pagado sin aceptar
      2: Aceptado (Solo se aceptan los pagados)
    /
    this.inscripcionesRemotasService.actualizarPagoSolicitud(this.idSolicitud, pago, estatus).subscribe(
      response => {
        if (!response.error) {
          this.solicitud.pago = pago;
          this.solicitud.estatus = estatus;
          this.procesandoPago = false;
          $.NotificationApp.send("Success!", 'The payment has been registered in the application successfully, it will be reflected in the payee account once the application for registration is accepted.', 'bottom-right', '#07926b', 'success', 8000);
        } else {
          $.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
          this.procesandoPago = false;
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "It has not been possible to register the payment, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
        this.procesandoPago = false;
      }
    );
  }*/

  public aceptarSolicitud() {
    if (this.solicitud.estatus == 1 || this.solicitud.estatus == 4) {
      if (!this.horse.number || isNaN(this.horse.number)) {
        $.NotificationApp.send("ERROR!", "Please enter an entry number.", 'bottom-right', '#fa5c7c', 'error', 5000);
        return;
      }

      this.inscripcionesRemotasService.aceptarSolicitud(this.solicitud, this.horse.number, this.idUsuario).subscribe(
        response => {
          if (!response.error) {
            this.solicitud.estatus = 2;
            $.NotificationApp.send("Accepted!", 'The request has been accepted successfully.', 'bottom-right', '#0acf97', 'success', 5000);
            this.resultadoService.guardarConcursoFirestore(this.idConcurso).toPromise();
            this.router.navigate(['/online-entries']);
            return;
          } else {
            $.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
          }
        },
        error => {
          console.log(error);
          $.NotificationApp.send("ERROR!", "It has not been possible to accept the request, please try again. Error: " + error, 'bottom-right', '#fa5c7c', 'error', 5000);
          this.procesandoPago = false;
        }
      );
    } else {
      $('form').addClass('was-validated');
    }
  }

  public markAsSigned() {
    this.inscripcionesRemotasService.markAsSigned(this.idSolicitud).subscribe(
      response => {
        if (!response.error) {
          this.solicitud.estatus = 3;
          $.NotificationApp.send("marked as signed!", 'The request has been marked as signed successfully.', 'bottom-right', '#0acf97', 'success', 5000);
        } else {
          $.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
        }
        console.log(response);
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "It has not been possible to mark as signed, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
      }
    );
  }

  public markAsPrinted() {
    this.inscripcionesRemotasService.markAsPrinted(this.idSolicitud).subscribe(
      response => {
        if (!response.error) {
          this.solicitud.estatus = 4;
          $.NotificationApp.send("marked as printed!", 'The request has been marked as printed successfully.', 'bottom-right', '#0acf97', 'success', 5000);
        } else {
          $.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
        }
        console.log(response);
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "It has not been possible to mark as printed, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
      }
    );
  }

  public markAsCancelled() {
    this.inscripcionesRemotasService.markAsCancelled(this.idSolicitud).subscribe(
      response => {
        if (!response.error) {
          this.solicitud.estatus = 4;
          $.NotificationApp.send("marked as cancelled!", 'The request has been marked as cancelled successfully.', 'bottom-right', '#0acf97', 'success', 5000);
        } else {
          $.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
        }
        console.log(response);
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "It has not been possible to mark as cancelled, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
      }
    );
  }

  public totalCargos(): number {
    if (this.solicitud.cargos) {
      return this.solicitud.cargos.reduce((sum, cargo) => {
        return sum + parseFloat(cargo.total||'0');
      }, 0);
    }

    return 10;
  }

  public numerosPruebas(pruebas: any[]): string {
    if(!pruebas) return '';
    return pruebas.map(p => p.numero).join(', ');
  }

  getSafeUrl(url): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
