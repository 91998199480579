import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable, catchError, firstValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchErrorMessage, handleError } from '.';
import { ADD_AUTH_HEADER } from '../http-interceptors';

@Injectable()
export class StripeService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getEntryInfo(showId: string | number): Promise<any>{
    const headers = { 'x-api-key': environment.api.key, 'Content-Type': 'application/json' };
    const params = JSON.stringify({ idConcurso: showId });
    return firstValueFrom(this.http.post(`${environment.api.uri}concursos/getEntryInfo/`, params, { headers }));
  }

  public createPaymentIntent(opts: CreatePaymentIntentOptions): Promise<any> {
    let params = { ...opts, currency: opts.currency||'usd', customer: opts.customer||'', description: opts.description||'', email: opts.email||'', metadata: {...(opts.metadata||{}), show: opts.idConcurso }, paymentMethod: opts.paymentMethod||'', sr: this.sr, test: this.test };
    if(opts.paymentMethodTypes) params.paymentMethodTypes = opts.paymentMethodTypes;
    return firstValueFrom(this.http.post(`${environment.api.uri}/stripe/PaymentIntent/create.php`, params));
  }

  public getCustomerPaymentMethods(customerId: string, showId: string | number): Promise<any>{
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}stripe/customers/payment_methods/${showId}/${customerId}`, { ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public updateCardholderName(paymentMethodId, name, showId): Observable<any> {
    const params = JSON.stringify({ paymentMethodId, name, showId });
    return this.http.post(`${environment.api.uri}stripe/PaymentMethods/update.php`, params);
  }

  public  createSetupIntent(showId: string, customer: string, paymentMethod: string): Observable<any> {
    const params = JSON.stringify({ showId, customer, paymentMethod, sr: this.sr, test: this.test });
    return this.http.post(`${environment.api.uri}stripe/SetupIntent/create.php`, params);
  }

  public getPaymentInfo(paymentIntentId, showId): Observable<any> {
      const params = JSON.stringify({ showId, paymentIntentId});
      return this.http.post(`${environment.api.nest.uri}stripe/getPaymentInfo`, params);
  }

  public searchCustomers(emails: string[], showId: string | number, test: boolean = false): Observable<any> {
    const params = JSON.stringify({ emails, showId, test });
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.http.post(`${environment.api.nest.uri}stripe/searchCustomers`, params, { headers });
  }

  public getPaymentDetails(paymentIntentId: string, showId: any): Observable<any> {
    const params = JSON.stringify({ paymentIntentId, showId , test:false});
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.http.post(`${environment.api.nest.uri}stripe/paymentIntent`, params, { headers });
  }
}
