<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
            <div class="container-fluid" style="width: 100%; float: left; display: flex;">
                <div class="card panel-club" style="margin-top:40px" [style.width]="(panelAgregar) ? '70%': '100%'">
                    <div class="card-header bg-dark text-white" i18n="@@titleClubes">Clubes</div>
                    <div class="card-body">
                        <div class="col-11 float-left example-header">
                            <input type="text" class="form-control" (keyup)="applyFilter($event.target.value)" i18n-placeholder="@@placeholderBuscarClub" placeholder="Buscar">
                        </div>
                        <div class="col-1 float-left" style="margin-top: 11px; margin-left: -20px;">
                            <button class="btn btn-icon no-shadow padding-0 float-right" style="font-size:1.875rem; margin-top:-5px; margin-bottom:-15px"  *ngIf="!panelAgregar" data-toggle="tooltip" title="Agregar nuevo club" (click)="mostrarPanelAgregar()">
                                <i class="mdi mdi-plus-circle" style="line-height: 0.5 !important; color: #0acf97 !important;"></i>
                            </button>
                            <button class="btn btn-icon no-shadow padding-0 float-right" style="font-size:1.875rem; margin-top:-5px; margin-bottom:-15px" *ngIf="panelAgregar" data-toggle="tooltip" title="Cancelar" (click)="mostrarPanelAgregar()">
                                <i class="mdi mdi-minus-circle" style="line-height: 0.5 !important; color: #fa5c7c!important"></i>
                            </button>
                        </div>
                        <mat-table #table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="Nombre">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" i18n="@@thClub">Nombre del club
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">{{(row.club) ? row.club : '-'}}</mat-cell>
                            </ng-container>
                            <!--ng-container matColumnDef="Correo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">eMail
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">{{(row.email) ? row.email : '-'}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Telefono">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" i18n="@@thTelefonoClub">Teléfono
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">{{(row.phone) ? row.phone : '-'}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="FEM">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">FEM
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">{{(row.fem) ? row.fem : '-'}}</mat-cell>
                            </ng-container-->
                            <ng-container matColumnDef="Siglas">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" i18n="@@thSiglasClub">Siglas
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">{{(row.siglas) ? row.siglas : '-'}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Ciudad">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" i18n="@@thCiudadClub">Ciudad
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">{{(row.ciudad) ? row.ciudad : '-'}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Estado">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" i18n="@@thEstadoClub">Estado
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">{{(row.estado) ? row.estado : '-'}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Pais">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" i18n="@@thPaisClub">Pais
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">{{(row.pais) ? row.pais : '-'}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Logo">
                              <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Logo
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row"><img src="{{(row.logo_url) ? row.logo_url : '-'}}" width="50" />
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Editar">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" i18n="@@thEditarClub">Editar
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <button mat-icon-button (click)='modificarClub(row)'>
                                        <mat-icon>create</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Eliminar">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" i18n="@@thEliminarClub">Eliminar
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <button mat-icon-button>
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
                    </div>
                </div>
                <div class="card panel-agregar" style="margin-top:40px" [style.margin-left]="(panelAgregar) ? '15px': '0'" [style.width]="(panelAgregar) ? '40%': '0'">
                    <div class="card-header bg-dark text-white" *ngIf="idClubSeleccionado" i18n="@@tituloActualizarClub">Actualizar club</div>
                    <div class="card-header bg-dark text-white" *ngIf="!idClubSeleccionado" i18n="@@tituloAgregarClub">Agregar club</div>
                    <div class="card-body">
                        <form #clubForm="ngForm" (ngSubmit)="onSubmitQuery()">
                            <div class="row2">
                                <div class="col2">
                                    <div class="form-group {{(validadorNombreClub.invalid && (validadorNombreClub.dirty || validadorNombreClub.touched)) ? 'has-error' : '' }}
                                                            {{(validadorNombreClub   .valid && (validadorNombreClub .dirty || validadorNombreClub    .touched)) ? 'has-success' : '' }}">
                                        <label i18n="@@labelNombreClub">Nombre del club<b style="color:red;">*</b></label>
                                        <input type="text" i18n-placeholder="@@placeholderNombreClub" placeholder="Ingrese el nombre" class="form-control" [formControl]="validadorNombreClub" required>
                                    </div>
                                </div>
                                <div class="col2">
                                    <div class="form-group {{(validadorCorreo.invalid && (validadorCorreo.dirty || validadorCorreo.touched)) ? 'has-error' : '' }}
                                                            {{(validadorCorreo.valid && (validadorCorreo.dirty || validadorCorreo.touched)) ? 'has-success' : '' }}">
                                        <label>eMail<b style="color:red;">*</b></label>
                                        <input type="text" i18n-placeholder="@@placeholderEMailClub" placeholder="Ingrese el correo" class="form-control" [formControl]="validadorCorreo" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row2">
                                <div class="col2">
                                    <div class="form-group {{(validadorTelefono.invalid && (validadorTelefono.dirty || validadorTelefono.touched)) ? 'has-error' : '' }}
                                                            {{(validadorTelefono.valid && (validadorTelefono.dirty || validadorTelefono.touched)) ? 'has-success' : '' }}">
                                        <label i18n="@@labelTelefonoClub">Teléfono<b style="color:red;">*</b></label>
                                        <input type="text" i18n-placeholder="@@placeholderTelefonoClub" placeholder="Ingrese el teléfono" class="form-control" [formControl]="validadorTelefono" required>
                                    </div>
                                </div>
                                <div class="col2">
                                    <div class="form-group {{(validadorFem.invalid && (validadorFem.dirty || validadorFem.touched)) ? 'has-error' : ''  }}
                                                            {{(validadorFem.valid && (validadorFem.dirty || validadorFem.touched)) ? 'has-success' : '' }}">
                                        <label>FEM<b style="color:red;">*</b></label>
                                        <input type="text" i18n-placeholder="@@placeholderFemClub" placeholder="Ingrese el código de FEM" class="form-control" [formControl]="validadorFem" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row2">
                                <div class="col2">
                                    <div class="form-group {{(validadorSiglas.invalid && (validadorSiglas.dirty || validadorSiglas.touched)) ? 'has-error' : '' }}
                                                            {{(validadorSiglas.valid && (validadorSiglas.dirty || validadorSiglas.touched)) ? 'has-success' : '' }}">
                                        <label i18n="@@labelSiglasClub">Siglas<b style="color:red;">*</b></label>
                                        <input type="text" i18n-placeholder="@@placeholderSiglasClub" placeholder="Ingrese las siglas del club" class="form-control" [formControl]="validadorSiglas" required>
                                    </div>
                                </div>
                                <div class="col2">
                                    <div class="form-group {{(validadorCiudad.invalid && (validadorCiudad.dirty || validadorCiudad.touched)) ? 'has-error' : '' }}
                                                            {{(validadorCiudad.valid && (validadorCiudad.dirty || validadorCiudad.touched)) ? 'has-success' : '' }}">
                                        <label i18n="@@labelCiudadClub">Ciudad<b style="color:red;">*</b></label>
                                        <input type="text" i18n-placeholder="@@placeholderCiudadClub" placeholder="Ingrese la ciudad" class="form-control" [formControl]="validadorCiudad" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row2">
                                <div class="col2">
                                    <div class="form-group {{(validadorEstado.invalid && (validadorEstado.dirty || validadorEstado.touched)) ? 'has-error' : '' }}
                                                            {{(validadorEstado.valid && (validadorEstado.dirty || validadorEstado.touched)) ? 'has-success' : '' }}">
                                        <label i18n="@@labelEstadoClub">Estado<b style="color:red;">*</b></label>
                                        <input type="text" i18n-placeholder="@@placeholderEstadoClub" placeholder="Ingrese el estado" class="form-control" [formControl]="validadorEstado" required>
                                    </div>
                                </div>
                                <div class="col2">
                                    <div class="form-group {{(validadorPais.invalid && (validadorPais.dirty || validadorPais.touched)) ? 'has-error' : '' }}
                                                            {{(validadorPais.valid && (validadorPais.dirty || validadorPais.touched)) ? 'has-success' : '' }}">
                                        <label i18n="@@labelPaisClub">Pais<b style="color:red;">*</b></label>
                                        <input type="text" i18n-placeholder="@@placeholderPaisClub" placeholder="Seleccione el pais" class="form-control" [formControl]="validadorPais" [matAutocomplete]="autocompletePais" required>
                                        <mat-autocomplete #autocompletePais="matAutocomplete" [displayWith]="displayPais">
                                            <mat-option *ngFor="let pais of paisArray | filterPais : queryPais" [value]="pais">
                                                <img src="/assets/img/banderas/{{ ((pais.siglas) ? pais.siglas : '-') | lowercase }}.jpg" aria-hidden height="25" style="vertical-align:middle;" onerror="this.src='assets/img/sin-bandera.jpg'">
                                                <span>{{ (pais.pais) ? pais.pais : '-' }}</span>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                            </div>
                            <div class="row2">
                              <div class="col2">
                                <label for="logo_url" style="line-height: 150px;">
                                  <div>
                                    <button class="btn btn-success" type="button" onclick="$('#logo_url').click();" i18n="@@AgregarLogo">Agregar logo</button>
                                  </div>
                                </label>
                                <input id="logo_url" name="logo_url" type="file" accept="image/png" style="display: none" (change)="subirImagen($event, 'logos')">
                              </div>
                              <div class="col2">
                                <img src="{{logo_url}}" style="min-height: 150px;max-height: 150px;">
                              </div>
                            </div>
                            <div style="width: 100%; text-align: center; margin-top: 30px;">
                                <button class="btn btn-outline-light" style="margin-right: 30px;color: black;" (click)="cerrarPanel()" type="button" i18n="@@botonCancelarClub">Cancelar</button>
                                <button class="btn btn-outline-success" type="submit" *ngIf="idClubSeleccionado" i18n="@@botonActualizarClub">Actualizar</button>
                                <button class="btn btn-outline-success" type="submit" *ngIf="!idClubSeleccionado" i18n="@@botonAgregarClub">Agregar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
