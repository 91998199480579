<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid" style="width: 100%; float: left; display: flex;">
        <div class="col-12" style="padding:0">
          <div class="col-12" style="margin-top: 30px">
            <div class="card">
              <div class="card-body" style="padding:0">
                <div class="col-12" style="margin-top:30px;">
                  <div class="form-group col-6 float-left">
                    <label>Class</label>
                    <select class="form-control" (change)="getBinomiosPruebasConcurso($any($event).target.value)">
                      <option value="">Select a class</option>
                      <option *ngFor="let prueba of pruebasConcurso" [value]="prueba.ipc">{{ prueba.numero_prueba }} - {{ prueba.nombre }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 float-left">
                  <button type="button" class="btn btn-block btn-xs btn-success" *ngIf="videosPendientes > 0" (click)="getBinomiosPruebasConcurso()">
                    New clips to upload. Click here to refresh.
                  </button>
                </div>
                <div class="col-12 float-left">
                  <div class="widget widget-fullwidth widget-small table-responsive">
                    <table class="table table-fw-widget table-hover table-sm">
                      <thead>
                        <tr>
                          <th>Entry</th>
                          <th>Results</th>
                          <th>Upload Clip 1</th>
                          <th>Url Clip 1</th>
                          <th>Hide Clip 1</th>
                          <th>Upload Clip 2</th>
                          <th>Url Clip 2</th>
                          <th>Hide Clip 2</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr #allTheseThings *ngFor="let binomio of binomios_pruebas_concurso; let i = index; let last = last" id="row-{{binomio.id_binomio}}">
                          <!--1. Entry -->
                          <td>
                            <div style="float:left;">
                              {{ i + 1 }}.-
                            </div>
                            <div style="float:left;">
                              {{ binomio.jinete }} <br>
                              {{ binomio.caballo}}
                            </div>
                          </td>
                          <!-- 2. Results -->
                          <td>
                            <ng-container *ngIf="binomio.result1">
                              {{ binomio.result1 }}
                            </ng-container>
                            <ng-container *ngIf="binomio.result2">
                              <br />
                              {{ binomio.result2 }}
                            </ng-container>
                          </td>
                          <!-- 3. input video 1 -->
                          <td>
                            <ng-container *ngIf="binomio.result1">
                              <div class="dropZone" *ngIf="!loading.get(binomio.id_binomio).loading1;else spinner">
                                <input type="file" accept=".mp4" (change)='fileDropped($event, binomio.id_binomio, 1)'>
                                <img style="height:25px;" src="assets/images/icons/folder.svg" alt="select video file">
                                <div>{{ loading.get(binomio.id_binomio).error1 || 'Drag and drop file here or browse for file'}}</div>
                              </div>
                            </ng-container>
                          </td>
                          <!-- 4. url video 1 -->
                          <td class="table-action">
                            <a [href]="binomio.video_app" target="_blank" class="action-icon" *ngIf="binomio.video_app">
                              <i class="mdi mdi-link mdi-24px"></i>
                            </a>
                          </td>
                          <!-- 5. Hidden video 1 -->
                          <td class="table-action">
                            <a href="javascript:;" class="action-icon" *ngIf="binomio.video_app" (click)="habilitarAcceso(binomio.id_binomio, 1,binomio.bloqueo1)">
                              <i class="mdi mdi-video mdi-24px text-success" *ngIf="!binomio.bloqueo1"></i>
                              <i class="mdi mdi-video-off mdi-24px text-danger" *ngIf="binomio.bloqueo1"></i>
                            </a>
                          </td>
                          <!-- 6. input video 2 -->
                          <td>
                            <ng-container *ngIf="binomio.result2">
                              <div class="dropZone" *ngIf="!loading.get(binomio.id_binomio).loading2;else spinner">
                                <input type="file" accept=".mp4" (change)='fileDropped($event, binomio.id_binomio, 2)'>
                                <img style="height:25px;" src="assets/images/icons/folder.svg" alt="select video file">
                                <div>{{ loading.get(binomio.id_binomio).error2 || 'Drag and drop file here or browse for file'}}</div>
                              </div>
                            </ng-container>
                          </td>
                          <!-- 7. url video 2 -->
                          <td class="table-action">
                            <a [href]="binomio.video_app_2" target="_blank" class="action-icon" *ngIf="binomio.video_app_2">
                              <i class="mdi mdi-link mdi-24px"></i>
                            </a>
                          </td>
                          <!-- 8. hidden video 2 -->
                          <td class="table-action">
                            <a href="javascript:;" class="action-icon" *ngIf="binomio.video_app_2" (click)="habilitarAcceso(binomio.id_binomio, 2,binomio.bloqueo2)">
                              <i class="mdi mdi-video mdi-24px text-success" *ngIf="!binomio.bloqueo2"></i>
                              <i class="mdi mdi-video-off mdi-24px text-danger" *ngIf="binomio.bloqueo2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
</ng-template>
