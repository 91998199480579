<!--div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div-->
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="w-100">
    <div class="content-page">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid">
        <div class="row w-100 mx-0 mt-3 m-print-0">
          <div class="col-12">
            <div class="card">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 d-print-none">
                    <ul class="nav nav-tabs">
                      <li class="nav-item" *ngFor="let dia of days" (click)="seleccionarDia(dia)">
                        <a class="nav-link" [ngClass]="{'active': diaSeleccionado == dia}" href="javascript:;">{{ (dia | date) ||'No date' }}</a>
                      </li>
                    </ul>
                  </div>
                  <ng-container *ngFor="let dia of pruebasPista">
                    <ng-container *ngIf="dia.nombre == diaSeleccionado">
                      <div class="col-12 d-print-none text-right mt-3 m-print-0">
                        <a class="btn btn-info btn-sm" href="javascript:;" [routerLink]="['/day-plan-print', dia.nombre]"><i class="mdi mdi-printer mr-1"></i> Print</a>
                      </div>
                      <div class="col-4">
                        <h6 class="text-center mb-2">{{ (dia.nombre | date:'EEEE, M/d/yy') ||'No date' }}
                        </h6>
                      </div>
                      <div class="col-4 text-center">
                        <img [src]="logoClub" height="50">
                      </div>
                      <div class="col-4">
                        Entries in day: {{ dia.inscritos }}
                        <ng-container *ngIf="dia?.inscritos_us > 0">
                          <br>
                          Entries U/S in day: {{ dia.inscritos_us }}
                        </ng-container>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12" *ngFor="let pista of dia.pistas"><!-- col-xl-6 -->
                            <div class="card">
                              <h5 class="card-header d-flex justify-content-between d-print-none">
                                <span>{{ pista.nombre || 'No ring'}}</span>
                                <span>
                                  Entries: {{ pista.inscritos }}
                                  <ng-container *ngIf="pista?.inscritos_us > 0">
                                    <br>
                                    Entries U/S: {{ pista.inscritos_us }}
                                  </ng-container>
                                </span>
                                <button type="button" class="btn btn-success btn-sm d-print-none" (click)="save(dia.nombre, pista.nombre)" [disabled]="isSaving" *ngIf="dia.nombre && pista.nombre">
                                  <span class="loader" *ngIf="isSaving"></span>
                                  Save
                                </button>
                              </h5>
                              <div class="card-body">
                                <table class="table table-sm table-classes">
                                  <thead>
                                    <tr class="nombre-pista d-none">
                                      <th class="text-center" colspan="3">
                                        <div class="d-flex justify-content-between">
                                          <span>{{ pista.nombre || 'No ring' }}</span>
                                          <span>Ring Entries: {{ pista.inscritos }}</span>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="d-print-none"></th>
                                      <th class="times">Times</th>
                                      <th class="className">Class</th>
                                      <th class="text-center">Entries</th>
                                      <!--th class="d-print-none">Time per horse</th-->
                                      <th class="d-print-none">Adjust</th>
                                      <th class="d-print-none text-center">
                                        <span class="d-none d-lg-inline d-print-inline">Duration</span>
                                        <span class="d-inline d-lg-none d-print-none">Dur.</span>
                                      </th>
                                      <th class="d-print-none text-center" *ngIf="dia.nombre && pista.nombre">Hard start</th>
                                      <th class="d-print-none">Hide <br class="d-block d-lg-none d-print-none"> start</th>
                                      <th class="d-print-none" *ngIf="!dia.nombre">Day</th>
                                      <th class="d-print-none" *ngIf="!pista.nombre">Ring</th>
                                      <th class="d-print-none" *ngIf="dia.nombre && pista.nombre">Break</th>
                                      <th class="d-print-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody class="list-drag-drop" cdkDropList [cdkDropListData]="pista.pruebas" (cdkDropListDropped)="drop($event)">
                                    <tr class="d-print-none">
                                      <td colspan="4">
                                        <form class="needs-validation" novalidate>
                                          <div class="input-group">
                                            <input [id]="'input-class-'+(pista.nombre||'No ring')" (keyup)="enterClassNumber($event, dia.nombre, pista.nombre)"  type="text" name="numero-prueba" class="form-control form-control-sm" placeholder="Add class by typing class number" style="max-width: 250px;">
                                            <button class="btn btn-sm btn-success" (click)="agregarPrueba(dia.nombre, pista.nombre)" type="button">Add</button>
                                          </div>
                                        </form>
                                      </td>
                                    </tr>
                                    <ng-container *ngFor="let prueba of pista.pruebas; let first = first;">
                                      <tr cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="first">
                                        <td class="table-action d-print-none">
                                          <a href="javascript: void(0);" class="action-icon" *ngIf="!first" cdkDragHandle>
                                            <i class="mdi mdi-arrow-all"></i>
                                          </a>
                                        </td>
                                        <td class="d-none"> {{ calcMinXPrueba( prueba ) }} </td>
                                        <td style="font-size: .8rem;">
                                          <span [ngClass]="{'d-print-none': prueba.hide_start}">
                                            <span>{{ calcInicioPrueba( prueba, pista.nombre, dia.nombre ) | date: 'h:mm a' }} </span>
                                            <span class="d-none d-lg-inline-flex d-print-inline-flex">  </span>
                                            <br class="d-block d-lg-none d-print-none">
                                            <span class="d-print-none">- {{ calcFinPrueba( prueba.numero, prueba.inicio, prueba.break ) | date: 'h:mm a' }}</span>
                                          </span>
                                        </td>
                                        <td><b>{{ prueba.numero }}</b>
                                          <span class="d-none d-lg-inline d-print-inline"> - {{ prueba.prueba | slice:0:30 }}</span>
                                          <span class="d-none d-lg-inline d-print-inline">{{ prueba.prueba | slice:30:129 }}</span>
                                        </td>
                                        <td class="text-center"> {{ prueba.us ? prueba.inscritos_us : prueba.inscritos }} </td>
                                        <!--td class="d-print-none"> {{ prueba.us ? '--' : prueba.minXCaballo }} </td-->
                                        <td class="d-print-none">
                                          <input type="number" class="form-control form-control-sm" [value]="prueba.minXCaballo" min="0.1" max="10.0" step="0.1" (change)="setTiempoXCaballo( $event, dia.nombre, pista.nombre, prueba.ipc, prueba.numero )" style="width: 45px; padding: 0 0 0 5px;">
                                        </td>
                                        <td class="d-print-none text-center"> {{ calcMinXPrueba( prueba ) }} </td>
                                        <td class="d-print-none text-center" *ngIf="dia.nombre && pista.nombre">
                                          <div class="d-flex">
                                            <div class="my-auto form-check">
                                              <input type="checkbox" class="form-check-input" id="cbox1" [(ngModel)]="prueba.hard_start" (change)="selectTiempoFijo(prueba.hard_start, prueba.numero)">
                                              <label class="form-check-label" for="exampleCheck1"></label>
                                            </div>
                                            <input type="time" class="form-control form-control-sm" id="inicioFijo{{ prueba.numero }}" name="inicioFijo{{ prueba.numero }}" min="07:00" max="18:00" (input)="setTiempoFijo( $event, prueba.numero )" [disabled]="!prueba.hard_start" [value]="prueba.horaHardStart" style="width: fit-content;">
                                          </div>
                                        </td>
                                        <td class="d-print-none text-center">
                                          <div class="mb-3 form-check">
                                            <input type="checkbox" class="form-check-input" [(ngModel)]="prueba.hide_start">
                                            <label class="form-check-label"></label>
                                          </div>
                                        </td>
                                        <td class="d-print-none" *ngIf="!dia.nombre">
                                          <select class="form-control form-control-sm" (change)="setDate($event, prueba.ipc, dia.nombre, prueba.pista)">
                                            <option value=""></option>
                                            <option [value]="dia" *ngFor="let dia of days">{{ dia | date }}</option>
                                          </select>
                                        </td>
                                        <td class="d-print-none" *ngIf="!pista.nombre">
                                          <select class="form-control form-control-sm" (change)="setRing($event, prueba.ipc, prueba.pista, dia.nombre)">
                                            <option value=""></option>
                                            <option [value]="pista" *ngFor="let pista of pistas">{{ pista }}</option>
                                          </select>
                                        </td>
                                        <td class="d-print-none" *ngIf="dia.nombre && pista.nombre">
                                          <input type="number" class="form-control form-control-sm" [(ngModel)]="prueba.break" style="width: 50px; padding: 0 0 0 5px;">
                                        </td>
                                        <td class="table-action text-center d-print-none">
                                          <a href="javascript: void(0);" class="action-icon" (click)="clearTimes(prueba.ipc, dia.nombre, pista.nombre)">
                                            <i class="mdi mdi-calendar-remove"></i>
                                          </a>
                                        </td>
                                      </tr>
                                    </ng-container>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
