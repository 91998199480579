import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { PruebaService } from '../services/prueba.service';
import { firstValueFrom } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.css']
})
export class PrizesComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public pruebas: any[];
  public ipc: string;
  public premios: any[];
  public altura: string;
  public categorias: string;
  public fecha: string;
  public modalidad: string;
  public nombre: string;
  public premio: string;
  public premiosPagarAparte: any[];
  public prize_distribution: number[];
  public name_prize_distribution: string;
  public type_prize_distribution: string;
  public id_modalidad: string;

  constructor(private authService: AuthService, private router: Router, private pruebaService: PruebaService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.pruebas = [];
    this.ipc = '';
    this.premios = [];
    this.altura = '';
    this.categorias = '';
    this.fecha = '';
    this.modalidad = '';
    this.nombre = '';
    this.premio = '';
    this.premiosPagarAparte = [];
    this.prize_distribution = [];
    this.name_prize_distribution = '';
    this.type_prize_distribution = '';
    this.id_modalidad = '';
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
    this.getPremiosPagarAparte();
  }

  public getPruebas(): void{
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.pruebas = response.pruebas.filter(p => (p.premio > 0 && p.estatus_prueba == '2'));
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public seleccionarPrueba(e): void{
    this.ipc = e.target.value;
    const prueba = this.pruebas.find(p => p.ipc == this.ipc);
    if(prueba){
      this.prize_distribution = prueba.prize_distribution;
      this.name_prize_distribution = prueba.name_prize_distribution;
      this.type_prize_distribution = prueba.type_prize_distribution;
    }
    $('#loader').show();
    this.pruebaService.getPremios(this.ipc).subscribe(
      response => {
        if(!response.error){
          this.altura = response.prueba.altura;
          this.categorias = response.prueba.categorias;
          this.fecha = response.prueba.fecha;
          this.modalidad = response.prueba.modalidad;
          this.nombre = response.prueba.nombre;
          this.premio = response.prueba.premio;
          this.premios = response.prueba.premios;
          this.id_modalidad = response.prueba.id_modalidad;
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the prizes.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public plantillaPremios(){
    //Lista de descuentos segun plantilla (25 o 30)
    const premio = parseFloat(this.premio);
    let posicionAnterior = 0;
    let sumaEmpate = 0;
    let indices = [];
    for(let i in this.premios){
      if(parseInt(i) < 12 && this.premios[i].estatus == '0'){
        if(this.premios[i].posicion == posicionAnterior){
          sumaEmpate += this.type_prize_distribution == 'percentage' ? parseFloat((premio * this.prize_distribution[i]).toFixed(2)) : parseFloat((this.prize_distribution[i]||0).toFixed(2));
          indices.push(i);
        } else{
          if(indices.length > 1){
            for(const indice of indices){
              this.premios[indice].monto = parseFloat((sumaEmpate / indices.length).toFixed(2));
              this.premios[indice].plantillaPremio = this.premios[indice].monto > 0;
            }
          }

          //Despues del if hace esto
          sumaEmpate = this.type_prize_distribution == 'percentage' ? parseFloat((premio * this.prize_distribution[i]).toFixed(2)) : parseFloat((this.prize_distribution[i]||0).toFixed(2));
          indices = [i];
        }
        this.premios[i].monto = this.type_prize_distribution == 'percentage' ? parseFloat((premio * this.prize_distribution[i]).toFixed(2)) : parseFloat((this.prize_distribution[i]||0).toFixed(2));
        this.premios[i].plantillaPremio = this.premios[i].monto > 0;
      }
      posicionAnterior = parseInt(this.premios[i].posicion);
    }
  }

  public totalAsignado(): number{
    return this.premios.reduce((a, b) => {
      return a + b.monto;
    }, 0);
  }
  public guardarPremio(premio, index): void{
    if(premio.monto > 0){
      $('#loader').show();
      const deduccion = this.premios[index].deduccion;
      this.premios[index].montoAPagar = premio.monto - (premio.monto * deduccion);

      const datos = {
        idUsuario: this.idUsuario,
        ipc: this.ipc,
        idPremio: premio.idPremio,
        idBinomio: premio.idBinomio,
        nombre: premio.posicion,
        monto: premio.monto,
        deduccion: premio.deduccion,
        pagarAparte: premio.pagarAparte ? 1 : 0,
        referencia: premio.referencia
      };

      this.pruebaService.agregarPremio(datos).subscribe(
        response => {
          if (!response.error) {
            const index = this.premios.findIndex(premio => premio.idBinomio == response.premio.idBinomio);

            response.premio.deduccion *= 100;
            response.premio.deduccion = parseFloat(response.premio.deduccion.toFixed(2));
            response.premio.plantillaPremio = false;

            this.premios[index] = response.premio;
            console.log(response);
            this.getPremiosPagarAparte();
            $('#loader').hide();
            $.NotificationApp.send("Saved", "The prize has been saved.", 'bottom-right', '#06d5a1', 'success');
          } else {
            $('#loader').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader').hide();
          $.NotificationApp.send("Error", "It has not been possible to save the prize.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      $.NotificationApp.send("Invalid prize", "The amount of the prize must be greater than zero", 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public eliminarPremio(idPremio) {
    this.pruebaService.eliminarPremio(idPremio).subscribe(
      response => {
        if (!response.error) {
          this.seleccionarPrueba({target:{ value: this.ipc }});
          $.NotificationApp.send("Deleted", "The prize has been deleted.", 'bottom-right', '#06d5a1', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to delete the prize", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getPremiosPagarAparte() {
    this.pruebaService.getPremiosPagarAparte(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.premiosPagarAparte = response.premios;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the prizes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public guardarReferenciaPremio(idPremio, e) {
    const referencia = e.target.value;

    this.pruebaService.guardarReferenciaPremio(idPremio, referencia).subscribe(
      response => {
        if (!response.error) {
          this.getPremiosPagarAparte();
          $.NotificationApp.send("Updated", "The prize has been updated", 'bottom-right', '#06d5a1', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It was not possible to update the prize", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public confirmAllPrizes(): void{
    $('#loader').show();
    const premios = this.premios.filter(p => p.plantillaPremio && p.monto > 0).map(p => {
      const datos = {
        idUsuario: this.idUsuario,
        ipc: this.ipc,
        idPremio: p.idPremio,
        idBinomio: p.idBinomio,
        nombre: p.posicion,
        monto: p.monto,
        deduccion: p.deduccion,
        pagarAparte: p.pagarAparte ? 1 : 0,
        referencia: p.referencia
      };

      return firstValueFrom(this.pruebaService.agregarPremio(datos)).then(response => {
        if(!response.error){
          const index = this.premios.findIndex(p => p.idBinomio == response.premio.idBinomio);
          response.premio.deduccion *= 100;
          response.premio.deduccion = parseFloat(response.premio.deduccion.toFixed(2));
          response.premio.plantillaPremio = false;
          this.premios[index] = response.premio;
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      }).catch(reason => {
        $.NotificationApp.send("Error", reason, 'bottom-right', '#fa5c7c', 'error');
        console.log(reason);
      });
    });
    Promise.all(premios).then(() => {
      this.getPremiosPagarAparte();
      $('#loader').hide();
      $.NotificationApp.send("Saved", "The prizes have been saved.", 'bottom-right', '#06d5a1', 'success');
    });
  }

  public mostrarConfirmarTodo(): boolean{
    return false;
  }

  public asignarPosicion(e): void{
    const posicion = e.target.value;
    console.log(posicion);
    console.log(this.premios);

    //Asignar posicion a binomio y poner posicion a todos los demas
  }
}
