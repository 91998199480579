import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { trigger, state, sequence, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-pantalla-grande',
  templateUrl: './pantalla-grande.component.html',
  styleUrls: ['./pantalla-grande.component.scss'],
  animations: [
    trigger('swing-left-fwd', [
      transition(':enter', [
        style({ transform: 'rotateY(-180deg)', 'transform-origin': 'left bottom' }),
        animate('500ms', style({ transform: 'rotateY(0)', 'transform-origin': 'left bottom' }))
      ]),
      transition(':leave', [
        style({ transform: 'rotateY(0)', 'transform-origin': 'left bottom' }),
        animate('500ms', style({ transform: 'rotateY(-180deg)', 'transform-origin': 'left bottom' }))
      ])
    ]),
    trigger('changeFaltas', [
      transition('* <=> *', sequence([
        animate('300ms', style({ opacity: 0})),
        //animate('500ms', style({ transform: 'translateY(-62px) rotateX(-90deg)' }))
      ]))
    ])
  ]
})
export class PantallaGrandeComponent implements OnInit {
  private idPantalla: string;
  private idConcurso: string;
  public pantalla: any;
  public prueba: any;
  public resultados: Array<any>;
  public enPista: any;
  public lider: any;
  public letreros: boolean;
  public faltasState: string;
  public enPistaFB: any;
  public cambiando: boolean;
  public cambiandoFaltas: boolean;
  public nombreConcurso: string;
  public fecha: Date;
  public nombrePrueba: string;
  public numeroPrueba: string;
  public tiempoAcordado: string;
  public posicionTablaResultados: string;
  public concurso: any;
  public cargarListeners: boolean;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore
  ) {
    this.idPantalla = '';
    this.pantalla = {};
    this.prueba = {};
    this.resultados = [];
    this.enPista = {};
    this.lider = {};
    this.letreros = true;
    this.faltasState = '';
    this.enPistaFB = {};
    this.cambiando = false;
    this.cambiandoFaltas = false;
    this.nombreConcurso = '-';
    this.fecha = new Date();
    this.nombrePrueba = '-';
    this.numeroPrueba = '-';
    this.tiempoAcordado = '';
    this.posicionTablaResultados = '';
    this.concurso = {};
    this.cargarListeners = true;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idPantalla = params['idPantalla'];
      this.idConcurso = params['idConcurso'];
    });
    this.getPantalla();
    this.infoConcurso();
  }
  
  private getPantalla(){
    this.db.collection('pantallas').doc(this.idConcurso).collection('pantallas').doc(this.idPantalla).valueChanges().subscribe(
        (pantalla: any) => {
            //Si hay cambio de prueba recargar la pagina
            if(this.pantalla.hasOwnProperty('ipc') && this.pantalla.ipc != pantalla.id_prueba){
                window.location.reload();
            }
            //Si hay cambio en tabla de posiciones
            if(this.pantalla.hasOwnProperty('resultados') && this.pantalla.resultados != pantalla.resultados){
                //TODO: Animacion para tabla de posiciones
            }

            this.pantalla = {
                pista: pantalla.pista,
                ipc: pantalla.id_prueba,
                pantalla: pantalla.pantalla,
                categoria: pantalla.agrupador,
                resultados: pantalla.resultados,
                letreros: pantalla.letreros
            };

            if(this.pantalla.ipc && this.cargarListeners){
                this.getEnPista(this.pantalla.ipc);
                this.getResultados(this.pantalla.ipc);
                this.infoPrueba(this.pantalla.ipc);
                //Crear bandera para que esto solo se ejecute una vez y no crear muchos listeners
                this.cargarListeners = false;
            }
        },
        error => {
            console.log(error);
        }
    );
}

private getEnPista(prueba) {
    this.db.collection('resultados').doc(prueba).collection("resultados", ref => ref.where("en_pista", "==", "1")).valueChanges().subscribe(
        (enPistaFB: any) => {
            if(enPistaFB.length > 0){
                //Si el binomio nuevo es diferente Desarmar Letrero
                if(this.enPistaFB.id_binomio != enPistaFB[0].id_binomio){
                    this.cambiando = true;
                    setTimeout(() => {
                        this.enPistaFB = enPistaFB[0];
                        this.cambiando = false;
                    }, 1000);// Tiempo que tarda la animacion en terminar esta en el css clase .en-pista.esconder 
                } else{
                    //Checar si hubo cambio en faltas
                    if(this.enPistaFB.faltas != enPistaFB[0].faltas){
                        this.cambiandoFaltas = true;
                        console.log('Cambio Faltas');
                        setTimeout(() => {
                            this.enPistaFB = enPistaFB[0];
                            this.cambiandoFaltas = false;
                        }, 150);//
                    } else{
                        this.cambiandoFaltas = false;
                        console.log('sin cambio en faltas');
                        console.log(this.enPistaFB.faltas);
                        console.log(enPistaFB[0].faltas);
                    }
                    this.enPistaFB = enPistaFB[0];
                }
                
            }
        },
        error => {
            console.log(error);
        }
    );
}


private getResultados(prueba){
    this.db.collection('resultados').doc(prueba).collection('resultados', ref => ref.orderBy('calificado_uno', 'desc').orderBy('posicion')).valueChanges().subscribe(
        resultados => {
            //TODO: filtrar datos lider por fase
            const filtroLider = resultados.filter(b => parseInt(b.posicion) == 1 && parseFloat(b.faltas) == 0 && parseFloat(b.tiempo) > 0);
            this.lider = filtroLider.length > 0 ? filtroLider.shift() : {};
            this.resultados = resultados.filter((b, i) => this.pantalla.resultados && ((this.pantalla.resultados == '0' && i < 5) || (this.pantalla.resultados == '1' && i >= 5 && i < 10) || (this.pantalla.resultados == '1' && i >= 10 && i < 15)));
        },
        error => {
            console.log(error);
        }
    );
}

public infoPrueba(prueba): void{
    this.db.collection('pruebas').doc(this.idConcurso).collection('pruebas').doc(prueba).valueChanges().subscribe(
        (prueba: any) => {
            this.nombreConcurso = prueba.nombreConcurso;
            this.nombrePrueba = prueba.nombre;
            this.numeroPrueba = prueba.numero;
            this.tiempoAcordado = prueba.tiempo_acordado;
            this.prueba = prueba;
            console.log(prueba);
        },
        error => {
            console.log(error);
        }
    );
}

public infoConcurso(): void{
    this.db.collection('concursos').doc(this.idConcurso).valueChanges().subscribe(
        concurso => {
            this.concurso = concurso;
        },
        error => {
            console.log(error);
        }
    );
}

public mostrarProgreso(): string{
    return `${this.resultados.filter(binomio => binomio.calificado_uno === '1').length} / ${this.resultados.length}`;
}

public mostrarProgresoPorcentaje(): string{
    let porcentaje = this.resultados.filter(binomio => binomio.calificado_uno === '1').length / this.resultados.length;
    porcentaje = isNaN(porcentaje) ? 0 : porcentaje * 100;

    return `${porcentaje}%`;
}

public traductorEstatus(estatus): string{
    return estatus;
  }
}
