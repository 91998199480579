import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterClub'
})
export class FilterClubPipe implements PipeTransform {
  transform(value: Array<any>, query: any): any {
    if(query){
      if(typeof(query) == 'string'){
        return value.filter((club) => {
          return club.club.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }
      return value;
    }
    return value;
  }
}
