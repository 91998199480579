
<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
  </div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid" style="width: 100%; float:left; display:flex;">
        <div class="col-md-12" style="padding: 0px;">
          <div class="col-md-12" style="margin-top: 30px;">
            <div class="card" *ngIf="peticionesPendientes.length > 0 && loaded == true">
              <div class="card-header bg-dark text-white">
                <span>Solicitudes pendientes de videos</span>
              </div>
              <div class="card-body padding-0" style="padding: 0px;">
                <div class="widget widget-fullwidth widget-small table-responsive" style="padding: 15px;">
                  <table class="table table-fw-widget table-hover table-sm" id="tabla-videos-pendientes">
                    <thead>
                      <tr>
                        <th><strong>Prueba</strong></th>
                        <th><strong>Binomio</strong></th>
                        <th><strong>Resultado</strong></th>
                        <th><strong>Contacto</strong></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let pendiente of peticionesPendientes"  class="clickable" [routerLink]="['/sistema-videos/',pendiente.id_prueba,pendiente.id_binomio]">
                        <td>
                          <div>
                            <strong>PBA {{ pendiente.numero_prueba }}</strong> - {{ pendiente.prueba }} <br>
                            {{ pendiente.inicio }} <br>
                            {{ pendiente.concurso}}
                          </div>
                        </td>
                        <td>
                          <div>
                            <strong>{{ pendiente.entrada }}</strong> | {{ pendiente.posicion }} - {{ pendiente.jinete}} <br>
                            {{ pendiente.caballo }}
                          </div>
                        </td>
                        <td>
                          <div>
                            <strong>{{ pendiente.faltas }} | {{ pendiente.tiempo }} </strong> <br>
                            <strong *ngIf="pendiente.tiempo2 != '0.00' || pendiente.faltas2 != '0.00'">{{ pendiente.faltas2 }} | {{ pendiente.tiempo2 }} </strong>
                          </div>
                        </td>
                        <td>
                          <div>
                            {{ pendiente.contacto }}
                          </div>
                        </td>
                        <td>
                          <button type="button" class="btn btn-icon btn-danger btn-lg fullsize-button" (click)="$event.stopPropagation();eliminarPeticion(pendiente.id_video)" >
                            <i class="mdi mdi-delete"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div id="pendientes-0" class="centered row justify-content-md-center" style="background-color: #fafbfe !important;" *ngIf="peticionesPendientes.length == 0 && loaded == true">
              <div class="col-6">
                <img style="width:100px;" src="assets/images/icons/partners-claping-hands.svg" alt="cool">
              </div>
              <div class="col-12">
                <p>¡Hurra! Todas las peticiones de videos han sido atendidas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
