import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-view-ingate',
  templateUrl: './view-ingate.component.html',
  styleUrls: ['./view-ingate.component.css']
})
export class ViewIngateComponent implements OnInit {
  public prueba: any;
  public en_pista: any;

  constructor(private db: AngularFirestore) {
    this.prueba = {};
    this.en_pista = {};
  }

  ngOnInit() {
    this.getIngate();
  }

  private getIngate(){
    this.db.collection('concursos').doc(`us_${sessionStorage.getItem('concurso')}`).collection('channels').doc('2').valueChanges().subscribe(
      (response: any) => {
        this.prueba = response.ingate.prueba;
        this.en_pista = response.ingate.en_pista;
        console.log(response);
      }
    );
  }
}
