import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {
  public idConcurso: string | boolean;
  public token: string;
  private sessionData: any;
  public privilegios: number;
  public idUsuario: string;
  public club: string;
  public reining: boolean;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private appService: AppService
  ) {
    this.idConcurso = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.idUsuario = '';
  }

  ngOnInit() {
    this.idConcurso = this.authService.validarConcurso() ? this.authService.validarConcurso() : '';
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.club = sessionStorage.getItem('club');
      this.privilegios = this.sessionData.privilegios;
      this.reining = sessionStorage.getItem('reining') == '1';
    }
  }

  ngAfterViewInit() {
    $.App.init();
  }

  public updateClasses() {
    //$('#loader').show();
    this.idConcurso = this.authService.validarConcurso() ? this.authService.validarConcurso() : '';
    this.appService.updateClasses(this.idConcurso);
  }

  public updateShow() {
    //$('#loader').show();
    this.idConcurso = this.authService.validarConcurso() ? this.authService.validarConcurso() : '';
    this.appService.updateShow(this.idConcurso);
  }

  public updateShows() {
    //$('#loader').show();
    this.appService.updateShows();
  }

  public async updateMasterList() {
    this.idConcurso = this.authService.validarConcurso()||'';
    this.appService.updateMasterList(this.idConcurso);
  }
}
