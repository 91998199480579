<div id="loader" class="d-none" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <!-- Modal Split -->
    <ng-container *ngTemplateOutlet="modalSplit"></ng-container>
    <!-- Fin modal split -->
    <!-- Modal traspaso -->
    <ng-container *ngTemplateOutlet="modalTraspaso"></ng-container>
    <!-- Fin modal traspaso -->
    <!-- Modal Pago con TC -->
    <ng-container *ngTemplateOutlet="modalTcStripe"></ng-container>
    <!-- Fin modal Pago con Stripe -->
    <!-- Modal Pago con Tarjeta -->
    <ng-container *ngTemplateOutlet="modalTc"></ng-container>
    <!-- Fin modal pago con tarjeta -->
    <!-- Modal otros metodos de pago -->
    <ng-container *ngTemplateOutlet="modalPago"></ng-container>
    <!-- Fin modal otros metodos de pago -->
    <!-- Modal motivo de anulacion traspaso -->
    <ng-container *ngTemplateOutlet="modalAnulacionTraspaso"></ng-container>
    <!-- Fin Modal motivo de anulacion traspaso -->
    <!-- Modal productos -->
    <ng-container *ngTemplateOutlet="modalProductos"></ng-container>
    <!-- Modal Settle Credits -->
    <ng-container *ngTemplateOutlet="modalSettleCredits"></ng-container>
    <!-- Fin modal Settle Credits -->
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <div class="row mt-4 justify-content-center m-print-0">
          <div class="col-12 p-print-0">
            <div class="card m-print-0">
              <div class="card-body p-print-0">
               <div class="form-group">
                <label>Trainers <span class="loader bg-white" *ngIf="loading.get('trainers')"></span></label>
                <select class="form-control d-print-none mb-3" style="max-width: 40%;" [(ngModel)]="entrenadorSeleccionado" (change)="seleccionarEntrenador()" [ngModelOptions]="{standalone: true}" [disabled]="loading.get('trainers')">
                  <option value="" selected disabled></option>
                  <option *ngFor="let entrenador of entrenadores" [value]="entrenador.idEntrenador">{{ entrenador.nombre||'No Name' }}</option>
                </select>
               </div>
                <div class="card panel-primary w-100" *ngIf="entrenadorSeleccionado">
                  <div style="flex: 1;" class="text-left">
                    <textarea name="" id="" cols="30" rows="3" [(ngModel)]="trainerNotes" (change)="saveNotes($event.target.value)" placeholder="Add notes..." class="form-control d-print-none" style="width: calc(100% - 15px); resize: none;">{{ trainerNotes }}</textarea>
                  </div>

                  <div style="display: flex; justify-content: space-between; padding:20px; width:100%;">
                    <div style="flex: 1;" class="text-left">
                      <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                    </div>
                    <div>
                      <h2>{{ nombreConcurso }}</h2>
                      <h4 style="text-align:center"><span>Trainer account</span></h4>
                    </div>
                    <div style="flex: 1;" class="text-right">
                      <img [src]="header2" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                    </div>
                  </div>
                  <div class="subheader" style="display:flex; justify-content: space-between; padding:20px ">
                    <div class="col p-0">
                      <strong>{{ nombre }}</strong>
                      <p class="mb-0" *ngIf="email">{{ email }}</p>
                      <p class="mb-0" >{{ phone }}</p>
                    </div>
                    <div class="col p-0 text-center d-print-none">
                      <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" id="validated" name="validated" type="checkbox" [(ngModel)]="validated" (change)="validarCuenta($event)">
                        <label class="custom-control-label" for="validated">Checked</label>
                      </div>
                    </div>
                    <div class="col p-0 text-right">
                      <div style="font-weight: bold; color:black; font-size:1.3em;" >
                        Balance:
                        <div class="spinner-container col-3 p-0" *ngIf="!visibleElements.balance" style="float: inline-end;">
                          <div class="spinner w-100 spinner-gray"></div>
                        </div>
                        <span *ngIf="visibleElements.balance">{{saldoEntrenador | currency }}</span>
                      </div>

                      <div class="alert alert-info mt-2 text-left" role="alert" *ngIf="saldoEntrenador < 0">
                        <i class="mdi mdi-lightbulb-on-outline"></i> Trainer credits can be sent to entries using a negative amount in a trainer split
                      </div>
                      <span style="float:right;line-height: 19px" class="text-panel d-print-block">{{ date | date: 'dd/MM/yyyy hh:mm a' }}</span>
                    </div>
                  </div>

                  <!-- Cargos entrenador -->
                  <ng-template *ngTemplateOutlet="cardCargosEntrenador"></ng-template>
                  <!-- Fin cargos entrenador -->

                  <!-- Feed orders summary -->
                  <ng-container *ngTemplateOutlet="cardFeedOrdersSummary"></ng-container>
                  <!-- Feed orders summary -->


                  <ng-container  *ngIf="entrenadorSeleccionado">
                    <div class="row">
                      <div class="col-7">
                        <!-- Splits entrenador -->
                        <ng-container *ngTemplateOutlet="cardSplitsEntrenador"></ng-container>
                      </div>
                      <div class="col-5">
                        <!-- Entries entrenador -->
                        <ng-container *ngTemplateOutlet="cardEntriesEntrenador"></ng-container>
                      </div>
                    </div>


                  </ng-container>
                  <!-- Fin splits entrenador -->

                  <!-- Cards -->
                  <div class="card-header bg-success text-white d-print-none" style="padding: 0.25rem 1rem" *ngIf="loading.get('cc')">
                    <h3 class="d-flex m-0 justify-content-between">
                      <span>Saved Cards</span>
                      <a href="javascript:;" class="text-white">
                        <i class="mdi mdi-credit-card-plus"></i>
                      </a>
                    </h3>
                </div>
                  <ng-container *ngIf="!loading.get('cc'); else cardPlaceholder">
                    <app-stripe-add-card [customers]="stripeCustomers"></app-stripe-add-card><!-- (onFinished)="updateStripePaymentMethods($event)" -->
                  </ng-container>
                  <!--div class="card-header bg-success text-white" style="padding: 0.25rem 1rem">
                      <h3 style="margin: 0px" class="d-flex">
                        <span>Saved Cards</span>
                        <a href="javascript:;" class="ml-auto text-white" (click)="showAddCard()">
                          <i class="mdi mdi-credit-card-plus "></i>
                        </a>
                      </h3>
                    </div>
                    <form [formGroup]="cardForm" (ngSubmit)="addCard()" class="px-1" *ngIf="showAddCardForm">
                      <div class="form-group">
                        <label>Name</label>
                        <input type="text" formControlName="name" class="form-control form-control-sm">
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend" tabindex="-1">
                            <span class="input-group-text"
                              style="background: transparent; border-right: 0;padding: 0 .9rem;" tabindex="-1">
                              <i class="mdi mdi-credit-card" tabindex="-1"></i></span>
                          </div>
                          <input id="cardNumber" formControlName="number" type="text" placeholder="Card number"
                            class="form-control form-control-sm"
                            style="border-left: 0; padding-left: 0; border-right: 0;" data-toggle="input-mask"
                            data-mask-format="9999 9999 9999 9990" minlength="18" required tabindex="0">
                          <div class="input-group-append">
                            <input formControlName="date" type="text" placeholder="MM/YYYY"
                              class="form-control form-control-sm"
                              style="border-left: 0; border-top-left-radius: 0; border-bottom-left-radius: 0;"
                              data-toggle="input-mask" data-mask-format="00/0000" minlength="7" required>
                          </div>
                          <div class="invalid-feedback d-block">
                            {{ errorTarjeta }}
                          </div>
                        </div>
                      </div>
                      <div class="form-group text-center">
                        <button type="button" class="btn btn-sm btn-light" (click)="hideAddCard()">Cancel</button>
                        <button type="submit" class="btn btn-sm btn-success ml-3">Save</button>
                      </div>
                    </form>
                    <app-stripe-card-list [stripeCards]="stripeCards"></app-stripe-card-list-->
                  <!-- Fin Cards -->

                  <!-- Pagos entries -->
                  <ng-container *ngIf="entrenadorSeleccionado">
                    <ng-container *ngTemplateOutlet="cardPagosEntries"></ng-container>
                  </ng-container>
                  <!-- Fin Pagos entries -->

                  <!-- Pagos entrenador -->
                  <ng-container  *ngIf="entrenadorSeleccionado">
                    <ng-container *ngTemplateOutlet="cardPagosEntrenador"></ng-container>
                  </ng-container>
                  <!-- Fin pagos entrenador -->

                  <!-- Cheques entrenador -->
                  <app-check-form [trainer]="entrenadorSeleccionado" [entries]="entriesEntrenador" *ngIf="entrenadorSeleccionado" (newCheckEvent)="addedCheck($event)"></app-check-form>
                  <!-- Fin Cheques entrenador -->

                  <div class="row d-none d-print-flex mt-3 mb-1">
                    <div class="col-12 text-center">
                      <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                    </div>
                  </div>
                  <div class="poweredBy d-none d-print-block">Powered by Equestrian Digital <img src="assets/images/logo.png"></div>
                </div>
              </div>
            </div>
            <!-- Horse Invoices -->
            <div class="container m-0" style="max-width: 100%;" *ngFor="let caballo of caballos">
              <div class="saltoDePagina"></div>
              <div class="card m-print-0">
                <div class="card-body p-print-0">
                  <div class="d-flex w-100" style="display: flex; justify-content: space-between; padding:20px; width:100%;">
                    <div style="flex: 1;" class="text-left">
                      <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                    </div>
                    <div>
                      <h2>{{ nombreConcurso }}</h2>
                      <h4 style="text-align:center"><span>Entry Invoice</span></h4>
                    </div>
                    <div style="flex: 1;" class="text-right">
                      <img [src]="header2" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                    </div>
                  </div>
                  <div class="row justify-content-center" style="padding: 10px 0;">
                    <div class="col-4">
                      <div class="mr-3">
                        <p class="mb-0">
                          <b style="vertical-align: middle;">{{ caballo.cucarda }} - {{ caballo.nombreCaballo }} <i class=" mdi mdi-chip" [ngClass]="{'text-danger': !caballo.chip_id, 'text-success': caballo.chip_id}"></i></b>
                        </p>
                        <p class="mb-0">
                          <img src="assets/images/icons/usef.svg" alt="" width="25">
                          <span style="line-height: 25px;"><b> {{ caballo.usefId }}</b></span>
                        </p>
                        <p class="mb-0" *ngIf="caballo.feiId != ''">
                          <img src="assets/images/icons/fei.svg" alt="" width="25">
                          <span style="line-height: 25px;"><b> {{ caballo.feiId }}</b></span>
                        </p>
                        <p class="mb-0">
                          Height: <b> {{ caballo.altura }}</b>
                        </p>
                        <p class="mb-0">
                          <img *ngIf="!caballo.suspended" class="d-print-none" style="max-width: 20px;" src="assets/images/icons/success.svg">
                          <img *ngIf="caballo.suspended" class="d-print-none" data-toggle="tooltip" [title]="caballo?.suspendedReason" style="max-width: 20px;" src="assets/images/icons/warning.svg">
                          <img *ngIf="!caballo.ushja" class="ml-1 d-print-none" style="max-width: 40px;" src="assets/images/icons/ushja_green.png">
                          <img *ngIf="caballo.ushja" class="ml-1 d-print-none" data-toggle="tooltip" [title]="caballo?.ushjaReason" style="max-width: 40px;" src="assets/images/icons/ushja_red.png">
                          <img *ngIf="caballo.ownerLikeUsef == 0" class="ml-1 d-print-none" data-toggle="tooltip" title="Check USEF Owner" style="max-width: 20px;" src="assets/images/icons/warning.svg">
                        </p>
                      </div>
                    </div>
                    <div class="col-4 text-left"></div>
                    <div class="col-4 text-right">
                      <div style="color:black; font-weight: bold;font-size: 1rem;">Balance: {{caballo.saldo | currency }}</div>
                      <span style="line-height: 19px" class="text-panel visible-print-block">{{ date | date: 'dd/MM/yyyy hh:mm a' }}</span>
                    </div>
                  </div>
                  <div class="row justify-content-center" style="padding: 10px 0;">
                    <div class="col-5">
                      <div class="mr-3">
                        <p class="mb-1">Payee: <b class="mr-3">{{ caballo.payee }}</b></p>
                        <p class="mb-1">{{ caballo.payeeAddress }}</p>
                        <p class="mb-1">{{ caballo.payeeAddress2 }}</p>
                      </div>
                    </div>
                    <div class="col-4 text-left">
                      <ng-container *ngFor="let entrenador of caballo.entrenadores">
                        <p class="mb-1">Trainer: <b>{{ entrenador.name }}</b></p>
                        <p class="mb-1">{{ entrenador.address }}</p>
                        <p class="mb-1">{{ entrenador.address2 }}</p>
                      </ng-container>
                    </div>
                    <div class="col-3 text-right">
                      <p class="mb-0">Owner: <b>{{ caballo.owner }}</b></p>
                      <p class="mb-1">{{ caballo.ownerAddress }}</p>
                      <p class="mb-1">{{ caballo.ownerAddress2 }}</p>
                    </div>
                  </div>
                  <!-- Inscripciones -->
                  <div class="card m-0 d-print-none">
                    <div class="card-body text-panel p-0">
                      <div class="card-header" style="color: #ffffff !important; background: rgb(65, 65, 65); padding: 0.25rem 1rem">
                        <h3 class="m-0" style="width: fit-content; display: contents;">
                          <span>Riders & Classes</span>
                        </h3>
                        <span class="d-print-none" style="float: right;"></span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body text-panel p-print-0 d-print-none">
                    <table class="table table-hover table-sm table-condensed m-print-0">
                      <thead style="display:none;">
                        <tr>
                          <th><b>Rider</b></th>
                          <!--th><b>Horse</b></th-->
                          <th><b>Notes</b></th>
                          <!--th><b>Total for Combination</b></th-->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="page-break" *ngFor="let binomio of caballo.binomios">
                          <td>
                            {{ binomio.jinete }}
                            <br>
                            <img *ngIf="!binomio.suspended" class="d-print-none" style="max-width: 20px;" src="assets/images/icons/success.svg">
                            <img *ngIf="binomio.suspended" class="d-print-none" data-toggle="tooltip" [title]="binomio?.suspendedReason" style="max-width: 20px;" src="assets/images/icons/warning.svg">
                            <img *ngIf="!binomio.ushja" class="ml-1 d-print-none" style="max-width: 40px;" src="assets/images/icons/ushja_green.png">
                            <img *ngIf="binomio.ushja" class="ml-1 d-print-none" data-toggle="tooltip" [title]="binomio?.ushjaReason" style="max-width: 40px;" src="assets/images/icons/ushja_red.png">
                            <img *ngIf="!binomio.usef" class="ml-1 d-print-none" style="max-width: 25px;" src="assets/images/icons/usef_green.png">
                            <img *ngIf="binomio.usef" class="ml-1 d-print-none" data-toggle="tooltip" [title]="binomio?.usefReason" style="max-width: 25px;" src="assets/images/icons/usef_red.png">
                          </td>
                          <!--td>{{ binomio.caballo }}
														<mat-icon *ngIf="binomio.numeroFeiFee" svgIcon="fei" style="vertical-align: middle;"></mat-icon>
													</td-->
                          <td>
                            <div style="margin-bottom: 7px;" *ngFor="let inscripcion of binomio.inscripciones">
                              <span class="circulo">{{ inscripcion.nombre }}</span>
                              <span class="cat-prueba" *ngIf="inscripcion.multicosto">{{ inscripcion.siglas }}</span>
                              <mat-icon style="font-size: 16px; height: 16px; width: 16px;vertical-align: middle">event
                              </mat-icon>
                              {{ inscripcion.inicio | date: 'EEEEEE dd MMM yyyy' | titlecase }}
                              <b [ngClass]="{scratched: inscripcion.scratched}">{{ inscripcion.costo | currency }}</b>
                              <span *ngIf="(inscripcion.scratched || inscripcion.extemporaneo) && !inscripcion.anularMulta" style="display: inline-table;">
                                <span class="label" [ngClass]="{'label-danger': inscripcion.scratched, 'label-blue': inscripcion.extemporaneo}"  data-toggle="tooltip" [title]="inscripcion.scratched ? 'Scratcheado' : 'Extemporaneo'">{{inscripcion.multa | currency }}</span>
                              </span>
                            </div>
                          </td>
                          <!--td style="text-align:center" class="total-binomio">{{ binomio.totalBinomio | currency }}</td-->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- Encabezado class fees -->
                  <div class="card m-0">
                    <div class="card-body text-panel p-0">
                      <div class="card-header" style="color: #ffffff !important; background: rgb(65, 65, 65); padding: 0.25rem 1rem">
                        <h3 class="m-0" style="width: fit-content; display: contents;">
                          <span>Class & Division Fees</span>
                          <span style="float: right;">{{ caballo.totalInscripciones | currency }}</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <!-- Contenido Inscripciones-->
                  <div class="card-body text-panel p-print-0">
                    <table class="table table-hover table-sm table-condensed m-print-0">
                      <thead style="display:none;">
                        <tr>
                          <th>Reference</th>
                          <th style="text-align: right;">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="page-break" *ngFor="let cargo of  caballo.cargosInscripciones">
                          <td>{{cargo.referencia}}</td>
                          <td style="text-align: right;">{{cargo.costo | currency}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="card-header bg-danger" style="color: #ffffff !important; padding: 0.25rem 1rem">
                        <h3 style="margin: 0px">
                          <span>Charges</span>
                          <span style="float:right">
                            <span *ngIf="visibleElements.totalCargos">{{ caballo.totalCargos | currency }}</span>
                          </span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-print-0">
                        <table class="table table-hover table-condensed m-print-0">
                          <thead>
                            <tr>
                              <th>Concept</th>
                              <th>Notes</th>
                              <th>Qty</th>
                              <th>Amount</th>
                              <!--th></th-->
                            </tr>
                          </thead>
                          <tbody>
                            <!--tr>
															<td colspan="3">Class Entries</td>
															<td>{{ caballo.totalInscripciones | currency }}</td>
															<td></td>
														</tr-->
                            <tr *ngFor="let nominacion of caballo.nominaciones">
                              <td>Nomination Fees*</td>
                              <td><span>Week </span> {{ nominacion.semana }}</td>
                              <td>{{ nominacion.cantidad }}</td>
                              <td>{{ nominacion.nominacion | currency }}</td>
                              <!--td></td-->
                            </tr>
                            <tr *ngFor="let feiFee of caballo.totalesFeiFee">
                              <td>FEI Fee</td>
                              <td><span>Week</span> {{ feiFee.semana }}</td>
                              <td><span>Horse</span> {{ feiFee.numeroCaballo }}</td>
                              <td>{{ feiFee.total | currency }}</td>
                              <!--td></td-->
                            </tr>
                            <ng-container *ngFor="let cargo of caballo.cargos; let index = index">
                              <tr>
                                <td>{{ cargo.nombre }}</td>
                                <td>{{ cargo.referencia }}</td>
                                <td>{{ cargo.cantidad }}</td>
                                <td>{{ cargo.monto | currency }}</td>
                                <!--td>
																	<button class="d-print-none" mat-icon-button *ngIf="!cargo.mostrarDetalle">
																		<mat-icon>expand_more</mat-icon>
																	</button>
																	<button class="d-print-none" mat-icon-button *ngIf="cargo.mostrarDetalle">
																		<mat-icon>expand_less</mat-icon>
																	</button>
																</td-->
                              </tr>
                              <ng-container *ngIf="cargo.mostrarDetalle">
                                <tr *ngFor="let detalleCargo of cargo.detalle">
                                  <td></td><!-- {{ detalleCargo.nombre }} -->
                                  <td>{{ detalleCargo.referencia }}</td>
                                  <td>{{ detalleCargo.cantidad }}</td>
                                  <td>{{ detalleCargo.monto | currency }}</td>
                                  <td></td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-3 d-print-none" [ngClass]="{'d-print-none': caballo.orders.length == 0}">
                      <div class="card-header bg-dark" style="color: #ffffff !important; padding: 0.25rem 1rem">
                        <h3 style="margin: 0px">
                          <span>Feed orders summary</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-print-0">
                        <table class="table table-hover table-condensed m-print-0">
                          <thead>
                            <tr>
                              <th>Concept</th>
                              <th>Qty</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let order of caballo.orders">
                              <td>{{ order.producto }}</td>
                              <td>{{ order.cantidad }}</td>
                              <td>{{ order.total | currency }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="caballo.splits">
                    <div class="card-header bg-info" style="color: #ffffff !important; padding: 0.25rem 1rem"
                      *ngIf="caballo.splits.length > 0">
                      <h3 class="m-0">
                        <span>Splits</span>
                        <span style="float:right">{{ caballo.totalSplits | currency }}</span>
                      </h3>
                    </div>
                    <div class="card-body text-panel p-print-0">
                      <table class="table table-hover table-condensed m-print-0" *ngIf="caballo.splits.length > 0">
                        <thead>
                          <tr>
                            <th>Trainer</th>
                            <th>Notes</th>
                            <th>Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let split of caballo.splits" [ngClass]="{'d-print-none': split.anulado}">
                            <td>{{ split.entrenador }}</td>
                            <td>{{ split.notas }}</td>
                            <td [ngClass]="{scratched: split.anulado}">{{ split.monto | currency }}</td>
                            <td>{{ split.fecha | date: 'EEEEEE dd MMM yyyy' | titlecase }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ng-container>
                  <div class="card-header bg-success" style="color: #ffffff !important; padding: 0.25rem 1rem">
                    <h3 style="margin: 0px">
                      <span>Payments</span>
                      <span style="float:right">{{ caballo.totalPagos | currency }}</span>
                    </h3>
                  </div>
                  <div class="card-body text-panel p-print-0">
                    <table class="table table-hover table-condensed m-print-0">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th><span class="d-print-none">Notes</span></th>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let pago of caballo.pagos" [ngClass]="{'d-print-none scratched': pago.anulado}">
                          <td>{{ traducirMetodoDePago(pago.metodo) }}</td>
                          <td><span class="d-print-none">{{ pago.referencia }}</span></td>
                          <td>{{ pago.fecha_pago | date: 'EEEEEE dd MMM yyyy' | titlecase }}</td>
                          <td>{{ pago.monto | currency }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-header bg-light" style="padding: 0.25rem 1rem">
                    <h2 style="margin: 0px; text-align:center; color: black;">
                      <span>Balance: {{ caballo.saldo | currency }}</span>
                    </h2>
                  </div>
                  <div class="row mt-3">
                    <!-- Premios aplicados -->
                    <div class="col-6" *ngIf="caballo.premiosAplicados.length > 0">
                      <div class="card-header"
                        style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
                        <h3 style="margin: 0px">
                          <span>Applied Prizes</span>
                          <span style="float:right">{{ caballo.totalPremiosAplicados | currency }}</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-print-0">
                        <table class="table table-hover table-condensed m-print-0" *ngIf="caballo.premiosAplicados.length > 0">
                          <thead>
                            <tr>
                              <th>Prize</th>
                              <th>Class</th>
                              <th>Rider</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let premio of caballo.premiosAplicados">
                              <td>{{ premio.nombre }}</td>
                              <td>{{ premio.nombrePrueba }}</td>
                              <td>{{ premio.jinete }}</td>
                              <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                              <td *ngIf="premio.monto == 0"> - </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- Fin Premios aplicados -->
                    <!-- Premios no aplicados -->
                    <div class="col-6" *ngIf="caballo.premios.length > 0">
                      <div class="card-header" style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
                        <h3 style="margin: 0px">
                          <span>Unapplied Prizes</span>
                          <span style="float:right">{{ caballo.totalPremios | currency }}</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-print-0">
                        <table class="table table-hover table-condensed m-print-0" *ngIf="caballo.premios.length > 0">
                          <thead>
                            <tr>
                              <th>Prize</th>
                              <th>Class</th>
                              <th>Rider</th>
                              <th>Amount</th>
                              <th *ngIf="caballo.championshipSeries">Championship Series</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let premio of caballo.premios">
                              <td>{{ premio.nombre }}</td>
                              <td>{{ premio.nombrePrueba }}</td>
                              <td>{{ premio.jinete }}</td>
                              <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                              <td *ngIf="premio.monto == 0"> - </td>
                              <td *ngIf="caballo.championshipSeries">
                                <img *ngIf="premio.id_prueba == '2'" style="max-height: 20px;" src="https://firebasestorage.googleapis.com/v0/b/srjt/o/championshipSeries.png?alt=media&token=d0cc7397-3317-4a66-878a-233f3a5f064d">
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="5" class="text-center" *ngIf="!aplicarPremios">
                                <!-- Prize money is not applied, please check the Payee and  provide a W-9 form. -->
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <!-- Fin Premios no aplicados -->
                    <!-- Premios championship series -->
                    <div class="col-6" *ngIf="caballo.premiosChampionshipSeries.length > 0">
                      <div class="card-header" style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: #007755;">
                        <h3 style="margin: 0px">
                          <span>Championship Series Prizes</span>
                          <span style="float:right">{{ caballo.totalPremiosChampionshipSeries | currency }}</span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-print-0">
                        <table class="table table-hover table-condensed m-print-0" *ngIf="caballo.premiosChampionshipSeries.length > 0">
                          <thead>
                            <tr>
                              <th>Prize</th>
                              <th>Class</th>
                              <th>Rider</th>
                              <th>Amount</th>
                              <!--th *ngIf="caballo.championshipSeries">Championship Series</th-->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let premio of caballo.premiosChampionshipSeries">
                              <td>{{ premio.nombre }}</td>
                              <td>{{ premio.nombrePrueba }}</td>
                              <td>{{ premio.jinete }}</td>
                              <td *ngIf="premio.monto != 0">{{ premio.monto | currency }}</td>
                              <td *ngIf="premio.monto == 0"> - </td>
                              <!--td *ngIf="caballo.championshipSeries">
                                <img *ngIf="premio.id_prueba == '2'" style="max-height: 20px;" src="https://firebasestorage.googleapis.com/v0/b/srjt/o/championshipSeries.png?alt=media&token=d0cc7397-3317-4a66-878a-233f3a5f064d">
                              </td-->
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="5" class="text-center" *ngIf="!aplicarPremios">
                                <!-- Prize money is not applied, please check the Payee and
                                provide a W-9 form. -->
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <!-- Fin Premios championship series -->
                    <!-- Resultados -->
                    <div class="col-6" *ngIf="caballo.resultados.length > 0">
                      <div class="card-header" style="color: #ffffff !important; padding: 0.25rem 1rem; background-color: rgb(65, 65, 65);">
                        <h3 style="margin: 0px">
                          <span>Results</span>
                          <span style="float:right"></span>
                        </h3>
                      </div>
                      <div class="card-body text-panel p-print-0">
                        <table class="table table-hover table-condensed m-print-0" *ngIf="caballo.resultados.length > 0">
                          <tbody>
                            <tr *ngFor="let resultado of caballo.resultados">
                              <td><span class="circulo">{{ resultado.numero_prueba }}</span></td>
                              <td>{{ resultado.nombre_prueba }}</td>
                              <td>{{ resultado.jinete }}</td>
                              <td>
                                <span style="color:#FFBC00">
                                  <i class="mdi mdi-trophy"></i>{{ resultado.posicion }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="5" class="text-center" *ngIf="!aplicarPremios">
                                <!-- Prize money is not applied, please check the Payee and provide a W-9 form. -->
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <!-- Fin Resultados -->
                    <!-- Cheques entry -->
                    <div class="col-6">
                      <app-check-form [entry]="caballo.cucarda" *ngIf="caballo.cucarda" [hideForm]="true"></app-check-form>
                    </div>
                    <!-- Fin Cheques entry -->
                  </div>
                  <!--div class="text-center p-2">
										<div style="color:black; font-weight: bold;font-size: 1rem;">Balance: {{caballo.saldo | currency }}</div>
										<span style="float:right;line-height: 19px" class="text-panel visible-print-block">{{ date | date: 'dd/MM/yyyy hh:mm a' }}</span>
									</div-->
                  <div class="row d-none d-print-flex mt-3 mb-1">
                    <div class="col-12 text-center">
                      <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'"
                        onload="this.style.display='-webkit-inline-box'">
                    </div>
                  </div>
                  <div class="poweredBy d-none d-print-block mb-3">Powered by EquestrianDigital <img src="assets/images/logo.png"></div>
                  <div class="saltoDePagina"></div>
                  <p class="d-print-block d-none" style="color: transparent;">-</p>
                </div>
              </div>
            </div>
            <!-- Fin Horse Invoices -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #entriesPago>
  <table class="table m-0 text-center">
    <thead>
      <tr>
        <th style="padding: .375rem">Entry</th>
        <th style="padding: .375rem">Balance</th>
        <th style="padding: .375rem">Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let caballo of entriesEntrenador">
        <td style="vertical-align: middle;"><b>{{ caballo.entry }}</b> - {{ caballo.nombre }}</td>
        <td><b>{{caballo.saldo | currency }}</b></td>
        <td>
          <input type="number" class="form-control" [disabled]="pagoEnProceso" [(ngModel)]="caballo.amountPayment" (change)="validarMontoPositivo()">
        </td>
      </tr>
      <tr>
        <td style="vertical-align: middle;">To trainer:</td>
        <td><b>{{ saldoEntrenador | currency }}</b></td>
        <td><input type="number" class="form-control" [disabled]="pagoEnProceso" [(ngModel)]="amountTrainerPayment" (change)="validarMontoPositivo()"></td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #modalSplit>
  <div id="modal-split" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="info-header-modalLabel" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-info">
          <h4 class="modal-title" id="info-header-modalLabel">Split</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-12">
                <div class="alert alert-info mt-2" role="alert" *ngIf="saldoEntrenador < 0">
                  <i class="mdi mdi-lightbulb-on-outline"></i> Negative amounts will <b>charge</b> the trainer account and <b>credit</b> entries.
                </div>
                <table class="table m-0 table-sm">
                  <thead>
                    <tr>
                      <th><b>Entry</b></th>
                      <th><b>Trainer</b></th>
                      <th><b>Amount</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="vertical-align: middle;">
                        <input type="text" class="form-control form-control-sm" id="add-entry-split" placeholder="Add entry in another account" (change)="addSplitEntry($any($event).target.value)" [disabled]="savingSplits">
                      </td>
                      <td colspan="2"></td>
                    </tr>

                    <tr *ngFor="let caballo of entriesEntrenador">
                      <td style="vertical-align: middle;" colspan="2">{{ caballo.entry }} - {{ caballo.nombre }} ({{caballo.saldo | currency }})</td>
                      <td><input type="number" min="0" class="form-control form-control-sm" name="splitAmount" [(ngModel)]="caballo.splitAmount" [disabled]="savingSplits"></td>
                    </tr>

                    <tr *ngFor="let entry of splitsEntries">
                      <td style="vertical-align: middle;">{{ entry.entry }} - {{ entry.horse }}</td>
                      <td>{{ entry.trainer }}</td>
                      <td><input type="number" min="0" class="form-control form-control-sm" name="splitAmount" [(ngModel)]="entry.amount" [disabled]="savingSplits"></td>
                    </tr>

                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2" class="text-right"><b>Total:</b></td>
                      <td>{{ sumaSplits() | currency }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="col-12 text-right mt-3">
                <button type="button" class="btn btn-light mr-3" data-dismiss="modal" [disabled]="savingSplits">Close</button>
                <button type="button" class="btn btn-info" (click)="agregarSplitEntrenador()" [disabled]="savingSplits">
                  <span class="loader mr-1" *ngIf="savingSplits"></span>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<ng-template #modalTraspaso>
  <div id="modal-traspaso" tabindex="-1" role="dialog" class="modal fade colored-header success">
    <div style="width: 50%;" class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-danger">
          <h4 class="modal-title">Balance transfer</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" (ngSubmit)="balanceTransfer()" [formGroup]="formTraspaso" novalidate>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label><b>From show</b></label>
                  <p>{{ nombreConcurso }}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label><b>From trainer</b></label>
                  <p>{{ nombre }}</p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label><b>To show</b></label>
                  <select class="form-control" formControlName="concurso" (change)="consultarEntrenadoresTraspaso(); consultarEntriesTraspaso();" required>
                    <option [value]="idConcurso">Test Show (internal)</option>
                    <option [value]="concurso.id" *ngFor="let concurso of concursos">{{ concurso.nombre }}</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label><b>To trainer</b></label>
                  <input formControlName="entrenador" type="text" class="form-control" [matAutocomplete]="autocompleteTrainer" (keyup)="getEntrenadoresTraspaso($event)" [attr.disabled]="formTraspaso.value.entry ? '' : null">
                  <mat-autocomplete #autocompleteTrainer="matAutocomplete" [displayWith]="displayEntrenador.bind(this)">
                    <mat-option *ngFor="let persona of entrenadoresTraspaso" [value]="persona.id_persona" class="fila-autocomplete">
                      {{ (persona.fullname) ? persona.fullname : '-' }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label><b>To Entry</b></label>
                  <span class="clearfix"></span>
                  <input type="text" class="form-control" data-provide="typeahead" id="entryTraspaso" (keyup)="searchEntryTraspaso($any($event).target.value)">
                  <!--select class="form-control" formControlName="entry" [attr.disabled]="formTraspaso.value.entrenador ? '' : null">
                    <option value=""></option>
                    <option [value]="entry.entry" *ngFor="let entry of entriesTraspaso">{{ entry.entry }} - {{ entry.horse }}</option>
                  </select-->
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label><b>Notes</b></label>
                  <input class="form-control input-s" type="text" formControlName="notas">
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label><b>Date</b></label>
                  <input class="form-control input-s" min="0" type="date" formControlName="fecha" required>

                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label><b>Amount</b></label>
                  <input class="form-control input-s" type="number" formControlName="monto" required>
                </div>
              </div>
              <div class="col-12 text-right mt-3">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close mr-3">Cancel</button>
                <button type="submit" class="btn btn-danger md-close">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalTcStripe>
  <div class="modal fade" id="cardPaymentStripeModal" tabindex="-1" role="dialog" aria-labelledby="creditPAymentStripeLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="creditPAymentStripeLabel">Credit/Debit Card Payment</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body pt-0">
          <div class="row">
            <div class="col-12">
              <!-- Entries pago -->
              <ng-container *ngTemplateOutlet="entriesPago"></ng-container>
              <!-- Fin Entries pago -->
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <!--Componente de pago stripe -->
              <ng-container *ngIf="stripeFormVisible">
                <app-stripe-payment-form [customers]="stripeCustomers" [showId]="idConcurso" [amount]="totalPayment" [metadata]="stripeMetadata" [description]="stripeDescription" [defaultCardHolder]="defaultCardHolder" (onError)="handleError($event)" (onConfirmPayment)="handleSubmit($event)"></app-stripe-payment-form>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalPago>
  <div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" aria-labelledby="paymentModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="paymentModalTitle">{{ traducirMetodoDePago(formPagoEntrenador?.value?.metodo) }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <ng-container *ngIf="traducirMetodoDePago(formPagoEntrenador?.value?.metodo) == 'Check'">
              <div class="col-12">
                <p class="mb-2">Type in the total amount for the check</p>
              </div>
              <div class="col-3">
                <b>Check Number:</b>
                <input type="number" id="numero-cheque" class="form-control" (change)="checkNumberPayment($event)" required />
              </div>
              <div class="col-3">
                <b>Amount for the check:</b>
                <input type="number" id="monto-cheque" class="form-control" (change)="checkAmountPayment($event)" required />
              </div>
              <br>
              <div class="col-12 mt-3">
                <p class="mb-2">Type in the amount going to each horse</p>
              </div>
            </ng-container>
            <div class="col-12 mt-3">
               <!-- Entries pago -->
              <ng-container *ngTemplateOutlet="entriesPago"></ng-container>
               <!-- fin Entries pago -->
            </div>
            <div class="col-12 text-center mt-3">
              <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
              <button (click)="addPayment()" type="button" class="btn btn-primary" [disabled]="pagoEnProceso">
                <span class="loader mr-1" *ngIf="pagoEnProceso"></span> Pay {{ totalPayment | currency }}
              </button>
            </div>
          </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<ng-template #modalTc>
  <div class="modal fade" id="cardModal" tabindex="-1" role="dialog" aria-labelledby="cardModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="cardModalTitle">CREDIT/DEBIT CARD PAYMENT</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
            <form [formGroup]="cardForm" class="needs-validation" (ngSubmit)="addCreditCardPayment()" autocomplete="off" id="formCard" novalidate>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Cards</label>
                    <select class="form-control" name="tarjetaSeleccionada" [(ngModel)]="tarjetaSeleccionada" [ngModelOptions]="{standalone: true}">
                      <option value="">-- Select a card --</option>
                      <option style="width: 100px; white-space: wrap;" [value]="card.customerRefNum" *ngFor="let card of creditCards">{{ card.ccAccountNum }} - {{ card.ccExp }} - {{ card.customerName }}</option>
                    </select>
                  </div>
                  <!--pre>{{ tarjetaSeleccionada }}</pre-->
                </div>
                <div class="col-12 text-center mb-2">
                  <b>OR</b>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          style="background: transparent; border-right: 0;padding: 0 .9rem;"><i
                            class="mdi mdi-credit-card"></i></span>
                      </div>
                      <input formControlName="number" type="text" placeholder="Card number" class="form-control"
                        style="border-left: 0; padding-left: 0; border-right: 0;" data-toggle="input-mask"
                        data-mask-format="9999 9999 9999 9990" minlength="18" required>
                      <div class="input-group-append">
                        <input formControlName="date" type="text" placeholder="MM/YYYY" class="form-control"
                          style="border-left: 0; border-top-left-radius: 0; border-bottom-left-radius: 0;"
                          data-toggle="input-mask" data-mask-format="00/0000" minlength="7" required>
                      </div>
                      <div class="invalid-feedback d-block">
                        {{ errorTarjeta }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="cardForm.get('save').value">
                  <div class="form-group">
                    <label>Name</label>
                    <input formControlName="name" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-12" *ngIf="cardForm.get('save').value">
                  <div class="form-group">
                    <label>Person</label>
                    <input formControlName="person" type="text" class="form-control" [matAutocomplete]="autocompletePerson" (keyup)="getPersonas($event)">
                    <mat-autocomplete #autocompletePerson="matAutocomplete" [displayWith]="displayPersona.bind(this)">
                      <mat-option *ngFor="let persona of personas" [value]="persona.id_persona"
                        class="fila-autocomplete">
                        {{ (persona.fullname) ? persona.fullname : '-' }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="save-card" formControlName="save">
                      <label class="custom-control-label" for="save-card">Save this card</label>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <!-- Entries pago -->
                  <ng-container *ngTemplateOutlet="entriesPago"></ng-container>
                  <!-- fin Entries pago -->
                </div>
                <div class="col-12 mt-3">
                  <b>Notes</b>
                  <input type="text" class="form-control col-6" formControlName="referencia" />
                </div>
                <div class="col-12 text-center mt-3">
                  <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn btn-primary" [disabled]="pagoEnProceso" [ngClass]="{'deshabilitado': pagoEnProceso}">
                    Pay {{ (formPagoEntrenador.value.metodo == 'Tc' ? totalPayment * 1.0325 : totalPayment) | currency }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
</ng-template>

<ng-template #modalAnulacionTraspaso>
  <div id="motivoAnulacionTraspaso" class="modal fade" tabindex="-1" role="dialog"
      aria-labelledby="motivoAnulacionTraspasoLabel" style="display: none;" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="motivoAnulacionTraspasoLabel">Reason</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
            <textarea style="resize: none;" cols="30" rows="4" class="form-control"
              [(ngModel)]="motivoAnulacionTraspaso"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" (click)="anularTraspaso()">Accept</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
</ng-template>

<ng-template #modalProductos>
  <div class="modal fade" id="modal-productos" tabindex="-1" role="dialog" aria-labelledby="storeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="storeModalLabel">Merch Store</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <table class="table table-sm mb-0">
                <tbody>
                  <tr>
                    <td>
                      <select name="productos" id="productos" class="form-control" (change)="changeTotal()" [(ngModel)]="selectedProduct" required>
                        <option value=""></option>
                        <option [value]="product.id" *ngFor="let product of products" [disabled]="product.qty == '0'">
                          {{ product.name }} ({{ product.qty }})
                        </option>
                      </select>
                    </td>
                    <td><input type="number" class="form-control" min="1" (change)="changeTotal()" [(ngModel)]="selectedQty" required></td>
                    <td><input type="number" class="form-control" readonly [(ngModel)]="selectedTotal"></td>
                    <td class="table-action text-center">
                      <a href="javascript: void(0);" class="action-icon" (click)="addProduct()"> <i class="mdi mdi-plus-circle-outline"></i></a>
                    </td>
                  </tr>
                  <tr *ngFor="let product of selectedProducts">
                    <td>{{ product.name }}</td>
                    <td>{{ product.qty }}</td>
                    <td>{{ product.price | currency }}</td>
                    <td class="table-action">
                      <a href="javascript: void(0);" class="action-icon" (click)="removeProduct(product.id)"> <i
                          class="mdi mdi-close"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="saveProductCharges()">Save</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
</ng-template>

<ng-template #cardCargosEntrenador>
  <div class="card-header bg-danger" style="color: #ffffff !important; padding: 0.25rem 1.5rem">
    <div style="margin: 0px">
      <span class="h3">Charges</span>
      <span *ngIf="visibleElements.totalCargosEntrenador" style="float: right" class="h3 m-0">
        {{ totalCargosEntrenador | currency }}
      </span>
      <div class="spinner-container col-2 p-0" style="float: inline-end;" *ngIf="!visibleElements.totalCargosEntrenador">
        <div class="spinner w-100 spinner-white"></div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!loading.get('charges'); else cardPlaceholder">
    <div class="row d-print-none mb-2">
      <div class="col-12">
        <button class="btn btn-danger mr-3" [disabled]="validated" (click)="mostrarModalTraspaso()">Balance transfer</button>
        <button type="button" class="btn btn-primary mb-1 mt-1" (click)="mostrarModalProductos()">Merch Store</button>
      </div>
    </div>
    <div class="card-body text-panel p-print-0">
      <table class="table table-hover table-condensed m-0">
        <thead>
          <tr>
            <th><b>Concept</b></th>
            <th><b>Notes</b></th>
            <th><b>Qty</b></th>
            <th><b>Amount</b></th>
            <th><b>Entry</b></th>
            <th class="hidden-print"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="hidden-print" [formGroup]="formCargoEntrenador">
            <td>
              <select class="form-control input-s" (change)="calcularCargoEntrenador()"
                formControlName="concepto"
                [ngClass]="{'no-valido': formCargoEntrenador.get('concepto').invalid && (formCargoEntrenador.get('concepto').touched || formCargoEntrenador.get('concepto').dirty)}">
                <option value=""></option>
                <option *ngFor="let concepto of conceptos" [value]="concepto.id">{{ concepto.nombre }}
                </option>
              </select>
            </td>
            <td>
              <input class="form-control input-s" type="text" formControlName="notas">
            </td>
            <td>
              <input min="0" class="form-control input-s" type="number" formControlName="cantidad"
                (change)="calcularCargoEntrenador()"
                [ngClass]="{'no-valido': formCargoEntrenador.get('cantidad').invalid && (formCargoEntrenador.get('cantidad').touched || formCargoEntrenador.get('cantidad').dirty)}">
            </td>
            <td>
              <input class="form-control input-s" type="number" formControlName="monto"
                [ngClass]="{'no-valido': formCargoEntrenador.get('monto').invalid && (formCargoEntrenador.get('monto').touched || formCargoEntrenador.get('monto').dirty)}">
            </td>
            <td>
              <select class="form-control input-s" formControlName="caballo">
                <option value="" selected disabled> - </option>
                <option *ngFor="let caballo of entriesEntrenador" [value]="caballo.entry">{{
                  caballo.entry }} - {{ caballo.nombre }}</option>
              </select>
            </td>
            <td style=" background-color: #fa5c7c;color: white;/* border-radius: 0px 15px 15px 0 */;text-align: center;">
              <button class="btn btn-icon btn-danger" style="font-size:1.5rem; padding:0; /*margin-top:-15px; margin-bottom:-15px;*/ box-shadow: 0 0px 0px 0 rgba(57,175,209,.5);" (click)="agregarCargoEntrenador()">
                <i class="mdi mdi-plus-circle"></i>
              </button>
            </td>
          </tr>
          <ng-container *ngFor="let cargo of cargosEntrenador; let index = index">
            <tr [ngClass]="{'d-print-none': cargo.monto == 0}">
              <td>{{ cargo.nombre }}</td>
              <td>{{ cargo.referencia }}</td>
              <td>{{ cargo.cantidad | number }}</td>
              <td>{{ cargo.monto | currency }}</td>
              <td></td>
              <td class="d-print-none">
                <button mat-icon-button (click)="toggleDetalleCargoEntrenador(index)"
                  *ngIf="!cargo.mostrarDetalle">
                  <mat-icon>expand_more</mat-icon>
                </button>
                <button mat-icon-button (click)="toggleDetalleCargoEntrenador(index)"
                  *ngIf="cargo.mostrarDetalle">
                  <mat-icon>expand_less</mat-icon>
                </button>
              </td>
            </tr>
            <ng-container *ngIf="cargo.mostrarDetalle">
              <tr *ngFor="let detalleCargo of cargo.detalle" [ngClass]="{'d-print-none': detalleCargo.monto == 0}">
                <td></td>
                <td>{{ detalleCargo.referencia }}</td>
                <td>{{ detalleCargo.cantidad | number}}</td>
                <td>{{ detalleCargo.monto | currency }}</td>
                <td>{{ detalleCargo.entry }} - {{ detalleCargo.caballo }}</td>
                <td>
                  <button class="d-print-none" [disabled]="validated" mat-icon-button
                    (click)="eliminarCargo(detalleCargo.id, detalleCargo.nombre, detalleCargo.cantidad, detalleCargo.monto)">
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>
</ng-template>

<ng-template #cardFeedOrdersSummary>
  <div class="card-header bg-dark" style="color: #ffffff !important; padding: 0.25rem 1.5rem" [ngClass]="{'d-print-none': orderSummary.length == 0}">
    <h3 style="margin: 0px">
      <span>Feed orders summary</span>
    </h3>
  </div>
  <div class="card-body text-panel p-print-0" [ngClass]="{'d-print-none': orderSummary.length == 0}" *ngIf="!loading.get('orders'); else cardPlaceholder">
    <div></div>
    <table class="table table-hover table-condensed m-0">
      <thead>
        <tr>
          <th><b>Concept</b></th>
          <th><b>Qty</b></th>
          <th><b>Total</b></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orderSummary">
          <td>{{ order.producto }}</td>
          <td>{{ order.cantidad }}</td>
          <td>{{ order.total | currency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #cardSplitsEntrenador>
  <div class="card-header bg-info" style="color: #ffffff !important; padding: 0.25rem 1.5rem">
    <h3 style="margin: 0px">
      <span>Splits</span>
      <span style="float: right">{{ totalSplitsEntrenador | currency }}</span>
    </h3>
  </div>
  <div class="card-body text-panel p-print-0" *ngIf="!loading.get('splits'); else cardPlaceholder">
    <h4 class="header-title mb-4">Splits</h4>
    <div class="btn btn-success d-print-none" (click)="makeSplits()">New Split</div>
    <table class="table table-hover table-condensed m-0">
      <thead>
        <tr>
          <th>Entry</th>
          <th>Notes</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let split of splitsEntrenador" [ngClass]="{'hidden-print': split.anulado}">
          <td>{{ split.entry }} - {{ split.caballo }}</td>
          <td>{{ split.notas }}</td>
          <td [ngClass]="{scratched: split.anulado}">{{ split.monto | currency }}</td>
          <td class="hidden-print">
            <button mat-icon-button [disabled]="validated" style="height: 30px; width: 30px; line-height: 1;" *ngIf="!split.anulado && privilegios >= 200" (click)="anularSplit(split.id, split.caballo, split.responsable, split.monto)">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #cardEntriesEntrenador>
  <div class="card-header bg-dark" style="color: #ffffff !important; padding: 0.25rem 1.5rem">
    <h3 style="margin: 0px">
      <span>Entries</span>
    </h3>
  </div>
  <div class="card-body text-panel p-print-0" *ngIf="!loading.get('entries'); else cardPlaceholder">
    <h4 class="header-title mb-3 row">
      <div class="col-lg-3">
        <span>Balance per Entry</span>
        <a href="javascript:;" (click)="refreshSaldosEntry()"><i class="mdi mdi-refresh"></i></a>
      </div>
      <div class="col-lg-1">
        <i *ngIf="cuentasEntries" (click)="ocultarCuentasEntries()" style="float: right;font-size: 1.5rem;margin-top: -6px; cursor: pointer;" class="mdi mdi-eye"></i>
        <i *ngIf="!cuentasEntries" (click)="mostrarCuentasEntries()" style="float: right;font-size: 1.5rem;margin-top: -6px; cursor: pointer;" class="mdi mdi-eye-off"></i>
      </div>
      <button class="btn btn-dark btn-sm mr-2 d-print-none col-lg-3" (click)="imprimirCuentas()" *ngIf="entrenadorSeleccionado">
        <i class="mdi mdi-printer"></i> Invoices
      </button>
      <button class="btn btn-dark btn-sm mr-2 d-print-none col-lg-3" [disabled]="!hasEntriesWithCredit()" (click)="openSettleCreditsModal()" *ngIf="entrenadorSeleccionado">
        Settle Credits
      </button>
      <button class="btn btn-dark btn-sm d-print-none col-lg-1" onclick="window.print()" *ngIf="entrenadorSeleccionado">
        <i class="mdi mdi-printer"></i>
      </button>

    </h4>
    <div class="row justify-content-left mb-2">
      <div class="col">
        <button class="btn btn-dark btn-sm d-print-none mt-2" (click)="marcarYDesmarcarTodo()" *ngIf="entrenadorSeleccionado">
          <span [innerHTML]="estatusMarcarYDesmarcarTodo() ? 'Select all' : 'Unselect all'"></span>
        </button>
      </div>
    </div>
    <div class="custom-control custom-checkbox w-100">
      <input type="checkbox" class="custom-control-input" id="trainerBalance" [(ngModel)]="trainerBalance"><!-- (change)="actualizarCantidadPago()" -->
      <label class="custom-control-label w-100 d-flex" for="trainerBalance"><b style="font-size: .9375rem;width: -webkit-fill-available;">Trainer Balance</b><span class="font-13 my-auto">{{saldoEntrenador | currency }}</span></label>
    </div>
    <div *ngFor="let caballo of entriesEntrenador; let i = index;" class="media mt-1">
      <!--img class="mr-3 rounded-circle" src="assets/images/icons/horse.svg" width="30" alt="Generic horse"-->
      <div class="custom-control custom-checkbox w-100">
        <input type="checkbox" class="custom-control-input" id="customCheck1{{i}}" [(ngModel)]="entriesEntrenador[i].sumar"><!-- (change)="actualizarCantidadPago()" -->
        <label class="custom-control-label w-100 d-flex" for="customCheck1{{i}}">
          <b style="font-size: .9375rem;width: -webkit-fill-available;">{{ caballo.entry }} - {{ caballo.nombre }}</b>
          <span class="font-13 my-auto">{{ caballo.saldo | currency }}</span>
        </label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <h4 class="mb-0 text-right">Total: {{ sumaTotalEntries() | currency }}</h4>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cardPagosEntries>
  <div class="card-header bg-success d-print-none" style="color: #ffffff !important; padding: 0.25rem 1.5rem">
    <h3 style="margin: 0px">
      <span>Payments - Entries</span>
    </h3>
  </div>
  <div class="card-body text-panel p-print-0 d-print-none" *ngIf="!loading.get('entriesPayments'); else cardPlaceholder">
    <table class="table table-sm table-hover table-condensed m-0">
      <thead>
        <tr>
          <th><b>Entry</b></th>
          <th><b>Date</b></th>
          <th><b>Method</b></th>
          <th><b class="d-print-none">Notes</b></th>
          <th><b>Amount</b></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of entriesPayments">
          <td>{{ payment.entry }} - {{ payment.caballo }}</td>
          <td>{{ payment.fecha_pago }}</td>
          <td>{{ traducirMetodoDePago(payment.metodo) }}</td>
          <td><span class="d-print-none">{{ payment.referencia }}</span></td>
          <td>
            <ng-container *ngIf="payment.metodo == 'Reembolso'">-</ng-container>{{ payment.monto | currency }}
          </td>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #cardPagosEntrenador>
  <div class="card-header bg-success" style="color: #ffffff !important; padding: 0.25rem 1.5rem">
    <h3 style="margin: 0px">
      <span>Payments</span>
      <span style="float: right">{{ totalPagosEntrenador | currency }}</span>
    </h3>
  </div>
  <div class="card-body text-panel p-print-0"  *ngIf="!loading.get('payments'); else cardPlaceholder">
    <table class="table table-sm table-hover table-condensed m-0">
      <thead>
        <tr>
          <th><b>Date</b></th>
          <th><b>Method</b></th>
          <th><b class="d-print-none">Notes</b></th>
          <th><b>Amount</b></th>
          <th class="hidden-print"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="hidden-print" [formGroup]="formPagoEntrenador">
          <td>
            <input class="form-control input-s" min="0" type="date" formControlName="fecha" [ngClass]="{'no-valido': formPagoEntrenador.get('fecha').invalid && (formPagoEntrenador.get('fecha').touched || formPagoEntrenador.get('fecha').dirty)}">
          </td>
          <td>
            <select class="form-control input-s" formControlName="metodo" [ngClass]="{'no-valido': formPagoEntrenador.get('metodo').invalid && (formPagoEntrenador.get('metodo').touched || formPagoEntrenador.get('metodo').dirty)}">
              <option value="" selected disabled></option>
              <option value="Tc">Credit Card</option>
              <option value="Cheque">Check</option>
              <option value="Deposito">Bank Deposit</option>
              <option value="Efectivo">Cash</option>
              <option value="Transferencia">Wire Transfer</option>
              <option value="Reembolso">Refund</option>
              <option value="Cortesia">Courtesy</option>
              <option value="Intercambio">Exchange</option>
              <option value="CC PrePayment">CC PrePayment</option>
              <option value="PrizeMoney">Prize Money</option>
            </select>
          </td>
          <td>
            <input class="form-control input-s" type="text" formControlName="referencia" [ngClass]="{'no-valido': formPagoEntrenador.get('referencia').invalid && (formPagoEntrenador.get('referencia').touched || formPagoEntrenador.get('referencia').dirty)}">
          </td>
          <td>
            <!--input class="form-control input-s" min="0" type="number" formControlName="monto" [ngClass]="{'no-valido': formPagoEntrenador.get('monto').invalid && (formPagoEntrenador.get('monto').touched || formPagoEntrenador.get('monto').dirty)}"-->
          </td>
          <td style=" background-color: #0acf97; color: white; /* border-radius: 0px 15px 15px 0 */; text-align: center;">
            <button class="btn btn-icon btn-success" style="font-size:1.5rem; padding:0; box-shadow: 0 0px 0px 0 rgba(57,175,209,.5);" (click)="agregarPagoEntrenador()">
              <i class="mdi mdi-plus-circle"></i>
            </button>
          </td>
        </tr>
        <tr *ngFor="let pago of pagosEntrenador" [ngClass]="{'scratched': pago.anulado}">
          <td>{{ pago.fecha_pago }}</td>
          <td>{{ traducirMetodoDePago(pago.metodo) }}</td>
          <td><span class="d-print-none">{{ pago.referencia }}</span></td>
          <td>
            <ng-container *ngIf="pago.metodo == 'Reembolso'">-</ng-container>{{ pago.monto | currency }}
          </td>
          <!--td>{{ pago.caballo }}</td-->
          <td class="table-action text-center">
            <a href="javascript: void(0);" class="action-icon" *ngIf="!pago.anulado"
              (click)="anularPago(pago.id, pago.monto, pago.metodo)">
              <i class="mdi mdi-close-circle-outline"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #modalSettleCredits>
  <div class="modal fade" id="settleCreditsModal" tabindex="-1" role="dialog" aria-labelledby="settleCreditsLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="settleCreditsLabel">Settle Credits</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body p-2">
          <div class="row">
            <div class="col-12" *ngIf="showSettleCredits">
              <app-settle-credits [entries]="entriesEntrenador" (onError)="onErrorSettleCredits($event)" (onSuccess)="onSuccessSettleCredits($event)"></app-settle-credits>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cardPlaceholder>

  <div class="card-body text-panel p-print-0">
    <p class="placeholder-glow">
      <span class="placeholder col-6"></span>
      <span class="placeholder w-75"></span>
      <span class="placeholder" style="width: 25%;"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder w-75"></span>
      <span class="placeholder" style="width: 25%;"></span>
    </p>
  </div>
</ng-template>
