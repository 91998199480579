<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
            <div class="container-fluid p-print-0">
                <div class="row">
                    <div class="col-6">
                        <div class="card">
                            <h5 class="card-header d-flex py-1 justify-content-between">
                                <span class="my-auto">Leading lady</span>
                                <span>
                                    <button class="btn btn-sm btn-success" onclick="$(this).next().click()"><i class="mdi mdi-file-excel-box mr-1"></i>Upload</button>
                                    <input class="d-none" type="file" (change)="cargarExcel($event, 'leadingLady')">
                                    <button *ngIf="leadingLady.length > 0" class="btn btn-sm btn-success ml-3" (click)="save('leadingLady')"><i class="mdi mdi-content-save mr-1"></i>Save</button>
                                </span>
                            </h5>
                            <div class="card-body">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Placing</th>
                                            <th>Name</th>
                                            <th>Pts</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rider of leadingLady">
                                            <td>{{ rider.placing }}</td>
                                            <td>{{ rider.name }}</td>
                                            <td>{{ rider.pts }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <h5 class="card-header d-flex py-1 justify-content-between">
                                <span class="my-auto">FEI</span>
                                <span>
                                    <button class="btn btn-sm btn-success" onclick="$(this).next().click()"><i class="mdi mdi-file-excel-box mr-1"></i>Upload</button>
                                    <input class="d-none" type="file" (change)="cargarExcel($event, 'fei')">
                                    <button *ngIf="fei.length > 0" class="btn btn-sm btn-success ml-3" (click)="save('fei')"><i class="mdi mdi-content-save mr-1"></i>Save</button>
                                </span>
                            </h5>
                            <div class="card-body">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Placing</th>
                                            <th>Name</th>
                                            <th>Pts</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rider of fei">
                                            <td>{{ rider.placing }}</td>
                                            <td>{{ rider.name }}</td>
                                            <td>{{ rider.pts }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <h5 class="card-header d-flex py-1 justify-content-between">
                                <span class="my-auto">JR/AM</span>
                                <span>
                                    <button class="btn btn-sm btn-success" onclick="$(this).next().click()"><i class="mdi mdi-file-excel-box mr-1"></i>Upload</button>
                                    <input class="d-none" type="file" (change)="cargarExcel($event, 'jrAm')">
                                    <button *ngIf="jrAm.length > 0" class="btn btn-sm btn-success ml-3" (click)="save('jrAm')"><i class="mdi mdi-content-save mr-1"></i>Save</button>
                                </span>
                            </h5>
                            <div class="card-body">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Placing</th>
                                            <th>Name</th>
                                            <th>Pts</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rider of jrAm">
                                            <td>{{ rider.placing }}</td>
                                            <td>{{ rider.name }}</td>
                                            <td>{{ rider.pts }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <h5 class="card-header d-flex py-1 justify-content-between">
                                <span class="my-auto">CH/AA</span>
                                <span>
                                    <button class="btn btn-sm btn-success" onclick="$(this).next().click()"><i class="mdi mdi-file-excel-box mr-1"></i>Upload</button>
                                    <input class="d-none" type="file" (change)="cargarExcel($event, 'chAa')">
                                    <button *ngIf="chAa.length > 0" class="btn btn-sm btn-success ml-3" (click)="save('chAa')"><i class="mdi mdi-content-save mr-1"></i>Save</button>
                                </span>
                            </h5>
                            <div class="card-body">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Placing</th>
                                            <th>Name</th>
                                            <th>Pts</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rider of chAa">
                                            <td>{{ rider.placing }}</td>
                                            <td>{{ rider.name }}</td>
                                            <td>{{ rider.pts }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <h5 class="card-header d-flex py-1 justify-content-between">
                                <span class="my-auto">Rider Bonus</span>
                                <span>
                                    <button class="btn btn-sm btn-success" onclick="$(this).next().click()"><i class="mdi mdi-file-excel-box mr-1"></i>Upload</button>
                                    <input class="d-none" type="file" (change)="cargarExcel($event, 'riderBonus')">
                                    <button *ngIf="riderBonus.length > 0" class="btn btn-sm btn-success ml-3" (click)="save('riderBonus')"><i class="mdi mdi-content-save mr-1"></i>Save</button>
                                </span>
                            </h5>
                            <div class="card-body">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Placing</th>
                                            <th>Name</th>
                                            <th>Pts</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rider of riderBonus">
                                            <td>{{ rider.placing }}</td>
                                            <td>{{ rider.name }}</td>
                                            <td>{{ rider.pts }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>