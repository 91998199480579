<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
  <div class="sk-cube-grid ed">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrap
">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
      <div class="content">
          <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
          <div class="container-fluid p-print-0">
              <div class="row mt-3">
                  <div class="col-4">
                      <div class="card">
                          <div class="card-body">
                              <table class="table table-sm table-hover table-striped" id="caballos">
                                  <thead>
                                      <tr>
                                          <th></th>
                                          <th></th>
                                      </tr>
                                  </thead>
                                  <tbody>

                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
                  <div class="col-8" *ngIf="caballoSeleccionado.ids">
                      <div class="card">
                          <div class="card-body">
                              <form [formGroup]="form" (ngSubmit)="merge()">
                                  <div class="row">
                                      <div class="col-12">
                                          <h4>{{ caballoSeleccionado.name }}</h4>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label># FEI</label>
                                              <select name="fei" id="fei" class="form-control form-control-sm" formControlName="fei" *ngIf="caballoSeleccionado?.feis?.length > 1">
                                                  <option [value]="fei" *ngFor="let fei of caballoSeleccionado.feis">{{ fei }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.fei" *ngIf="!(caballoSeleccionado?.feis?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label># USEF</label>
                                              <select name="usef" id="usef" class="form-control form-control-sm" formControlName="usef" *ngIf="caballoSeleccionado?.usefs?.length > 1">
                                                  <option [value]="usef" *ngFor="let usef of caballoSeleccionado.usefs">{{ usef }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.usef" *ngIf="!(caballoSeleccionado?.usefs?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label># NSBA</label>
                                              <select name="nsba" id="nsba" class="form-control form-control-sm" formControlName="nsba" *ngIf="caballoSeleccionado?.nsbas?.length > 1">
                                                  <option [value]="nsba" *ngFor="let nsba of caballoSeleccionado.nsbas">{{ nsba }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.nsba" *ngIf="!(caballoSeleccionado?.nsbas?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label>Owner</label>
                                              <select name="owner" id="owner" class="form-control form-control-sm" formControlName="owner" *ngIf="caballoSeleccionado?.owners?.length > 1">
                                                  <option [value]="owner" *ngFor="let owner of caballoSeleccionado.owners">{{ owner }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.owner" *ngIf="!(caballoSeleccionado?.owners?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label>Chip</label>
                                              <select name="chip" id="chip" class="form-control form-control-sm" formControlName="chip" *ngIf="caballoSeleccionado?.chips?.length > 1">
                                                  <option [value]="chip" *ngFor="let chip of caballoSeleccionado.chips">{{ chip }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.chip" *ngIf="!(caballoSeleccionado?.chips?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label>Country of birth</label>
                                              <select name="country" id="country" class="form-control form-control-sm" formControlName="country" *ngIf="caballoSeleccionado?.countries?.length > 1">
                                                  <option [value]="country" *ngFor="let country of caballoSeleccionado.countries">{{ country }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.country" *ngIf="!(caballoSeleccionado?.countries?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label>Color</label>
                                              <select name="color" id="color" class="form-control form-control-sm" formControlName="color" *ngIf="caballoSeleccionado?.colors?.length > 1">
                                                  <option [value]="color" *ngFor="let color of caballoSeleccionado.colors">{{ color }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.color" *ngIf="!(caballoSeleccionado?.colors?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-4">
                                          <div class="form-group">
                                              <label>Color complement</label>
                                              <select name="complement" id="complement" class="form-control form-control-sm" formControlName="complement" *ngIf="caballoSeleccionado?.complements?.length > 1">
                                                  <option [value]="complement" *ngFor="let complement of caballoSeleccionado.complements">{{ complement }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.complement" *ngIf="!(caballoSeleccionado?.complements?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-4">
                                          <div class="form-group">
                                              <label>Breed</label>
                                              <select name="breed" id="breed" class="form-control form-control-sm" formControlName="breed" *ngIf="caballoSeleccionado?.breeds?.length > 1">
                                                  <option [value]="breed" *ngFor="let breed of caballoSeleccionado.breeds">{{ breed }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.breed" *ngIf="!(caballoSeleccionado?.breeds?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-4">
                                          <div class="form-group">
                                              <label>Gender</label>
                                              <select name="gender" id="gender" class="form-control form-control-sm" formControlName="gender" *ngIf="caballoSeleccionado?.genders?.length > 1">
                                                  <option [value]="gender" *ngFor="let gender of caballoSeleccionado.genders">{{ gender }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.gender" *ngIf="!(caballoSeleccionado?.genders?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-6">
                                          <div class="form-group">
                                              <label>Date of birth</label>
                                              <select name="date" id="date" class="form-control form-control-sm" formControlName="date" *ngIf="caballoSeleccionado?.dates?.length > 1">
                                                  <option [value]="date" *ngFor="let date of caballoSeleccionado.dates">{{ date }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.date" *ngIf="!(caballoSeleccionado?.dates?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label>Sire</label>
                                              <select name="sire" id="sire" class="form-control form-control-sm" formControlName="sire" *ngIf="caballoSeleccionado?.sires?.length > 1">
                                                  <option [value]="sire" *ngFor="let sire of caballoSeleccionado.sires">{{ sire }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.sire" *ngIf="!(caballoSeleccionado?.sires?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label>Dam</label>
                                              <select name="dam" id="dam" class="form-control form-control-sm" formControlName="dam" *ngIf="caballoSeleccionado?.dams?.length > 1">
                                                  <option [value]="dam" *ngFor="let dam of caballoSeleccionado.dams">{{ dam }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.dam" *ngIf="!(caballoSeleccionado?.dams?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label>Height</label>
                                              <select name="height" id="height" class="form-control form-control-sm" formControlName="height" *ngIf="caballoSeleccionado?.heights?.length > 1">
                                                  <option [value]="height" *ngFor="let height of caballoSeleccionado.heights">{{ height }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.height" *ngIf="!(caballoSeleccionado?.heights?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label>Height category</label>
                                              <select name="category" id="category" class="form-control form-control-sm" formControlName="category" *ngIf="caballoSeleccionado?.categories?.length > 1">
                                                  <option [value]="category" *ngFor="let category of caballoSeleccionado.categories">{{ category }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.category" *ngIf="!(caballoSeleccionado?.categories?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-12">
                                          <div class="form-group">
                                              <label>Notes</label>
                                              <select name="nota" id="nota" class="form-control form-control-sm" formControlName="nota" *ngIf="caballoSeleccionado?.notas?.length > 1">
                                                  <option [value]="nota" *ngFor="let nota of caballoSeleccionado.notas">{{ nota }}</option>
                                              </select>
                                              <input type="text" class="form-control form-control-sm" readonly [value]="form.value.nota" *ngIf="!(caballoSeleccionado?.notas?.length > 1)">
                                          </div>
                                      </div>
                                      <div class="col-12 text-right">
                                          <button type="button" class="btn btn-secondary mr-3" (click)="caballoSeleccionado = {}">Cancel</button>
                                          <button type="submit" class="btn btn-success">Merge</button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

