import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DivisionChartsService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public getResults(divisionIds): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };
    const params = JSON.stringify({ divisionIds });

    return this.http.post(`${environment.api.uri}divisions/champCharts/`, params, { headers: headers });
  }

  public getDivisionchart(divisionId, idConcurso){
    return this.http.get(`${environment.api.nest.uri}divisiones/charts/${divisionId}?idConcurso=${idConcurso}`);
  }
}
