<div class="am-wrapper">
    <menu class="navbar-custom"></menu>
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="page-head hidden-print" style="margin-bottom: 35px;">
            <h2>{{ nombreConcurso }}</h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-default">
                    <div class="panel-body">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="card">
                              <div class="card-body">
                                <div class="col-md-12">
                                  <h4 class="header-title mt-2"><b>Riders (in classes)</b></h4>
                                  <table class="table table-striped table-fw-widget table-hover">
                                    <tbody>
                                      <tr>
                                        <td><b>FEI Riders</b></td>
                                        <td>{{ jinetesInternacionales.semana1 }}</td>

                                      </tr>
                                      <tr>
                                        <td><b>National Riders</b></td>
                                        <td>{{ jinetesNacionales.semana1 }}</td>

                                      </tr>
                                      <tr>
                                        <td><b>Total Riders</b></td>
                                        <td>{{ totalJinetes.semana1 }}</td>

                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="card">
                              <div class="card-body">
                                  <h4 class="header-title mt-2"><b>Horses (in classes)</b></h4>
                                  <table class="table table-striped table-fw-widget table-hover">
                                    <tbody>
                                      <tr>
                                        <td><b>FEI Horses</b></td>
                                        <td>{{ caballosCsi.semana1 }}</td>
                                      </tr>
                                      <tr>
                                        <td><b>FEI Young Horses</b></td>
                                        <td>{{ caballosJovenes.semana1 }}</td>
                                      </tr>
                                      <tr>
                                        <td><b>Total FEI Horses</b></td>
                                        <td>{{ caballosInternacionales.semana1 }}</td>
                                      </tr>
                                      <tr>
                                        <td><b>National Horses</b></td>
                                        <td>{{ caballosNacionales.semana1 }}</td>
                                      </tr>
                                      <tr>
                                        <td><b>Total</b></td>
                                        <td>{{ totalGlobal.semana1 }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="col-md-12">
                                <div class="card">
                                  <div class="card-body">
                                    <h4 class="header-title mt-2"><b>Participants per Day</b></h4>
                                    <div class="table-responsive">
                                      <table class="table table-striped table-centered mb-0">
                                        <thead>
                                          <tr>
                                            <th *ngFor="let dia of participantes.encabezados1"><b>{{ dia | date: 'EEEE' }}</b></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td *ngFor="let total of participantes.semana1">{{ total }}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
