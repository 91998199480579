<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
        <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
        <div class="container-fluid" style="width: 100%; float: left; display: flex;"> 
          <div class="col-md-12">
            <div class="card margin-top-35">
              <div *ngIf="solicitudesUsuarioOnline.length > 0" class="card-body padding-0">
                <div class="col-md-12" style="margin-top:30px;">
                  <div class="col-md-12 table-responsive">
                    <table class="table table-tw-widget table-hover table-sm">
                      <thead>
                        <tr>
                          <th>Email (usuario)</th>
                          <th>Teléfono</th>
                          <th>Nombre</th>
                          <th>ID del responsable</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let solicitud of solicitudesUsuarioOnline">
                          <td> {{ solicitud.usuario }}</td>
                          <td> {{ solicitud.pass }}</td>
                          <td> {{ solicitud.nombre }}</td>
                          <td> {{ solicitud.id }}</td>
                          <td> 
                            <button class="btn btn-dark" (click)="accionesUsuarioOnline(solicitud.id,solicitud.nombre,solicitud.usuario);">
                              Habilitar usuario
                            </button>  
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div id="pendientes-0" class="centered row justify-content-md-center" style="background-color: #fafbfe !important;" *ngIf="solicitudesUsuarioOnline.length == 0 && loaded == true">
                <div class="col-6">
                  <img style="width:100px;" src="assets/images/icons/partners-claping-hands.svg" alt="cool">
                </div>
                <div class="col-12">
                  <p>¡Hurra! No hay peticiones pendientes por atender</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>