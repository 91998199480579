/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS, HttpContextToken, HttpContext } from '@angular/common/http';

import { AuthInterceptor } from './auth-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

export const AUTH_HEADER = new HttpContextToken(() => false);

export const ADD_AUTH_HEADER = { context: new HttpContext().set(AUTH_HEADER, true) };
