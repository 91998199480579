<div id="loader" style="display:block; opacity: 1; background: rgba(255, 255, 255, 0.4); z-index: 1051;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid p-print-0">
                <div class="row justify-content-center">
                    <div class="col-10 mt-3 master-list">
                        <div class="card">
                            <div class="card-body p-2" style="border: solid 1px #d0d0d0">
                                <div class="row">
                                    <div class="col-12 text-right d-print-none">
                                        <button type="button" class="btn btn-outline-primary mr-2" *ngFor="let evento of eventos" [ngClass]="{'active': eventoSeleccionado == evento}" (click)="getMasterList(evento)">{{ evento }}</button>
                                        <button type="button" class="btn btn-primary" onclick="print()">
                                            <i class="mdi mdi-printer mr-1"></i>
                                            <span>Print</span>
                                        </button>
                                    </div>
                                    <!-- Encabezado -->
                                    <div class="col-3 my-auto">
                                        <img style="max-width:75%; max-height: 100px;" [src]="logoClub" />
                                    </div>
                                    <div class="col-6 my-auto">
                                        <h4 class="text-center"><b>{{ nombreConcurso }}</b></h4>
                                        <h4 class="text-center"><b>Master List</b></h4>
                                    </div>
                                    <div class="col-3 my-auto text-right">
                                        <img style="max-width:75%; max-height: 100px;" [src]="header" />
                                    </div>
                                    <!-- Fin encabezado -->
                                    <div class="col-12 mt-2">
                                        <table class="table table-hover table-condensed table-sm mb-0">
                                            <thead>
                                                <tr>
                                                    <!--th>Show Name</th-->
                                                    <th>Back Number</th>
                                                    <th>Horse Name</th>
                                                    <th>Horse Info</th>
                                                    <th>Owner</th>
                                                    <th>Owner Info</th>
                                                    <th>Trainer</th>
                                                    <th>Rider</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let horse of horses">
                                                    <!--td>{{ horse['Show Name'] }}</td-->
                                                    <td>{{ horse['Back Number'] }}</td>
                                                    <td>{{ horse['Horse Name'] }}</td>
                                                    <td>{{ horse['Horse Info'] }}</td>
                                                    <td>{{ horse['Owner'] }}</td>
                                                    <td>{{ horse['Owner Info'] }}</td>
                                                    <td>{{ horse['Trainer'] }}</td>
                                                    <td>{{ horse['Rider'] }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- Footer -->
                                    <div class="col-12 text-center">
                                        <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                                    </div>
                                    <!-- Fin footer -->
                                    <div class="col-12 d-none d-print-block">
                                        <div class="poweredBy">Powered by Equestrian Digital <img src="assets/images/logo.png"></div>
                                    </div>
                                    <div class="col-12 d-none d-print-block text-right">
                                        <p class="mb-4 mt-2">Download the App for Class Results, Live Video and Add/Scratches</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
