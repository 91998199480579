<div class="row mt-4 justify-content-center px-3">
    <div class="col-12 col-sm-6 col-md-4" *ngFor="let pista of pistas">
        <div class="card">
            <div class="card-header text-white bg-primary text-xs-center p-2">
                {{ pista.nombre }}
            </div>
            <div class="card-body p-0">
                <table class="table table-sm table-hover mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Class</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="prueba" *ngFor="let prueba of pista.pruebas" [routerLink]="['/order-paddock', prueba.ipc]">
                            <td>{{ prueba.numero }}</td>
                            <td>{{ prueba.nombre }}</td>
                            <td>{{ fechaApple(prueba.inicio) | date }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>