import { Component, OnInit, AfterViewInit, ViewChildren, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PersonaService } from '../services/persona.service';
import { AuthService } from '../services/auth.service';
import { PaisService } from '../services/pais.service';
import { ChaseService } from '../services/chase.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
declare var $: any;
export interface Personas {
}
@Component({
  selector: 'directorio-personas',
  templateUrl: './directorio-personas.component.html',
  styleUrls: ['./directorio-personas.component.css']
})

export class DirectorioPersonasComponent implements OnInit, AfterViewInit {
  private idUsuario: string;
  public idConcurso: string | boolean;
  public nombreConcurso: string;
  private token: string;
  private sessionData: any;
  public persons: any[];
  public personaSeleccionada: string;
  public form: UntypedFormGroup;
  public paises: any[];
  public queryPais: string;
  public mostrarPanel: boolean;
  public organization: boolean;
  public cards: any[];
  public errorTarjeta: string;
  public cardForm: UntypedFormGroup;
  public dataSource;
  public displayedColumns: string[];
  public historial: any[];
  public reining: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private personaService: PersonaService,
    private paisService: PaisService,
    private chaseService: ChaseService,
    private db: AngularFirestore
  ) {
    this.idUsuario = '';
    this.idConcurso = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.token = '';
    this.sessionData = {};
    this.persons = [];
    this.personaSeleccionada = '';
    this.form = new UntypedFormGroup({
      fei: new UntypedFormControl('', []),
      usef: new UntypedFormControl('', []),
      ushja: new UntypedFormControl('', []),
      nsba: new UntypedFormControl('', []),
      nrha: new UntypedFormControl('', []),
      name: new UntypedFormControl('', [Validators.required]),
      lastname: new UntypedFormControl('', []),
      maternalLastname: new UntypedFormControl('', []),
      notas: new UntypedFormControl('', []),
      amateurProStatus: new UntypedFormControl('', []),
      w9: new UntypedFormControl('', []),
      email: new UntypedFormControl('', []),
      age: new UntypedFormControl('', []),
      nationality: new UntypedFormControl('', []),
      dateOfBirth: new UntypedFormControl('', []),
      telephone: new UntypedFormControl('', []),
      cellPhone: new UntypedFormControl('', []),
      gender: new UntypedFormControl('', []),
      usefAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      taxAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      mailAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      taxId: new UntypedFormControl('', []),
      taxName: new UntypedFormControl('', []),
      suspensionStatus: new UntypedFormControl('', []),
      amateur: new UntypedFormControl('', [])
    });
    this.paises = [];
    this.queryPais = '';
    this.mostrarPanel = false;
    this.organization = false;
    this.cards = [];
    this.errorTarjeta = '';
    this.cardForm = new UntypedFormGroup({
      idPersona: new UntypedFormControl('', [Validators.required]),
      idConcurso: new UntypedFormControl('', [Validators.required]),
      ccAccountNum: new UntypedFormControl('', [Validators.required, Validators.minLength(18)/*, Validators.maxLength(19)*/]),
      ccExp: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
      customerName: new UntypedFormControl('', [Validators.required]),
      customerAddress1: new UntypedFormControl('', []),
      customerAddress2: new UntypedFormControl('', []),
      customerCity: new UntypedFormControl('', []),
      customerState: new UntypedFormControl('', []),
      customerZIP: new UntypedFormControl('', []),
      customerPhone: new UntypedFormControl('', []),
      customerCountryCode: new UntypedFormControl('', [])
    });
    this.dataSource = new MatTableDataSource<Personas>();
    this.historial = [];
    this.reining = sessionStorage.getItem('reining') == '1';
    this.displayedColumns = this.reining ? ['Name', 'Nationality', 'Nrha', 'History', 'Edit'] : ['Name', 'Nationality', 'Usef', 'Fei', 'History', 'Edit'];;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    $('#loader').show();
    this.form.get('nationality').valueChanges.subscribe(data => {
      this.queryPais = data;
    });
    this.getPaises();
  }

  /**
   * Method that runs when the view finishes rendering
   */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getPersons();
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filterPredicate = (data: Personas, filter: string): boolean => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return (currentTerm + (data as { [key: string]: any })[key] + '◬');
      }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      return dataStr.indexOf(transformedFilter) != -1;
    }
    this.dataSource.filter = filterValue;
  }

  public getPersons() {
    this.personaService.getPersons(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          const persons: any[] = response.personas.map(person => {
            return [person.fullname, `<img height="20" onerror="this.src='assets/images/icons/sin-bandera.jpg'" style="vertical-align:middle;" src="${person.bandera_url}"> ${person.siglas_pais}`, person.usef_id, person.fei, person.nrha||'', `<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon" person="${person.id_persona}"> <i class="mdi mdi-pencil"></i></a></div>`];
          });
          this.persons = response.personas;
          this.dataSource.data = response.personas;
          $('#loader').hide();
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the persons list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getPaises() {
    this.paisService.getPaises().subscribe(
      response => {
        if (!response.error) {
          this.paises = response.paises;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the countries list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getPersonFeiInfo() {
    const fei = this.form.get('fei').value;
    if (fei) {
      $('#loader-fei').show();
      this.personaService.getFeiInfo(fei).subscribe(
        response => {
          if (!response.error) {
            $('#loader-fei').hide();
            const persona = response.persona;
            //Verificar si el pais de nacimiento esta en el lista de paises
            const countryOfBirth = this.paises.find(p => p.acronym == persona.countryOfBirth) ? this.paises.find(p => p.acronym == persona.countryOfBirth)['id'] : '';
            this.form.patchValue({
              name: persona.name||this.form.value.name,
              lastname: persona.lastname||this.form.value.lastname,
              nationality: countryOfBirth||this.form.value.nationality,
              dateOfBirth: persona.dateOfBirth||this.form.value.dateOfBirth,
              gender: persona.gender||this.form.value.gender,
            });
            $.NotificationApp.send("Success", "" , 'bottom-right', '#00b581', 'success');
          } else {
            $('#loader-fei').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader-fei').hide();
          $.NotificationApp.send("Something didn´t work...", "We are having problems performing this request. Please try again or notify this error", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      $.NotificationApp.send("Invalid FEI Number.", "The FEI number is invalid or the input is empty. Please double check the number and try again", 'bottom-right', '#ffbc00', 'warning',7000);
    }
  }

  public getPersonUsefData() {
    const usef = this.form.get('usef').value;
    if (usef) {
      $('#loader-usef').show();
      this.personaService.getUsefData(usef, this.organization).subscribe(
        response => {
          if (!response.error) {
            $('#loader-usef').hide();
            const persona = response.usefData;
            this.form.patchValue({
              name: persona.name||this.form.value.name,
              lastname: persona.lastname||this.form.value.lastname,
              email: persona.email||this.form.value.email,
              age: persona.age||this.form.value.age,
              fei: persona.fei||this.form.value.fei,
              telephone: persona.telephone||this.form.value.telephone,
              dateOfBirth: persona.dateOfBirth||this.form.value.dateOfBirth,
              gender: persona.gender||this.form.value.gender,
              amateurProStatus: persona.amateurProStatus||this.form.value.amateurProStatus,
              suspensionStatus: persona.suspensionStatus||this.form.value.suspensionStatus,
              usefAddress: {
                address: persona.address||this.form.value.usefAddress.address,
                address2: persona.address2||this.form.value.usefAddress.address2,
                country: persona.country||this.form.value.usefAddress.country,
                city: persona.city||this.form.value.usefAddress.city,
                state: persona.state||this.form.value.usefAddress.state,
                zip: persona.zip||this.form.value.usefAddress.zip,
              }
            });
          } else {
            $('#loader-usef').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader-usef').hide();
          console.log(error);
          $.NotificationApp.send("Error", "It has not been possible to perform the query.", 'bottom-right', '#fa5c7c', 'error');
        }
      );
    } else {
      $.NotificationApp.send("Invalid Usef ID.", "The Usef ID is invalid or not assigned.", 'bottom-right', '#ffbc00', 'warning');
    }
  }

  //TODO: Hacer funcionamiento
  public getPersonNrhaData() {

  }

  public selectPerson(idPersona) {
    $('#loader').show();
    this.personaSeleccionada = idPersona;
    this.mostrarPanel = true;
    this.organization = false;
    this.cards = [];
    $('#editPerson').removeClass('was-validated');
    //Consultar datos de persona
    this.personaService.getInfo(idPersona).subscribe(
      response => {
        if (!response.error) {
          console.log(response.info);
          this.cards = response.info.cards;
          this.form.setValue({
            fei: response.info.fei,
            usef: response.info.usef_id,
            ushja: response.info.ushja_id,
            nsba: response.info.nsba_id,
            nrha: response.info.nrha,
            name: response.info.name,
            lastname: response.info.lastname_1,
            maternalLastname: response.info.lastname_2,
            notas: response.info.notas,
            amateurProStatus: response.info.categoria,
            w9: response.info.w9||'',
            email: response.info.email,
            age: response.info.age,
            nationality: response.info.id_nacionalidad == '' ? null : response.info.id_nacionalidad,
            dateOfBirth: response.info.fecha_de_nacimiento,
            telephone: response.info.telefono,
            cellPhone: response.info.celular,
            gender: response.info.genero,
            usefAddress: {
              country: response.info.usef_address.country||'',
              state: response.info.usef_address.state||'',
              city: response.info.usef_address.city||'',
              address: response.info.usef_address.address||'',
              address2: response.info.usef_address.address2||'',
              zip: response.info.usef_address.zip||'',
            },
            taxAddress: {
              country: response.info.tax_address.country||'',
              state: response.info.tax_address.state||'',
              city: response.info.tax_address.city||'',
              address: response.info.tax_address.address||'',
              address2: response.info.tax_address.address2||'',
              zip: response.info.tax_address.zip||'',
            },
            mailAddress: {
              country: response.info.mail_address.country||'',
              state: response.info.mail_address.state||'',
              city: response.info.mail_address.city||'',
              address: response.info.mail_address.address||'',
              address2: response.info.mail_address.address2||'',
              zip: response.info.mail_address.zip||'',
            },
            taxId: response.info.rfc,
            taxName: response.info.razon_social,
            suspensionStatus: response.info.suspensionStatus,
            amateur: response.info.amateur
          });
          this.organization = response.info.entity_type == 'O';
          if (response.info.email) {
            for (const card of this.cards) {
              this.db.collection('cards').doc(response.info.email).collection('cards').doc(card.customerRefNum).set(card);
            }
          }
          $('#loader').hide();
        } else {
          $('#loader-usef').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the info.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public historialPersona(idPersona) {
    this.personaService.historialPersona(idPersona).subscribe(
      response => {
        if (!response.error) {
          this.historial = response.historial;
          $('#loader').hide();
          $('#modalHistorialPersona').modal('show');
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the info.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public panelAgregar() {
    $("#fei-person").focus();
    this.personaSeleccionada = '';
    this.mostrarPanel = true;
    this.organization = false;
    $('#editPerson').removeClass('was-validated');
    this.form.setValue({
      fei: '',
      usef: '',
      nrha: '',
      ushja: '',
      nsba: '',
      name: '',
      lastname: '',
      maternalLastname: '',
      amateurProStatus: '',
      w9: '',
      notas: '',
      email: '',
      age: '',
      nationality: null,
      dateOfBirth: '',
      telephone: '',
      cellPhone: '',
      gender: '',
      usefAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      mailAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxId: '',
      taxName: '',
      suspensionStatus: '',
      amateur: false
    });
  }

  public editPerson() {
    if (this.form.valid) {
      $('#loader').show();
      let persona = {
        ...this.form.value,
        address: this.form.value.usefAddress.address,
        address2: this.form.value.usefAddress.address2,
        country: this.form.value.usefAddress.country,
        city: this.form.value.usefAddress.city,
        state: this.form.value.usefAddress.state,
        zip: this.form.value.usefAddress.zip,
      };
      persona.entity_type = this.organization ? 'O' : 'I';
      //Editar persona
      if (this.personaSeleccionada){
        this.personaService.editPerson(persona, this.personaSeleccionada, this.idConcurso, this.idUsuario).subscribe(
          response => {
            if (!response.error) {
              this.personaSeleccionada = '';
              this.mostrarPanel = false;
              this.getPersons();
            } else {
              $('#loader').hide();
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
            }
          },
          error => {
            $('#loader').hide();
            $.NotificationApp.send("Error", "It has not been possible to edit the person.", 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
          }
        );
      } else {
        //Agregar persona
        this.personaService.addPersona(persona, this.idConcurso, this.idUsuario).subscribe(
          response => {
            if (!response.error) {
              this.mostrarPanel = false;
              this.getPersons();
            } else {
              $('#loader').hide();
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
            }
          },
          error => {
            $('#loader').hide();
            $.NotificationApp.send("Error", "It has not been possible to add the person.", 'bottom-right', '#fa5c7c', 'error');
            console.log(error);
          }
        );
      }
    }
  }

  public displayPais(idPais): string {
    const pais = this.paises.find(p => p.id == idPais);
    return pais ? pais.name : idPais;
  }

  public mostrarModalTarjeta() {
    this.errorTarjeta = '';
    this.cardForm.setValue({
      idPersona: this.personaSeleccionada,
      idConcurso: this.idConcurso,
      ccAccountNum: '',
      ccExp: '',
      customerName: '',
      customerAddress1: '',
      customerAddress2: '',
      customerCity: '',
      customerState: '',
      customerZIP: '',
      customerPhone: '',
      customerCountryCode: ''
    });
    $('#modalAgregarTarjeta form').removeClass('was-validated');
    $('#modalAgregarTarjeta').modal('show');
  }

  public addCard() {
    if (this.cardForm.valid) {
      $('#loader').show();
      this.chaseService.addCard(this.cardForm.value).subscribe(
        response => {
          if (!response.error) {
            $('#modalAgregarTarjeta').modal('hide');
            this.selectPerson(this.personaSeleccionada);
          } else {
            $('#loader').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader').hide();
          $.NotificationApp.send("Error", "It has not been possible to save the card.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    }
  }
}
