<!-- <div class="wrapper"> -->
  <!-- <menu-lateral class="left-side-menu"></menu-lateral> -->
  <!-- <div class="content-page p-print-0"> -->
    <!-- <div class="content"> -->
      <!-- <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu> -->
      <div class="row page-break" *ngIf="divisionChartData?.length > 0">
        <div class="col-12">
          <h4 class="text-center">{{showName}}</h4>
          <h2 class="text-center">Division Chart</h2>
          <h3 class="text-center">{{divisionCode}} - {{divisionName}}</h3>
        </div>
        
        <div class="table">
            <table class="table table-striped table-fw-widget table-hover small-print table-sm p-print-0">
             
              <thead>
                <tr>
                  <th *ngFor="let item of divisionChartData[0] | keys">{{ item.key }}</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of divisionChartData">
                  <td *ngFor="let item of row | keys">
                      <span [ngClass]="item.key == 'Entry' ? 'bold' : item.key == 'Total Points' ? 'bold' : 'regular' " >
                        {{ item.value == 0 ? ' ' : item.value }}
                      </span>

                  </td>
              </tr>
              </tbody>
                
               
            </table>
        </div>
      </div>
    <!-- </div> -->
  <!-- </div> -->
<!-- </div> -->
