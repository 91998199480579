<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid">
                <div class="row mt-4 justify-content-center">
                    <div class="col-8">
                        <div class="card card-body mb-0">
                            <div class="form-group mb-3">
                                <label for="pista">Pista</label>
                                <input type="text" id="pista" class="form-control" (change)="crearPantalla($event)">
                            </div>
                            <div class="row">
                                <div *ngFor="let pantalla of pantallas" class="col-6">
                                    <div class="card">
                                        <h5 class="card-header">{{ pantalla.pista }}</h5>
                                        <div class="card-body">
                                            <table class="table table-hover mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td><b>Control</b></td>
                                                        <td>
                                                            <button type="button" (click)="clipboard('//pantalla/control/', pantalla.id)" class="btn btn-outline-success mr-1">
                                                                <i class="mdi mdi-content-copy mr-1"></i>Copiar
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <a [routerLink]="['/pantalla/control', 'us_'+idConcurso, pantalla.id]" target="_blank">
                                                                <button type="button" class="btn btn-outline-info">
                                                                    <i class="mdi mdi-window-maximize mr-1"></i>Abrir
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Pantalla</b></td>
                                                        <td>
                                                            <button type="button" (click)="clipboard('//pantalla/', pantalla.id)" class="btn btn-outline-success mr-1">
                                                                <i class="mdi mdi-content-copy mr-1"></i>Copiar
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <a [routerLink]="['/pantalla', 'us_'+idConcurso, pantalla.id]" target="_blank">
                                                                <button type="button" class="btn btn-outline-info">
                                                                    <i class="mdi mdi-window-maximize mr-1"></i>Abrir
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Youtube</b></td>
                                                        <td>
                                                            <button type="button" (click)="clipboard('//pantalla/youtube/', pantalla.id)" class="btn btn-outline-success mr-1">
                                                                <i class="mdi mdi-content-copy mr-1"></i>Copiar
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <a [routerLink]="['/pantalla/youtube', 'us_'+idConcurso, pantalla.id]"  target="_blank">
                                                                <button type="button" class="btn btn-outline-info">
                                                                    <i class="mdi mdi-window-maximize mr-1"></i>Abrir
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Scoreboard</b></td>
                                                        <td>
                                                            <button type="button" (click)="clipboard('//pantalla/scoreboard/', pantalla.id)" class="btn btn-outline-success mr-1">
                                                                <i class="mdi mdi-content-copy mr-1"></i>Copiar
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <a [routerLink]="['/pantalla/scoreboard', 'us_'+idConcurso, pantalla.id]"  target="_blank">
                                                                <button type="button" class="btn btn-outline-info">
                                                                    <i class="mdi mdi-window-maximize mr-1"></i>Abrir
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Juez</b></td>
                                                        <td>
                                                            <button type="button" (click)="clipboard('//pantalla/juez/', pantalla.id)" class="btn btn-outline-success mr-1">
                                                                <i class="mdi mdi-content-copy mr-1"></i>Copiar
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <a [routerLink]="['/pantalla/juez', 'us_'+idConcurso, pantalla.id]"  target="_blank">
                                                                <button type="button" class="btn btn-outline-info">
                                                                    <i class="mdi mdi-window-maximize mr-1"></i>Abrir
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>