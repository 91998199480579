<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid">
                <div class="row mt-3 m-print-0 justify-content-center">
                    <div class="col-10">
                        <div class="card">
                            <div class="card-body p-2">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <!--th>Id</th-->
                                            <th>Sku</th>
                                            <th>Qty</th>
                                            <th>Price</th>
                                            <th>Cost</th>
                                            <th>Name</th>
                                            <th>Category</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr [formGroup]="form">
                                            <!--td></td-->
                                            <td><input type="text" class="form-control" formControlName="sku"></td>
                                            <td><input type="number" class="form-control" formControlName="qty"></td>
                                            <td><input type="number" class="form-control" formControlName="price"></td>
                                            <td><input type="number" class="form-control" formControlName="cost"></td>
                                            <td><input type="text" class="form-control" formControlName="name"></td>
                                            <td><input type="text" class="form-control" formControlName="category"></td>
                                            <td class="table-action">
                                                <i class="mdi mdi-spin mdi-loading" *ngIf="form.disabled"></i>
                                                <a *ngIf="!form.disabled" href="javascript: void(0);" class="action-icon" (click)="addProduct()"> <i class="mdi mdi-plus-circle-outline text-success"></i></a>
                                            </td>
                                        </tr>
                                        <ng-container  *ngFor="let product of products">
                                            <tr *ngIf="!product.editable">
                                                <td>{{ product.sku }}</td>
                                                <td>{{ product.qty }}</td>
                                                <td>{{ product.price | currency }}</td>
                                                <td>{{ product.cost | currency }}</td>
                                                <td>{{ product.name }}</td>
                                                <td>{{ product.category }}</td>
                                                <td class="table-action">
                                                    <a *ngIf="!product.editable" href="javascript: void(0);" class="action-icon" (click)="product.editable = true;"> <i class="mdi mdi-pencil"></i></a>
                                                </td>
                                            </tr>
                                            <tr *ngIf="product.editable">
                                               <td>
                                                <input type="text" class="form-control" (change)="editarProducto(product)" [(ngModel)]="product.sku">
                                               </td> 
                                               <td>
                                                <input type="number" class="form-control" (change)="editarProducto(product)" [(ngModel)]="product.qty">
                                               </td>
                                               <td>
                                                <input type="number" class="form-control" (change)="editarProducto(product)" [(ngModel)]="product.price">
                                               </td>
                                               <td>
                                                <input type="number" class="form-control" (change)="editarProducto(product)" [(ngModel)]="product.cost">
                                               </td>
                                               <td>
                                                <input type="text" class="form-control" (change)="editarProducto(product)" [(ngModel)]="product.name">
                                               </td>
                                               <td>
                                                <input type="text" class="form-control" (change)="editarProducto(product)" [(ngModel)]="product.category">
                                               </td>
                                               <td class="table-action">
                                                    <a href="javascript: void(0);" class="action-icon" (click)="product.editable = false;"> <i class="mdi mdi-window-close"></i></a>
                                               </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

