import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ClubService } from '../services/club.service';
import { environment } from './../../environments/environment';
declare let $: any;

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  @Input()
  set concurso(concurso: string) {
    this._concurso = sessionStorage.getItem('nombreConcurso')|| 'Selecting a show...';
  }

  @Input()
  set idConcurso(idConcurso: string) {
    this._idConcurso = sessionStorage.getItem('concurso')
  }

  get concurso(): string { return this._concurso; }
  get idConcurso(): string { return this._idConcurso; }

  @Output() uploaded = new EventEmitter<string>();

  cambiarConcursoOutput() {
    this.uploaded.emit('cambiarConcurso');
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  private _concurso = '';
  private _idConcurso = '';
  public version: string;
  public token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public siglasClub: string;
  public logoClub: string;
  public nombre: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private clubService: ClubService,
    private router: Router
  ) {
    this.token = '';
    this.siglasClub = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.nombre = '';
    this.version = environment.version;
  }

  ngOnChanges(changes: SimpleChanges) {
    const idConcurso: SimpleChange = changes.idConcurso;
    if(idConcurso.currentValue == false){
      this.siglasClub = '';
    }else{
      this._idConcurso = idConcurso.currentValue;
      this.getLogoClub(this._idConcurso);
    }
  }

  public updateUrl(event){
    /*
        Si no se cuenta con la imagen del club, se asigna un valor '' a la variable
        y así se muestra el logo de Ecuestre Digital (validación en vista)
    */
    this.siglasClub = '';
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
      this.nombre = this.sessionData.nombre;
    }
  }

  public logOut(){
    this.authService.logOut();
  }

  public async getLogoClub(val){
    const { error, club } = await this.clubService.getLogo(val).toPromise().then(r => {
      const [club] = r.data||[{}];
      return { error: false, club };
    }, r => ({
      error: r.error,
      club: {}
    })).catch(e => {
      return { error: e.error, club: {} };
    });
    if(error){
      $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }

    this.siglasClub = (club.siglas||'').toLowerCase();
    this.logoClub = club.logo_url||'';
  }
}
