import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class ClassListService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public validarListaUsef(idConcurso, lista): Observable<any>{
    const params = JSON.stringify({ idConcurso, lista });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/validar-usef/`, params, { headers: headers });
  }

  public validarLista(idConcurso, lista, ipc): Observable<any>{
    const params = JSON.stringify({ idConcurso, lista, ipc });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/validar-lista/`, params, { headers: headers });
  }

  public revertirPublicacion(ipc, idUsuario): Observable<any>{
    const params = JSON.stringify({ idUsuario, ipc });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/revertir-publicacion/`, params, { headers: headers });
  }

  public publicarIndividual(ipc, idUsuario): Observable<any>{
    const params = JSON.stringify({ idUsuario, ipc });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/publicar-individual/`, params, { headers: headers });
  }

  public guardarListaUsef(ipc, lista): Observable<any>{
    const params = JSON.stringify({ ipc, lista });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/guardar-usef/`, params, { headers: headers });
  }

  public getBinomios(ipc): Observable<any>{
    const params = JSON.stringify({ ipc });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/class-sheet/`, params, { headers: headers });
  }

  public getListasEntrenador(idConcurso, idEntrenador): Observable<any>{
    const params = JSON.stringify({ idConcurso, idEntrenador });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/listas-entrenador/`, params, { headers: headers });
  }

  public getEntrenadoresConcurso(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/listas-entrenador/entrenadores-concurso/`, params, { headers: headers });
  }

  public getInscripciones(idConcurso): Observable<any>{
    const params = JSON.stringify({ id_concurso: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/listas-entrenador/pruebas-concurso/`, params, { headers: headers });
  }

  public guardarLista(binomios): Observable<any>{
    const params = JSON.stringify({ binomios });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/guardar-lista/`, params, { headers: headers });
  }

  public publicar(idConcurso, idUsuario, nombreConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso, idUsuario, nombreConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/publicar/`, params, { headers: headers });
  }

  public cambiarEntrada(binomios): Observable<any>{
    const params = JSON.stringify({ binomios });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}listas/cambiarEntrada/`, params, { headers: headers });
  }
}
