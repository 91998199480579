import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable, catchError } from 'rxjs';
import { environment } from './../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Firestore, setDoc } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';
import { doc } from 'firebase/firestore';
import { ADD_AUTH_HEADER } from '../http-interceptors';
import { catchErrorMessage, handleError } from '.';

@Injectable()
export class EntriesService extends Api {

  constructor(private http: HttpClient, private db: AngularFirestore, private fs: Firestore) {
    super();
  }

  public getEntries(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

   // return this.http.post(`${environment.api.uri}entries/`, params, { headers: headers });
   return this.http.get(`${environment.api.nest.uri}entries/${idConcurso}`, { headers: headers });
  }

  public getEntriesPaginated(idConcurso, page): Promise<any> {
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    // return this.http.post(`${environment.api.uri}entries/`, params, { headers: headers });
    return firstValueFrom(this.http.get(`${environment.api.nest.uri}entries/paginate/${idConcurso}/${page}`, { headers: headers })).then((r: any) => ({ ...(r.data||{}) })).catch(reason => ({ error: true, message: reason.error.text||(reason.error.error.message||(reason.message||(reason.error||(reason||'Error during request.'))))}));
  }

  public searchEntries(idConcurso, filter): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    // return this.http.post(`${environment.api.uri}entries/`, params, { headers: headers });
    return this.http.get(`${environment.api.nest.uri}entries/${idConcurso}/${filter}`, { headers: headers });
  }

  public addEntry(entry, idConcurso, idUsuario): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/add/`, params, { headers: headers });
  }

  public getInvoice(entry, idConcurso): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/invoice/`, params, { headers: headers });
  }

  public getEntryRidersClasses(entry, idConcurso): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/invoice/riders-classes/`, params, { headers: headers });
  }

  public getEntryInfo(entry, idConcurso): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/invoice/info/`, params, { headers: headers });
  }

  public getEntryClassFees(entry, idConcurso): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/invoice/charges/`, params, { headers: headers });
  }

  public getEntrySplits(entry, idConcurso): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/invoice/splits/`, params, { headers: headers });
  }

  public getInfo(showId: number | string, entryNumbers: string[]): Promise<any> {
    const params = JSON.stringify({ showId, entryNumbers });
    const headers = { 'Content-Type': 'application/json;charset=UTF-8' };

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}entries/invoice/info/`, params, { headers, ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public getStripeCustomers(showId: number | string, entryNumber: string): Promise<any> {
    const params = JSON.stringify({ showId, entryNumber });
    const headers = { 'Content-Type': 'application/json;charset=UTF-8' };

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}entries/invoice/stripe-customers/`, params, { headers, ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public getCharges(showId: number | string, entryNumbers: string[] ): Promise<any> {
    const params = JSON.stringify({ entryNumbers, showId });
    const headers = { 'Content-Type': 'application/json;charset=UTF-8'};

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}entries/invoice/charges/`, params, { headers: headers, ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public getOrdersSummary(showId: number | string, entryNumbers: string[] ): Promise<any> {
    const params = JSON.stringify({ entryNumbers, showId });
    const headers = { 'Content-Type': 'application/json;charset=UTF-8'};

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}entries/invoice/orders-summary/`, params, { headers: headers, ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public getRidersClasses(showId: number | string, entryNumbers: string[] ): Promise<any> {
    const params = JSON.stringify({ entryNumbers, showId });
    const headers = { 'Content-Type': 'application/json;charset=UTF-8'};

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}entries/invoice/riders-classes/`, params, { headers: headers, ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public getClassFees(showId: number | string, entryNumbers: string[] ): Promise<any> {
    const params = JSON.stringify({ entryNumbers, showId });
    const headers = { 'Content-Type': 'application/json;charset=UTF-8'};

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}entries/invoice/class-fees/`, params, { headers: headers, ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public getSplits(showId: number | string, entryNumbers: string[] ): Promise<any> {
    const params = JSON.stringify({ entryNumbers, showId });
    const headers = { 'Content-Type': 'application/json;charset=UTF-8'};

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}entries/invoice/splits/`, params, { headers: headers, ...ADD_AUTH_HEADER }).pipe(catchError(catchErrorMessage))).catch(handleError);
  }

  public getPayments(entry, idConcurso): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/invoice/payments/`, params, { headers: headers });
  }

  public getPrizes(entry, idConcurso): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/invoice/prizes/`, params, { headers: headers });
  }

  public addCharge(datos): Observable<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/addCharge/`, params, { headers: headers });
  }

  public addCharges(datos): Promise<any> {
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}entries/addCharges/`, params, { headers: headers })).catch(reason => ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public cancelCharge(idTransaccion, monto, idUsuario, idConcurso, entry): Observable<any> {
    const params = JSON.stringify({ idTransaccion, monto, idUsuario, idConcurso, entry });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/cancelCharge/`, params, { headers: headers });
  }

  public addPayment(pago: any, comisiones: any[]): Promise<any> {
    const params = JSON.stringify({ pago, comisiones });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}entries/addPayment/`, params, { headers: headers })).catch(reason => ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public cancelPayment(idTransaccion, metodo, monto, idUsuario, idConcurso, entry, motivo = ''): Observable<any> {
    console.log(metodo);
    const params = JSON.stringify({ idTransaccion, metodo, monto, idUsuario, idConcurso, entry, motivo });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/cancelPayment/`, params, { headers: headers });
  }

  //TODO: Eliminar aqui y tambien del api ya que no se usa
  public getInfoResumen(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/resumen/`, params, { headers: headers });
  }

  public editHorsePayee(horse, payee, entry, idConcurso, idUsuario): Observable<any> {
    const params = JSON.stringify({ horse, payee, entry, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/edit/`, params, { headers: headers });
  }

  public editRiderTrainer(rider, trainer, idBinomio, idConcurso, entry): Observable<any> {
    const params = JSON.stringify({ rider, trainer, idBinomio, idConcurso, entry });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/editRiderTrainer/`, params, { headers: headers });
  }

  public addCombination(rider, trainer, horse, payee, owner, entry, idConcurso): Observable<any> {
    const params = JSON.stringify({ rider, trainer, horse, payee, owner, entry, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/addCombination/`, params, { headers: headers });
  }

  public inscribir(bpc): Observable<any> {
    const params = JSON.stringify(bpc);

    return this.http.post(`${environment.api.uri}entries/inscribir/`, params);
  }

  public getEntriesAutobilling(concepto: string, ipc: string, idConcurso: any): Observable<any> {
    const params = JSON.stringify({ concepto, ipc, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/getAutobilling/`, params, { headers: headers });
  }

  public addAutobillingCharge(cargos, idUsuario, idConcurso, concepto): Observable<any> {
    const params = JSON.stringify({ cargos, idUsuario, idConcurso, concepto });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/addAutobillingCharge/`, params, { headers: headers });
  }

  public eliminarBinomioNP(idBinomio, ipc, idConcurso, idUsuario): Observable<any> {
    const params = JSON.stringify({ idBinomio, ipc, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/eliminarBinomioNP/`, params, { headers: headers });
  }

  public riderSwap(ibpc, rider): Observable<any> {
    const params = JSON.stringify({ ibpc, rider });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/riderSwap/`, params, { headers: headers });
  }

  public copy(entries, idConcurso, idConcursoNuevo, idUsuario): Observable<any> {
    const params = JSON.stringify({ entries, idConcurso, idConcursoNuevo, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/copy/`, params, { headers: headers });
  }

  public tarjetasRelacionadas(idConcurso, entries): Observable<any> {
    const params = JSON.stringify({ idConcurso, entries });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/tarjetasRelacionadas/`, params, { headers: headers });
  }

  public async getCustomersStripeFs(personas, idClub): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let stripeCustomerIds = [];
      for(const persona of personas){
        let stripeCustomers = await this.db.collectionGroup('stripe', query => query.where('email', '==', persona.email).where('club_id', '==', idClub)).get().toPromise().then(r => r.docs.map(d => d.data()));
        const stripeCustomer: any = stripeCustomers.shift();
        if(!stripeCustomer) continue;
        stripeCustomerIds.push({
          name: persona.name,
          id: stripeCustomer. customer_id
        });
      }
      resolve(stripeCustomerIds)
    });
  }

  public balanceTransfer(traspaso): Observable<any> {
    const params = JSON.stringify({ traspaso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/balance-transfer/`, params, { headers: headers });
  }

  public getEntriesFei(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}fei/get_entries.php`, params, { headers: headers });
  }

  public validarJinetesyCaballos(jinetes, caballos, idConcurso): Observable<any> {
    const params = JSON.stringify({ jinetes, caballos, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/validar-jinetes-caballos/`, params, { headers: headers });
  }

  public saveEntries(entries, idConcurso): Observable<any> {
    const params = JSON.stringify({ entries, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}fei/save_entries.php`, params, { headers: headers });
  }

  public marcarBinomiosEnMasterList(idConcurso, entries): Observable<any> {
    const params = JSON.stringify({ idConcurso, entries });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/validar-master-list/`, params, { headers: headers });
  }

  public desinscribirCanceladosFei(idConcurso, entries, idUsuario): Observable<any> {
    const params = JSON.stringify({ idConcurso, entries, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/desinscribirCanceladosFei/`, params, { headers: headers });
  }

  public validarCuenta(idConcurso, entry, validated): Observable<any> {
    const params = JSON.stringify({ idConcurso, entry, validated });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/validate-invoice/`, params, { headers: headers });
  }

  public saveNotes(idConcurso, entry, notes): Observable<any> {
    const params = JSON.stringify({ idConcurso, entry, notes });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/save-notes/`, params, { headers: headers });
  }

  public getAllChecks(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/getAllChecks/`, params, { headers: headers });
  }

  public getCheck(idConcurso, entry): Observable<any> {
    const params = JSON.stringify({ idConcurso, entry });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/getCheck/`, params, { headers: headers });
  }

  public scratch(idBinomio, ipc): Observable<any> {
    const params = JSON.stringify({ idBinomio, ipc });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/scratch/`, params, { headers: headers });
  }

  public changeOwner(idCaballo, owner, idConcurso): Observable<any> {
    const params = JSON.stringify({ idCaballo, owner, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/change-owner/`, params, { headers: headers });
  }

  public changeTrainer(idCaballo, trainer, idConcurso): Observable<any> {
    const params = JSON.stringify({ idCaballo, trainer, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/change-trainer/`, params, { headers: headers });
  }

  public changeEntry(idUsuario, idConcurso, entry, nuevoEntry): Observable<any> {
    const params = JSON.stringify({ idUsuario, idConcurso, entry, nuevoEntry });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/change-entry/`, params, { headers: headers });
  }

  public recalcularDivisiones(entry, idConcurso, idUsuario): Observable<any> {
    const params = JSON.stringify({ entry, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/recalcular-divisiones/`, params, { headers: headers });
  }

  public getEntriesToCopy(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/to-copy/`, params, { headers: headers });
  }

  public federationEntryAgreement(idConcurso, entry, rider = ''): Observable<any> {
    const params = JSON.stringify({ idConcurso, entry, rider });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/federation-entry-agreement/`, params, { headers: headers });
  }

  public removeRider(binomio, idUsuario): Observable<any> {
    const params = JSON.stringify({ binomio, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}entries/removeRider/`, params, { headers: headers });
  }

  public setHeight(height: string, ipc: string, idBinomio: string): Promise<any>{
    const params = JSON.stringify({ height, ipc, idBinomio });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}entries/set-height/`, params, { headers: headers })).catch(reason => ({ error: true, message: reason.error.text||(reason.error.error.message||(reason.message||(reason.error||(reason||'Error during request.'))))}));
  }

  public setDeclaredSectionCode(declaredSectionCode: string, ipc: string, idBinomio: string): Promise<any>{
    const params = JSON.stringify({ declaredSectionCode, ipc, idBinomio });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}entries/set-declared-section-code/`, params, { headers: headers })).catch(reason => ({ error: true, message: reason.error.text||(reason.error.error.message||(reason.message||(reason.error||(reason||'Error during request.'))))}));
  }

  public async assignCredit(entrySelected, entries, idConcurso, idUsuario){
    const params = JSON.stringify({ entrySelected, entries, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}entries/assign-credit/`, params, { headers: headers })).catch(reason => ({ error: true, message: reason.error.text||(reason.error.error.message||(reason.message||(reason.error||(reason||'Error during request.'))))}));
  }

  public async setEntryFS(entry: ResultFS){
    console.log(`${entry.ipc}_${entry.id_binomio.replace(/us_/,'')}`, entry);
    return setDoc(doc(this.fs, 'results', `${entry.ipc}_${entry.id_binomio.replace(/us_/,'')}`), entry, { merge: true });
  }
}
