
<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid">
        <!--INICIO DE TABLA DE Binomios-->
        <div class="row margin-top-35">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="container">
                    <div class="col-4 float-left">
                      <button (click)="updateClubes()" type="button" class="btn btn-success">Actualizar Clubs</button>
                    </div>
                    <div class="col-4 float-left">
                        <button type="button" class="btn btn-success">Actualizar Caballos</button>
                    </div>
                    <div class="col-4 float-left">
                        <button type="button" class="btn btn-success">Actualizar Jinetes</button>
                    </div>
                  </div>
                </div>
              </div> <!-- end card-->
            </div> <!-- end card-body-->
          </div> <!-- end card-->
        </div> <!-- end col-->
      </div>
    </div>
  </div>
</div>

