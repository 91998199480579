import { Component, OnInit} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router";

//SERVICES
import { VideoService } from '../services/videos.service';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'peticiones-videos',
  templateUrl: './peticiones-videos.component.html',
  styleUrls: ['./peticiones-videos.component.css']
})
export class PeticionesVideosComponent implements OnInit {
  private idUsuario: string;
  public nombreConcurso: string;
  public idConcurso: string | boolean;
  public peticionesPendientes: any;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public loaded:boolean;

  constructor(
    private videoService: VideoService,
    private authService: AuthService,
    private router: Router
  ) {
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.idConcurso = '';
    this.peticionesPendientes = [];
    this.loaded = false;
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
        this.authService.logOut();
        return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.privilegios = this.sessionData.privilegios;
        this.idUsuario = String(this.sessionData['idUsuario']);
    }
    if(this.authService.validarConcurso() && this.privilegios >= 300){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getVideosPendientes();
    $('#loader').show();
  }

  public getVideosPendientes(){
    this.videoService.getPeticionesPendientes().subscribe(
      response => {
        this.peticionesPendientes = response;
        this.loaded = true;
        $('#loader').fadeOut(1000);
        if(this.peticionesPendientes.length == 0){
          $('#pendientes-0').fadeIn(1000);
        }
      },
      error => {
        $.NotificationApp.send("ERROR",error, 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  /**
   * @param idVideo Petición que será eliminada (solo si campo Editado = 0)
   */
  public eliminarPeticion(idVideo){
    $('#loader').show();
    this.videoService.eliminarPeticion(idVideo).subscribe(
      response => {
        this.getVideosPendientes();
        $.NotificationApp.send("Success!", 'The request has been discarded', 'bottom-right', '#06b785', 'success');
      },
      error => {
        $.NotificationApp.send("ERROR",error, 'bottom-right', '#e23357', 'error');
        console.log(error);
      }
    );
  }
}
