import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { PruebaService } from '../services/prueba.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import * as XLSX from 'xlsx';
import { DataSource } from '@angular/cdk/table';
import { ListaService } from '../services/lista.service';
import { Router } from "@angular/router";
declare var jquery:any;
declare var $ :any;

@Component({
    selector: 'importar-listas',
    templateUrl: './importar-listas.component.html',
    styleUrls: ['./importar-listas.component.css']
})

export class ImportarListasComponent implements OnInit, AfterViewInit {
    public lista: Array<any>;
    public idConcurso: string | boolean;
    private idUsuario: string;
    public pruebas: Array<any>;
    public displayedColumns;
    public dataSource;
    public ipc: string;
    public validado: boolean;
    public checked: boolean;
    private token: string;
    private sessionData: any;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private pruebaService: PruebaService,
        private route: ActivatedRoute,
        private listaService: ListaService,
        private authService: AuthService,
        private router: Router
    ) {
        this.route.params.subscribe(params => {
            this.idConcurso = params['idConcurso'];
            this.idUsuario = params['idUsuario'];
        });
        this.lista = [];
        this.pruebas = [];
        this.displayedColumns = ['validado', 'entrada', 'femFeiJinete', 'jinete', 'femFeiCaballo', 'caballo', 'cucarda'];
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = this.lista;
        this.ipc = '';
        this.validado = false;
        this.checked = false;
        this.token = '';
        this.sessionData = {};
    }

    ngAfterViewInit(): void {
      setTimeout(() => {
        $(() => {
          $('[data-toggle="tooltip"]').tooltip();
        })
      });
    }

    ngOnInit() {
        if(!this.authService.isLoggedIn()){
            this.authService.logOut();
            return;
        } else{
            this.token = this.authService.getAuthorizationToken();
            this.sessionData = this.authService.getSessionData(this.token);
            this.idUsuario = String(this.sessionData['idUsuario']);
        }
        if(this.authService.validarConcurso()){
            this.idConcurso = this.authService.validarConcurso();
        } else{
            this.router.navigate(['']);
            return;
        }
        this.getPruebas();
        this.iconRegistry.addSvgIcon('xlsx', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/xlsx.svg'));
        this.iconRegistry.addSvgIcon('diskette', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/diskette.svg'));
        this.iconRegistry.addSvgIcon('success', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/success.svg'));
        this.iconRegistry.addSvgIcon('error', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/error.svg'));
    }

    public seleccionarArchivoExcel(){
        $('#xlsx').click();
    }

    public importarExcel(evt){
        //Limpiar lista de con objetos de lista
        this.lista = [];
        this.dataSource.data = [];
        this.validado = false;
        this.checked = false;

        const file: File = evt.target.files[0];
        let reader: FileReader = new FileReader();

        reader.onload = (e:any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            const aoa = XLSX.utils.sheet_to_json(ws, {header: 1});
            //console.log(aoa);
            let lista = [];
            for(let i = 1; i < aoa.length; i++){
                const row = aoa[i];
                lista.push({
                    entrada:  '',
                    cucarda: row[0] ? row[0].trim() : '',
                    femFeiJinete: row[1] ? row[1].trim() : '',
                    jinete: row[2] ? row[2].trim() : '',
                    apellidosJinete: row[3] ? row[3].trim() : '',
                    femFeiCaballo: row[4] ? row[4].trim() : '',
                    caballo: row[5] ? row[5].trim() : '',
                    validado: false,
                    msj: 'Not validated.'
                });
            }
            lista = lista.filter((value) => {
                return /*value['entrada'] && */value['jinete'] && value['caballo'];
            });
            lista = lista.map((o, i) => {
                o.entrada = i + 1;
                if(o.femFeiJinete.length > 0 && o.femFeiJinete.length < 4){//Validar largo de numero fem jinete
                    if(o.femFeiJinete.length === 1){
                        o.femFeiJinete = `000${o.femFeiJinete}`;
                    } else if(o.femFeiJinete.length === 2){
                        o.femFeiJinete = `00${o.femFeiJinete}`;
                    } else if(o.femFeiJinete.length == 3){
                        o.femFeiJinete = `0${o.femFeiJinete}`;
                    }
                }

                if(o.femFeiCaballo.length > 0 && o.femFeiCaballo.length < 4){//Validar largo de numero fem caballo
                    if(o.femFeiCaballo.length === 1){
                        o.femFeiCaballo = `000${o.femFeiCaballo}`;
                    } else if(o.femFeiCaballo.length === 2){
                        o.femFeiCaballo = `00${o.femFeiCaballo}`;
                    } else if(o.femFeiCaballo.length == 3){
                        o.femFeiCaballo = `0${o.femFeiCaballo}`;
                    }
                }

                return o;
            });
            this.lista = lista;
            this.dataSource.data = lista;
        };
        reader.readAsBinaryString(file);
    }

    private getPruebas(){
        this.pruebaService.getPruebasList(this.idConcurso).subscribe(
            response => {
                this.pruebas = response.pruebas;
                //this.ipc = this.pruebas.length > 0 ? this.pruebas[0]['ipc'] : '';
            },
            error => {
                $.NotificationApp.send('Error', 'An error occurred while the classes were being queried.',  'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    public validarLista(){
        $('#loader').show();
        this.listaService.validarLista(this.idConcurso, this.lista, this.ipc).subscribe(
            response => {
                if(response.error){
                    $('#loader').hide();
                    $.NotificationApp.send('Error', response.message,  'bottom-right', '#fa5c7c', 'error');
                    console.log(response);
                } else if(response.listaValidada){
                    let validado = true;
                    const listaValidada = response.listaValidada;

                    for(const binomio of listaValidada){
                        if(!binomio.validado)
                            validado = false;
                    }

                    this.checked = true;
                    this.validado = validado;
                    this.lista = listaValidada;
                    this.dataSource.data = listaValidada;
                    $('#loader').hide();
                }else{
                    $('#loader').hide();
                    $.NotificationApp.send('Error', 'An error occurred while validating.',  'bottom-right', '#fa5c7c', 'error');
                    console.log(response);
                }
            },
            error => {
                $('#loader').hide();
                $.NotificationApp.send('Error', 'An error occurred while validating.',  'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    public guardarLista(){
        $('#loader').show();
        this.lista = this.lista.map(binomio => {
        binomio['ipc'] = this.ipc;
        binomio['idConcurso'] = this.idConcurso;
        binomio['categoria'] = 0;

            return binomio;
        });

        this.listaService.guardarListaUsef(this.ipc, this.lista).subscribe(
            response => {
                if(response.error){
                    $('#loader').hide();
                    $.NotificationApp.send('Error', response.message,  'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                } else{
                    $('#loader').hide();
                    $.NotificationApp.send('Success', 'List saved successfully.',  'bottom-right', '#fa5c7c', 'success');
                }
            },
            error => {
                $('#loader').hide();
                $.NotificationApp.send('Error', 'An error occurred while saving the list.',  'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }
}
