import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PantallaService } from '../services/pantalla.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
declare var $ :any;

@Component({
    selector: 'pantallas',
    templateUrl: 'pantallas.component.html',
    styleUrls: ['pantallas.component.css']
})

export class PantallasComponent implements OnInit {
    public idConcurso: string | boolean;
    private idUsuario: string;
    private token: string;
    private sessionData: any;
    public privilegios: number;
    public nombreConcurso: string;
    public pantallas: Array<any>;

    constructor(
        private authService: AuthService,
        private pantallaService: PantallaService,
        private db: AngularFirestore,
        private router: Router
    ) {
        this.idConcurso = '';
        this.idUsuario = '';
        this.token = '';
        this.sessionData = {};
        this.privilegios = 0;
        this.nombreConcurso = this.authService.getNombreConcurso();
        this.pantallas = [];
    }

    ngOnInit() {
        if(!this.authService.isLoggedIn()){
            this.authService.logOut();
            return;
        } else{
            this.token = this.authService.getAuthorizationToken();
            this.sessionData = this.authService.getSessionData(this.token);
            this.idUsuario = String(this.sessionData['idUsuario']);
            this.privilegios = this.sessionData.privilegios;
        }
        if(this.authService.validarConcurso() && this.privilegios >= 300){
            this.idConcurso = this.authService.validarConcurso();
        } else{
            this.router.navigate(['']);
            return;
        }
        this.getPantallas();
    }

    public getPantallas(){
        this.db.collection('pantallas').doc(`us_${this.idConcurso}`).collection('pantallas').snapshotChanges().subscribe(
            pantallas => {
                this.pantallas = [];
                pantallas.forEach(pantalla => {
                    this.pantallas.push({
                        id: pantalla.payload.doc.data().id,
                        pista: pantalla.payload.doc.data()['pista']
                    });
                });
                $('#loader').hide();
            }, error => {
                $('#loader').hide();
                console.log(error);
                $.NotificationApp.send("Error", 'It has not been possible to query the screens.', 'bottom-right', '#fa5c7c', 'error');
            }
        );
    }

    public crearPantalla(e){
        $('#loader').show();
        const pista = e.target.value;

        if(pista){
            this.db.collection('pantallas').doc(`us_${this.idConcurso}`).collection('pantallas').add({
                agrupador: '0',
                id_concurso: `us_${this.idConcurso}`,
                id_prueba: '',
                letreros: true,
                pantalla: '1',
                pista: pista,
                resultados: '1',
                tiempo_lider: ''
            }).then(function(docRef) {
                $('#loader').hide();
                $.NotificationApp.send("Created", 'The screen has been created successfully.', 'bottom-right', '#fa5c7c', 'success');
                $('#pista').val('');
            })
            .catch(function(error) {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The screen could not be created.', 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            });
        } else{
            $('#loader').hide();
            $.NotificationApp.send("Error", 'The name can not be empty.', 'bottom-right', '#fa5c7c', 'warning');
        }
    }

    public clipboard(s: string, id: string){
        const el = document.createElement('textarea');
        el.style.position = 'fixed';
        el.style.left = '0';
        el.style.top = '0';
        el.style.opacity = '0';
        el.value = `${s}us_${this.idConcurso}/${id}`;
        document.body.appendChild(el);
        el.focus();
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
}
