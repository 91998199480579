import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ConcursoService } from '../services/concurso.service';
import { EntrenadorService } from '../services/entrenador.service';
import { ConceptoService } from '../services/concepto.service';
import { ResponsablesService } from '../services/responsables.service';
import { CaballoService } from '../services/caballo.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, NgForm } from '@angular/forms';
import { StripeService } from '../services/stripe.service';
import { ChaseService } from '../services/chase.service';
import { FeedOrdersService } from '../services/feed-orders.service';
import { PrintService } from '../services/print.service';
import { EntriesService } from '../services/entries.service';
import { PersonaService } from '../services/persona.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProductService } from '../services/product.service';
import { firstValueFrom } from 'rxjs';
import { parse } from 'path';
declare var $: any;
declare let Stripe: any;

@Component({
  selector: 'app-cuenta-entrenador',
  templateUrl: './cuenta-entrenador.component.html',
  styleUrls: ['./cuenta-entrenador.component.css']
})
export class CuentaEntrenadorComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public entrenadores: any[];
  public entrenadorSeleccionado: string;
  public entriesEntrenador: any[];
  public cargosEntrenador: any[];
  public pagosEntrenador: any[];
  public totalCargosEntrenador: number;
  public totalPagosEntrenador: number;
  public formCargoEntrenador: UntypedFormGroup;
  public formPagoEntrenador: UntypedFormGroup;
  public formSplitEntrenador: UntypedFormGroup;
  public saldoEntrenador: number;
  public splitsEntrenador: any[];
  public totalSplitsEntrenador: number;
  public header: string;
  public header2: string;
  public footer: string;
  public conceptos: any[];
  public caballos: any[];
  public baseUrl: string;
  public pagoEnProceso: boolean;
  public card: any;
  public cardHandler = this.onChange.bind(this);
  public error: string;
  public email: string;
  public phone: string;
  public nombre: string;
  public cardForm: UntypedFormGroup;
  public fecha: string;
  public errorTarjeta: string;
  public orderSummary: any[];
  public trainerBalance: boolean;
  public cuentasEntries: boolean;
  public creditCards: any[];
  public tarjetaSeleccionada: string;
  public personas: any[];
  public formTraspaso: UntypedFormGroup;
  public concursos: any[];
  public entrenadoresTraspaso: any[];
  public entries: any[];
  public customersPaymentsMethods: any[];
  public splitsEntries: any[];
  public validated: boolean;
  public motivoAnulacionTraspaso: string;
  public idTransaccionAnulacionTraspaso: string;
  public montoAnulacionTraspaso: string;
  public products: any[];
  public selectedProducts: any[];
  public selectedProduct: string;
  public selectedQty: number;
  public selectedTotal: number;
  public entriesTraspaso: any[];
  public numeroCheque: string;
  public montoCheque: number | string;
  public retryTrace: string;
  public trainerNotes: any;
  /* Stripe */
  public public_key: string;
  public stripe: any;
  public elements: any;
  public comision_stripe: number;
  public newCard: boolean;
  //TODO:: Cambiar a StripeCustomer[]
  public stripeCustomers: StripeCustomer[];
  public aplicarPremios: boolean;
  public date: Date;
  public checks: any[];
  public showAddCardForm: boolean;
  public stripeMetadata: any;
  public stripeDescription: string;
  public amountTrainerPayment: number | string;
  private _totalPayment: string | number;
  public stripeFormVisible: boolean;
  public showSettleCredits: boolean;
  public savingSplits: boolean;
  public defaultCardHolder: string;
  public visibleElements: any;
  public entriesPayments: any[];
  public loading: Map<string, boolean>;
  @ViewChild('cardInfo') cardInfo: ElementRef;

  constructor(
    private authService: AuthService,
    private concursoService: ConcursoService,
    private entrenadorService: EntrenadorService,
    private conceptoService: ConceptoService,
    private responsablesService: ResponsablesService,
    private caballoService: CaballoService,
    private stripeService: StripeService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private chaseService: ChaseService,
    private feedOrdersService: FeedOrdersService,
    private printService: PrintService,
    private entriesService: EntriesService,
    private personaService: PersonaService,
    private db: AngularFirestore,
    private productService: ProductService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.entrenadores = [];
    this.entrenadorSeleccionado = '';
    this.entriesEntrenador = [];
    this.cargosEntrenador = [];
    this.pagosEntrenador = [];
    this.totalCargosEntrenador = 0;
    this.totalPagosEntrenador = 0;
    this.fecha = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getUTCDate()).padStart(2, '0')}`;
    this.formCargoEntrenador = new UntypedFormGroup({
      concepto: new UntypedFormControl('', [Validators.required]),
      notas: new UntypedFormControl('', []),
      cantidad: new UntypedFormControl('', [Validators.required]),
      monto: new UntypedFormControl({ value: '', disabled: false }, [Validators.required]),
      caballo: new UntypedFormControl('', [])
    });
    this.formPagoEntrenador = new UntypedFormGroup({
      metodo: new UntypedFormControl('', [Validators.required]),
      referencia: new UntypedFormControl('', []),
      fecha: new UntypedFormControl(this.fecha, [Validators.required]),
      monto: new UntypedFormControl('', []),
      caballo: new UntypedFormControl('', [])
    });
    this.formSplitEntrenador = new UntypedFormGroup({
      /*caballo: new FormControl('', [Validators.required]),*/
      notas: new UntypedFormControl('', [])/*,
      monto: new FormControl('', [Validators.required]),*/
    });
    this.saldoEntrenador = 0;
    this.splitsEntrenador = [];
    this.totalSplitsEntrenador = 0;
    this.header = '';
    this.header2 = '';
    this.footer = '';
    this.caballos = [];
    this.baseUrl = window.location.hostname;
    this.pagoEnProceso = false;
    this.error = null;
    this.email = '';
    this.phone = '';
    this.nombre = '';
    this.customersPaymentsMethods = [];
    this.cardForm = new UntypedFormGroup({
      number: new UntypedFormControl('', [Validators.required, Validators.minLength(18)/*, Validators.maxLength(19)*/]),
      date: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
      name: new UntypedFormControl('', []),
      person: new UntypedFormControl('', []),
      save: new UntypedFormControl(false, []),
      referencia: new UntypedFormControl('', []),
    });
    this.errorTarjeta = '';
    this.orderSummary = [];
    this.trainerBalance = true;
    this.cuentasEntries = false;
    this.creditCards = [];
    this.tarjetaSeleccionada = '';
    this.personas = [];
    this.formTraspaso = new UntypedFormGroup({
      concurso: new UntypedFormControl('', [Validators.required]),
      entrenador: new UntypedFormControl('', []),
      entry: new UntypedFormControl('', []),
      fecha: new UntypedFormControl(this.fecha, [Validators.required]),
      notas: new UntypedFormControl('', []),
      monto: new UntypedFormControl('', [Validators.required]),
      deEntrenador: new UntypedFormControl('', [Validators.required]),
      deConcurso: new UntypedFormControl(this.idConcurso, [Validators.required]),
      idUsuario: new UntypedFormControl(this.idUsuario, [Validators.required])
    });
    this.concursos = [];
    this.entrenadoresTraspaso = [];
    this.entries = [];
    this.splitsEntries = [];
    this.validated = false;
    this.motivoAnulacionTraspaso = '';
    this.idTransaccionAnulacionTraspaso = '';
    this.montoAnulacionTraspaso = '';
    this.products = [];
    this.selectedProducts = [];
    this.selectedProduct = '';
    this.selectedQty = 1;
    this.selectedTotal = 0;
    this.entriesTraspaso = [];
    this.numeroCheque = '';
    this.montoCheque = '';
    this.retryTrace = '';
    this.trainerNotes = '';
    /* Stripe */
    this.public_key = sessionStorage.getItem('public_key') || '';
    this.stripe = this.public_key ? Stripe(this.public_key) : null;
    this.comision_stripe = .0325;
    this.newCard = false;
    this.aplicarPremios = false;
    this.date = new Date();
    this.checks = [];
    this.showAddCardForm = false;
    this.stripeMetadata = {};
    this.stripeDescription = '';
    this.amountTrainerPayment = 0;
    this.stripeCustomers = [];
    this.stripeFormVisible = false;
    this.showSettleCredits = false;
    this.savingSplits = false;
    this.defaultCardHolder = '';
    this.entriesPayments = [];
    this.visibleElements = {
      balance: false,
      totalCargos: false,
      totalCargosEntrenador: false,
      caliSplitSave: false,
      caliSplitSplit: false
    };
    this.loading = new Map<string, boolean>([
      ['trainers', true],
      ['orders', false],
      ['charges', false],
      ['payments', false],
      ['splits', false],
      ['cc', false],
      ['entries', false],
      ['entriesPayments', false]
    ]);
  }

  ngOnInit() {
    // validacion de sesión
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return; // si no cuenta con una sesión activa, regresa al login
    } else {
      this.token = this.authService.getAuthorizationToken(); // obtenemos token de sesión
      this.sessionData = this.authService.getSessionData(this.token); // obtenemos datos de la sesión
      this.idUsuario = String(this.sessionData['idUsuario']); // id del usuario logueado
      this.privilegios = this.sessionData.privilegios;  // privilegios del usuario logueado
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso(); // Verificamos si hay un concurso seleccionado
    } else {
      this.router.navigate(['']); // si no hay concurso seleccionado, redirigimos al home
      return;
    }

    if (this.route.snapshot.params.idEntrenador||'') {
      this.entrenadorSeleccionado = this.route.snapshot.params.idEntrenador||'';
      this.loading = new Map<string, boolean>([
        ['trainers', true],
        ['orders', true],
        ['charges', true],
        ['payments', true],
        ['splits', true],
        ['cc', true],
        ['entries', true],
        ['entriesPayments', true]
      ]);
    }
    this.getConceptos(); // Obtenemos los conceptos del concurso
    this.getProducts(); // Obtenemos los productos del concurso

    this.printService.onprintstatechange = (isPrinting) => {
      if (isPrinting) {
        $('#loader').show();
      } else {
        $('#loader').hide();
      }
    };

  }

  ngAfterViewInit() {
    /*this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);*/
    $('#cardPaymentStripeModal').on('hide.bs.modal', (e) => {
      this.stripeFormVisible = false;
    });
    $('#settleCreditsModal').on('hide.bs.modal', (e) => {
      this.showSettleCredits = false;
    });
    //Plugin Typeahead
    $("#entryTraspaso").typeahead({
      hint: !0,
      highlight: !0,
      minLength: 1
    },
    {
      name: 'entryTraspaso',
      display: value => `${value.entry} - ${value.horse}`,
      templates: {
        notFound: '<div class="empty-message">No matches.</div>',
        suggestion: data => {
          return `<div>${data.entry} - ${data.horse}</div>`;
        }
      },
      source: (this.entriesTraspaso,
        (e, a) => {
          const substrRegex = new RegExp(e, "i");
          const t = this.entriesTraspaso.filter(tm => substrRegex.test(tm.entry) || substrRegex.test(tm.horse));
          a(t.map(tm => tm));
      }
    )
    });
    $('#entryTraspaso').bind('typeahead:select', (ev, suggestion) => {
      this.formTraspaso.get('entry').setValue(suggestion.entry);
    });
  }

  public get totalPayment(): number {
    return this.amountTrainerPayment + this.entriesEntrenador.reduce((acc, e) => acc + e.amountPayment, 0);
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  public ngRendered() {
    document.getElementById("sheetResponsables").click();
    document.getElementById("sheetPruebas").click();
  }


  public async searchEntryTraspaso(searchString: any) {
    this.entriesTraspaso = await firstValueFrom(this.entriesService.searchEntries(this.formTraspaso.value.concurso||this.idConcurso, searchString||'')).then(r => r.data||[]);
    const theVal = $('#entryTraspaso').val();
    $("#entryTraspaso").typeahead('val', '')
    $("#entryTraspaso").focus().typeahead('val',theVal).focus();
    //$("#entryTraspaso").trigger("input");
    return this.entriesTraspaso;
  }

  ///// Previo a seleccionar una cuenta de entrenador

  /** STEP 1.0 */
  private getConceptos() {
    firstValueFrom(this.conceptoService.getConceptos(this.idConcurso)).then(
      response => {
        if (!response.error) {
          this.conceptos = response.conceptos.filter(c => !c.feed); // Quitamos los conceptos de feed orders
          this.getConcursos(); // Si todo sale bien, obtenemos los concursos
        } else {
          this.handleError(response.message); // Error handling
        }
      }
    ).catch(reason => this.handleError(reason, "It has not been possible to query the charges list. Please notify this to support"));
  }

  /* STEP 1.1: */
  private getProducts(): void {
    firstValueFrom(this.productService.getProducts()).then(
      r => {
        (!r.error) ? this.products = r.products : this.handleError(r.message);
      },
    ).catch( reason => this.handleError(reason, "It has not been possible to retrieve the products list. Please notify this to support"));
  }

  /** STEP 1.2: */
  public getConcursos() {
    firstValueFrom(this.concursoService.getConcursos()).then(
      r => {
        if (!r.error) {
          //Filtrar concursos que sean del mismo club y del año actual #TODO: Cambiar a solo concursos del mismo club
          this.concursos = r.consursos.filter(c => c.id_club == (sessionStorage.getItem('club')||'') && (new Date(c.inicio).getFullYear()) == (new Date().getFullYear()));
          this.getConcurso(); // Si todo sale bien, obtenemos la informacion del concurso actual
        } else {
          this.handleError(r.message); // Error handling
        }
      }
    ).catch(reason => this.handleError(reason, "It has not been possible to consult the show list"));
  }

  /** STEP 1.3: */
  public getConcurso() {
    // Obtiene la informacion del concurso actual
    firstValueFrom(this.concursoService.getConcurso(this.idConcurso)).then(
      r => {
        if (!r.error) {
          this.aplicarPremios = r.concurso.aplicar_premios;
          this.header = r.concurso.header;
          this.header2 = r.concurso.header2;
          this.footer = r.concurso.footer;
          this.comision_stripe = r.concurso.comision_stripe;
          this.getEntrenadores(); // Si todo sale bien, obtenemos los entrenadores
        } else {
          $.NotificationApp.send("Something went wrong", "Please notify this to support", 'bottom-right', '#fa5c7c', 'error');
          this.handleError(r.message);
        }
      },
    ).catch(reason => {
      this.handleError(reason);
      $.NotificationApp.send("Oh no...", "It has not been possible to consult the show info", 'bottom-right', '#fa5c7c', 'error');
    });
  }

  /* STEP 1.4: */
  private getEntrenadores() {
    this.loading.set('trainers', true);
    firstValueFrom(this.entrenadorService.getEntrenadores(this.idConcurso)).then(
      r => {
        if (!r.error) {
          this.entrenadores = r.entrenadores;
          if (this.entrenadorSeleccionado) this.seleccionarEntrenador(); // Si se recibe el parametro del id del entrenador, se selecciona
          //this.consultarEntriesTraspaso(`${this.idConcurso}`) // si todo funciona, se obtienen los entries por transpaso
          this.loading.set('trainers', false);
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Something went wrong", "Please notify support about this", 'bottom-right', '#fa5c7c', 'error');
          this.loading.set('trainers', false);
          this.handleError(r.message);
        }
      }
    ).catch(reason => {
      this.handleError(reason);
      this.loading.set('trainers', false);
      $.NotificationApp.send("Oh no...", 'It was not possible to retrieve the trainers list. Please notify this to support', 'bottom-right', '#fa5c7c', 'error');
    });
  }

  /* STEP 1.5 */
  /*public async consultarEntriesTraspaso(idConcurso?: number | string) {
    this.formTraspaso.get('entry').setValue('');
    this.entriesTraspaso = [];
    const concurso = idConcurso || this.formTraspaso.value.concurso;
    try {
      const response = await firstValueFrom(this.entriesService.getEntries(concurso));
      if (response.error) return this.handleError(response.message);
      if (idConcurso) {
        this.entries = response.data.map(e => {
          const [trainer] = e.trainers.split(',');
          const [trainer_id] = e.trainers_id.toString().split(',');
          e.trainer = trainer;
          e.trainer_id = trainer_id;
          return e;
        });
      } else {
        this.entriesTraspaso = response.data;
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to retrieve the entries list.");
    }
  }*/

  ///// Posterior a seleccionar una cuenta de entrenador (sea desde el scroll down o desde el parámetro de la URL). Aun no carga la información de la cuenta seleccionada

  /** STEP 2.0 */
  public async seleccionarEntrenador() {
    //Placeholders para las cards en la cuenta de entrenador
    this.loading.set('orders', true);
    this.loading.set('charges', true);
    this.loading.set('payments', true);
    this.loading.set('splits', true);
    this.loading.set('cc', true);
    this.loading.set('entries', true);
    this.loading.set('entriesPayments', true);
    // Se reinician los valores de elementos ocultos
    this.resetUI();
    //Feed Orders
    this.getOrderSummary(); // Obtiene las transacciones de los feed orders del entrenador
    const index = this.entrenadores.findIndex(entrenador => entrenador.idEntrenador == this.entrenadorSeleccionado);
    this.nombre = this.entrenadores[index].nombre;
    this.defaultCardHolder = (this.nombre||'').split(', ').reverse().join(' ');
    //$('#loader').show();
    this.trainerBalance = true;
    this.entriesEntrenador = [];
    this.cargosEntrenador = [];
    this.pagosEntrenador = [];
    this.entriesPayments = [];
    this.caballos = [];
    this.checks = [];
    this.stripeCustomers = [];
    this.totalCargosEntrenador = 0;
    this.totalPagosEntrenador = 0;
    this.formCargoEntrenador.reset();
    this.formPagoEntrenador.reset();
    this.formPagoEntrenador.get('fecha').setValue(this.fecha);
    this.formSplitEntrenador.setValue({ notas: '' });
    this.trainerNotes = '';
    this.stripeDescription = `Show: ${this.nombreConcurso}, Trainer: ${this.nombre}`;
    if (this.entrenadorSeleccionado) {
      try {
        const response = await firstValueFrom(this.entrenadorService.getEntrenadorById(this.idConcurso, this.entrenadorSeleccionado, this.nombre));
        if (response.error) return this.handleError(response.message); // Error de lógica en el servidor

        this.validated = response.validated;
        this.trainerNotes = response.notes;
        if (this.validated) {
          this.formCargoEntrenador.disable();
          this.formSplitEntrenador.disable();
        } else {
          this.formCargoEntrenador.enable();
          this.formSplitEntrenador.enable();
        }
        this.getTrainerCreditCards(response.stripe_customers||[], response.useStripe); // Obtiene las tarjetas de crédito del entrenador
        this.getTrainerEntries(this.entrenadores[index].entries||[], (response.caballos||[]).map(c => ({ entry: c.entry, nombre: c.nombre }))); // Obtiene las entries del entrenador
        this.getEntriesPayments(this.entrenadores[index].entries||[]);
        this.getTrainerCharges(response.cargos||[], response.totalCargos||0); // Obtiene los cargos del entrenador
        this.getTrainerPayments(response.pagos||[], response.totalPagos||0); // Obtiene los pagos del entrenador
        this.getTrainerSplits(response.splits||[], response.totalSplits||0); // Obtiene los splits del entrenador
        this.saldoEntrenador = response.saldo;
        this.email = response.email;
        this.phone = response.phone;
        $('#modal-productos').modal('hide');
        this.visibleElements.balance = true;
        this.visibleElements.totalCargosEntrenador = true;
      } catch (error) {
        this.loading.set('orders', false);
        this.loading.set('charges', false);
        this.loading.set('payments', false);
        this.loading.set('splits', false);
        this.loading.set('cc', false);
        this.loading.set('entries', false);
        this.loading.set('entriesPayments', false);
        this.handleError(error, "It has not been possible to retrieve the trainer's account information.");
      }
    } else {
      this.loading.set('orders', true);
      this.loading.set('charges', true);
      this.loading.set('payments', true);
      this.loading.set('splits', true);
      this.loading.set('cc', true);
      this.loading.set('entries', true);
      this.loading.set('entriesPayments', true);
    }
  }

  /* STEP 2.1*/
  public async getOrderSummary(): Promise<any> {
    return firstValueFrom(this.feedOrdersService.trainerSummary(this.idConcurso, this.entrenadorSeleccionado)).then(r => {
      this.loading.set('orders', false);
      return (!r.error) ? this.orderSummary = r.summary : this.handleError(r.message);
    } // Obtiene el resumen de las ordenes de comida del entrenador
    ).catch(r => {
      this.loading.set('orders', false);
      return this.handleError(r, "It has not been possible to query the feed orders summary.");
    });
  }

  public async getTrainerCharges(cargos: any[], totalCargos: number): Promise<any> {
    this.cargosEntrenador = cargos;
    this.totalCargosEntrenador = totalCargos;
    this.loading.set('charges', false);
    return this.cargosEntrenador;
  }

  public async getTrainerPayments(pagos: any[], totalPagos: number): Promise<any> {
    this.pagosEntrenador = pagos;
    this.totalPagosEntrenador = totalPagos;
    this.loading.set('payments', false);
    return this.pagosEntrenador;
  }

  public async getTrainerSplits(splits: any[], totalSplits: number): Promise<any> {
    this.splitsEntrenador = splits;
    this.totalSplitsEntrenador = totalSplits;
    this.loading.set('splits', false);
    return this.splitsEntrenador;
  }

  public async getTrainerCreditCards(stripe_customers: StripeCustomer[], useStripe: boolean): Promise<any> {
    this.stripeCustomers = stripe_customers || []; // Stripe customer ids guardados en mysql
    const customerEmails = this.stripeCustomers.filter(c => c.email).map(c => c.email).filter((e,i,a) => a.indexOf(e) === i); //Obtener emails de los customers guardados en MySQL
    if(useStripe && customerEmails.length){
      try {
        const responseSearchCustomers = await firstValueFrom(this.stripeService.searchCustomers(customerEmails, `${this.idConcurso}`)); // Obtiene los ID de los customers en stripe
        if(responseSearchCustomers.length > 0){
          this.stripeCustomers = [
            ...this.stripeCustomers, // IDs de los customers guardados en MySQL
            ...responseSearchCustomers.map(c => ({ id: c.id||'', name: c.name||'No name', email: c.email||'', paymentMethods: [] })) // IDs de los customers en stripe
          ].filter((c,i,a) => a.findIndex(e => e.id == c.id) === i);
          this.loading.set('cc', false);
        } else{
          this.loading.set('cc', false);
        }
      } catch (error) {
        this.loading.set('cc', false);
        this.handleError(error);
      }
    }
  }

  public async getTrainerEntries(entries: any[], caballos: any[]): Promise<any> {
    //Actualizar cargos de inscripciones y divisiones de cada entry
    const promises = entries.map(e => firstValueFrom(this.entriesService.recalcularDivisiones(e, this.idConcurso, this.idUsuario)));
    await Promise.all(promises).catch(r => {
      this.loading.set('entries', false);
      this.handleError(r, "It has not been possible to recalculate the divisions of the entries.");
      return;
    });
    try {
      const response = await firstValueFrom(this.entrenadorService.getSaldoEntries(this.idConcurso, caballos));
      if (response.error) return this.handleError(response.message); // Error de lógica en el servidor
       this.entriesEntrenador = (response.entries||[]).map(e => {
        e.sumar = true;
        e.saldoPagoSugerido = e.saldo > 0 ? e.saldo : 0;
        e.amountPayment = 0;
        return e;
      });
      this.loading.set('entries', false);
    } catch (error) {
      this.loading.set('entries', false);
      this.handleError(error, "It has not been possible to retrieve the trainer's entries information.");
    }
  }

  public async getEntriesPayments(entries:any[]){
    const promises = entries.map(e => firstValueFrom(this.entriesService.getPayments(e, this.idConcurso)).then(r => ({...r, pagos: r.pagos.map(p => ({...p, entry: e}))})));
    const entriesPayments: any = await Promise.all(promises).catch(r => {
      this.loading.set('entriesPayments', false);
      this.handleError(r, "It has not been possible to retrieve the entries payments.");
      return;
    });
    this.entriesPayments = entriesPayments.reduce((acc, e) => [...acc, ...e.pagos], []);
    this.loading.set('entriesPayments', false);
    console.log('entriesPayments', this.entriesPayments);
  }

  /// Posterior a la carga de informacion de la cuenta de entrenador
  public async handleSubmit(paymentIntent: any) {
    if (paymentIntent.status != 'succeeded') {
      $.NotificationApp.send("Error", `Payment failed, status: ${paymentIntent.status}`, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const cardFee = paymentIntent.cardFee;
    const paymentIntentId = paymentIntent.id;
    $('#cardPaymentStripeModal').modal('hide');
    this.addPayment({ pi: paymentIntentId, comision: cardFee });
    return;
  }

  public calcularCargoEntrenador() {
    if (this.formCargoEntrenador.value.concepto && this.formCargoEntrenador.value.cantidad) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.formCargoEntrenador.value.concepto;
      });
      this.formCargoEntrenador.get('monto').setValue(parseFloat(this.conceptos[index].monto) * this.formCargoEntrenador.value.cantidad);
    } else if (this.formCargoEntrenador.value.concepto && !this.formCargoEntrenador.value.cantidad) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.formCargoEntrenador.value.concepto;
      });
      this.formCargoEntrenador.get('cantidad').setValue(1);
      this.formCargoEntrenador.get('monto').setValue(parseFloat(this.conceptos[index].monto));
    }
  }

  public async agregarCargoEntrenador() {
    this.formCargoEntrenador.get('concepto').markAsTouched();
    this.formCargoEntrenador.get('cantidad').markAsTouched();
    this.formCargoEntrenador.get('monto').markAsTouched();
    this.formCargoEntrenador.get('caballo').markAsTouched();
    //Obtener el nombre del entrenador seleccionado
    const index = this.entrenadores.findIndex(entrenador => entrenador.idEntrenador == this.entrenadorSeleccionado);
    const nombre = this.entrenadores[index].nombre;
    if (this.formCargoEntrenador.valid) {
      const datos = {
        concepto: this.formCargoEntrenador.get('concepto').value,
        cantidad: this.formCargoEntrenador.get('cantidad').value,
        monto: this.formCargoEntrenador.get('monto').value,
        notas: this.formCargoEntrenador.get('notas').value,
        entry: this.formCargoEntrenador.get('caballo').value,
        idUsuario: this.idUsuario,
        idConcurso: this.idConcurso,
        idEntrenador: this.entrenadorSeleccionado,
        nombre: nombre
      };
      try {
        const response = await firstValueFrom(this.entrenadorService.agregarCargo(datos));
        if (!response.error) {
          this.seleccionarEntrenador();
          $.NotificationApp.send("Saved", response.message, 'bottom-right', '#06d5a1', 'success');
        } else {
          this.handleError(response.message, "It has not been possible to add the charge.");
        }
      } catch (error) { this.handleError(error) }
    }
  }

  public toggleDetalleCargoEntrenador(index) {
    this.cargosEntrenador[index].mostrarDetalle = !this.cargosEntrenador[index].mostrarDetalle;
  }

  public async eliminarCargo(idCargo, nombreCargo, cantidad, monto) {
    const datos = {
      idCargo: idCargo,
      nombreCargo: nombreCargo,
      cantidad: cantidad,
      monto: monto,
      idUsuario: this.idUsuario,
      idConcurso: this.idConcurso,
      //idResponsable: this.responsableSeleccionado,
      nombre: ''
    };
    try {
      const response = await firstValueFrom(this.responsablesService.eliminarCargo(datos));
      if (!response.error) {
        this.seleccionarEntrenador();
        $.NotificationApp.send("Removed", response.message, 'bottom-right', '#06d5a1', 'success');
      } else {
        this.handleError(response.message, "It has not been possible to remove the charge.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to remove the charge.");
    }
  }

  public async agregarSplitEntrenador() {
    this.savingSplits = true;
    const splitsEntries = [...this.entriesEntrenador.filter(e => parseFloat(e.splitAmount||'0') != 0).map(e => ({
      entry: e.entry,
      notes: '',
      amount: e.splitAmount,
      idConcurso: this.idConcurso,
      idUsuario: this.idUsuario,
      idEntrenador: this.entrenadorSeleccionado,
      entrenador: this.nombre
    })), ...this.splitsEntries.map(e => ({
      entry: e.entry,
      notes: '',
      amount: e.amount,
      idConcurso: this.idConcurso,
      idUsuario: this.idUsuario,
      idEntrenador: this.entrenadorSeleccionado,
      entrenador: this.nombre
    }))].filter(e => parseFloat(e.amount||'0') != 0);
    if(splitsEntries.length == 0){
      this.savingSplits = false;
      $.NotificationApp.send("Warning", "Please add at least one split.", 'bottom-right', '#ffbc00', 'warning', 10000);
      return;
    }
    const response = await firstValueFrom(this.entrenadorService.agregarSplit(splitsEntries)).catch(r => ({ error: true, message: 'It has not been possible to add the splits.' }));
    if(response.error){
      this.savingSplits = false;
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 10000);
      console.log(response.message);
      return;
    }

    this.seleccionarEntrenador();
    this.savingSplits = false;
    $('#modal-split').modal('hide');
    $.NotificationApp.send("Saved", 'The splits have been added successfully', 'bottom-right', '#06d5a1', 'success', 10000);
  }

  public async anularSplit(idTransaccion, nombreCaballo, nombreResponsable, monto) {
    //Obtener el nombre del entrenador seleccionado
    const index = this.entrenadores.findIndex(e => e.idEntrenador == this.entrenadorSeleccionado);
    const nombre = this.entrenadores[index].nombre;

    const datos = {
      idTransaccion,
      nombreCaballo,
      nombreResponsable,
      monto,
      idUsuario: this.idUsuario,
      idConcurso: this.idConcurso,
      idEntrenador: this.entrenadorSeleccionado,
      nombre
    };

    try {
      const response = await firstValueFrom(this.entrenadorService.anularSplit(datos));
      if (!response.error) {
        this.seleccionarEntrenador();
        $.NotificationApp.send("Canceled", response.message, 'bottom-right', '#06d5a1', 'success');
        console.log(response.message);
      } else {
        this.handleError(response.message, "It has not been possible to cancel the split.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to cancel the split.");
    }
  }

  public async agregarPagoEntrenador() {
    this.formPagoEntrenador.markAllAsTouched();
    this.numeroCheque = '';
    this.montoCheque = '';
    $('#numero-cheque').val('');
    $('#monto-cheque').val('');
    setTimeout(() => {
      $('#numero-cheque').focus();
    }, 400);

    //Limpiar montos de los entries en el modal
    this.amountTrainerPayment = 0;
    this.entriesEntrenador.map(e => {
      e.amountPayment = 0;
      return e;
    });

    if (this.formPagoEntrenador.valid) {
      //Validar si el metodo de pago es tarjeta
      if (this.formPagoEntrenador.get('metodo').value == 'Tc') {
        //Si el concurso usa stripe
        if (this.public_key) {
          this.stripeMetadata = { entrenador: this.nombre, user: this.idUsuario, show: this.idConcurso, showName: this.nombreConcurso };
          this.stripeFormVisible = true;
          $('#cardPaymentStripeModal').modal('show');
          return;
        } else {//Chase
          //En pagos con chase no poner cantidades iniciales en los entries ni en el entrenador
          this.formPagoEntrenador.get('monto').setValue(0);
          this.retryTrace = this.makeid(16);
          this.cardForm.reset();
          this.cardForm.setValue({
            number: '',
            date: '',
            name: '',
            person: '',
            save: false,
            referencia: this.formPagoEntrenador.get('referencia').value
          });
          this.errorTarjeta = '';
          this.personas = [];
          $('#formCard').removeClass('was-validated');
          this.tarjetaSeleccionada = '';
          //Consulta de tarjetas relacionada
          await this.getTarjetasRelacionadas();
          this.pagoEnProceso = false;
          this.formPagoEntrenador.get('monto').setValue(this.formPagoEntrenador.value.monto);
          $('#cardModal').modal('show');
          return;
        }
      }

      $('#paymentModal').modal('show');
      return;
    }
  }

  private async getTarjetasRelacionadas() {
    const response: any = await firstValueFrom(this.entriesService.tarjetasRelacionadas(this.idConcurso, this.entriesEntrenador.map(e => e.entry)));
    if (response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      console.log(response.message);
      return;
    }
    this.creditCards = response.cards.map(c => {
      c.ccExp = [c.ccExp.slice(4, 6), c.ccExp.slice(0, 4)].join('/');
      return c;
    });
  }

  public seleccionarTarjetaStripe(tarjetaSeleccionada) {
    this.tarjetaSeleccionada = tarjetaSeleccionada;
  }

  // Fetches the payment intent status after payment submission
  public async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.showMessage("Payment succeeded!");
        break;
      case "processing":
        this.showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        this.showMessage("Your payment was not successful, please try again.");
        break;
      default:
        this.showMessage("Something went wrong.");
        break;
    }
  }

  // ------- UI helpers -------
  public showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(() => {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 4000);
  }

  public valorAbsoluto(num: number): number {
    return Math.abs(num);
  }

  public imprimirCuentas() {
    const entries = this.entriesEntrenador.filter(entry => entry.sumar).map(entry => entry.entry);
    this.printService.printDocument('entry-invoice', 'Entry Invoice', this.idConcurso, entries);
  }

  public async mostrarCuentasEntries() {
    this.cuentasEntries = true;
    const entries = this.entriesEntrenador.filter(e => e.sumar).map(caballo => (
      {entry: caballo.entry, caballo: caballo.nombre })
    );
    try {
      $('#loader').show();
      const response = await firstValueFrom(this.caballoService.getCuentas(entries, this.idConcurso));
      if (!response.error) {
        this.caballos = response.caballos;
        setTimeout(() => {
          $(() => {
            $('[data-toggle="tooltip"]').tooltip();
          });
        });
        $('#loader').hide();
      } else {
        this.handleError(response.message, "It has not been possible to query the horse invoices.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to query the horse invoices.");
    }
  }

  public ocultarCuentasEntries() {
    this.cuentasEntries = false;
    this.caballos = [];
  }

  /**
   * Pagos con tarjeta de credito - Chase
   */
  public async addCreditCardPayment() {
    //Validar que los datos esten bien y completos
    if (this.tarjetaSeleccionada) {
      const card = this.creditCards.find(c => c.customerRefNum == this.tarjetaSeleccionada);
      if (!card) return;

      this.pagoEnProceso = true;
      //Agregar pagos a transacciones
      const ccAccountNum = card.ccAccountNum;
      //TODO: En lugar de mandar pagosEntries, mandar un solo pago y registrar las transacciones despues ya no en el endpoint de chase

      try {
        const response = await firstValueFrom(
          this.chaseService.trainerPaymentToken(
            this.tarjetaSeleccionada,
            ccAccountNum,
            this.idConcurso,
            this.entrenadorSeleccionado,
            this.formPagoEntrenador.value,
            [],
            this.idUsuario,
            this.retryTrace
          )
        );

        if (!response.error) {
          this.pagoEnProceso = false;
          $('#cardModal').modal('hide');
          $.NotificationApp.send("Success", response.message, 'bottom-right', '#06d5a1', 'success');
          this.errorTarjeta = '';
          this.seleccionarEntrenador();
        } else {
          this.handleError(response.message, 'It has not been possible to make the payment, please try again.');
          this.pagoEnProceso = false;
          this.errorTarjeta = response.message;
        }
      } catch (error) {
        this.handleError(error, 'It has not been possible to make the payment, please try again.');
        this.pagoEnProceso = false;
      }

    } else if (this.cardForm.valid) {
      this.pagoEnProceso = true;

      if (this.cardForm.value.save) {
        //Validar que el valor de responsable sea tomado desde la lista
        const indexPersona = this.personas.findIndex(p => p.id_persona == this.cardForm.get('person').value);
        if (indexPersona == -1) this.cardForm.get('person').setValue('');

        if (!this.cardForm.get('name').value || !this.cardForm.get('person').value) {
          $.NotificationApp.send("Error", 'Please complete the name and person fields.', 'bottom-right', '#ffbc00', 'warning');
          return;
        }
      }

      //Agregar pagos a transacciones
      //TODO: En lugar de mandar pagosEntries, mandar un solo pago y registrar las transacciones despues ya no en el endpoint de chase
      try {
        const response = await firstValueFrom(
          this.chaseService.trainerPayment(this.cardForm.value,this.idConcurso,this.entrenadorSeleccionado,this.formPagoEntrenador.value,[],this.idUsuario,this.retryTrace)
        );

        if (!response.error) {
          this.pagoEnProceso = false;
          $('#cardModal').modal('hide');
          $.NotificationApp.send("Success", response.message, 'bottom-right', '#06d5a1', 'success');
          this.errorTarjeta = '';
          for (const person of response.cardsByMail) {
            const email = person.email;
            for (const card of person.cards) {
              this.db.collection('cards').doc(email).collection('cards').doc(card.customerRefNum).set(card);
            }
          }
          this.seleccionarEntrenador();
        } else {
          this.handleError(response.message);
          this.pagoEnProceso = false;
          this.errorTarjeta = response.message;
        }
      } catch (error) {
        this.handleError(error, 'It has not been possible to make the payment, please try again.');
        this.pagoEnProceso = false;
      }
    }
  }

  /**
   * @param stripe Parametro opcional para agregar comision de stripe a los montos de pago, registrar el payment intent y agregar las comisiones a las transacciones
   * @returns
   */
  public async addPayment(stripe?: { pi: string, comision: number }) {
    this.pagoEnProceso = true;

    //Hacer un arreglo de pagos y enviarlo en lugar de pagosEntries
    const payments = [
      ...this.entriesEntrenador.map(e => ({
        metodo: this.formPagoEntrenador.value.metodo,
        referencia: this.formPagoEntrenador.value.referencia,
        fecha: this.formPagoEntrenador.value.fecha,
        monto: e.amountPayment,
        entry: e.entry,
        idUsuario: this.idUsuario,
        idConcurso: this.idConcurso,
        idEntrenador: this.entrenadorSeleccionado,
        nombre: this.nombre
      })),
      {
        metodo: this.formPagoEntrenador.value.metodo,
        referencia: this.formPagoEntrenador.value.referencia,
        fecha: this.formPagoEntrenador.value.fecha,
        monto: this.amountTrainerPayment,
        entry: '',
        idUsuario: this.idUsuario,
        idConcurso: this.idConcurso,
        idEntrenador: this.entrenadorSeleccionado,
        nombre: this.nombre
      }
    ].filter(p => p.monto > 0);

    const totalPagos = payments.reduce((a, b) => a + b.monto, 0);

    //Validar que el metodo de pago sea cheque y que el numero de cheque no este vacio
    if (this.formPagoEntrenador.get('metodo').value == 'Cheque') {
      const [decimales] = `${+(this.montoCheque||'')}`.split('.').reverse();
      const montoCheque = +this.montoCheque;
      const totalCheque = +totalPagos.toFixed((decimales||'').length);
      if(this.numeroCheque == '') {
        $('#numero-cheque').addClass('no-valido');
        this.pagoEnProceso = false;
        return;
      } else if(this.montoCheque == '') {
        $('#monto-cheque').addClass('no-valido');
        this.pagoEnProceso = false;
        return;
      } else if(totalCheque != montoCheque){
        $.NotificationApp.send("Error", 'Check that the sum matches what’s on the first step', 'bottom-right', '#fa5c7c', 'error', 15000);
        this.pagoEnProceso = false;
        return;
      } else{
        let referencia = this.formPagoEntrenador.get('referencia').value ? this.formPagoEntrenador.get('referencia').value + '. Check number: ' + this.numeroCheque : 'Check number: ' + this.numeroCheque;
        this.formPagoEntrenador.get('referencia').setValue(referencia);
      }
    }

    //Validar que al menos tenga un pago
    if (!payments.length) {
      $.NotificationApp.send("Warning", "Please add at least one payment.", 'bottom-right', '#ffbc00', 'warning', 10000);
      this.pagoEnProceso = false;
      return;
    }

    let cardFees = [];
    if (stripe && stripe.comision) {
      //Arreglo con los cargos de comision stripe
      cardFees = payments.map(p => {
        return {
          cantidad: 1,
          monto: p.monto * stripe.comision,
          entry: p.entry,
          notas: [`Payment ID: ${stripe.pi}`, `${p.referencia || ''}`].filter(v => v).join(', '),
          concepto: null,
          idConcurso: p.idConcurso,
          idUsuario: this.idUsuario,
          idEntrenador: this.entrenadorSeleccionado,
          nombre: this.nombre
        };
      });

      //Si es stripe agregar comision a los montos individuales
      payments.map(p => {
        p.monto = p.monto * (1 + stripe.comision);
        p.referencia = [`Payment ID: ${stripe.pi}`, `${p.referencia || ''}`].filter(v => v).join(', ');
        return p;
      });
    }

    //TODO: Ajustar para pagos con chase
    const response = await this.entrenadorService.agregarPagos(payments, cardFees).catch(r => {
      const error = r.error.message || (r.message || (r.error || r));
      $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error', 15000);
      return;
    });
    if (response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      this.pagoEnProceso = false;
      this.numeroCheque = '';
      this.montoCheque = '';
      return;
    }
    this.pagoEnProceso = false;
    $('#paymentModal').modal('hide');
    $.NotificationApp.send("Success", response.logs, 'bottom-right', '#06d5a1', 'success');
    this.seleccionarEntrenador();
  }

  public checkNumberPayment(e) {
    this.numeroCheque = e.currentTarget.value;
    if (this.numeroCheque == '')
      $('#numero-cheque').addClass('no-valido');
    else
      $('#numero-cheque').removeClass('no-valido');
  }

  public checkAmountPayment(e){
    this.montoCheque = e.currentTarget.value;
    if (this.montoCheque == '')
      $('#monto-cheque').addClass('no-valido');
    else
      $('#monto-cheque').removeClass('no-valido');
  }

  public sumaTotalEntries(): number {
    return this.entriesEntrenador.filter(e => e.sumar).reduce((a, b) => {
      return a + parseFloat(b.saldo);
    }, 0) + (this.trainerBalance ? this.saldoEntrenador : 0);
  }

  public sumaTotalEntriesSinNegativos(): number {
    return this.entriesEntrenador.filter(e => e.sumar).reduce((a, b) => {
      return a + (parseFloat(b.saldo) > 0 ? parseFloat(b.saldo) : 0);
    }, 0) + (this.trainerBalance ? (this.saldoEntrenador > 0 ? this.saldoEntrenador : 0) : 0);
  }

  public traducirMetodoDePago(metodo): string {
    const metodos = {
      'Tc': 'Credit Card',
      'Cheque': 'Check',
      'Deposito': 'Bank Deposit',
      'Efectivo': 'Cash',
      'Transferencia': 'Wire Transfer',
      'Reembolso': 'Refund',
      'Cortesia': 'Courtesy',
      'Intercambio': 'Exchange',
      'Traspaso': 'Balance Transfer',
      'PrizeMoney': 'Prize Money',
    };

    return metodos[metodo] ? metodos[metodo] : metodo;
  }

  public estatusMarcarYDesmarcarTodo(): boolean {
    let marcar = false;

    this.entriesEntrenador.forEach(e => {
      if (!e.sumar) {
        marcar = true;
      }
    });

    if (!this.trainerBalance) {
      marcar = true;
    }

    return marcar;
  }

  public marcarYDesmarcarTodo() {
    if (this.estatusMarcarYDesmarcarTodo()) {//Seleccionat todo
      this.entriesEntrenador.map(e => {
        e.sumar = true;

        return e;
      });
      this.trainerBalance = true;
    } else {//Desmarcar todo
      this.entriesEntrenador.map(e => {
        e.sumar = false;

        return e;
      });
      this.trainerBalance = false;
    }
  }

  public async getEntrenadoresTraspaso(e: any) {
    const filtro: string = e.target.value;
    // Solo hacer consulta de personas cuando la cadena tiene más de 3 letras
    if (filtro.length > 3) {
      const concurso = this.formTraspaso.get('concurso').value;
      try {
        const response = await firstValueFrom(this.entrenadorService.getEntrenadoresFiltrados(filtro, concurso));
        if (!response.error) {
          this.entrenadoresTraspaso = response;
        } else {
          this.handleError(response.message, "It has not been possible to query the person, please try again.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to query the person, please try again.");
      }
    }
  }

  public async getPersonas(e) {
    // Solo hacer consulta de personas cuando la cadena tiene más de 3 letras
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      $('#loader').show();

      try {
        const response = await firstValueFrom(this.personaService.getPersonasFiltradas(filtro, this.idConcurso));
        (!response.error) ? this.personas = response.personas : this.handleError(response.message, 'It has not been possible to query the person, please try again.');
      } catch (error) {
        this.handleError(error, 'It has not been possible to query the person, please try again.');
      }
    }
  }

  public displayPersona(idPersona): string {
    const persona = this.personas.find(p => p.id_persona == idPersona);

    return persona ? persona.fullname : idPersona;
  }

  public displayEntrenador(idPersona): string {
    const persona = this.entrenadoresTraspaso.find(p => p.id_persona == idPersona);

    return persona ? persona.fullname : idPersona;
  }

  public async mostrarModalTraspaso() {
    $('form').removeClass('was-validated');
    //this.entrenadoresTraspaso = this.entrenadores.filter(e => e.idEntrenador != this.entrenadorSeleccionado);
    this.entrenadoresTraspaso = this.personas.filter(e => e.id_persona != this.entrenadorSeleccionado);
    this.formTraspaso.setValue({
      concurso: this.idConcurso,
      entrenador: '',
      entry: '',
      fecha: this.fecha,
      notas: '',
      monto: '',
      deEntrenador: this.entrenadorSeleccionado,
      deConcurso: this.idConcurso,
      idUsuario: this.idUsuario
    });
    this.formTraspaso.get('entry').setValue('');
    /*this.entriesTraspaso = [];
    try {
      const response = await firstValueFrom(this.entriesService.getEntries(this.idConcurso));
      (!response.error) ? this.entriesTraspaso = response.data : this.handleError(response.message, 'It has not been possible to consult the entries list.');
    } catch (error) {
      this.handleError(error, 'It has not been possible to consult the entries list.');
    }*/
    $('#modal-traspaso').modal('show');
  }

  public async consultarEntrenadoresTraspaso() {
    $('#loader').show();
    this.formTraspaso.get('entrenador').setValue('');
    if (this.formTraspaso.value.concurso == this.idConcurso) {
      this.entrenadoresTraspaso = this.entrenadores.filter(e => e.idEntrenador != this.entrenadorSeleccionado);
      $('#loader').hide();
    } else {
      this.entrenadoresTraspaso = [];
      try {
        const response = await firstValueFrom(this.entrenadorService.getEntrenadores(this.formTraspaso.value.concurso));
        (!response.error) ? (this.entrenadoresTraspaso = response.entrenadores) : this.handleError(response.message, 'Error fetching trainers.');
      } catch (error) {
        this.handleError(error, 'Error fetching trainers.');
      }
    }
  }

  public async balanceTransfer() {
    if (this.formTraspaso.valid && (this.formTraspaso.get('entrenador').value || this.formTraspaso.get('entry').value)) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entrenadorService.balanceTransfer(this.formTraspaso.value));
        if (!response.error) {
          $('#modal-traspaso').modal('hide');
          this.seleccionarEntrenador();
          $.NotificationApp.send("Success", response.message, 'bottom-right', '#0acf97', 'success', 10000);
        } else {
          this.handleError(response.message, 'Error performing balance transfer.');
        }
      } catch (error) {
        this.handleError(error, 'Error performing balance transfer.');
      }
    }
  }

  public makeSplits() {
    $('#form-split').addClass('was-validated');
    $('#add-entry-split').val('');
    if (this.formSplitEntrenador.valid) {
      this.splitsEntries = [];
      this.entriesEntrenador.map(e => {
        e.splitAmount = '';

        return e;
      });
      $('#modal-split').modal('show');
    }
  }

  public async addSplitEntry(num){
    console.log('Add New Entry: ', num);
    if(!num) return;


    await this.entriesService.searchEntries(this.idConcurso, num).subscribe((response: any) => {
      this.entries = response.data;
      console.log('Entries: ', this.entries);
      console.log('Available fields in entry:', Object.keys(this.entries[0]));
      const entry = this.entries.find(e => e.entry == num);
      if(!entry){
        $('#add-entry-split').val('');
        $.NotificationApp.send("Error", 'Entry not found', 'bottom-right', '#fa5c7c', 'error');
        return;
      }
      $('#add-entry-split').val('');
      this.splitsEntries.push({
        entry: entry.entry,
        horse: entry.horse,
        amount: '',
        trainer: entry.trainer,
      });

    })






  }

  public sumaSplits(): number {
    return this.entriesEntrenador.reduce((a, b) => a + parseFloat(b.splitAmount||'0'), 0) +  this.splitsEntries.reduce((a, b) => a + parseFloat(b.amount||'0'), 0);
  }

  public async validarCuenta(e) {
    const validated = e.target.checked ? 1 : 0;
    try {
      const response = await firstValueFrom(this.entrenadorService.validarCuenta(this.idConcurso, this.entrenadorSeleccionado, validated));
      if (!response.error) {
        if (validated === 1) {
          this.formCargoEntrenador.disable();
          this.formSplitEntrenador.disable();
        } else {
          this.formCargoEntrenador.enable();
          this.formSplitEntrenador.enable();
        }
      } else {
        this.handleError(response.message, "It has not been possible to validate the account.");
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to validate the invoice.');
    }
  }

  public async anularPago(idTransaccion: any, monto: any, metodo: string) {
    if (metodo == 'Traspaso') {
      $('#motivoAnulacionTraspaso').modal('show');
      this.motivoAnulacionTraspaso = '';
      this.idTransaccionAnulacionTraspaso = idTransaccion;
      this.montoAnulacionTraspaso = monto;
      return;
    }

    try {
      $('#loader').show();
      const response = await firstValueFrom(this.entrenadorService.cancelPayment(idTransaccion, metodo, monto, this.idUsuario, this.idConcurso, this.entrenadorSeleccionado));
      if (!response.error) {
        this.seleccionarEntrenador();
        $.NotificationApp.send("Canceled", response.message, 'bottom-right', '#06d5a1', 'success');
      } else {
        this.handleError(response.message);
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to cancel the payment.");
    }
  }

  public async anularTraspaso(): Promise<void> {
    $('#loader').show();
    try {
      const response = await firstValueFrom(
        this.entrenadorService.cancelPayment(this.idTransaccionAnulacionTraspaso,'Traspaso',this.montoAnulacionTraspaso,this.idUsuario,this.idConcurso,this.entrenadorSeleccionado,this.motivoAnulacionTraspaso)
      );
      if (!response.error) {
        this.seleccionarEntrenador();
        $('#motivoAnulacionTraspaso').modal('hide');
        $.NotificationApp.send("Canceled", response.message, 'bottom-right', '#06d5a1', 'success', 10000);
      } else {
        this.handleError(response.message, "It has not been possible to cancel the payment.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to cancel the payment.");
    }
  }

  public mostrarModalProductos(): void {
    this.selectedProducts = [];
    this.selectedProduct = '';
    this.selectedQty = 1;
    this.selectedTotal = 0;
    $('#modal-productos').modal('show');
  }

  public changeTotal(): void {
    if (this.selectedProduct && this.selectedQty > 0) {
      const product = this.products.find(p => p.id == this.selectedProduct);
      this.selectedTotal = parseFloat(product.price) * this.selectedQty;
    }
  }

  public addProduct(): void {
    if (this.selectedProduct && this.selectedQty > 0) {
      const product = this.products.find(p => p.id == this.selectedProduct);
      if (this.selectedQty > parseInt(product.qty)) {//No hay inventario suficiente
        $.NotificationApp.send("Error", "Not enough products.", 'bottom-right', '#fa5c7c', 'error');
        return;
      }
      //Validar que hay suficiente inventario
      this.selectedProducts.push({
        id: product.id,
        name: product.name,
        sku: product.sku,
        qty: this.selectedQty,
        price: this.selectedTotal
      });
      this.selectedProduct = '';
      this.selectedQty = 1;
      this.selectedTotal = 0;
    }
  }

  public removeProduct(id) {
    const index = this.selectedProducts.findIndex(p => p.id == id);
    this.selectedProducts.splice(index, 1);
  }

  public async saveProductCharges(): Promise<void> {
    if (this.selectedProducts.length > 0) {
      $('#loader').show();
      //Obtener el nombre del entrenador seleccionado
      const index = this.entrenadores.findIndex(entrenador => {
        return entrenador.idEntrenador == this.entrenadorSeleccionado;
      });
      const nombre = this.entrenadores[index].nombre;
      const concepto = await firstValueFrom(this.productService.concept(this.idConcurso)).then(r => r.concepto);
      //Promesas para agregar cargos a la bd
      const requests = this.selectedProducts.map(p => firstValueFrom(this.entrenadorService.agregarCargo({
        concepto: concepto.id,
        cantidad: p.qty,
        monto: p.price,
        notas: p.name,
        entry: '',
        idUsuario: this.idUsuario,
        idConcurso: this.idConcurso,
        idEntrenador: this.entrenadorSeleccionado,
        nombre: nombre
      })));
      //Promersas para actualizar inventario
      const requests2 = this.selectedProducts.map(p => {
        return firstValueFrom(this.productService.updateQty(p));
      });
      await Promise.all(requests.concat(requests2)).catch(r => {
        const error = r.error.message || (r.message || (r.error || r));
        $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error', 15000);
        return;
      });
      this.getProducts();
      this.seleccionarEntrenador();
    }
  }

  public async refreshSaldosEntry() {
    $('#loader').show();
    const promises: Promise<any>[] = this.entriesEntrenador.map(e => firstValueFrom(this.entriesService.recalcularDivisiones(e.entry, this.idConcurso, this.idUsuario)));
    await Promise.all(promises).catch(r => {
      const error = r.error.message || (r.message || (r.error || r));
      $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error', 15000);
      return;
    });
    this.seleccionarEntrenador();
  }

  private makeid(length) {
    var result = [];
    var characters = '123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  }

  public agregarComision(amount: number): number {
    var comision = 1;
    // Solo agregar comision si es pago con tarjeta
    if (this.formPagoEntrenador.value.metodo == 'Tc') {
      comision = 1 + (this.public_key ? this.comision_stripe : .0325);
    }
    return amount * comision;
  }

  addedCheck(e) {
    console.log('Nuevo', e);
  }

  public toggleEditCardholderName(e, stripeCard) {
    e.stopPropagation();
    stripeCard.editable = !stripeCard.editable;
    if (stripeCard.editable) {
      setTimeout(() => {
        $(`#${stripeCard.id}`).select();
      });
    }
  }

  public async updateCardholderName(e, stripeCard) {
    const name = e.target.value;
    const { paymentMethod, message } = await firstValueFrom(this.stripeService.updateCardholderName(stripeCard.id, name, this.idConcurso));
    if (message) {
      $.NotificationApp.send("Error", message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    stripeCard.billing_details.name = paymentMethod.billing_details.name;
    stripeCard.editable = false;
  }

  public async deleteCard(customerRefNum, personID) {
    const response = await firstValueFrom(this.chaseService.deleteCard(customerRefNum, personID));
    if (response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    await this.getTarjetasRelacionadas();
  }

  public showAddCard() {
    this.cardForm.reset({
      number: '',
      date: '',
      name: '',
      save: false
    });
    this.showAddCardForm = true;
    setTimeout(() => {
      $.App.init();
    }, 500);
  }

  public hideAddCard() {
    this.showAddCardForm = false;
  }

  public async addCard() {
    if (this.cardForm.valid) {
      const idEntrenador = this.entrenadorSeleccionado;
      const card = {
        idConcurso: this.idConcurso,
        idPersona: idEntrenador,
        customerName: this.cardForm.value.name,
        customerAddress1: '',
        customerAddress2: '',
        customerCity: '',
        customerState: '',
        customerZIP: '',
        customerPhone: '',
        customerCountryCode: '',
        ccAccountNum: this.cardForm.value.number,
        ccExp: this.cardForm.value.date
      };
      const response: any = await firstValueFrom(this.chaseService.addCard(card));
      if (response.error) {
        $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        return;
      }
      this.showAddCardForm = false;
      await this.getTarjetasRelacionadas();
    }
  }

  public async saveNotes(e) {
    $('#loader').show();
    try {
      const response = await firstValueFrom(this.entrenadorService.saveNotes(this.idConcurso, this.entrenadorSeleccionado, e));
      (!response.error) ? (this.trainerNotes = e) : this.handleError(response.message, 'It has not been possible to save the notes.')
    } catch (error) {
      this.handleError(error, 'It has not been possible to save the notes.');
    }
  }

  public async validarMontoPositivo(){
    this.entriesEntrenador.map(e => {
      const amountPayment = parseFloat(e.amountPayment||'0');
      if(amountPayment < 0) e.amountPayment = 0;
      return e;
    });
    if(parseFloat(`${this.amountTrainerPayment}`||'0') < 0) this.amountTrainerPayment = 0;
  }

  public openSettleCreditsModal(){
    this.showSettleCredits = true;
    $('#settleCreditsModal').modal('show');
  }

  public onErrorSettleCredits(e){
    $.NotificationApp.send("Error", e, 'bottom-right', '#fa5c7c', 'error');
  }

  public onSuccessSettleCredits(e){
    $('#settleCreditsModal').modal('hide');
    this.seleccionarEntrenador();
    $.NotificationApp.send("Success", e, 'bottom-right', '#06d5a1', 'success');
  }

  public hasEntriesWithCredit(): boolean{
    return this.entriesEntrenador.filter((entry: any) => entry.saldo < 0).length > 0;
  }

  // REUTILIZABLES

  public resetUI(){
    this.visibleElements = {
      balance: false,
      totalCargos: false,
      totalCargosEntrenador: false,
      caliSplitSave: false,
      caliSplitSplit: false
    }
  }

  public handleError(reason: any, messageToUser?: string) {
    $('#loader').hide();
    const message = (reason.error || {}).text || (((reason.error || {}).error || {}).message || reason.message || reason.error || 'Error during request.');
    $.NotificationApp.send("Error", messageToUser || message, 'bottom-right', '#fa5c7c', 'error');
    console.log(`%c Handling Error:`, "color: white; font-size: 11px; background-color: #a02d24; padding: 1px 2px; border-radius: 3px;");
    console.error(message);
    return { error: true, message: messageToUser || message };
  }

}

