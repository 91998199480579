import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ConcursoService } from '../services/concurso.service';
import { EntriesService } from '../services/entries.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-copy-entries',
  templateUrl: './copy-entries.component.html',
  styleUrls: ['./copy-entries.component.css']
})
export class CopyEntriesComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public concursos: any[];
  public form: UntypedFormGroup;
  public entries: any[];
  public entriesValidados: any[];

  constructor(
    private authService: AuthService,
    private concursoService: ConcursoService,
    private entriesService: EntriesService,
    private router: Router
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.concursos = [];
    this.form = new UntypedFormGroup({
      idConcurso: new UntypedFormControl('', [Validators.required])
    });
    this.entries = [];
    this.entriesValidados = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getConcursos();
  }

  private getConcursos(){
    this.concursoService.getConcursos(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.getEntries();
          // Remove the current show from the list
          this.concursos = response.consursos.filter(c => c.id != this.idConcurso);
          console.log(this.concursos);
          // Filter shows not from the current year
          this.concursos = this.concursos.filter(c => new Date(c.inicio).getFullYear() == new Date().getFullYear());
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to query the shows list.', 'bottom-right', '#fa5c7c', 'error', 10000);
        console.log(error);
      }
    );
  }

  public getEntries(){
    this.entriesService.getEntries(this.idConcurso).subscribe(
      response => {
        if(!response.error){
            this.entries = response.data
            .sort(
              (a, b) => {
                if (a.trainers < b.trainers) {
                  return -1;
                }
                if (a.trainers > b.trainers) {
                  return 1;
                }
                return 0;
              })
            .map(e => {
              e.copy = false;
              e.entry = `${e.entry}`;
              return e;
            });

          ;
          //console.log(this.entries);
          $('#loader').hide();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to query the entries list.', 'bottom-right', '#fa5c7c', 'error', 10000);
        console.log(error);
      }
    );
  }

  public copyEntries(){
    if(this.form.valid){
      const entries = this.entries.filter(e => e.copy);
      if(entries.length > 0){
        $('#loader').show();
        this.entriesService.copy(entries, this.idConcurso, this.form.value.idConcurso, this.idUsuario).subscribe(
          response => {
            if(!response.error){
              $('#loader').hide();
              this.entriesValidados = response.entries;
              console.log(response);
            } else{
              $('#loader').hide();
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
            }
          },
          error => {
            $('#loader').hide();
            $.NotificationApp.send("Error", 'It has not been possible to copy the entries.', 'bottom-right', '#fa5c7c', 'error', 10000);
            console.log(error);
          }
        );
      } else{
        $.NotificationApp.send("Error", 'Please select at least one entry.', 'bottom-right', '#fa5c7c', 'error', 10000);
      }
    }
  }

  public entryPrefix(e){
    const prefijo = e.target.value;
    this.entries.map(e => {
      e.entry = `${prefijo}${e.entry}`

      return e;
    });
  }
}
