<div class="wrapper">
    <div class="content">
        <div class="container-fluid">
            <div class="row pagebreak" *ngFor="let solicitud of solicitudes">
                <div class="col-12">
                    <div class="card print-header">
                        <div class="row">
                        <div class="col-4 text-center">
                            <p class="mb-0 entries-due mt-3"><b>ENTRIES DUE<br>{{ entriesDue | date : 'MMM dd, yyyy' | titlecase }}</b></p>
                        </div>
                        <div class="col-4 text-center">
                            <img [src]="logoClub" style="max-width: 100px;">
                            <h5 class="m-0">{{ nombreConcurso }}</h5>
                            <h4 class="m-0">Entry Form</h4>
                        </div>
                        <div class="col-4 text-center mt-auto">
                            <p class="mb-0 info">ONE HORSE PER ENTRY FORM<br>
                            <b>{{ entryAmount | currency }} Deposit Due {{ entriesDue | date : 'MMM dd, yyyy' | titlecase }}</b><br>
                            <span>All horses must have membership cards,<br>
                                a valid coggins and health certificate.
                            </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 w-p-100">
                <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Horse</h5>
                        <div class="row">
                          <div class="col-3">
                            <div class="form-group mb-0">
                              <label for="horseName">Name </label>
                              <input type="text" id="horseName" class="form-control-plaintext" readonly [value]="solicitud.caballo.horseName">
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-0">
                              <label for="horseUsef">Usef # </label>
                              <input type="text" id="horseUsef" class="form-control-plaintext" readonly [value]="solicitud.caballo.horseUsef">
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-0">
                              <label for="horseFei">Fei # </label>
                              <input type="text" id="horseFei" class="form-control-plaintext" readonly [value]="solicitud.caballo.horseFei">
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-0">
                              <label for="horseNumber">Entry # </label>
                              <input type="text" id="horseNumber" readonly class="form-control-plaintext" [value]="solicitud.entry">
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group mb-0">
                                <label for="emergencyContact">Emergency contact: </label>
                                {{ solicitud.emergency_contact.name }} - {{ solicitud.emergency_contact.phone }}
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 w-p-50">
                <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Rider</h5>
                        <div class="row">
                          <div class="col-12">
                              <div style="display: flex; flex-wrap: wrap;">
                                <div class="w-50">
                                    <div class="form-group mb-0">
                                      <label for="riderName">Name: </label>
                                      <input ctype="text" id="riderName" class="form-control-plaintext inline-input" readonly [value]="solicitud.jinete.riderName+' '+solicitud.jinete.riderLastName">

                                    </div>
                                  </div>
                                  <div class="w-25">
                                    <div class="form-group mb-0">
                                      <label for="riderUsef">Usef #</label>
                                      <input type="text" id="riderUsef" class="form-control-plaintext inline-input" readonly [value]="solicitud.jinete.riderUsef">
                                    </div>
                                  </div>
                                  <div class="w-25">
                                    <div class="form-group mb-0">
                                      <label for="riderFei">Fei #</label>
                                      <input type="text" id="riderFei" class="form-control-plaintext inline-input" readonly [value]="solicitud.jinete.riderFei">
                                    </div>
                                  </div>
                                  <div class="w-50">
                                    <div class="form-group mb-0">
                                      <label for="riderPhone">Phone</label>
                                      <input type="text" id="riderPhone" class="form-control-plaintext inline-input" readonly [value]="solicitud.jinete.riderPhone">
                                    </div>
                                  </div>
                                  <div class="w-50">
                                    <div class="form-group mb-0">
                                      <label for="riderEmail">Email</label>
                                      <input type="text" id="riderEmail" class="form-control-plaintext inline-input" readonly [value]="solicitud.jinete.riderEmail">
                                    </div>
                                  </div>
                                  <div class="w-100">
                                    <div class="form-group mb-0">
                                      <label for="riderAddress">Address</label>
                                      <input type="text" id="riderAddress" class="form-control-plaintext inline-input address" [value]="solicitud.jinete.riderAddress">
                                    </div>
                                </div>
                              </div>

                          </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 w-p-50">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Owner</h5>
                    <div class="row">
                      <div class="col-12">
                        <div style="display: flex; flex-wrap: wrap;">
                            <div class="w-50">
                                <div class="form-group mb-0">
                                <label for="ownerName">Name</label>
                                <input type="text" id="ownerName" class="form-control-plaintext inline-input" readonly [value]="solicitud.propietario.ownerName+' '+solicitud.propietario.ownerLastName">
                                </div>
                            </div>
                            <div class="w-50">
                                <div class="form-group mb-0">
                                <label for="ownerUsef">Usef #</label>
                                <input type="text" id="ownerUsef" class="form-control-plaintext inline-input" readonly [value]="solicitud.propietario.ownerUsef">
                                </div>
                            </div>
                            <div class="w-50">
                                <div class="form-group mb-0">
                                <label for="ownerPhone">Phone</label>
                                <input type="text" id="ownerPhone" class="form-control-plaintext inline-input" readonly [value]="solicitud.propietario.ownerPhone">
                                </div>
                            </div>
                            <div class="w-50">
                                <div class="form-group mb-0">
                                <label for="ownerEmail">Email</label>
                                <input type="text" id="ownerEmail" class="form-control-plaintext inline-input" readonly [value]="solicitud.propietario.ownerPhone">
                                </div>
                            </div>
                            <div class="w-100">
                                <div class="form-group mb-0">
                                <label for="ownerAddress">Address</label>
                                <input type="text" id="ownerAddress" class="form-control-plaintext inline-input address" readonly [value]="solicitud.propietario.ownerAddress">
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 w-p-50">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Trainer</h5>
                    <div class="row">
                        <div class="col-12">
                            <div style="display: flex; flex-wrap: wrap;">
                                <div class="w-50">
                                    <div class="form-group mb-0">
                                        <label for="trainerName">Name</label>
                                        <input type="text" id="trainerName" class="form-control-plaintext inline-input" readonly  [value]="solicitud.entrenador.trainerName+' '+solicitud.entrenador.trainerLastname">
                                    </div>
                                    </div>
                                    <div class="w-50">
                                    <div class="form-group mb-0">
                                        <label for="trainerUsef">Usef #</label>
                                        <input type="text" id="trainerUsef" class="form-control-plaintext inline-input" readonly [value]="solicitud.entrenador.trainerUsef">
                                    </div>
                                    </div>
                                    <div class="w-50">
                                    <div class="form-group mb-0">
                                        <label for="trainerPhone">Phone</label>
                                        <input type="text" id="trainerPhone" class="form-control-plaintext inline-input" readonly [value]="solicitud.entrenador.trainerPhone">
                                    </div>
                                    </div>
                                    <div class="w-50">
                                    <div class="form-group mb-0">
                                        <label for="trainerEmail">Email</label>
                                        <input type="text" id="trainerEmail" class="form-control-plaintext inline-input" readonly [value]="solicitud.entrenador.trainerEmail">
                                    </div>
                                    </div>
                                    <div class="w-100">
                                    <div class="form-group mb-0">
                                        <label for="trainerAddress">Address</label>
                                        <input type="text" id="trainerAddress" class="form-control-plaintext inline-input address" [value]="solicitud.entrenador.trainerAddress">
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
        </div>
        <div class="col-12 w-p-50">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Payee</h5>
                    <div class="row">
                      <div class="col-12">
                        <div style="display: flex; flex-wrap: wrap;">
                            <div class="w-50">
                                <div class="form-group mb-0">
                                <label for="payeeName">Name</label>
                                <input type="text" id="payeeName" class="form-control-plaintext inline-input" readonly [value]="solicitud.responsable.payeeName+' '+solicitud.responsable.payeeLastName">
                                </div>
                            </div>
                            <div class="w-50">
                                <div class="form-group mb-0">
                                <label for="payeeUsef">Usef #</label>
                                <input type="text" id="payeeUsef" class="form-control-plaintext inline-input" readonly [value]="solicitud.responsable.payeeUsef">
                                </div>
                            </div>
                            <div class="w-50">
                                <div class="form-group mb-0">
                                <label for="payeePhone">Phone</label>
                                <input type="text" id="payeePhone" class="form-control-plaintext inline-input" readonly [value]="solicitud.responsable.payeePhone">
                                </div>
                            </div>
                            <div class="w-50">
                                <div class="form-group mb-0">
                                <label for="payeeEmail">Email</label>
                                <input type="text" id="payeeEmail" class="form-control-plaintext inline-input" readonly [value]="solicitud.responsable.payeeEmail">
                                </div>
                            </div>
                            <div class="w-100">
                                <div class="form-group mb-0">
                                <label for="payeeAddress">Address</label>
                                <input type="text" id="payeeAddress" class="form-control-plaintext inline-input address"readonly [value]="solicitud.responsable.payeeAddress">
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
        </div>
        <div class="col-12 w-p-50">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Stabling information</h5>
                    <div class="row">
                      <div class="col-12">
                        <div class="d-flex">
                            <div class="form-group mb-0">
                              <label for="stableWith">Stable with</label>
                              <input type="text" id="stableWith" class="form-control-plaintext inline-input" readonly [value]="solicitud.otros.stableWith">
                            </div>
                          </div>
                          <div class="d-flex">
                            <div class="form-group mb-0">
                              <label for="arrivalDate">Arrival Date</label>
                              <input type="text" id="arrivalDate" class="form-control-plaintext inline-input" readonly [value]="solicitud.otros.arrivalDate">
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
        </div>
          <div class="col-12 w-p-50">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Classes</h5>
                  <div class="row">
                    <div class="col-12">
                      <!--*ngFor="let prueba of solicitud.pruebas"-->
                      <div class="w-100 d-flex prueba">
                        <div class="numero">{{ numerosPruebas(solicitud.pruebas) }}</div><!-- {{ prueba.numero }} -->
                        <!--div class="altura">{{ prueba.altura }}</div>
                                              <div>{{ prueba.prueba }}</div>
                                              <div class="ml-1" *ngIf="prueba.ambito >= 3"><img style="max-width: 2rem" src="assets/images/icons/fei.svg"></div-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-12 w-p-100">
            <div class="card charges">
                <div class="card-body">
                  <h5 class="card-title">Charges</h5>
                  <div class="row">
                    <div class="col-12">
                      <ul class="list-unstyled mb-1" style="column-count: 3;">
                        <li *ngFor="let cargo of solicitud.cargos" class="charge">{{ cargo.nombre }} ({{ cargo.monto |
                          currency }}) x {{ cargo.qty }} = {{ cargo.total | currency }}</li>
                      </ul>
                      <p class="text-right lead mb-0">Total: {{ totalCargos(solicitud.cargos) | currency }}</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-12  w-p-100 d-print-none">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Tax id</h5>
                  <p class="mb-0">{{ solicitud.tax_id }}</p>
                </div>
              </div>
          </div>
          <div class="col-12">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Payment</h5>
                  <p><b>Transaction ID: </b> {{ solicitud.pago.chaseId ? solicitud.pago.chaseId : solicitud.pago.notas }}</p>
                </div>
              </div>
          </div>
          <div class="col-12 legal d-none d-print-block">
            <div class="card">
              <h5>FEDERATION ENTRY AGREEMENT</h5>
              <p>By entering a Federation-licensed Competition and signing this entry blank as the Owner, Lessee,
                Trainer, Manager, Agent, Coach, Driver, Rider, Handler,
                Vaulter or Longeur and on behalf of myself and my principals, representatives, employees and agents, I
                agree that I am subject to the Bylaws and Rules
                of The United States Equestrian Federation, Inc. (the “Federation”) and the local rules of
                __________________________(Competition). I agree to be
                bound by the Bylaws and Rules of the Federation and of the competition. I will accept as final the
                decision of the Hearing Committee on any question arising
                under the Rules, and agree to release and hold harmless the competition, the Federation, their
                officials, directors and employees for any action taken under
                the Rules. I represent that I am eligible to enter and/or participate under the Rules, and every horse I
                am entering is eligible as entered. I also agree that as
                a condition of and in consideration of acceptance of entry, the Federation and/or the Competition may
                use or assign photographs, videos, audios, cable -
                casts, broadcasts, internet, film, new media or other likenesses of me and my horse taken during the
                course of the competition for the promotion, coverage
                or benefit of the competition, sport, or the Federation. Those likenesses shall not be used to advertise
                a product and they may not be used in such a way as
                to jeopardize amateur status. I hereby expressly and irrevocably waive and release any rights in
                connection with such use, including any claim to compensation, invasion of privacy, right of publicity,
                or to misappropriation. The construction and application of Federation rules are governed by the laws of
                the State
                of New York, and any action instituted against the Federation must be filed in New York State. See
                GR908.4.</p>
              <p><b>I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND ALL OF ITS TERMS AND CONDITIONS, AND
                  KNOW IT CONTAINS AN ASSUMPTION
                  OF RISK, RELEASE AND WAIVER FROM LIABILITY, AS WELL AS A HOLD HARMLESS AND INDEMNIFICATION
                  OBLIGATIONS.</b></p>
              <p>By signing below, I (as the participant or as the Parent/Legal Guardian of the minor identified below)
                hereby accept and agree to the terms and conditions of this Agreement in connection with my (or the
                minor’s) participation in any USEF Event. If, despite this Agreement, I, or anyone on my behalf or the
                minor’s behalf, makes a claim for Liability against any of the Released Parties, I will indemnify,
                defend and hold harmless each of the Released Parties
                from any such Liabilities as the result of such claim.</p>
              <p><b>BY SIGNING BELOW, I AGREE</b> to be bound by all applicable Federation Rules and all terms and
                provisions of this entry blank and all terms and provisions
                of this Prize List. If I am signing and submitting this Agreement electronically, I acknowledge that my
                electronic signature shall have the same validity,
                force and effect as if I affixed my signature by my own hand.</p>

                <h5>WAIVER AND RELEASE OF LIABILITY, ASSUMPTION OF RISK AND INDEMNITY AGREEMENT</h5>
                <p>For and in consideration of United States Equestrian Federation, Inc. dba US Equestrian (“USEF”) allowing me, the undersigned, to participate in any
                  capacity (including as a rider, driver, handler, vaulter, longeur, lessee, owner, agent, coach, official, trainer or volunteer) in a USEF sanctioned, licensed or
                  approved event or activity, including but not limited to equestrian clinics, practices, shows, competitions and related or incidental activities and
                  ________________________________(<b>“USEF Event”</b> or <b>“USEF Events”</b>); I, for myself, and on behalf of my spouse, children, heirs and next of kin,
                  and any legal and personal representatives, executors, administrators, successors,, and assigns, hereby agree to and make the following contractual repre- sentations pursuant to this Agreement (the “Agreement”):
                </p>
                <p>
                  A. RULES AND REGULATIONS: I hereby agree that I have read, understand, and agree to be bound by all applicable Federation Bylaws, rules, and policies
                  including the USEF Safe Sport Policy and Minor Athlete Abuse Prevention Policies (MAAPP) as published at www.usef.org, as amended from time to time.
                </p>
                <p>B. ACKNOWLEDGMENT OF RISK: I knowingly, willingly, and voluntarily acknowledge the inherent risks associated with the sport of equestrian and know that
                  horseback riding and related equestrian activities are inherently dangerous, and that participation in any USEF Event involves risks and dangers including, without limitation, the potential for serious bodily injury (including broken bones, head or neck injuries), sickness and disease (including communicable diseases),
                  trauma, pain & suffering, permanent disability, paralysis and death; loss of or damage to personal property (including my mount & equipment) arising out of the
                  unpredictable behavior of horses; exposure to extreme conditions and circumstances; accidents involving other participants, eventstaff, volunteersor spectators; contact orcollision with other participants and horses, natural or manmadeobjects; adverseweather conditions; facilities issues and premises conditions;
                  failure of protective equipment (including helmets); inadequate safety measures; participants of varying skill levels; situations beyond the immediate control of
                  the USEF Event organizers and competition management; and other undefined, not readily foreseeable and presently unknown risks and dangers <b>(“Risks”)</b>.
                </p>
                <p class="text-center"><b>EQUINE ACTIVITY LIABILITY ACT WARNING:</b><br>
                  CAUTION: HORSEBACK RIDING AND EQUINE ACTIVITIES CAN BE DANGEROUS. RIDE AT YOUR OWN RISK.<br>
                  <b>Under the laws of most States, an equine activity sponsor or equine professional is not liable for any injury to, or the death of, a participant in
                    equine activities resulting from the inherent risks of equine activities.</b>
                </p>
                <p>C. ASSUMPTION OF RISK: I understand that the aforementioned Risks may be caused in whole or in part or result directly or indirectly from the negligence
                  of my own actions or inactions, the actions or inactions of others participating in the USEF Events, or the negligent acts or omissions of the Released Parties defined below, and I hereby voluntarily and knowingly assume all such Risks and responsibility for any damages, liabilities, losses, or expenses that I
                  incur as a result of my participation in any USEF Events. I also agree to be responsible for any injury or damage caused by me, my horse, my employees or
                  contractors under my direction and control at any USEF Event.
                </p>
                <p>D. WAIVER AND RELEASE OF LIABILITY, HOLD HARMLESS AND INDEMNITY: In conjunction with my participation in any USEF Event, I hereby release, waive
                  and covenant not to sue, and further agree to indemnify, defend and hold harmless the following parties: USEF, USEF Recognized Affiliate Associations,
                  the United States Olympic & Paralympic Committee (USOPC), USEF clubs, members, Event participants (including athletes/riders, coaches, trainers, judges/officials, and other personnel), the Event owner, licensee, and competition managers; the promoters, sponsors, or advertisers of any USEF Event; any
                  charity or other beneficiary which may benefit from the USEF Event; the owners, managers, or lessors of any facilities or premises where a USEF Event may
                  be held; and all directors, officers, employees, agents, contractors, and volunteers of any of the aforementioned parties <b>(Individually and Collectively, the
                  “Released Parties” or “Event Organizers”)</b>, with respect to any liability, claim(s), demand(s), cause(s) of action, damage(s), loss, or expense (including
                  court costs and reasonable attorney fees) of any kind or nature <b>(“Liability”)</b> which may arise out of, result from, or relate in any way to my participation in
                  the USEF Events, including claims for Liability caused in whole or in part by the negligent acts or omissions of the Released Parties.
                </p>
                <p>E. COMPLETE AGREEMENT AND SEVERABILITY CLAUSE: This Agreement represents the complete understanding between the parties regarding these issues
                  and no oral representations, statements or inducements have been made apart from this Agreement. If any provision of this Agreement is held to be
                  unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and
                  enforceability of any remaining provisions.
                </p>
                <p><b>I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND ALL OF ITS TERMS AND CONDITIONS, AND KNOW IT CONTAINS AN ASSUMPTION
                  OF RISK, RELEASE AND WAIVER FROM LIABILITY, AS WELL AS A HOLD HARMLESS AND INDEMNIFICATION OBLIGATIONS.</b>
                </p>
                <p>By signing below, I (as the participant or as the Parent/Legal Guardian of the minor identified below) hereby accept and agree to the terms and conditions of this Agreement in connection with my (or the minor’s) participation in any USEF Event. If, despite this Agreement, I, or anyone on my behalf or the
                  minor’s behalf, makes a claim for Liability against any of the Released Parties, I will indemnify, defend and hold harmless each of the Released Parties
                  from any such Liabilities as the result of such claim.
                </p>
                <p><b>The parties agree that this agreement may be electronically signed. The parties agree that the electronic signatures appearing on this agreement
                  are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility.</b>
                </p>
            </div>
          </div>
          <div class="col-12 mt-1">
            <div class="card">
              <div class="row">
                <div class="col-4 text-center">
                  <img *ngIf="solicitud.signature_rider" style="max-width: 100%;" [src]="solicitud.signature_rider"
                    onerror="this.style.display = 'none'">
                </div>
                <div class="col-4 text-center">
                  <img *ngIf="solicitud.signature_owner" style="max-width: 100%;" [src]="solicitud.signature_owner"
                    onerror="this.style.display = 'none'">
                </div>
                <div class="col-4 text-center">
                  <img *ngIf="solicitud.signature_trainer" style="max-width: 100%;" [src]="solicitud.signature_trainer"
                    onerror="this.style.display = 'none'">
                </div>
                <div class="col-4 text-center">
                  <div style="border-top: 1px solid black;">Rider</div>
                </div>
                <div class="col-4 text-center">
                  <div style="border-top: 1px solid black;">Owner</div>
                </div>
                <div class="col-4 text-center">
                  <div style="border-top: 1px solid black;">Trainer</div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
