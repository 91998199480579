import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AUTH_HEADER } from '../http-interceptors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the value of the AUTH_HEADER token from the request context.
    const addAuthHeader = req.context.get(AUTH_HEADER);
    // If the token is not set, do not add the authorization header.
    if(!addAuthHeader) return next.handle(req);

    // Get the auth token from the service.
    const authToken = this.auth.getAuthorizationToken();
    // Clone the request and replace the original headers with cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
