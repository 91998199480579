import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntriesService } from '../services/entries.service';
import { PrintService } from '../services/print.service';

@Component({
  selector: 'app-entry-invoice',
  templateUrl: './entry-invoice.component.html',
  styleUrls: ['./entry-invoice.component.css']
})
export class EntryInvoiceComponent implements OnInit {
  public entries: string[];
  private idConcurso: string;
  public concurso: Promise<any>;
  public entryDetails: Promise<any>[];
  public date: Date;

  constructor(private route: ActivatedRoute, private entriesService: EntriesService, private printService: PrintService) {
    this.entries = this.route.snapshot.params['entries'].split(',');
    this.idConcurso = this.route.snapshot.params['idConcurso'];
    this.date = new Date();
  }

  ngOnInit() {
    this.entryDetails = this.entries.map(entry => this.getEntryInvoice(entry));
    Promise.all(this.entryDetails).then(() => setTimeout(() => { this.printService.onDataReady() }, 2000));
  }

  getEntryInvoice(entry: string): Promise<any> {
    return this.entriesService.getInvoice(entry, this.idConcurso).toPromise();
  }

  public traducirMetodoDePago(metodo): string {
    // Creo que falta agregar uno
    const metodos = {
      'Tc': 'Credit Card',
      'Cheque': 'Check',
      'Deposito': 'Bank Deposit',
      'Efectivo': 'Cash',
      'Transferencia': 'Wire Transfer',
      'Reembolso': 'Refund',
      'Cortesia': 'Courtesy',
      'Intercambio': 'Exchange',
      'CC PrePayment': 'CC PrePayment'
    };

    return metodos[metodo] ? metodos[metodo] : '';
  }
}
