import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ContabilidadService } from '../services/contabilidad.service';
import { ConcursoService } from '../services/concurso.service';
import { FeedOrdersService } from '../services/feed-orders.service';
import { ProductService } from '../services/product.service';
import { UntypedFormControl, Validators } from '@angular/forms';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'contabilidad',
  templateUrl: './contabilidad.component.html',
  styleUrls: ['./contabilidad.component.css']
})

export class ContabilidadComponent implements OnInit {
  public token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public idConcurso: string | boolean;
  public fechaInicio: string;
  public fechaFin: string;
  public responsables: string;
  public pruebas: string;
  public pocisiones: string;
  public caballos: string;
  public jinetes: string;
  public aplicaCheque: string;
  public premios: string;
  public filas: Array<any>;
  public incomeRows: any;
  public expenses: any;
  public testPrizes: any;
  public totalPrizes: number;
  public totalChampionshipPrizes: number;
  public totalExpenses: number;
  public paymentsReceived: Array<any>;
  public paymentToReceive: Array<any>;
  public totalPayments: Array<any>;
  public paymentsPerUser: Array<any>;
  public totalPaymentAmount: number;
  public totalInscritos: number;
  public expenseConcept: UntypedFormControl;
  public expensePaymentMethod: UntypedFormControl;
  public expenseReference: UntypedFormControl;
  public expenseAmount: UntypedFormControl;
  public detallesMetodo: Array<any>;
  public metodoSeleccionado: string;
  public semanas: Array<any>;
  public orders: any[];
  public header: string;
  public merch: any[];
  public totalMerch: any;
  public printMerch: boolean;
  public filtro: string;

  public abc: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private contabilidadService: ContabilidadService,
    private concursoService: ConcursoService,
    private feedOrdersService: FeedOrdersService,
    private productService: ProductService
  ) {
      this.token = '';
      this.sessionData = {};
      this.privilegios = 0;
      this.idConcurso = '';
      this.nombreConcurso = this.authService.getNombreConcurso();
      this.fechaInicio = '';
      this.fechaFin = '';
      this.responsables = '';
      this.pruebas = '';
      this.pocisiones = '';
      this.caballos = '';
      this.jinetes = '';
      this.aplicaCheque = '';
      this.premios = '';
      this.filas = [];
      this.incomeRows = [];
      this.expenses = [];
      this.paymentsReceived = [];
      this.paymentToReceive = [];
      this.totalPayments = [];
      this.paymentsPerUser = [];
      this.testPrizes = [];
      this.totalPrizes = 0;
      this.totalChampionshipPrizes = 0;
      this.totalExpenses = 0;
      this.totalPaymentAmount = 0;
      this.totalInscritos = 0;
      this.expenseConcept = new UntypedFormControl('',[]);
      this.expensePaymentMethod = new UntypedFormControl('',[Validators.pattern('[0-9]{0,}')]);
      this.expenseReference = new UntypedFormControl('',[]);
      this.expenseAmount = new UntypedFormControl('',[]);
      this.detallesMetodo = [];
      this.metodoSeleccionado = '';
      this.semanas = [];
      this.orders = [];
      this.header = '';
      this.merch = [];
      this.totalMerch = 0;
      this.printMerch = false;
      this.filtro = '';
      this.abc = this.authService.isLoggedIn();
    }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 200){
      this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getConcurso();
    this.getListaPremios();//checar
    this.getSummaryIncome();
    this.getPaymentReceived();
    this.getPaymentToReceive();
    this.getTotalPayment();
    this.getExpenses();
    this.getPrizesPerTest();//checar
    this.getSemanas();
    this.getOrdersSummary();
    this.getMerchSummary();
  }
  public getConcurso(){
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.header = response.concurso.logoClub;
          this.fechaInicio = response.concurso.inicio;
          this.fechaFin = response.concurso.fin;
          $('#loader').hide();
        } else{
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
      }
    );
  }
  public getOrdersSummary(){
    this.feedOrdersService.summary(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.orders = response.summary.sort((a, b) => {
            if(a.producto > b.producto) return 1;
            if(a.producto < b.producto) return -1;

            return 0;
          });
        } else{
          console.log(response.message);
          $.NotificationApp.send("¡ERROR!", response.message,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  public getMerchSummary(){
    this.productService.getMerchSummary(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          console.log('Respuesta: ');
          this.merch = response.summary;
          this.totalMerch = response.total;
        } else{
          console.log(response);
          $.NotificationApp.send("¡ERROR!", response,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  public printMerchSummary() {
    this.printMerch = true;
    setTimeout(() => {
      window.print();
      this.printMerch = false;
    }, 500);
  }

  public getListaPremios(){
    this.contabilidadService.getFilasBolsaPremio(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.filas = response.total;
          this.getTotalPrize();
        } else{
          console.log(response.message);
          $.NotificationApp.send("¡ERROR!", response.message,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public getListaPremiosPorSemana(semana, numero){
    this.contabilidadService.getFilasBolsaPremioPorSemana(this.idConcurso, semana, numero).subscribe(
      response => {
        if(!response.error){
          this.filas = response.total;
          this.getTotalPrize();
        } else{
          console.log(response.message);
          $.NotificationApp.send("¡ERROR!", response.message,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public getSummaryIncome(){
    this.contabilidadService.getIngresos(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.incomeRows = response.incomeRows;
        } else{
          console.log(response.message);
          $.NotificationApp.send("¡ERROR!", response.message,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public getSummaryIncomePorSemana(semana, numero){
    this.contabilidadService.getIngresosPorSemana(this.idConcurso, semana, numero).subscribe(
      response => {
        if(!response.error){
          this.incomeRows = response.incomeRows;
        } else{
          console.log(response.message);
          $.NotificationApp.send("¡ERROR!", response.message,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  private getTotalPrize(){
    console.log(this.filas);
    this.filas.forEach(
      fila => {
        if(fila.id_prueba != '2'){
          this.totalPrizes += parseFloat(fila.monto);
        } else {
          this.totalChampionshipPrizes += parseFloat(fila.monto);
        }
      }
    );
  }

  private getTotalIsncritos(){
    this.testPrizes.forEach(
      fila => {
        this.totalInscritos += parseFloat(fila.inscritos);
      }
    );
  }

  private getTotalPaymentsAmount(){
    this.totalPayments.forEach(
      payments => {
        this.totalPaymentAmount += parseFloat(payments.cantidad);
      }
    );
  }

  public getPaymentToReceive(){
    this.contabilidadService.getPaymentToReceive(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.paymentToReceive = response.paymentToReceive;
        } else{
          console.log(response.message);
          $.NotificationApp.send("¡ERROR!", response.message,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public getExpenses(){
    this.contabilidadService.getAllExpenses(this.idConcurso).subscribe(
      response => {
        this.expenses = response;
        this.getTotalExpenses();
      },
      error => {
        console.log(error);
      }
    )
  }

  private getTotalExpenses(){
    this.totalExpenses = 0;
    this.expenses.forEach(
      expense => {
        this.totalExpenses += parseFloat(expense.cantidad);
      }
    );
  }

  private resetExpenseFormControl(){
    this.expenseConcept.reset();
    this.expensePaymentMethod.reset();
    this.expenseReference.reset();
    this.expenseAmount.reset();
  }

  public setChangeExpense(action, idExpense?){
    let accion = action;
    const destino = {
      idConcurso: this.idConcurso,
      concepto: this.expenseConcept.value,
      forma_pago: this.expensePaymentMethod.value,
      referencia: this.expenseReference.value,
      cantidad: this.expenseAmount.value,
      accion: accion,
      id_gasto: idExpense
    }
    if((this.expenseConcept.value == '' || this.expensePaymentMethod.value == '' || this.expenseConcept.value == '') && accion == 'plus'){
      /*
        Mensaje de error si no hay monto capturado, metodo de pago o concepto.
        *La referencia puede ir vacia
      */
      $.NotificationApp.send("¡ERROR!", "There are empty fields, please validate",  'bottom-right', '#fa5c7c', 'error');
    }else{
      this.contabilidadService.setActionExpenses(destino).subscribe(
        response => {
          if(response.error == 0){
            console.log(response.msj);
            if(accion == 'less'){
              var mensaje = "Se elimino un gasto";
            }else if(accion == 'plus'){
              var mensaje = "Se agrego un nuevo gasto";
            }
            $.NotificationApp.send("SUCCESS!", mensaje , 'bottom-right', '#06d5a1', 'success');
            this.resetExpenseFormControl();
            this.getExpenses();
          }else{
            console.log(response.msj);
            $.NotificationApp.send("ERROR!", "There was an error, try again.",  'bottom-right', '#fa5c7c', 'error');
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  public setPaymentReceivedNotes(event, id){
    const destino = {
      id: id,
      destino: event.currentTarget.value
    }
    this.contabilidadService.setPaymentReceivedNotes(destino).subscribe(
      response => {
        if(response.error == 0){
          console.log(response.msj);
          console.log('Se actualizo las anotaciones del pago con id ' + destino.id);
          $.NotificationApp.send("SUCCESS!", "Payment notes updated.", 'bottom-right', '#06d5a1', 'success');
        }else{
          console.log(response.msj);
          console.log('Hubo un inconveniente mientras actualizaba las anotaciones del pago, favor de intentarlo de nuevo');
          $.NotificationApp.send("ERROR!",  "There was an error, try again.",  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        console.log('Error durante la actualización, favor de validar :( ');
      }
    )
  }

  public getTotalPayment(){
    this.contabilidadService.getTotalPayments(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.totalPayments = response.totales_por_concepto;
          this.paymentsPerUser = response.totales_individuales;
          this.getTotalPaymentsAmount();
        } else{
          console.log(response.message);
          $.NotificationApp.send("¡ERROR!", response.message,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public getPaymentReceived(){
    this.contabilidadService.getPaymentReceived(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.paymentsReceived = response.paymentsReceived;
        } else{
          console.log(response.message);
          $.NotificationApp.send("¡ERROR!", response.message,  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public setPaymentReceived(event, id){
    const destino = {
      id: id,
      procesado: $(event.currentTarget).is(':checked') ? 1 : 0
    }
    let texto;
    if(destino.procesado == 0){
      texto = 'not received';
    }else{
      texto = 'received';
    }
    this.contabilidadService.setPaymentReceived(destino).subscribe(
      response => {
        if(response.error == 0){
          $.NotificationApp.send("SUCCESS!",  "The payment was set as "+ texto +".", 'bottom-right', '#06d5a1', 'success');
          this.getPaymentReceived();
          this.getPaymentToReceive();
        }else{
          console.log(response.msj);
          console.log('Hubo un inconveniente mientras actualizaba el estatus del pago, favor de intentarlo de nuevo');
          $.NotificationApp.send("ERROR!",  "There was an error, try again.",  'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        console.log('Error durante la actualización, favor de validar :( ');
        $.NotificationApp.send("ERROR!",  "Error in connection to network, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
      }
    )
  }

  public getPrizesPerTest(){
    this.contabilidadService.getAllPrizesPerTest(this.idConcurso).subscribe(
      response => {
        this.testPrizes = response;
        this.getTotalIsncritos();
      },
      error => {
        console.log(error);
      }
    );
  }

  public mostrarDetalleMetodo(metodo){
    this.metodoSeleccionado = metodo;

    this.contabilidadService.getDetalleMetodo(this.idConcurso, metodo).subscribe(
      response => {
        if(!response.error){
          this.detallesMetodo = response.pagos;
          $("#modal-metodos").modal("show");
        } else{
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!",  "Error in connection to network, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  public mostrarDetalleMetodoPersona(metodo, persona){
    this.metodoSeleccionado = metodo;

    console.log(metodo, persona);

    this.contabilidadService.getDetalleMetodo(this.idConcurso, metodo).subscribe(
      response => {
        if(!response.error){
          this.detallesMetodo = response.pagos.filter(p => p.usuario == persona);
          $("#modal-metodos").modal("show");
        } else{
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!",  "Error in connection to network, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  private getSemanas(){
    this.concursoService.getSemanas(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.semanas = response.semanas;
        } else{
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!",  "Error in connection to network, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  public changeIndex(e: number){
    //Vaciar variables con informacion
    this.incomeRows = [];
    this.totalPrizes = 0;
    this.totalChampionshipPrizes = 0;
    this.totalExpenses = 0;

    const index: number = e - 1;
    if(index > -1){
      const semana = this.semanas[index];
      this.getSummaryIncomePorSemana(semana.semana, semana.numero);
      this.getListaPremiosPorSemana(semana.semana, semana.numero);
    } else{
      this.getSummaryIncome();
      this.getListaPremios();
      this.getExpenses();
    }
  }

  public filtrarPagos(array: any[]){
    if(this.filtro){
      return array.filter(a => a.responsable.indexOf(this.filtro) > -1 || a.cantidad.indexOf(this.filtro) > -1 || a.referencia.indexOf(this.filtro) > -1 || a.destino.indexOf(this.filtro) > -1 || a.fecha_pago.indexOf(this.filtro) > -1);
    }
    return array;
  }
}
