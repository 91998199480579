<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>


            <div class="modal fade" id="modal-distribucion" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="myLargeModalLabel">Add/Edit Distribution</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="form" novalidate>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input type="text" class="form-control form-control-sm" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Type</label>
                                            <select class="form-control form-control-sm" formControlName="type">
                                                <option value="percentage">Percentage</option>
                                                <option value="amount">Amount</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>{{ form.value.type | titlecase }}</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control form-control-sm" (change)="agregarMonto()" [(ngModel)]="monto" [ngModelOptions]="{standalone: true}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-dark btn-sm" type="button" (click)="agregarMonto()">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center px-2 py-1" *ngFor="let m of montos; let i = index">
                                                    <div class="d-flex">
                                                        <p class="m-0" style="width: 30px;"><b>{{ i+1 }}</b></p> <input type="number" class="form-control form-control-sm" [value]="m" (change)="editarMonto($event, i)">
                                                    </div>
                                                    <a href="javascript:;" (click)="borrarMonto(i)">
                                                       <i class="mdi mdi-close"></i>
                                                    </a>
                                                </li>
                                                <div class="mt-2">
                                                    <b>Total: </b> 
                                                    <span *ngIf="form.value.type == 'amount'">{{ totalDistribucion | currency }}</span>
                                                    <span *ngIf="form.value.type == 'percentage'">{{ totalDistribucion }}%</span>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 text-right">
                                        <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Cancel</button>
                                        <button type="button" class="btn btn-success" (click)="guardarDistribucion()">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container-fluid p-print-0 w-100">
                <div class="row justify-content-center w-100">
                    <div class="col-8">
                        <div class="card mt-3">
                            <div class="card-body p-2">
                                <div class="row mb-3">
                                    <div class="col-12 text-right">
                                        <button class="btn btn-info btn-sm" type="button" (click)="mostrarModalAgregarDistribucion()">
                                            Add Distribution
                                        </button>
                                    </div>
                                </div>
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Created</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let distribucion of distribuciones">
                                            <td>{{ distribucion.nombre }}</td>
                                            <td>{{ distribucion.tipo | titlecase }}</td>
                                            <td>{{ distribucion.created | date }}</td>
                                            <td class="table-action">
                                                <a href="javascript: void(0);" class="action-icon" (click)="mostrarModalEditarDistribucion(distribucion)"> <i class="mdi mdi-pencil"></i></a>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
