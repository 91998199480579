<!-- Loader -->
<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>

<div class="wrapper">
    <menu class="navbar-custom"></menu>
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page" style="width: calc(100% - 250px);">
        <div class="col-12 mt-3 mb-4">
            <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <select class="form-control" [(ngModel)]="ipc">
                                    <option value="">Select a class</option>
                                    <option *ngFor="let prueba of pruebas" value="{{ prueba['ipc'] }}">{{ prueba['numero'] }} - {{ prueba['nombre'] }}</option>
                                </select>
                                <a href="https://firebasestorage.googleapis.com/v0/b/srjt/o/ListFormats%2FImport%20List%20Format.xlsx?alt=media&token=0c9b0f19-c466-45e3-b9f3-9a73df03fd96" download=""><small style="color: #007241">Download format</small></a>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="form-group">
                                <button style="color: #007241" (click)="seleccionarArchivoExcel()" class="mr-3 btn btn btn-light" type="button">
                                    <i class="mdi mdi-file-excel-box mr-1"></i> Import
                                </button>
                                <input hidden type="file" name="xlsx" id="xlsx" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (change)="importarExcel($event)">
                                <button [disabled]="!lista.length || !ipc" class="btn btn btn-light mr-3" type="button" (click)="validarLista()">
                                    <ng-container *ngIf="checked">
                                        <mat-icon *ngIf="validado" style="margin-top: -3px; margin-right: 8px;height: 30px;width: 30px;" svgIcon="success"></mat-icon>
                                        <mat-icon *ngIf="!validado" style="margin-top: -3px; margin-right: 8px;height: 30px;width: 30px;" svgIcon="error"></mat-icon>
                                    </ng-container>
                                    Validate
                                </button>
                                <button [disabled]="!validado" class="btn btn btn-light" type="button" (click)="guardarLista()">
                                    <i class="mdi mdi-content-save mr-1"></i> Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table table-sm" *ngIf="lista.length > 0">
                <thead>
                    <tr>
                        <th></th>
                        <th>Entry</th>
                        <th>Reg Num. (Rider)</th>
                        <th>Rider Name</th>
                        <th>Reg Num. (Horse)</th>
                        <th>Horse Name</th>
                        <th>Horse Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let binomio of lista">
                        <td>
                            <ng-container *ngIf="checked">
                                <mat-icon style="vertical-align: middle;" *ngIf="binomio.validado" svgIcon="success"></mat-icon>
                                <mat-icon data-toggle="tooltip" [title]="binomio?.msj" style="vertical-align: middle;" *ngIf="!binomio.validado" svgIcon="error"></mat-icon>
                            </ng-container>
                        </td>
                        <td>{{ binomio.entrada }}</td>
                        <td>{{ binomio.femFeiJinete }}</td>
                        <td>{{ binomio.jinete }}</td>
                        <td>{{ binomio.femFeiCaballo }}</td>
                        <td>{{ binomio.caballo }}</td>
                        <td>{{ binomio.cucarda }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
