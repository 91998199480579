<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>
<div id="loader-fei">
	<div class="sk-cube-grid">
		<!-- Created with Inkscape (http://www.inkscape.org/) -->
		<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#"
			xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
			xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
			xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="168.75053mm" height="50.237492mm"
			viewBox="0 0 597.93496 178.00686" id="FEI-svg" version="1.1" inkscape:version="0.91 r13725"
			sodipodi:docname="drawing.svg">
			<path id="fei-picto"
				d="m 217.744,20.586 6.203,-14.78 -90.265,0 0,70.734 c 0,5.674 -1.319,10.82 -7.918,12.008 l 91.98,0 6.203,-14.78 -64.532,0 0,-20.058 51.863,0 5.411,-13.725 -57.274,0 0,-19.399 58.329,0 z m 15.176,67.962 25.734,0 0,-82.742 -25.734,0 0,82.742 z M 104.913,70.733 c -5.674,-4.223 -10.161,-8.182 -9.765,-14.78 -3.035,4.882 -4.091,10.557 -4.091,14.12 0,10.425 6.466,17.287 8.313,18.475 l 17.552,0 c -9.369,-3.035 -3.167,-11.217 -12.009,-17.815 z m 23.094,-22.698 c 0,-2.507 -2.111,-4.355 -4.486,-4.355 -2.508,0 -4.488,1.848 -4.488,4.355 0,2.508 1.98,4.487 4.488,4.487 2.375,0 4.486,-1.979 4.486,-4.487 z m -32.859,7.654 7.918,-13.725 -43.072,0 C 74.165,25.059 96.043,17.301 122.861,21.378 l 8.973,-15.572 -23.225,0 c -35.098,0 -63.603,11.393 -80.156,36.158 l -20.535,0 L 0,55.689 l 21.087,0 c -3.993,9.495 -6.643,20.422 -7.759,32.859 l 29.297,0 c 1.042,-12.473 3.986,-23.492 8.556,-32.859 l 43.967,0 z m 181.396,-42.832 0,-4.501 -1.95,4.501 -1.141,0 -1.891,-4.441 0,4.441 -1.468,0 0,-7.051 1.911,0 2.067,4.908 2.067,-4.908 1.796,0 0,7.051 -1.391,0 z m -9.832,-5.737 0,5.737 -1.468,0 0,-5.737 -2.163,0 0,-1.314 5.795,0 0,1.314 -2.164,0 z"
				inkscape:connector-curvature="0" style="fill:#4a0d53;" stroke="#4a0d53" class="path" />
		</svg>
	</div>
</div>
<!-- Loader USEF -->
<div id="loader-usef">
	<div class="sk-cube-grid">
		<!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="US_Equestrian" xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="150px" height="136.98px"
			viewBox="0 0 150 136.98" style="enable-background:new 0 0 150 136.98;" xml:space="preserve">
			<path class="path" stroke="#14377D" style="fill:#14377D;" d="M111.22,25.41c-3.13-7.34-18.59-17.24-18.59-17.24c8.25,9.64,8.51,9.21,13.07,18.33
				c0,6.3,4.13,9.03,4.13,9.03c5.56,6.43,15.9,14.46,17.81,23.1c1.69,7.56-5.73,14.72-13.2,12.94c6.69,1.61,18.41,1.48,20.11-7.51
				c2.13-11.34-16.42-26.28-22.8-34.44C111.74,25.93,111.22,25.41,111.22,25.41z M92.76,53.77c-4.47-1.17-16.24-4.47-16.72-10.21
				c0.26,3,2.69,15.11,22.93,16.89c0,0,11.16,0.78,12.68,5.3C108.7,57.15,100.27,55.72,92.76,53.77z M73.39,116.39
				c-0.52-9.16-1.22-18.37-4-27.14c-4.16-13.07-13.09-24.23-23.93-32.97c-29.24,47.92,27.62,59.9,27.62,59.9
				c-59.92-7.06-52.36-40.57-34.99-65.28c-4.61-3.05-9.42-5.69-14.26-7.9C10.29,36.79-4.18,29.05,1.12,12.12
				c-3.67,15.07,23.54,26.32,40.73,33.75c4.45-5.63,9.24-10.6,13.56-14.42C76.52,12.77,102.57,0,102.57,0
				c-25.99,19.47-42.6,35.83-52.59,49.54c1.19,0.59,2.22,1.15,3.04,1.66C75.78,65.54,84.12,92.2,73.39,116.39z" />
			<path class="path-text" stroke="#DA1A32" style="fill:#DA1A32;" d="M86.98,80.95h7.77v22.02c0,4.82,2.95,7.56,7.25,7.56c4.26,0,7.25-2.74,7.25-7.56V80.95h7.73v22.02
				c0,9.47-6.25,14.11-14.98,14.11s-15.03-4.6-15.03-14.11V80.95L86.98,80.95z M125.81,107.19c3.13,1.87,6.91,3.21,10.47,3.21
				c3.82,0,5.78-1.56,5.78-3.87c0-6.64-19.2-2.17-19.2-15.63c0-5.82,4.82-10.55,13.03-10.55c4.65,0,8.82,1.13,12.77,3l-2.82,6.04
				c-3.13-1.56-6.43-2.35-9.6-2.35c-3.39,0-5.43,1.61-5.43,3.73c0,6.56,19.2,1.91,19.2,15.63c0,5.99-4.56,10.64-13.68,10.64
				c-5.34,0-10.77-1.78-14.16-3.91L125.81,107.19z" />
			<path class="path-text" stroke="#14377D" style="fill:#14377D;" d="M25.7,123.34h9.6v2.48h-6.78v2.91h6.04v2.21h-6.04v3.08h6.82v2.48H25.7V123.34z M44.16,123.12
				c4,0,6.82,2.82,6.82,6.78c0,1.69-0.48,3.13-1.39,4.3l1.48,1.26l-1.43,1.52l-1.56-1.39c-1.09,0.74-2.43,1.13-3.95,1.13
				c-4,0-6.82-2.87-6.82-6.86C37.34,125.95,40.21,123.12,44.16,123.12z M45.46,130.55l2.08,1.87c0.39-0.69,0.61-1.56,0.61-2.52
				c0-2.65-1.61-4.39-3.95-4.39c-2.3,0-3.95,1.74-3.95,4.39c0,2.69,1.65,4.43,3.95,4.43c0.74,0,1.43-0.17,2.04-0.56l-2.08-1.78
				L45.46,130.55z M53.59,123.34h2.87v8.16c0,1.78,1.09,2.82,2.69,2.82c1.56,0,2.69-1,2.69-2.82v-8.16h2.87v8.16
				c0,3.52-2.3,5.21-5.56,5.21c-3.21,0-5.56-1.69-5.56-5.21C53.59,131.51,53.59,123.34,53.59,123.34z M67.66,123.34h9.6v2.48h-6.73
				v2.91h6.04v2.21h-6.04v3.08h6.82v2.48h-9.68V123.34z M80.82,133.07c1.17,0.69,2.56,1.17,3.87,1.17c1.39,0,2.13-0.56,2.13-1.43
				c0-2.48-7.12-0.83-7.12-5.78c0-2.17,1.78-3.91,4.82-3.91c1.74,0,3.26,0.43,4.73,1.13l-1.04,2.26c-1.17-0.56-2.39-0.87-3.56-0.87
				c-1.26,0-2,0.61-2,1.39c0,2.43,7.12,0.69,7.12,5.78c0,2.21-1.69,3.95-5.08,3.95c-1.95,0-4-0.65-5.26-1.43L80.82,133.07z
				M95.67,125.86h-4.34v-2.52h11.55v2.52h-4.34v10.64h-2.82v-10.64H95.67z M105.09,123.34h5.78c3.78,0,5.08,2.08,5.08,4.17
				c0,1.56-0.78,2.95-2.39,3.65l2.52,5.34H113l-2.04-4.91h-3v4.91h-2.87V123.34z M110.83,129.51c1.52,0,2.26-0.83,2.26-1.95
				c0-1.13-0.74-1.95-2.21-1.95h-2.91v3.91H110.83z M118.73,123.34h2.87v13.16h-2.87V123.34z M128.37,123.34h3.21l4.78,13.16h-3.04
				l-0.83-2.48h-4.99l-0.83,2.48h-3.08L128.37,123.34z M128.2,131.85h3.56l-1.78-5.34h-0.04L128.2,131.85z M138.45,123.34h2.69
				l5.86,8.69v-8.69h2.69v13.16H147l-5.86-8.64v8.64h-2.69V123.34z" />
		</svg>

	</div>
</div>
<div class="wrapper">
	<menu-lateral class="left-side-menu"></menu-lateral>
	<div class="content-page">
		<div class="content">
			<menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
			<div class="container-fluid">
				<div class="row justify-content-center mt-4">
					<div class="col-12">
						<div class="card">
							<div class="card-body">
								<div class="mb-2 text-right">
									<!--button class="btn btn-info btn-sm" type="button" [disabled]="loading"
										(click)="sendSignatureMails()">
										<i class="mdi mdi-email mr-1" *ngIf="!loading; else cargando"></i>
										<ng-template #cargando>
											<i class="mdi mdi-spin mdi-loading"></i>
										</ng-template>
										Send signature mails
									</button-->
                  <ng-container *ngTemplateOutlet="acceptEntriesModal;context:this;"></ng-container>
                  <button type="button" class="btn btn-sm" [disabled]="loading || !hasSelectedEntries" (click)="showAcceptModal()">
                    <i class="mdi mdi-check-all mr-1"></i> Accept entries
                  </button>

                  <button class="btn btn-sm ml-3" type="button" [disabled]="loading" (click)="printSignatureFormats()">
										<i class="mdi mdi-printer mr-1" *ngIf="!loading; else cargando"></i>
										<ng-template #cargando><i class="mdi mdi-spin mdi-loading"></i></ng-template>
										Print
									</button>
								</div>
								<ul class="nav nav-tabs nav-justified nav-bordered mb-3">
									<li class="nav-item">
										<a href="#pendientes-sin-pagar" data-toggle="tab" aria-expanded="false"
											class="nav-link">
											<i class="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
											<span class="d-none d-lg-block">Pending payment</span>
										</a>
									</li>
									<li class="nav-item">
										<a href="#pendientes-pagadas" data-toggle="tab" aria-expanded="false"
											class="nav-link">
											<i class="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
											<span class="d-none d-lg-block">Paid</span>
										</a>
									</li>
									<!--li class="nav-item">
										<a href="#aceptada-impresa" data-toggle="tab" aria-expanded="true"
											class="nav-link active">
											<i class="mdi mdi-settings-outline d-lg-none d-block mr-1"></i>
											<span class="d-none d-lg-block">Printed</span>
										</a>
									</li-->
									<li class="nav-item">
										<a href="#aceptada-sin-firmar" data-toggle="tab" aria-expanded="true"
											class="nav-link active">
											<i class="mdi mdi-settings-outline d-lg-none d-block mr-1"></i>
											<span class="d-none d-lg-block">Accepted</span>
										</a>
									</li>
									<!--li class="nav-item">
										<a href="#aceptada-firmada" data-toggle="tab" aria-expanded="false"
											class="nav-link">
											<i class="mdi mdi-settings-outline d-lg-none d-block mr-1"></i>
											<span class="d-none d-lg-block">Signed</span>
										</a>
									</li-->
									<li class="nav-item">
										<a href="#cancelada" data-toggle="tab" aria-expanded="false"
											class="nav-link">
											<i class="mdi mdi-settings-outline d-lg-none d-block mr-1"></i>
											<span class="d-none d-lg-block">Cancelled</span>
										</a>
									</li>
								</ul>
								<div class="tab-content">
                  <div class="tab-pane" id="pendientes-sin-pagar">
                    <!-- Pago pendiente -->
                    <ng-container [ngTemplateOutlet]="entryList" [ngTemplateOutletContext]="{ solicitudes: solicitudes.get('unpaid'), status: 'unpaid', filtro: filtro }"></ng-container>
									</div>
									<div class="tab-pane" id="pendientes-pagadas">
                    <!-- Pagadas -->
                    <ng-container [ngTemplateOutlet]="entryList" [ngTemplateOutletContext]="{ solicitudes: solicitudes.get('paid'), status: 'paid', filtro: filtro }"></ng-container>
									</div>
									<!--div class="tab-pane show active" id="aceptada-impresa">
										<! Sin firmar ->
                    <ng-container [ngTemplateOutlet]="entryList" [ngTemplateOutletContext]="{ solicitudes: solicitudes.get('unsigned') }"></ng-container>
									</div-->
									<div class="tab-pane show active" id="aceptada-sin-firmar">
                    <!-- Aceptadas -->
                    <ng-container [ngTemplateOutlet]="entryList" [ngTemplateOutletContext]="{ solicitudes: solicitudes.get('accepted'), status: 'accepted', filtro: null }"></ng-container>
									</div>
									<!--div class="tab-pane" id="aceptada-firmada">
										<table class="table table-striped table-hover mb-0">
											<thead>
												<tr>
													<th>Entry</th>
													<th>Trainer</th>
													<th>Horse</th>
													<th>Date</th>
													<th>Order ID</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let solicitud of filtroSolicitudesAceptadas(3)"
													class="solicitud" (click)="verSolicitud(solicitud.id)">
													<td>{{ solicitud.entry }}</td>
													<td>{{ solicitud.entrenador }}</td>
													<td>{{ solicitud.caballo.horseName }}</td>
													<td>{{ solicitud.created | date: 'dd MMMM yyyy - HH:mm' | titlecase
														}}</td>
													<td>{{ solicitud.orderId }}</td>
												</tr>
											</tbody>
										</table>
									</div-->
									<div class="tab-pane" id="cancelada">
										<!-- Canceladas -->
                    <ng-container [ngTemplateOutlet]="entryList" [ngTemplateOutletContext]="{ solicitudes: solicitudes.get('cancelled'), status:'cancelled', filtro: null }"></ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #entryList let-solicitudes='solicitudes' let-status='status' let-filtro="filtro">
  <table class="table table-striped table-hover mb-0">
    <thead>
      <tr>
		    <th *ngIf="status == 'accepted'" (click)="reOrder('entry', status)" style="cursor: pointer;">Entry <i class="mdi mdi-sort mr-1"></i></th>
        <th *ngIf="status == 'paid' || status == 'unpaid'"></th><!-- Switch column -->
		<th (click)="reOrder('trainer', status)" style="cursor: pointer;">Trainer <i class="mdi mdi-sort mr-1"></i> </th>
		<th (click)="reOrder('rider', status)" style="cursor: pointer;">Rider <i class="mdi mdi-sort mr-1"></i> </th>
        <th (click)="reOrder('caballo', status)" style="cursor: pointer;">Horse <i class="mdi mdi-sort mr-1"></i></th>
		<th>Email</th>
        <th (click)="reOrder('created', status)" style="cursor: pointer;">Date <i class="mdi mdi-sort mr-1"></i></th>
        <th (click)="reOrder('show', status)" style="cursor: pointer;">Show <i class="mdi mdi-sort mr-1"></i></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let solicitud of solicitudes" class="solicitud" >
        <ng-container *ngIf="!filtro || (filtro && ((solicitud.horseUsef && filtro.horseUsef == solicitud.horseUsef) || filtro.horseName == solicitud.caballo))">
          <tr (click)="verSolicitud(solicitud.id)">
            <td *ngIf="status == 'accepted'">{{ solicitud.entry }}</td>
            <td *ngIf="status == 'paid' || status == 'unpaid'" (click)="$any($event).stopPropagation();">
              <div>
                  <input type="checkbox" [id]="'selected-entry-'+solicitud.id" data-switch="success" (change)="toogleSelection(solicitud.id, $any($event).target.checked, status)" />
                  <label [for]="'selected-entry-'+solicitud.id" class="mb-0 d-block"></label><!-- data-off-label="No" [attr.data-on-label]="'Yes'" -->
              </div>
            </td>
            <td>{{ solicitud.trainer | titlecase }}</td>
			<td>{{ solicitud.rider | titlecase }}</td>
            <td>{{ solicitud.caballo }}</td>
			<td>{{ solicitud.email }}</td>
            <td>{{ solicitud.created | date: 'dd MMM - HH:mm' | titlecase }}</td>
            <td>{{ solicitud.show }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</ng-template>

<ng-template #acceptEntriesModal let-solicitudes="entriesSelected" let-loading="loading">
  <div id="accept-entries-modal" tabindex="-1" role="dialog" class="modal fade colored-header success">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-success">
          <h4 class="modal-title">Accept entries</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>
        </div>
        <div class="modal-body">
          <form class="text-left">
            <div class="mb-3">
              <label for="entry-number" class="form-label">Entry number</label>
              <input type="number" id="entry-number" name="entry-number" class="form-control form-control-sm" [disabled]="loading" placeholder="Entry number" [(ngModel)]="entryNumber">
            </div>
          </form>
          <table class="table table-striped table-hover table-sm mb-0 text-left">
            <thead>
              <tr>
                <th>Trainer</th>
                <th>Horse</th>
                <th>Date</th>
                <th>Show</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let solicitud of solicitudes" class="solicitud">
                <td *ngIf="status == 'accepted'">{{ solicitud.entry }}</td>
                <td>{{ solicitud.entrenador }}</td>
                <td>{{ solicitud.caballo }}</td>
                <td>{{ solicitud.created | date: 'dd MMMM yyyy - HH:mm' | titlecase }}</td>
                <td>{{ solicitud.show }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal" aria-hidden="true">Close</button>
          <button type="button" class="btn btn-success" (click)="acceptEntries()" [disabled]="loading">
            <span class="loader" *ngIf="loading"></span>
            Accept
          </button>
      </div>
      </div>
    </div>
  </div>
</ng-template>
