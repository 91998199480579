import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ClubService } from '../services/club.service';
import { PaisService } from '../services/pais.service';
import { AuthService } from '../services/auth.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {UntypedFormControl, Validators, FormBuilder, FormGroup, NgModel} from '@angular/forms';
import { EmailValidator } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import firebase from 'firebase/compat/app';
import  'firebase/compat/storage';
declare var jquery:any;
declare var $ :any;

@Component({
    selector: 'crear-club',
    templateUrl: './crear-club.component.html',
    styleUrls: ['./crear-club.component.css']
})

export class CrearClubComponent implements OnInit, AfterViewInit {
    public idClubSeleccionado: string;
    public displayedColumns: Array<string>;
    public dataSource;
    public panelAgregar: boolean;
    public validadorNombreClub: UntypedFormControl;
    public validadorCorreo: UntypedFormControl;
    public validadorTelefono: UntypedFormControl;
    public validadorFem: UntypedFormControl;
    public validadorSiglas: UntypedFormControl;
    public validadorCiudad: UntypedFormControl;
    public validadorEstado: UntypedFormControl;
    public validadorPais: UntypedFormControl;
    public paisArray: Array<any>;
    public queryPais: string;
    private idUsuario: string;
    public idConcurso: string | boolean;
    public nombreConcurso: string;
    private token: string;
    private sessionData: any;
    public logo_url: string;
    public subiendoLogo: boolean;
    public porcentajeSubida: number;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    /*
    ->Validators.pattern('[0-9]{0,13}') -> realiza la validación de la entrada del formulario.
    Generara un error si la entrada tiene una longitud mayor a 13 numeros
    y si los valores ingresados no son numéricos. Esto por medio de expresiones regulares.
    */
    constructor(
        private clubService: ClubService,
        private paisService: PaisService,
        private authService: AuthService,
        private router: Router
    ){
        this.idClubSeleccionado = '';
        this.displayedColumns = ['Nombre', 'Siglas', 'Ciudad', 'Estado', 'Pais','Logo', 'Editar'];
        this.dataSource = new MatTableDataSource();
        this.panelAgregar = false;
        this.validadorNombreClub = new UntypedFormControl('', [Validators.required]);
        this.validadorCorreo = new UntypedFormControl('', [Validators.required, Validators.email]);
        this.validadorTelefono = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]{0,13}')]);
        this.validadorFem = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]{0,13}')]);
        this.validadorSiglas = new UntypedFormControl('', [Validators.required, Validators.pattern('[A-Za-zÀ-ÿ\u00f1\u00d10-9 -/*+=]{0,63}')]);
        this.validadorCiudad = new UntypedFormControl('', [Validators.required, Validators.pattern('[A-Za-zÀ-ÿ\u00f1\u00d10-9 -.]{0,50}')]);
        this.validadorEstado = new UntypedFormControl('', [Validators.required, Validators.pattern('[A-Za-zÀ-ÿ\u00f1\u00d10-9 -.]{0,50}')]);
        this.validadorPais = new UntypedFormControl('', [Validators.required]);
        this.paisArray = [];
        this.queryPais = '';
        this.idUsuario = '';
        this.idConcurso = '';
        this.token = '';
        this.sessionData = {};
        this.nombreConcurso = this.authService.getNombreConcurso();
        this.logo_url = '';
        this.subiendoLogo = false;
    }

    ngOnInit() {
        $('#loader').show();
        if(!this.authService.isLoggedIn()){
            this.authService.logOut();
            return;
        } else{
            this.token = this.authService.getAuthorizationToken();
            this.sessionData = this.authService.getSessionData(this.token);
            this.idUsuario = String(this.sessionData['idUsuario']);
        }
        if(this.authService.validarConcurso()){
            this.idConcurso = this.authService.validarConcurso();
        } else{
            this.router.navigate(['']);
            return;
        }
        this.getClubs();
        //this.getPaises();
        this.validadorPais.valueChanges.subscribe(
            data => {
                this.queryPais = data;
            }
        );
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(
          () => {
            this.paginator.pageIndex = 0;
            const active = this.sort.active;
            const direction = this.sort.direction;
          }
        );

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        setTimeout(() => {
          $(() => {
            $('[data-toggle="tooltip"]').tooltip();
          })
        });
    }

    public getClubs() {
        this.clubService.getClubs().subscribe(
          response => {
            this.dataSource.data = response.clubs;
            console.log(this.dataSource.data);
            $('#loader').hide();
          },
          error => {
            console.log(error);
          }
        );
    }

    public getPaises() {
        this.paisService.getPaises().subscribe(
          response => {
            this.paisArray = response;
          },
          error => {
            console.log(error);
          }
        );
    }

    public displayPais(val): string {
        return val ? val.pais : val;
    }

    public applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    public cerrarPanel() {
        this.panelAgregar = false;
    }

    public mostrarPanelAgregar() {
        this.panelAgregar = (this.panelAgregar) ? false : true;
        this.idClubSeleccionado = '';
        this.validadorNombreClub.reset();
        this.validadorCorreo.reset();
        this.validadorTelefono.reset();
        this.validadorFem.reset();
        this.validadorSiglas.reset();
        this.validadorCiudad.reset();
        this.validadorEstado.reset();
        this.validadorPais.reset();
        this.logo_url = '';
        ;
    }

    public onSubmitQuery() {
        console.log(
            this.validadorNombreClub.valid,
            this.validadorCiudad.valid,
            this.validadorEstado.valid,
            this.validadorPais.valid,
            this.validadorSiglas.valid);
      if (
        this.validadorNombreClub.valid &&
        //this.validadorFem.valid &&
        this.validadorCiudad.valid &&
        //this.validadorCorreo.valid &&
        this.validadorEstado.valid &&
        this.validadorPais.valid &&
        this.validadorSiglas.valid
        //this.validadorTelefono.valid
      ) {
            const club = {
                idclub: this.idClubSeleccionado,
                nombre: this.validadorNombreClub.value,
                correo: this.validadorCorreo.value,
                telefono: this.validadorTelefono.value,
                fem: this.validadorFem.value,
                siglas: this.validadorSiglas.value,
                ciudad: this.validadorCiudad.value,
                estado: this.validadorEstado.value,
                pais: !this.validadorPais.value ? '' :
                (typeof(this.validadorPais.value.id) === 'undefined') ? '' : this.validadorPais.value.id
            };
            if (this.idClubSeleccionado) {
                this.clubService.updateClub(club).subscribe(
                    response => {
                        if (response.error == 0) {
                            this.panelAgregar = false;
                            this.getClubs();
                            $.NotificationApp.send("Success", response.msj, 'bottom-right', '#06d5a1', 'success');
                        }else if (response.error == 1) {
                            $.NotificationApp.send("Error", response.msj, 'bottom-right', '#fa5c7c', 'error');
                            console.log(response.msj);
                        }else {
                            console.log(response.msj);
                        }
                    },
                    error => {
                        $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                    }
                );
            }else {
                this.clubService.addClub(club).subscribe(
                    response => {
                        if (response.error == 0) {
                            this.panelAgregar = false;
                            this.getClubs();
                            $.NotificationApp.send("Success", response.msj, 'bottom-right', '#06d5a1', 'success');
                        }else if (response.error == 1) {
                            $.NotificationApp.send("Error", response.msj, 'bottom-right', '#fa5c7c', 'error');
                            console.log(response.msj);
                        }else {
                            console.log(response.msj);
                        }
                    },
                    error => {
                        $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                    }
                );
            }
        }else {
            $.NotificationApp.send("Invalid data", 'There are pending fields to be completed', 'bottom-right', '#ffbc00', 'warning');
        }
  }

  public subirImagen(event: any, tipo) {
    console.log('Club Actual: ');
    console.log(this.idClubSeleccionado);
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      if ((file.type === 'image/jpeg' || file.type === 'image/png' )) {
        this.porcentajeSubida = 0;
            this.subiendoLogo = true;
        }

        const ref = firebase.storage().ref(tipo).child(String(file.name));
        const task = ref.put(file);
        task.on('state_changed',
          (snapshot: any) => { //Cuando hay cambio de estado
            this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
          },
          (error: any) => { //Cuando hubo un error
            this.subiendoLogo = false;

            $('#loader').hide();
            $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
            console.log(error);
          },
          () => { //Cuando la funcion termino correctamente
            task.snapshot.ref.getDownloadURL().then(
              downloadURL => {
                this.clubService.guardarLogo(this.idClubSeleccionado, downloadURL).subscribe(
                  response => {
                    if (!response.error) {
                      this.logo_url = downloadURL;
                      this.subiendoLogo = false;
                      $.NotificationApp.send("Updated", "Club logo updated", 'bottom-right', '#06d5a1', 'success');
                    } else {
                      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                      console.log(response.message);
                    }
                  },
                  error => {
                    $.NotificationApp.send("Error", 'Club logo NOT updated', 'bottom-right', '#fa5c7c', 'error');
                    console.log(error);
                  }
                );



              },
              error => {
                this.subiendoLogo = false;
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
                console.log(error);
              }
            );
          }
        );
      } else {
        $.NotificationApp.send("Invalid file.", 'This type of file is not allowed, please try with a JPG image.', 'bottom-right', '#ffbc00', 'warning');
      }
    }


    public modificarClub(club) {
        this.panelAgregar = true;
        this.idClubSeleccionado = club.id_club;
        this.validadorNombreClub.setValue(club.club);
        this.validadorCorreo.setValue(club.email);
        this.validadorTelefono.setValue(club.phone);
        this.validadorFem.setValue(club.fem);
        this.validadorSiglas.setValue(club.siglas);
        this.validadorCiudad.setValue(club.ciudad);
        this.validadorEstado.setValue(club.estado);
        this.validadorPais.setValue({
            pais: club.pais
        });
    }
}
