import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-unassigned-payments',
  templateUrl: './unassigned-payments.component.html',
  styleUrls: ['./unassigned-payments.component.css']
})
export class UnassignedPaymentsComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public payments: any[];

  constructor(private router: Router, private authService: AuthService, private inscripcionesRemotasService: InscripcionesRemotasService) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.payments = [];
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getPayments();
  }

  private getPayments() {
    this.inscripcionesRemotasService.getUnassignedPayments(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.payments = response.pagos;
          $('#loader').hide();
        } else{
          console.log(response);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
    $('#loader').hide();
  }

  public refundPayment(payment){
    $('#loader').show();
    console.log(payment);
    this.inscripcionesRemotasService.refundPayment(this.idConcurso, payment.chase_id, payment.id, payment.amount).subscribe(
      response => {
        if(!response.error){
          this.getPayments();
        } else{
          console.log(response);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
  }

  public async createEntryFromBackup(paymentId){
    const response = await this.inscripcionesRemotasService.getEntryFromBackup(paymentId).toPromise();
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }

    const response2 = await this.inscripcionesRemotasService.createEntryFromBackup(response.backup).toPromise();
    if(response2.error){
      $.NotificationApp.send("Error", response2.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }

    this.getPayments();

    $.NotificationApp.send('Created', 'The application for registration has been successfully generated.', 'bottom-right', '#0acf97', 'success', 5000);
    console.log(response.backup);
  }
}
