<ng-container *ngFor="let check of checks">
  <div class="space-top" [ngClass]="{'space-top-multiple': checks.length > 1}"></div>
  <div class="row">
    <div class="col-8">
    </div>
    <div class="col-4">
      <p class="date">{{ check.fecha | date : 'MMMM d, yyyy' }}</p>
    </div>
  </div>
  <div class="row payee-amount">
    <div class="col-8">
      <p class="payee">{{ check.payee }}</p>
    </div>
    <div class="col-4">
      <p class="amount">{{ check.amount }}</p>
    </div>
  </div>
  <p class="amount-words">{{ toWords(check.amount) }}</p>
  <p class="payee2">{{ check.payee }}</p>
  <p class="address">{{ check.domicilio }}</p>
  <p class="address2">{{ check.domicilio2 }}</p>

  <div class="row middle-part horse2">
    <div style="width:100%;">
      <p>{{ check.entry }} - {{check.horse}} / {{ check.payee }}</p>
    </div>
    <ul style="float:left;">
      <li *ngFor="let premio of check.premios"><b>{{ premio.rank }}</b> / {{ premio.classNumber }} - {{
        premio.className }} / {{ premio.rider }} / {{ premio.amount | currency }}</li>
    </ul>
  </div>


  <div class="row bottom">
    <div class="col-10 payee-bottom">
      <p>{{ check.payee }}</p>
      <p>{{ check.entry }} - {{ check.horse }}</p>
    </div>
    <div class="col-2 amount-bottom">
      <p>{{ check.amount }}</p>
    </div>
  </div>
  <div class="page-break"></div>
</ng-container>
