import { Component, OnInit } from '@angular/core';
import { DayPlanComponent } from './../day-plan/day-plan.component';

@Component({
  selector: 'app-day-plan-print',
  templateUrl: './../day-plan/day-plan.component.html',
  styleUrls: ['./day-plan-print.component.css']
})
export class DayPlanPrintComponent extends DayPlanComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }


}
