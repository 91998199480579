import { Component, OnInit, Directive, QueryList, ViewChildren, ViewChild} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import {UntypedFormControl, Validators, Form,FormBuilder, FormGroup} from '@angular/forms';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

//SERVICES
import { VideoService } from '../services/videos.service';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'clientes-videos',
  templateUrl: './clientes-videos.component.html',
  styleUrls: ['./clientes-videos.component.css']
})
export class ClientesVideosComponent implements OnInit {

  public idConcurso: string | boolean;
  public nombreConcurso: string;
  private idUsuario: string;
  private token: string;
  private sessionData: any;

  public panelAgregarCliente: boolean;
  public privilegios: number;
  public displayedColumns: Array<string>
  public dataSource;
  public cuentas: any;
  public pagosPendientes: number;

  public validadorTelefonoCliente: UntypedFormControl;
  public validadorCorreoCliente: UntypedFormControl;
  public validadorNombreCliente: UntypedFormControl;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private videoService: VideoService,
    private router: Router) {

    this.panelAgregarCliente = false;
    this.idConcurso = '';
    this.token = '';
    this.idUsuario = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.pagosPendientes = 0;
    this.cuentas = [];
    this.displayedColumns = ['nombre','contacto','comprados','enviados','saldo'];
    this.dataSource = new MatTableDataSource();
    this.validadorTelefonoCliente = new UntypedFormControl('',[Validators.pattern('[0-9]{0,13}')]);
    this.validadorCorreoCliente = new UntypedFormControl('',[Validators.required,Validators.email]);
    this.validadorNombreCliente = new UntypedFormControl('',[Validators.required]);
    this.nombreConcurso = this.authService.getNombreConcurso();
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 300){
      this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    $('#loader').fadeIn(200);
    this.getCuentas();
    this.getVentasSinAsignar();
  }

  ngAfterViewInit(){
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(
      () => {
        this.paginator.pageIndex = 0;
        const active = this.sort.active;
        const direction = this.sort.direction;
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.blockEnterKey();
    this.panelDisplay();
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public getCuentas(){
    this.videoService.getCuentas().subscribe(
      response => {
        this.dataSource.data = response;
        $('#loader').fadeOut(2000);
        //this.cuentas = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  public getVentasSinAsignar(){
    this.videoService.getPagosSinAsignar().subscribe(
      response => {
        this.pagosPendientes = response.length;
      },
      error => {
        console.log(error);
      }
    );
  }

  public cerrarPanel(){
    this.panelAgregarCliente = false;
    this.validadorCorreoCliente.reset();
    this.validadorNombreCliente.reset();
    this.validadorTelefonoCliente.reset();
  }

  public mostrarPanelAgregar(a?:boolean){
    if(a == true){
      this.panelAgregarCliente = true;
    }else{
      this.panelAgregarCliente = (this.panelAgregarCliente) ? false : true;
    }
    document.getElementById("formControlInicio").focus();
  }

  public getError(error){
    if(error == 1){
      return this.validadorNombreCliente.hasError('required') ? 'Este campo es obligatorio' : '';
    }else if(error == 2){
      return this.validadorCorreoCliente.hasError('required') ? 'Este campo es obligatorio' :
        /* -> */this.validadorCorreoCliente.hasError('email') ? 'Correo electrónico es inválido' :  '';
    }
  }

  public blockEnterKey(){
    $('form input').on('keypress', function(e) {
      return e.which !== 13;
    });
  }

  public panelDisplay(){
    $(document).on('keypress', (e) => {
      let key = String.fromCharCode(e.which);
      if(key == '+'){
        this.mostrarPanelAgregar(true);
      }else if(key == '-'){
        this.cerrarPanel();
      }
    });
  }

  public onSubmitAgregarCliente(){
    if(this.validadorCorreoCliente.valid && this.validadorNombreCliente.valid){
      let valido = true;
      if(valido){
        let cliente = {
          nombre: this.validadorNombreCliente.value,
          correo: this.validadorCorreoCliente.value,
          telefono: this.validadorTelefonoCliente.value
        };
        this.videoService.setCuentaNueva(cliente).subscribe(
          response => {
                if(response.error == 0){
                  $.NotificationApp.send("Customer added",  "The customer was added successfully.", 'bottom-right', '#06d5a1', 'success');
                  this.getCuentas();
                  this.cerrarPanel();
                } else if(response.error == 1){
                  $.NotificationApp.send("Error", response.msj, 'bottom-right', '#fa5c7c', 'error');
                } else if(response.error == 2){
                  $.NotificationApp.send("Duplicate customer",  response.msj, 'bottom-right', '#ffd358', 'warning');
                } else{
                  $.NotificationApp.send("Error", "The customer has not been added.", 'bottom-right', '#fa5c7c', 'error');
                }
              },
              error => {
                console.log(error);
                $.NotificationApp.send("Error", "The customer has not been added.", 'bottom-right', '#fa5c7c', 'error');
              }
          );

      }
    } else{
      $.NotificationApp.send("Invalid data", "There are pending fields to be completed.", 'bottom-right', '#ffd358', 'warning');
    }
  }
}
