import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AppService } from '../services/app.service';
import { Firestore } from '@angular/fire/firestore';
import { MlsjService } from '../services/mlsj.service';
import { firstValueFrom } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-mlsj',
  templateUrl: './mlsj.component.html',
  styleUrls: ['./mlsj.component.css']
})
export class MlsjComponent implements OnInit, AfterViewInit{
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public riders: any[];
  public form: UntypedFormGroup;
  public teams: any[];
  public puntos: any[];
  public cambiosPendientesGuardar: boolean;
  public formRider: UntypedFormGroup;
  public teamMembers: any[];
  public actualizando: boolean;
  public teamsMlsj: TeamMlsj[];
  public ridersMlsj: RiderMlsj[];
  public classMlsj: ClassMlsj;
  public saving: boolean;
  public spreadsheetId: string;
  public APIKey: string;
  public sheetName: Map<string, string>;
  public cellRange: Map<string, string>;
  public teamsMlsjSheet: TeamMLSJSheet[];
  public ridersMlsjSheet: RiderMlsjSheet[];
  public ta1: string;
  public ta2: string;
  public intervalId: any;
  public ms: number;
  public calificadosR1: number;
  public calificadosR2: number;
  public calificadosR3: number;


  constructor(
    private authService: AuthService,
    private router: Router,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private appService: AppService,
    private firestore: Firestore,
    private mlsjService: MlsjService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.riders = [];
    this.form = new UntypedFormGroup({
      placing: new UntypedFormControl('', [Validators.required]),
      name: new UntypedFormControl('', [Validators.required]),
      photo: new UntypedFormControl('', [Validators.required]),
      team: new UntypedFormControl('', [Validators.required]),
      pts: new UntypedFormControl('', [Validators.required])
    });
    this.teams = [];
    /**
     * Rank 1 Score 20
     * Rank 2 Score 15
     * Rank 3 Score 12
     * Rank 4 Score 10
     * Rank 5 Score 8
     * Rank 6 Score 6
     * Rank 7 Score 4
     * Rank 8 Score 2
    */
    this.puntos = [20, 15, 12, 10, 8, 6, 4, 2];
    this.cambiosPendientesGuardar = false;
    this.formRider = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      photo: new UntypedFormControl('', []),
      team: new UntypedFormControl('', [Validators.required]),
      fei: new UntypedFormControl('', [Validators.required]),
    });
    this.teamMembers = [];
    this.actualizando = false;
    this.teamsMlsj = [];
    this.ridersMlsj = [];
    this.classMlsj = {
      ipc: '',
      estatus_prueba: '0',
      tiempo_acordado: '',
      tiempo_acordado_2: ''
    };
    this.APIKey = 'AIzaSyDq5-nkgkIEUg2OtUaktaejIqwOZiPzHkI';
    this.spreadsheetId = '1KsNAdfzyMCIporMLY55oI8bLh9Gd23xHjYDyGQhDW-8';
    this.sheetName = new Map<string, string>();
    this.sheetName.set('ta', 'Round 1');
    this.sheetName.set('ta2', 'Round 2');
    this.sheetName.set('teams', 'Round 1');
    this.sheetName.set('teams2', 'Round 2');
    this.sheetName.set('teams3', 'Round 3');
    this.sheetName.set('riders', 'Round 1');
    this.sheetName.set('riders2', 'Round 2');
    this.sheetName.set('riders3', 'Round 3');

    this.cellRange = new Map<string, string>();
    this.cellRange.set('ta', 'Q11');
    this.cellRange.set('ta2', 'Q12');
    this.cellRange.set('teams', 'P2:V9');
    this.cellRange.set('teams2', 'P2:V5');
    this.cellRange.set('teams3', 'P2:V5');
    this.cellRange.set('riders', 'A2:J25');
    this.cellRange.set('riders2', 'A2:J9');
    this.cellRange.set('riders3', 'A2:H5');
    this.teamsMlsjSheet = [];
    this.ridersMlsjSheet = [];
    this.intervalId = null;
    this.ms = 1000;
    this.calificadosR1 = 0;
    this.calificadosR2 = 0;
    this.calificadosR3 = 0;
  }

  async ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getRiders();
    this.getTeams();
    this.getTeamMembers();
    await this.getTeamsOnce();
  }
  ngAfterViewInit(): void {
    $("#riderName").typeahead({
      hint: !0,
      highlight: !0,
      minLength: 1
    },
      {
        name: 'riders',
        display: value => value.name,
        templates: {
          notFound: '<div class="empty-message">No matches.</div>',
          suggestion: data => {
            return `<div>${data.name}</div>`;
          }
        },
        source: (this.teamMembers,
          (e, a) => {
            const substrRegex = new RegExp(e, "i");
            const t = this.teamMembers.filter(tm => substrRegex.test(tm.name));
            a(t.map(tm => tm));
          })
      });

    $('#riderName').bind('typeahead:select', (ev, suggestion) => {
      this.form.get('name').setValue(suggestion.name);
      this.form.get('photo').setValue(suggestion.photo);
      this.form.get('team').setValue(suggestion.team);
    });
  }

  public async getSpreadsheetResults(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      await this.getSpreadsheetTA();
      await this.getSpreadsheetTA2();
      //Si ya esta la info de la ronda 1, no se vuelve a pedir
      //TODO: Cambiar a 21 por 24
      if(this.calificadosR1 < 24){
        await this.getSpreadsheetRidersR1();
        await this.getSpreadsheetTeamsR1();
      } else if(this.calificadosR1 == 24 && this.calificadosR2 < 8){
        await this.getSpreadsheetRidersR2();
        await this.getSpreadsheetTeamsR2();
      } else if(this.calificadosR1 == 24 && this.calificadosR2 == 8 && this.calificadosR3 < 4){
        await this.getSpreadsheetRidersR3();
        await this.getSpreadsheetTeamsR3();
      }
      return resolve();
    });
  }

  public getRiders() {
    this.db.collection('mlsj').doc('league_info').collection(`individualStandings${new Date().getFullYear()}`).valueChanges({ idField: 'id' }).subscribe(riders => {
      this.riders = riders.sort((a, b) => {
        if (a.placing == b.placing) return 0;
        return a.placing > b.placing ? 1 : -1;
      });
      $('#loader').hide();
    });
  }

  public getTeams() {
    this.db.collection('mlsj').doc('league_info').collection(`teams${new Date().getFullYear()}`).valueChanges({ idField: 'id' }).subscribe(teams => {
      //console.log(teams);
      this.teams = teams.sort((a, b) => {
        if (a.order == b.order) return 0;
        return a.order > b.order ? 1 : -1;
      });
      $('#loader').hide();
    });
  }

  public addRider() {
    if (this.form.valid) {
      let value = this.form.value;
      //value.placing = parseInt(value.placing);
      this.db.collection('mlsj').doc('league_info').collection(`individualStandings${new Date().getFullYear()}`).add(value).then(() => {
        this.form.reset('');
        $('#riderName').typeahead('val', null);
      });
    }
  }

  public editRider(e, rider, campo) {
    let data = {};
    data[campo] = e.target.value;
    //if(campo == 'placing' && data[campo]) data[campo] = parseInt(data[campo]);
    this.db.collection('mlsj').doc('league_info').collection(`individualStandings${new Date().getFullYear()}`).doc(rider.id).set(data, { merge: true });
  }

  public editTeam(e, team, campo) {
    let data = {};
    data[campo] = e.target.value;
    //if(campo == 'placing' && data[campo]) data[campo] = parseInt(data[campo]);
    this.db.collection('mlsj').doc('league_info').collection(`teams${new Date().getFullYear()}`).doc(team.id).set(data, { merge: true });
  }

  public async recalcularPosiciones() {
    this.cambiosPendientesGuardar = true;
    const teamShowRanks = await this.db.collection('mlsj').doc('shows').collection(`${new Date().getFullYear()}`).doc(`${this.idConcurso}`).collection('teams').get().toPromise().then(r => r.docs.map(d => d.data()));
    for (const team of teamShowRanks) {
      //Rank se toma del json team que es el que tiene la posicion del equipo en la prueba del concurso actual
      //Los puntos acumulados hasta antes de este concurso se toman de teamGlobal que es arreglo de equipos de la liga (no por concurso)
      const teamGlobal = this.teams.find(t => team.teamName == t.teamName);
      const indexTeamGlobal = this.teams.findIndex(t => team.teamName == t.teamName);
      if (!teamGlobal) continue;
      this.teams[indexTeamGlobal].showRank = team.rank;
      if (this.puntos[team.rank - 1]) {
        this.teams[indexTeamGlobal].addedPoints = this.puntos[team.rank - 1];
        //Suma los puntos que ya tenia acumulados mas los que gano en el concurso
        this.teams[indexTeamGlobal].puntajeNuevo = parseFloat(teamGlobal.points || '0') + this.puntos[team.rank - 1];
      }
    }
    //Posicion nueva
    this.teams.sort((a, b) => {
      if (a.puntajeNuevo == b.puntajeNuevo) return 0;
      return a.puntajeNuevo < b.puntajeNuevo ? 1 : -1;
    }).map((t, i) => {
      t.newRank = i + 1;
      return t;
    });
  }

  public guardarPosicionesRecalculadas() {
    const batch = this.db.firestore.batch();
    for (const team of this.teams) {
      batch.set(this.db.collection('mlsj').doc('league_info').collection(`teams${new Date().getFullYear()}`).doc(team.teamId).ref, { order: `${team.newRank}`, points: `${team.puntajeNuevo}` }, { merge: true });
    }
    batch.commit().then(() => {
      $.NotificationApp.send("Well Done!", "Score has been updated successfully.", "bottom-right", "rgba(0,0,0,0.2)", "success");
      this.cambiosPendientesGuardar = false;
    });
  }

  public getTeamMembers() {
    this.db.collection('mlsj').doc('league_info').collection(`teamMembers${new Date().getFullYear()}`).valueChanges({ idField: 'documentId' }).subscribe(
      members => {
        this.teamMembers = members.sort((a, b) => {
          if (a.team > b.team) return 1
          if (a.team < b.team) return -1
          return 0
        });
      }
    );
  }

  public async addTeamMember() {
    if (this.formRider.valid) {
      await this.db.collection('mlsj').doc('league_info').collection(`teamMembers${new Date().getFullYear()}`).add(this.formRider.value);
      this.formRider.reset({
        name: '',
        photo: '',
        team: '',
        fei: ''
      });
    }
  }

  async uploadphoto(e: any, index: any | boolean = false) {
    if (e.target.files.length) {
      const file: File = e.target.files[0];
      //Si la imagen no es png o jpg
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        $.NotificationApp.send("Error", 'Please upload a jpg or png file.', 'bottom-right', '#fa5c7c', 'error');
      }

      await this.storage.upload(`teamMembers/${this.idConcurso}/${Date.now()}`, file).then(async snapshot => {
        const url = await snapshot.ref.getDownloadURL();
        //Si no se recibio el argumento del indice, significa que es en el formulario de creacion
        if (index === false) {
          this.formRider.get('photo').setValue(url);
        } else {
          this.teamMembers[index].photo = url;
          this.updateTeamMember(this.teamMembers[index]);
        }
      });
    }
  }

  async updateTeamMember(teamMember: any) {
    await this.db.collection('mlsj').doc('league_info').collection(`teamMembers${new Date().getFullYear()}`).doc(teamMember.documentId).set(teamMember, { merge: true });
  }

  async updateTeamLogos() {
    this.actualizando = true;
    console.log('riders', this.teamMembers.map(t => ({ fei: t.fei, photo: t.photo, teamName: t.team })));
    console.log('feis', this.teamMembers.map(t => t.fei));
    console.log('idConcurso', this.idConcurso);
    const responseLogos = await this.appService.updateTeamLogos(this.teamMembers.map(t => ({ fei: t.fei, photo: t.photo, teamName: t.team })), this.teamMembers.map(t => t.fei), this.idConcurso);
    if (responseLogos.error) {
      this.actualizando = false;
      $.NotificationApp.send("Error", responseLogos.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    await this.appService.updateShow(this.idConcurso);
    this.actualizando = false;
  }

  async deleteTeamMember(documentId) {
    await this.db.collection('mlsj').doc('league_info').collection(`teamMembers${new Date().getFullYear()}`).doc(documentId).delete();
  }

  private async getTeamsOnce(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      this.classMlsj = await this.mlsjService.getClass(`${this.idConcurso}`).then((r: any) => ({
        ipc: r.id||(r.ipc||''),
        estatus_prueba: r.estatus_prueba||'0',
        tiempo_acordado: r.tiempo_acordado||'',
        tiempo_acordado_2: r.tiempo_acordado_2||''
      }))
      console.log('classMlsj', this.classMlsj);
      this.ridersMlsj = await this.mlsjService.getRiders(this.classMlsj.ipc).then(r => (r.binomios||[]).map((b, i) => ({
        clubName: '',
        country: b.representa||'',
        entryNumber: b.cucarda||'',//b.entradaPublicada||'',
        faults1: '',
        faults2: '',
        faults3: '',
        horseFEI: b.regCaballo||'',
        horseName: b.caballo||'',
        id: b.idBinomio||'',
        ipc: this.classMlsj.ipc||'',
        order: i%3,
        prize: '',
        rank: '',
        result1: '',
        result2: '',
        result3: '',
        riderBadge: b.bandera_url||'',
        riderFEI: b.regJinete||'',
        riderName: b.nombreJinete||'',
        status1: '',
        status2: '',
        status3: '',
        team: b.team||'',
        time1: '',
        time2: '',
        time3: '',
        timeFaults1: '',
        video1: '',
        video2: '',
        video3: ''
      })));
      console.log('ridersMlsj', this.ridersMlsj);
      this.teamsMlsj = await this.mlsjService.getTeams(this.idConcurso).then(r => r.data.map((d, i) => ({
        faults1: '',
        faults2: '',
        faults3: '',
        id: d.id,
        ipc: this.classMlsj.ipc||'',
        order: i,
        rank: `${i+1}`,
        result1: '',
        result2: '',
        result3: '',
        riders: this.ridersMlsj.filter(r => r.team == d.id).map(r => ({ ...r, team: d.name })),
        teamBadge: d.logo,
        teamName: d.name,
        time1: '',
        time2: '',
        time3: ''
      })));
      console.log('teamsMlsj', this.teamsMlsj);
      return resolve();
    });
  }

  public async updateFirestore(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      this.saving = true;
      const prueba = {
        tiempo_acordado: this.ta1,
        tiempo_acordado_2: this.ta2,
        estatus_prueba: this.classMlsj.estatus_prueba||'0'
      };
      await this.mlsjService.updateResultsFirebase(this.teamsMlsj, `${this.idConcurso}`, this.classMlsj.ipc, prueba);
      this.saving = false;
      return resolve();
    });
  }

  public async getSpreadsheetTA(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const [[ta]] = await this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, this.sheetName.get('ta'), this.cellRange.get('ta'));
      this.ta1 = ta;
      this.classMlsj.tiempo_acordado = ta;
      return resolve();
    });
  }

  public async getSpreadsheetTA2(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const [[ta]] = await this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, this.sheetName.get('ta2'), this.cellRange.get('ta2'));
      this.ta2 = ta;
      this.classMlsj.tiempo_acordado_2 = ta;
      return resolve();
    });
  }

  public async getSpreadsheetRidersR1(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const riders = await this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, this.sheetName.get('riders'), this.cellRange.get('riders')).then(r => r.map((v,i) => {
        const [order, backNumber, riderName, horseName, team, flag, faults1, time1, timeFaults1, totalFaults1] = v;
        return {
          order: parseInt(order)-1,
          entryNumber: backNumber,
          riderName,
          horseName,
          team,
          //status1
          faults1,//totalFaults1
          time1: (time1 == '0' || !time1) ? '' : parseFloat(time1),
          timeFaults1,
          totalFaults1,
          result1: parseFloat(time1||'0') > 0 ? `${totalFaults1||'0'} | ${time1}` : '',
        };
      }));
      if(this.ridersMlsjSheet.length == 0){
        this.ridersMlsjSheet = riders;
      } else{
        this.ridersMlsjSheet.map((r: RiderMlsjSheet) => {
          const rider = riders.find((rider: any) => rider.entryNumber == r.entryNumber);
          if(rider){
            r.faults1 = rider.faults1;
            r.order = rider.order;
            r.result1 = rider.result1;
            r.time1 = rider.time1;
            r.timeFaults1 = rider.timeFaults1;
          }
          return r;
        });
      }
      this.ridersMlsjSheet.sort((a, b) => {
        if(a.team > b.team) return 1;
        if(a.team < b.team) return -1;

        if(a.order > b.order) return 1;
        if(a.order < b.order) return -1;

        return 0;
      }).map((r, i) => {
        r.order = i%3;
        return r;
      });
      this.ridersMlsj.map((r: RiderMlsj) => {
        const rider = this.ridersMlsjSheet.find((rider: any) => rider.entryNumber == r.entryNumber);
        if(rider){
          r.faults1 = rider.faults1;
          r.order = rider.order;
          r.result1 = rider.result1;
          r.time1 = `${rider.time1}`;
          r.timeFaults1 = rider.timeFaults1;
        }
        return r;
      });
      return resolve();
    });
  }

  public async getSpreadsheetTeamsR1(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const teams = await this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, this.sheetName.get('teams'), this.cellRange.get('teams')).then(r => r.map(v => {
        const [order, teamName, rank, logo, faults1, time1, ridersGone] = v;
        return {
          order: parseInt(ridersGone||0) < 3 ? parseInt(order)-1 : parseInt(rank)-1,
          teamName,
          rank,
          faults1,
          time1: (time1 == '0' || !time1) ? '' : parseFloat(time1),
          result1: parseInt(ridersGone||0) > 0 ? `${faults1} | ${time1}` : '',
          ridersGone: parseInt(ridersGone||0),
        };
      }));
      if(this.teamsMlsjSheet.length == 0){
        this.teamsMlsjSheet = teams;
      } else{
        this.teamsMlsjSheet.map((t: TeamMLSJSheet) => {
          const team = teams.find((team: any) => team.teamName == t.teamName);
          if(team){
            t.faults1 = team.faults1;
            t.order = team.order;
            t.rank = team.rank;
            t.result1 = team.result1;
            t.time1 = team.time1;
          }
          return t;
        });
      }
      this.teamsMlsj.map((t: TeamMlsj) => {
        const team = this.teamsMlsjSheet.find((team: any) => team.teamName == t.teamName);
        if(team){
          t.faults1 = team.faults1;
          t.order = team.order;
          t.rank = team.rank;
          t.result1 = team.result1;
          t.time1 = team.time1;
        }
        t.riders = this.ridersMlsj.filter(r => r.team == t.id).map(r => ({ ...r, team: t.teamName })).sort((a, b) => a.order - b.order);
        return t;
      });
      this.teamsMlsj.sort((a, b) => a.order - b.order);
      this.calificadosR1 = teams.reduce((acc, t) => acc + (t.ridersGone||0), 0);
      return resolve();
    });
  }

  public async getSpreadsheetRidersR2(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const riders = await this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, this.sheetName.get('riders2'), this.cellRange.get('riders2')).then(r => r.map((v,i) => {
        const [order, backNumber, riderName, horseName, team, flag, faults2, time2, timeFaults2, totalFaults2] = v;
        return {
          order: parseInt(order)-1,
          entryNumber: backNumber,
          riderName,
          horseName,
          team,
          //status2
          faults2,//totalFaults2
          time2: (time2 == '0' || !time2) ? '' : parseFloat(time2),
          timeFaults2,
          totalFaults2,
          result2: parseFloat(time2||'0') > 0 ? `${totalFaults2||'0'} | ${time2}` : '',
        }
      }));
      this.ridersMlsjSheet.map((r: RiderMlsjSheet) => {
        const rider = riders.find((rider: any) => rider.entryNumber == r.entryNumber);
        if(rider){
          r.faults2 = rider.faults2;
          r.order = rider.order;
          r.result2 = rider.result2;
          r.time2 = rider.time2;
          r.timeFaults2 = rider.timeFaults2;
        } else{
          r.order = 50;
        }
        return r;
      });
      this.ridersMlsjSheet.sort((a, b) => {
        if(a.team > b.team) return 1;
        if(a.team < b.team) return -1;

        if(a.order > b.order) return 1;
        if(a.order < b.order) return -1;

        return 0;
      }).map((r, i) => {
        r.order = i%3;
        return r;
      });
      this.ridersMlsj.map((r: RiderMlsj) => {
        const rider = this.ridersMlsjSheet.find((rider: any) => rider.entryNumber == r.entryNumber);
        const riderSheet = riders.find((rider: any) => rider.entryNumber == r.entryNumber);
        if(rider && riderSheet){
          r.faults2 = rider.faults2;
          r.order = rider.order;
          r.result2 = rider.result2;
          r.time2 = `${rider.time2}`;
          r.timeFaults2 = rider.timeFaults2;
        } else if(rider){
          r.order = rider.order;
        }
        return r;
      });
      return resolve();
    });
  }

  public async getSpreadsheetTeamsR2(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const teams = await this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, this.sheetName.get('teams2'), this.cellRange.get('teams2')).then(r => r.map(v => {
        const [order, teamName, rank, logo, faults2, time2, ridersGone] = v;
        return {
          order: parseInt(ridersGone||0) < 2 ? parseInt(order)-1 : parseInt(rank)-1,
          teamName,
          rank,
          faults2,
          time2: (time2 == '0' || !time2) ? '' : parseFloat(time2),
          result2: parseInt(ridersGone||0) > 0 ? `${faults2} | ${time2}` : '',
          ridersGone: parseInt(ridersGone||0),
        };
      }));
      this.teamsMlsjSheet.map((t: TeamMLSJSheet) => {
        const team = teams.find((team: any) => team.teamName == t.teamName);
        if(team){
          t.faults2 = team.faults2;
          t.order = team.order;
          t.rank = team.rank;
          t.result2 = team.result2;
          t.time2 = team.time2;
        }
        return t;
      });
      this.teamsMlsj.map((t: TeamMlsj) => {
        const team = this.teamsMlsjSheet.find((team: any) => team.teamName == t.teamName);
        const teamSheet = teams.find((team: any) => team.teamName == t.teamName);
        if(team && teamSheet){
          t.faults2 = team.faults2;
          t.order = team.order;
          t.rank = team.rank;
          t.result2 = team.result2;
          t.time2 = team.time2;
        }
        t.riders = this.ridersMlsj.filter(r => r.team == t.id).map(r => ({ ...r, team: t.teamName })).sort((a, b) => a.order - b.order);
        return t;
      });
      this.teamsMlsj.sort((a, b) => a.order - b.order);
      this.calificadosR2 = teams.reduce((acc, t) => acc + (t.ridersGone||0), 0);
      return resolve();
    });
  }

  public async getSpreadsheetRidersR3(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const riders = await this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, this.sheetName.get('riders3'), this.cellRange.get('riders3')).then(r => r.map((v,i) => {
        const [order, backNumber, riderName, horseName, team, flag, faults3, time3] = v;
        return {
          order: parseInt(order)-1,
          entryNumber: backNumber,
          riderName,
          horseName,
          team,
          //status2
          time3,
          result3: parseFloat(time3||'0') > 0 ? `${time3}` : '',
        }
      }));
      this.ridersMlsjSheet.map((r: RiderMlsjSheet) => {
        const rider = riders.find((rider: any) => rider.entryNumber == r.entryNumber);
        if(rider){
          r.order = 0;
          r.result3 = rider.result3;
          r.time3 = rider.time3;
        } else{
          r.order = 1;
        }
        return r;
      });
      this.ridersMlsjSheet.sort((a, b) => {
        if(a.team > b.team) return 1;
        if(a.team < b.team) return -1;

        if(a.order > b.order) return 1;
        if(a.order < b.order) return -1;

        return 0;
      }).map((r, i) => {
        r.order = i%3;
        return r;
      });
      this.ridersMlsj.map((r: RiderMlsj) => {
        const rider = this.ridersMlsjSheet.find((rider: any) => rider.entryNumber == r.entryNumber);
        const riderSheet = riders.find((rider: any) => rider.entryNumber == r.entryNumber);
        if(rider && riderSheet){
          r.order = rider.order;
          r.result3 = rider.result3;
          r.time3 = `${rider.time3}`;
        } else if(rider){
          r.order = rider.order;
        }
        return r;
      });
      console.log('getSpreadsheetRidersR3', this.ridersMlsj);
      return resolve();
    });
  }

  public async getSpreadsheetTeamsR3(): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const teams = await this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, this.sheetName.get('teams3'), this.cellRange.get('teams3')).then(r => r.map(v => {
        const [order, teamName, rank, logo, faults3, time3, ridersGone] = v;
        return {
          order: parseInt(ridersGone||0) < 1 ? parseInt(order)-1 : parseInt(rank)-1,
          teamName,
          rank,
          time3: (time3 == '0' || !time3) ? '' : parseFloat(time3),
          result3: parseInt(ridersGone||0) > 0 ? `${time3}` : '',
          ridersGone: parseInt(ridersGone||0),
        };
      }));
      this.teamsMlsjSheet.map((t: TeamMLSJSheet) => {
        const team = teams.find((team: any) => team.teamName == t.teamName);
        if(team){
          t.order = team.order;
          t.rank = team.rank;
          t.result3 = team.result3;
          t.time3 = team.time3;
        }
        return t;
      });
      this.teamsMlsj.map((t: TeamMlsj) => {
        const team = this.teamsMlsjSheet.find((team: any) => team.teamName == t.teamName);
        const teamSheet = teams.find((team: any) => team.teamName == t.teamName);
        if(team && teamSheet){
          t.order = team.order;
          t.rank = team.rank;
          t.result3 = team.result3;
          t.time3 = team.time3;
        }
        t.riders = this.ridersMlsj.filter(r => r.team == t.id).map(r => ({ ...r, team: t.teamName })).sort((a, b) => a.order - b.order);
        return t;
      });
      this.teamsMlsj.sort((a, b) => a.order - b.order);
      this.calificadosR3 = teams.reduce((acc, t) => acc + (t.ridersGone||0), 0);
      console.log('getSpreadsheetTeamsR3', teams);
      return resolve();
    });
  }

  public async getSpreadsheetData(sheetName: string, cellRange: string){
    return this.mlsjService.getSpreadsheetData(this.APIKey, this.spreadsheetId, sheetName, cellRange);
  }

  public async interval(){
    if(this.intervalId) clearInterval(this.intervalId);
    this.intervalId = setInterval(async () => {
      const init = Date.now();
      await this.getSpreadsheetResults();
      await this.updateFirestore();
      console.log(Date.now()-init);
    }, 1000);
  }

  changeUpdate(e: any){
    if(e.target.checked){
      this.interval();
    } else{
      if(this.intervalId) clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
