<!-- Topbar Start -->

<ul class="list-unstyled topbar-right-menu float-right mb-0">
	<li>
		<div class="version nav-user d-flex" style="padding: 0 10px!important; border: 0 !important; background: unset; text-align: center !important;">
		  <span class="my-auto"><small>v{{ version }}</small></span>
		</div>
	</li>	

	<li class="dropdown notification-list">
		<a class="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="false"
			aria-expanded="false">
			<span class="account-user-avatar">
				<img src="./../../assets/images/logo.png" alt="user-image" class="rounded-circle">
			</span>
			<span>
				<span class="account-user-name">{{ nombre }}</span>
				<span [ngSwitch]="privilegios">
					<span *ngSwitchCase="300">Developer</span>
					<span *ngSwitchCase="250">Accountant</span>
					<span *ngSwitchCase="200">Office leader</span>
					<span *ngSwitchCase="150">Office</span>
					<span *ngSwitchCase="100">Judge</span>
					<span *ngSwitchCase="90">Office FEM</span>
					<span *ngSwitchCase="80">Vet</span>
					<span *ngSwitchCase="50">Trainer</span>
					<span *ngSwitchDefault>Rol</span>
				</span>
				<!--span class="account-position">
					{{privilegios == 300 ? 'ED' : privilegios == 250 ? 'Contador' : privilegios == 180 ? 'Lider Of.' : privilegios == 150 ? 'Oficina': 'Rol'}}</span-->
			</span>
		</a>
		<div class="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown ">
			<!--
				Si se desea agregar una opción al menu de "mi cuenta" solo hay que agregar un elemento similar
				al que se encuentra abajo
			-->
			<a href="javascript:void(0);" class="dropdown-item notify-item" (click)="logOut()">
				<i class="mdi mdi-logout"></i>
				<span>Log out</span>
			</a>

		</div>
	</li>

</ul>

<!-- Inicio de boton de acceso a menu para vista movil -->
<button class="button-menu-mobile open-left disable-btn">
	<i class="mdi mdi-menu"></i>
</button>
<!-- fin de boton de acceso a menu para vista movil -->
<div class="float-left"  style="margin-left: -30px;" id="concurso">
	<a class="nav-link dropdown-toggle nav-user arrow-none mr-0 gray-hover" href="javascript:void(0)" (click)="cambiarConcursoOutput()" role="button" aria-haspopup="false"
		aria-expanded="false">
		<span class="account-user-avatar">
			<!--
				(error) detecta si no contamos con la imagen del logo
				Si no se detecta, muestra logo de ED
			-->
			<img (error)="updateUrl($event)" src="{{logoClub ? logoClub : '/assets/images/logo.png'}}" alt="user-image">
		</span>
		<span>
			<span class="account-user-name" style="font-weight: 800 !important;">{{ concurso }}</span>
			<span class="account-position">Switch Shows</span>
		</span>
	</a>
</div>
<div class="app-search float-right">
	
</div>

<!-- end Topbar -->
