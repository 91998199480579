import { Component, OnInit } from '@angular/core';
import { PrintService } from '../services/print.service';
import { ConcursoService } from '../services/concurso.service';
import { PruebaService } from '../services/prueba.service';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-print-divisions',
  templateUrl: './print-divisions.component.html',
  styleUrls: ['./print-divisions.component.css']
})
export class PrintDivisionsComponent implements OnInit {
  public idConcurso: string;
  public divisionIds: string[];
  public concurso: Promise<any>;
  public divisions: Promise<any>[];

  constructor(private printService: PrintService, private route: ActivatedRoute, private concursoService: ConcursoService, private pruebaService: PruebaService) {
    this.idConcurso = this.route.snapshot.paramMap.get('idConcurso');
    this.divisionIds = this.route.snapshot.paramMap.get('divisions').split(',');
  
  }

  ngOnInit() {
    this.concurso = this.concursoService.getConcurso(this.idConcurso).toPromise();
    this.divisions = this.divisionIds.map(d => this.pruebaService.getDivision(d, this.idConcurso).toPromise());
    Promise.all([this.concurso].concat(this.divisions)).then(() => setTimeout(() => { this.printService.onDataReady() }, 500));
  }

}
