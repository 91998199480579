import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConcursoService } from '../services/concurso.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-copy-show',
  templateUrl: './copy-show.component.html',
  styleUrls: ['./copy-show.component.css']
})
export class CopyShowComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public toShows: any[];
  public fromShows: any[];
  public form: UntypedFormGroup;
  public currentOrganizer;

  constructor(private authService: AuthService, private router: Router, private concursoService: ConcursoService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.currentOrganizer;
    this.toShows = [];
    this.fromShows = [];
    this.form = new UntypedFormGroup({
      from: new UntypedFormControl('', [Validators.required]),
      to: new UntypedFormControl('', [Validators.required]),
      classes: new UntypedFormControl(true, []),
      divisions: new UntypedFormControl(false, []),
      prizeMoneyDistributions: new UntypedFormControl(false, []),
      billingItems: new UntypedFormControl(false, []),
      showSetting: new UntypedFormControl(false, [])
    });
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getConcursos();
  }

  private getConcursos() {
    console.log("Concurso: " + this.idConcurso);
    console.log("Privilegios: " + this.privilegios);
    console.log(this.sessionData);
    this.concursoService.getConcursos().subscribe(
      response => {

        // Identify current show organizer ID
        this.currentOrganizer = response.consursos.filter(c => c.id == this.idConcurso)[0]['id_club'];

        // Only display shows from current organizer
        this.fromShows = response.consursos.filter(c => c.id_club == this.currentOrganizer).sort((a, b) => {
          const dateA = new Date(a.inicio).getTime();
          const dateB = new Date(b.inicio).getTime();
          if (dateA == dateB) return 0;
          return dateA > dateB ? 1 : -1;
        });

        // Only display current show
        this.toShows = response.consursos.filter(c => c.id == this.idConcurso).sort((a, b) => {
          const dateA = new Date(a.inicio).getTime();
          const dateB = new Date(b.inicio).getTime();
          if (dateA == dateB) return 0;
          return dateA > dateB ? 1 : -1;
        });

        const indexFrom = this.fromShows.findIndex(s => s.id == this.idConcurso);
        const indexTo = this.toShows.findIndex(s => s.id == this.idConcurso);
        if (indexFrom > -1) this.form.get('from').setValue(this.idConcurso);
        if (indexTo > -1) this.form.get('to').setValue(this.idConcurso);
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public copiar() {
    if (this.form.valid && (this.form.value.classes || this.form.value.divisions || this.form.value.prizeMoneyDistributions || this.form.value.billingItems || this.form.value.showSetting)) {
      this.concursoService.copy(this.form.value.from, this.form.value.to, this.form.value.classes, this.form.value.divisions, this.form.value.prizeMoneyDistributions, this.form.value.billingItems, this.form.value.showSetting).subscribe(
        response => {
          if (!response.error) {
            this.form.setValue({
              from: '',
              to: '',
              classes: true,
              divisions: false,
              prizeMoneyDistributions: false,
              billingItems: false,
              showSetting: false
            });
            $.NotificationApp.send("Success", 'The show has been successfully updated.', 'bottom-right', '#06d5a1', 'success');
          } else {
            $.NotificationApp.send("Error", response.mensaje, 'bottom-right', '#fa5c7c', 'error');
            console.log(response);
          }
        },
        error => {
          $.NotificationApp.send("Error", "It has not been possible to query the prize distribution list.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    }
  }
}
