<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid">
                <div class="row mt-4 justify-content-center">
                    <div class="col-8">
                        <div class="card">
                            <h4 class="card-header">{{ pantalla.pista }}</h4>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="pruebas">Prueba</label>
                                            <select class="form-control" id="pruebas" [(ngModel)]="ipc" (change)="seleccionarPrueba()">
                                                <option value="">Select a Class</option>
                                                <option *ngFor="let prueba of pruebas" [value]="prueba.ipc">{{ prueba.numero }} - {{ prueba.nombre }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <div class="row">
                                            <div class="col-2">
                                                <button (click)="seleccionarPantalla(3)" type="button" class="btn btn-outline-primary pantalla texto" [ngClass]="{active: pantalla.pantalla == '3'}">
                                                    Show
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <div class="row">
                                            <div class="col-2">
                                                <button (click)="seleccionarPantalla(4)" type="button" class="btn btn-outline-warning pantalla texto" [ngClass]="{active: pantalla.pantalla == '4'}">
                                                    Class
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <div class="row">
                                            <div class="col-2">
                                                <button (click)="seleccionarPantalla(1)" type="button" class="btn btn-outline-success pantalla" [ngClass]="{active: pantalla.pantalla == '1'}">
                                                    <span><i class="mdi mdi-video"></i></span>
                                                </button>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="toggleLetreros(!pantalla.letreros)" type="button" class="btn btn-outline-info pantalla" [ngClass]="{active: pantalla.letreros}">
                                                    <span><i class="mdi mdi-format-text" style="font-size: 2vw;"></i></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-2">
                                        <button (click)="seleccionarPantalla(2)" type="button" class="btn btn-outline-info pantalla" [ngClass]="{active: pantalla.pantalla == '2'}">
                                            <span><i class="mdi mdi-format-list-numbered"></i></span>
                                        </button>
                                    </div>
                                    <div class="col-1 d-flex px-0" style="flex-direction: column;">
                                        <div class="col">
                                            <button type="button" class="btn btn-outline-success pantalla" (click)="scrollTablaResultados('-')">
                                                <span><i class="mdi mdi-menu-up" style="font-size: 2vw;"></i></span>
                                            </button>
                                        </div>
                                        <div class="col-auto">
                                           <p class="m-0" style="line-height: 30px; text-align: center; font-weight: bold; white-space: nowrap;">{{ filtroResultados(pantalla.resultados) }}</p>
                                        </div>
                                        <div class="col">
                                            <button type="button" class="btn btn-outline-danger pantalla" (click)="scrollTablaResultados('+')">
                                                <span><i class="mdi mdi-menu-down" style="font-size: 2vw;"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                    <!--div class="col-9">
                                        <div class="row">
                                            <div class="col-1" style="flex: 0 0 11.111%; max-width: 11.111%;" *ngFor="let categoria of categorias">
                                                <button (click)="seleccionarCategoria(categoria.id)" type="button" [class]="claseCategoria(categoria.id)" [ngClass]="{active: categoria.id == pantalla.categoria}">
                                                    <span style="font-size: 1vw;">{{ categoria.siglas }}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div></div>
                                        </div>
                                    </div-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
