import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
declare var $ :any;


@Component({
  selector: 'app-users-ingate',
  templateUrl: './users-ingate.component.html',
  styleUrls: ['./users-ingate.component.css']
})
export class UsersIngateComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public form: UntypedFormGroup;
  public emails: any[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private db: AngularFirestore,
  ) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
    this.emails = [];
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 300){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getEmails();
  }

  private async getEmails(): Promise<void>{
    const concurso: any = await this.db.collection('concursos').doc(`us_${this.idConcurso}`).get().toPromise().then(r => r.data())||{};
    const emails = concurso.usersIngate||[];
    this.emails = emails;
    $('#loader').hide();
  }

  public submit(){
    if(this.form.valid){
      this.emails.push(this.form.value.email);
      this.db.collection('concursos').doc(`us_${this.idConcurso}`).set({
        usersIngate: this.emails
      }, { merge: true });
      this.form.setValue({
        email: ''
      });
    }
  }

  public eliminarEmail(i){
    this.emails.splice(i, 1);
    this.db.collection('concursos').doc(`us_${this.idConcurso}`).set({
      usersIngate: this.emails
    }, { merge: true });
  }
}
