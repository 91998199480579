import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class PantallaService extends Api {
    constructor(private http: HttpClient) {
        super();
    }

    public getPantallas(idConcurso): Observable<any>{
        const params = JSON.stringify({idConcurso});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}pantallas/`, params, {headers: headers});
    }

    public crearPantalla(idConcurso, pista): Observable<any>{
        const params = JSON.stringify({idConcurso, pista});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}pantallas/crear/`, params, {headers: headers});
    }

    public getPantalla(idPantalla): Observable<any>{
        const params = JSON.stringify({idPantalla});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}pantallas/get/`, params, {headers: headers});
  }

  public getEnPista(idPrueba): Observable<any> {
    const params = JSON.stringify({ idPrueba });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pantallas/get/`, params, { headers: headers });
  }

    public seleccionarPrueba(idPantalla, ipc): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}pantallas/prueba/?id=${idPantalla}&ipc=${ipc}`, {headers: headers});
    }

    public seleccionarPantalla(idPantalla, pantalla): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}pantallas/seleccionar/?id=${idPantalla}&pantalla=${pantalla}`, {headers: headers});
    }

    public seleccionarCategoria(idPantalla, categoria): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}pantallas/categoria/?id=${idPantalla}&categoria=${categoria}`, {headers: headers});
    }

    public scrollTablaResultados(idPantalla, resultados): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}pantallas/resultados/?id=${idPantalla}&resultados=${resultados}`, {headers: headers});
    }

    public toggleLetreros(idPantalla, letreros): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}pantallas/letreros/?id=${idPantalla}&letreros=${letreros}`, {headers: headers});
    }
}
