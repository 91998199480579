<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid">
                <div class="row mt-3 m-print-0 justify-content-center">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <table class="table table-striped table-hover table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th>Transaction ID</th>
                                            <th>Chase ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Horse</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let payment of payments">
                                            <td>{{ payment.id }}</td>
                                            <td>{{ payment.chase_id }}</td>
                                            <td>{{ payment.name }}</td>
                                            <td>{{ payment.email }}</td>
                                            <td>{{ payment.horse }}</td>
                                            <td>{{ payment.amount | currency }}</td>
                                            <td>{{ payment.created | date }}</td>
                                            <td>
                                              <button class="btn btn-danger btn-sm" (click)="refundPayment(payment)">Refund</button>
                                            </td>
                                            <td>
                                              <button class="btn btn-info btn-sm" title="Create entry from backup" (click)="createEntryFromBackup(payment.id)">Backup</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
