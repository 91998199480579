import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponsablesService } from '../services/responsables.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
declare var jquery:any;
declare var $ :any;

@Component({
    selector: 'pase',
    templateUrl: 'pase.component.html',
    styleUrls: ['pase.component.css']
})

export class PaseComponent implements OnInit {
    public pase: any;

    constructor(
        private route: ActivatedRoute,
        private responsablesService: ResponsablesService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        this.pase = {};
    }

    ngOnInit() {
        this.iconRegistry.addSvgIcon('horse', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/horse.svg'));
        this.route.params.subscribe(params => {
            this.responsablesService.getPase(params['idPase']).subscribe(
                response => {
                    if(!response.error){
                        this.pase = response.consulta;
                        console.log(this.pase);
                    } else{
                        $.gritter.add({title: "Error", text: response.message, class_name:"color danger"});
                    }
                },
                error => {
                    $.gritter.add({title: "Error", text: "No ha sido posible consultar los datos del pase de salida.", class_name:"color danger"});
                    console.log(error);
                }
            );
        });
    }
}