<!--
  Elementos para la animación de EcuestreDigital
-->
<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div id="modal-editar" tabindex="-1" role="dialog" class="modal fade" style="display: none;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header modal-colored-header bg-dark">
          <h3 class="modal-title">Edit usuer </h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              <i style="color: #ffffff;" class=" mdi mdi-close-box-outline"></i>
          </button>
      </div>
      <div class="modal-body">
        <form #usuarioForm="ngForm" (ngSubmit)="editarUsuarioActivo()">
          <div class="formGroup">
            <input type="text" placeholder="Nombre del responsable" class="form-control" [formControl]="nombreResponsable" (keyup)="getResponsables($event)">
            <mat-autocomplete #autoCompleteResponsable="matAutocomplete" [displayWith]="displayResponsableCuenta">
              <mat-option *ngFor="let responsable of responsables" [value]="responsable" class="fila-autocomplete" (click)="setIdUsuarioOnline(responsable.id_responsable,index,responsable.fullname)">
                {{ (responsable.fullname) ? responsable.fullname : '-' }}
              </mat-option>
            </mat-autocomplete>
            <input type="text" placeholder="Ingrese el correo electrónico" class="form-control" [formControl]="usuarioResponsable">
            <input type="text" placeholder="Ingrese la nueva contraseña" class="form-control" [formControl]="passwordResponsable">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-success">Update information</button>
        <button type="button" data-dismiss="modal" class="btn btn-danger">Close</button>
      </div>
    </div>
  </div>
</div>

  <div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
      <div class="content">
          <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
          <div class="container-fluid" style="width: 100%; float: left; display: flex;">
            <div class="col-md-12 padding-0">
              <div class="card margin-top-35">
                <div class="card-body padding-0">
                  <div class="col-md-12" style="margin-top:30px;">
                    <div class="col-md-12 padding-0 table-responsive" *ngIf="tableLoaded == true">
                      <table class="table dt-responsive nowrap" id="users-responsables-table">
                        <thead class="bg-dark text-white">
                          <tr>
                            <th>Email (usuario)</th>
                            <th>Payee info</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr #allTheseThings *ngFor="let usuario of usuariosOnlineActivos; let index = index;">
                            <td *ngIf="usuario.boton_acciones != 1"> {{ usuario.usuario }}</td>
                            <td *ngIf="usuario.boton_acciones == 1">
                              <input type="text" placeholder="Ingrese el correo electrónico" [formControl]="usuarioResponsable" class="form-control">
                            </td>
                            <td *ngIf="usuario.boton_acciones != 1"> {{ usuario.fullname }}</td>
                            <td *ngIf="usuario.boton_acciones == 1">
                              <input type="text" placeholder="Nombre del responsable" value={{usuario.fullname}} class="form-control" [matAutocomplete]="autoCompleteResponsable" (keyup)="getResponsables($event)">
                              <mat-autocomplete #autoCompleteResponsable="matAutocomplete" [displayWith]="displayResponsableCuenta">
                                <mat-option *ngFor="let responsable of responsables" [value]="responsable" class="fila-autocomplete" (click)="setIdUsuarioOnline(responsable.id_responsable,index,responsable.fullname)">
                                  {{ (responsable.fullname) ? responsable.fullname : '-' }}
                                </mat-option>
                              </mat-autocomplete>
                            </td>
                            <td>
                              <button *ngIf="usuario.boton_acciones == 0" class="btn btn-dark btn-icon" (click)="areYouSure(index,1);">
                                <i class="mdi mdi-square-edit-outline"></i>
                                Edit
                              </button>

                              <button *ngIf="usuario.boton_acciones == 1" class="btn btn-danger btn-icon" (click)="areYouSure(index,0);">
                                <i class="mdi mdi-close-box"></i>
                                Cancel
                              </button>
                              <button *ngIf="usuario.boton_acciones == 2" class="btn btn-danger btn-icon" (click)="eliminarUsuarioActivo(usuario.id_responsable,usuario.usuario);">
                                <i class="mdi mdi-delete"></i>
                                ¿Are you sure?
                              </button>
                            </td>
                            <td>
                              <button *ngIf="usuario.boton_acciones == 0"  class="btn btn-dark btn-icon" (click)="areYouSure(index,2);">
                                <i class="mdi mdi-delete-empty"></i>
                                Eliminate
                              </button>
                              <button *ngIf="usuario.boton_acciones == 2" class="btn btn-success btn-icon" (click)="areYouSure(index,0);">
                                  <i class="mdi mdi-close-box"></i>
                                  Nope, nope, nope
                              </button>
                              <button *ngIf="usuario.boton_acciones == 1" class="btn btn-success btn-icon" (click)="editarUsuarioActivo();">
                                <i class="mdi mdi-delete"></i>
                                Save Changes
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
