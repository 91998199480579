<table style="width: 100%">
  <thead><tr><td>
    <div class="header-space">&nbsp;</div>
  </td></tr></thead>
  <tbody><tr><td>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </td></tr></tbody>
  <tfoot><tr><td>
    <div class="footer-space">&nbsp;</div>
  </td></tr></tfoot>
</table>

<div class="header">
  <div class="row">
    <div class="col-3 text-left">
      <img [src]="(concurso | async)?.concurso.header" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
    </div>
    <div class="col-6" >
      <p style="font-size: 0.5em">{{ (concurso | async)?.concurso.nombre }}</p>
      <p class="m-0" style="font-size: 0.375em">{{ nombreReporte }}</p>
    </div>
    <div class="col-3 text-right">
      <img [src]="(concurso | async)?.concurso.header2" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
    </div>
  </div>
  <!--div style="font-size: 0.5em">{{ nombreReporte }}</div-->
</div>
<div class="footer-layout">
  <img [src]="(concurso | async)?.concurso.footer" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
</div>
