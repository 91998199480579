<!-- ========== Left Sidebar Start ========== -->
<div class="slimscroll-menu">
  <!-- LOGO -->
  <a href="#" class="logo text-center">
    <span class="logo-lg">
      <img src="/assets/images/logo-ed-marco-transparente.png" alt="" height="64">
    </span>
    <span class="logo-sm">
      <img src="/assets/images/logo-ed-marco-transparente.png" alt="" height="64">
    </span>
  </a>
  <!--- Sidemenu -->
  <ul class="metismenu side-nav">
    <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngIf="privilegios>=100">
      <a routerLink="/" class="side-nav-link" aria-expanded="false">
        <i class="mdi mdi-chart-bar"></i>
        <span>Start</span>
      </a>
    </li>
    <!-- Ingate: 50 -->
    <li class="side-nav-item" *ngIf="privilegios >= 50">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="true">
        <i class="mdi mdi-newspaper"></i>
        <span>Show info</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level" aria-expanded="true">
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/show-info">
            <i class="mdi mdi-newspaper"></i>
            <span>Show info</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/divisions">
            <i class="mdi mdi-format-list-bulleted"></i>
            <span>Divisions</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/class-planner">
            <i class="mdi mdi-calendar"></i>
            <span>Class Planner</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/day-plan">
            <i class="mdi mdi-calendar"></i>
            <span>Day Plan</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/copy-show">
            <i class="mdi mdi-reorder-horizontal"></i>
            <span>Copy show</span>
          </a>
        </li>
      </ul>
    </li>
    <ng-container *ngIf="!reining">
      <li class="side-nav-item" *ngIf="privilegios >= 100">
        <a href="javascript: void(0);" class="side-nav-link" aria-expanded="true">
          <!--i class="mdi mdi-reorder-horizontal"></i-->
          <img src="./../../assets/images/icons/fei_white.svg"
            style="display: inline-block; line-height: 1.0625rem; margin: 0 10px 0 3px; text-align: center; vertical-align: middle; width: 20px;"
            alt="FEI">
          <span>Synchronize</span>
          <span class="menu-arrow"></span>
        </a>
        <ul class="side-nav-second-level" aria-expanded="true">
          <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <a routerLink="/fei-show">
              <i class="mdi mdi-newspaper"></i>
              <span>Show info</span>
            </a>
          </li>
          <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <a routerLink="/fei-entries">
              <i class="mdi mdi-format-list-numbered"></i>
              <span>Entries</span>
            </a>
          </li>
          <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <a routerLink="/validar-master-list">
              <i class="mdi mdi-reorder-horizontal"></i>
              <span>Master list</span>
            </a>
          </li>
        </ul>
      </li>
    </ng-container>
    <li class="side-nav-item" *ngIf="privilegios >= 100">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="false">
        <i class="mdi mdi-account-card-details"></i>
        <span>Directory</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level" aria-expanded="true">
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/people">
            <i class="mdi mdi-account"></i>
            <span>People / Orgs</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/horses">
            <img src="assets/images/icons/horse-head-white.svg" style="width:15px;" alt="Horses">
            <span>Horses</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/merge-riders">
            <i class="mdi mdi-source-merge"></i>
            Merge people
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/merge-horses">
            <i class="mdi mdi-source-merge"></i>
            Merge horses
          </a>
        </li>
      </ul>
    </li>
    <li class="side-nav-item" *ngIf="privilegios >= 100">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="false">
        <i class="mdi mdi-format-list-numbered"></i>
        <span>Entries</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level" aria-expanded="true">
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/entries">
            <i class="mdi mdi-format-list-numbered"></i>
            <span>Entries</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/trainer-account">
            <i> <img src="assets/images/icons/coach.svg" style="width:15px;" alt="Trainers"></i>
            <span>Trainer Accounts</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/online-entries">
            <i class="mdi mdi-web"></i>
            <span>Online</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/trainer-reservations">
            <i class="mdi mdi-web"></i>
            <span>Trainer Reservations</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/copy-entries">
            <i class="mdi mdi-content-copy"></i>
            <span>Copy</span>
          </a>
        </li>
      </ul>
    </li>
    <!-- Ingate: 50 -->
    <li class="side-nav-item" *ngIf="privilegios >= 50">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="true">
        <i class="mdi mdi-reorder-horizontal"></i>
        <span>Lists & Results</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level" aria-expanded="true">
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <!-- Si el concurso es de reining, se redirige al componente de listas por division -->
          <a [routerLink]="['/listas']" routerLinkActive="router-link-active">
            <i class="mdi mdi-format-list-numbered"></i>
            <span>Orders of Go (Class)</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <!-- Si el concurso es de reining, se redirige al componente de listas por division -->
          <a [routerLink]="['/class-division-list']" routerLinkActive="router-link-active">
            <i class="mdi mdi-format-list-numbered"></i>
            <span>Orders of Go (Division)</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/manually-sorted-orders-of-go">
            <i class="mdi mdi-clipboard-text"></i>
            <span>Manually Sorted</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/lista-editable">
            <i class="mdi mdi-playlist-edit"></i>
            <span>Order changes</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/class-sheets">
            <i class="mdi mdi-format-list-numbered"></i>
            <span>Class Sheets</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/judgeCards">
            <i class="mdi mdi-cards-variant"></i>
            <span>Judge Cards</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/results">
            <i class="mdi mdi-trophy"></i>
            <span>Results</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/division-champions">
            <i class="mdi mdi-trophy"></i>
            <span>Division Champions</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/listas-entrenador">
            <i class="mdi mdi-human-greeting"></i>
            <span>Trainer</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/importar-listas">
            <i class="mdi mdi-file-excel-box"></i>
            <span>Import</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/master-list">
            <i class="mdi mdi-reorder-horizontal"></i>
            <span>Master list</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="side-nav-item" *ngIf="privilegios>=100">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="true">
        <i class="mdi mdi-calculator"></i>
        <span>Billing & Accounting</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level" aria-expanded="true">
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/billing">
            <i class="mdi mdi-calculator"></i>
            <span>Billing</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
          *ngIf="privilegios >= 300">
          <a routerLink="/unassigned-payments">
            <i class="mdi mdi-cash"></i>
            <span>Unassigned payments</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/prize-distributions">
            <i class="mdi mdi-cash"></i>
            <span>Prize Distributions</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/print-checks">
            <i class="mdi mdi-cash"></i>
            <span>Print checks</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/feed-orders">
            <i class="mdi mdi-format-list-bulleted-square"></i>
            <span>Feed Orders</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/trainer-account">
            <i> <img src="assets/images/icons/coach.svg" style="width:15px;" alt="Trainers"></i>
            <span>Trainer Accounts</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngIf="privilegios >= 250">
          <a routerLink="/accounting">
            <i class="mdi mdi-scale-balance"></i>
            <span>Accounting</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
      *ngIf="privilegios >= 200 && club == '377'">

      <a routerLink="/products" class="side-nav-link" aria-expanded="false">
        <i class="dripicons-box"></i>
        <span>Products</span>
      </a>
    </li>
    <li class="side-nav-item" *ngIf="privilegios > 90">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="true">
        <i style="font-size:1.4rem" class=" mdi mdi-file"></i>
        <span>Reports</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level collapse" aria-expanded="false">
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
          *ngIf="privilegios >= 200">
          <a routerLink="/reports">
            <i class="mdi mdi-code-not-equal"></i>
            <span>General</span>
          </a>
        </li>

        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/executive-summary">
            <i class="mdi mdi-notebook"></i>
            <span>Executive Summary</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="side-nav-item" *ngIf="privilegios >= 100">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="false">
        <i class="mdi mdi-cellphone-link"></i>
        <span>App</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level" aria-expanded="true">
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a href="javascript:void(0)" (click)="updateMasterList()">
            <i class="mdi mdi-format-list-checks"></i>
            <span>Update Master List</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a href="javascript:void(0)" (click)="updateShows()">
            <i class="mdi mdi-format-list-checks"></i>
            <span>Update Shows</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a href="javascript:void(0)" (click)="updateClasses()">
            <i class="mdi mdi-format-list-checks"></i>
            <span>Update Classes</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a href="javascript:void(0)" (click)="updateShow()">
            <i class="mdi mdi-format-list-bulleted"></i>
            <span>Update Results</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a href="javascript:void(0)" [routerLink]="['/leaderboards']" routerLinkActive="router-link-active">
            <i class="mdi mdi-format-list-bulleted"></i>
            <span>Leaderboards</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a href="javascript:void(0)" [routerLink]="['/location-guide']" routerLinkActive="router-link-active">
            <i class="mdi mdi-format-list-bulleted"></i>
            <span>Location Guide</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/create-announcement">
            <i class="mdi mdi-cloud-upload"></i>
            <span>Create announcement</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngIf="privilegios >= 300">
          <a href="javascript:void(0)" routerLink="/mlsj">
            <i class="mdi mdi-format-list-checks"></i>
            <span> MLSJ</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
      *ngIf="privilegios >= 300 || idUsuario == '630'">
      <a class="side-nav-link" routerLink="/sistema-videos" aria-expanded="false">
        <i style="font-size:1.4rem" class="mdi mdi-video"></i>
        <span style="color:teal">Video System</span>

      </a>
    </li>

    <li class="side-nav-item" *ngIf="privilegios >= 200">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="true">
        <i style="font-size:1.4rem" class=" mdi mdi-account-group"></i>
        <span>Users</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level" aria-expanded="true" style="">
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
          *ngIf="privilegios >= 300">
          <a routerLink="/usuarios">
            <i style="font-size: 1.3rem;" class="mdi mdi-account-group"></i>
            <span style="color:teal"> Users</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
          *ngIf="privilegios >= 200">
          <a routerLink="/users-orders">
            <i style="font-size: 1.3rem;" class="mdi mdi-account-group"></i>
            <span style="color:teal"> Orders</span>
          </a>
        </li>
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
          *ngIf="privilegios >= 200">
          <a routerLink="/users-ingate">
            <i style="font-size: 1.3rem;" class="mdi mdi-account-group"></i>
            <span> Ingate</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
      *ngIf="privilegios >= 300">
      <a routerLink="/pantallas" class="side-nav-link" aria-expanded="false">
        <i class="mdi mdi-monitor-multiple"></i>
        <span style="color:tomato">Screens</span>
      </a>
    </li>
    <li class="side-nav-item" *ngIf="privilegios >= 300">
      <a href="javascript: void(0);" class="side-nav-link" aria-expanded="true">
        <i style="font-size:1.4rem" class=" mdi mdi-wrench"></i>
        <span style="color:tomato">Management</span>
        <span class="menu-arrow"></span>
      </a>
      <ul class="side-nav-second-level" aria-expanded="true" style="">
        <!--li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                    <a routerLink="/merge-horses-manual">
                        <i class="mdi mdi-source-merge"></i>
                        Merge horses manual
                    </a>
                </li-->
        <li class="side-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="/limpiarBD/caballos">
            <img src="assets/images/icons/horse-head-white.svg" style="width:15px;" alt="Caballo">
            FEI Duplicates
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <div class="clearfix"></div>
</div>
<!-- Sidebar -left -->

<!-- Left Sidebar End -->
