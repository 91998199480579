import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterJovenes'
})
export class FilterJovenesPipe implements PipeTransform {
  transform(value: Array<any>, query: string): Array<any> {
    if(query){
        if(query == '5'){
            value = value.map(v => {
                v.hidden = !v['5'];

                return v;
            });
            return value;
        } else if(query == '6'){
            value = value.map(v => {
                v.hidden = !v['6'];

                return v;
            });

            return value;
        } else if(query == '7'){
            value = value.map(v => {
                v.hidden = !v['7'];

                return v;
            });
            return value;
        } else if(query == 'i'){
            value = value.map(v => {
                v.hidden = (v['5'] || v['6'] || v['7']);
                return v;
            });
            return value;
        }

        value = value.map(v => {
            v.hidden = false;

            return v;
        });

        return value;
    }

    value = value.map(v => {
        v.hidden = false;

        return v;
    });

    return value;
  }
}