<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <div class="row mt-4 justify-content-center m-print-0">
          <div class="col-8 p-print-0">
            <div class="card m-print-0">
              <div class="card-body p-print-0">
                <div class="row">
                  <div class="col-8">
                    <select class="form-control d-print-none mb-3" [(ngModel)]="ipc" (change)="seleccionarPrueba()">
                      <option value="" selected disabled>Select a class</option>
                      <option *ngFor="let prueba of pruebas" [value]="prueba.ipc">{{ prueba.numero }} - {{ prueba.nombre
                        }}</option>
                    </select>
                  </div>
                  <div class="col-4">
                    <button type="button" class="btn btn-info" [disabled]="!ipc" (click)="guardarFirebase()">Sync to
                      App</button>
                  </div>
                </div>
                <ul class="list-group" cdkDropList [attr.cdkDropListData]="binomios"
                  (cdkDropListDropped)="drop($event)">
                  <li class="list-group-item px-2 py-1" *ngIf="ipc">
                    <div class="row">
                      <div class="col-1">
                        <b>Order</b>
                      </div>
                      <div class="col-1">
                        <b>Entry</b>
                      </div>
                      <div class="col-5">
                        <b>Rider</b>
                      </div>
                      <div class="col-5">
                        <b>Horse</b>
                      </div>
                    </div>
                  </li>
                  <ng-container *ngFor="let binomio of binomios">
                    <li class="list-group-item px-2 py-1" cdkDrag cdkDragLockAxis="y">
                      <div class="row">
                        <div class="col-1 my-auto">
                          {{ binomio.entradaPublicada }}
                        </div>
                        <div class="col-1 my-auto">
                          {{ binomio.cucarda }}
                        </div>
                        <div class="col-5 my-auto">
                          {{ binomio.nombreJinete }} {{ binomio.apellidosJinete }}
                        </div>
                        <div class="col-5 my-auto">
                          {{ binomio.caballo }}
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
                <!--table class="table" *ngIf="ipc">
                  <thead>
                    <tr>
                      <th>Entry</th>
                      <th>Rider</th>
                      <th>Horse</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let binomio of binomios">
                      <td><input class="form-control text-center" min="1" type="number" [value]="binomio.entradaPublicada" style="width: 4rem;" onclick="this.select()" (change)="cambiarEntrada($event, binomio.ibpc)"></td>
                      <td>{{ binomio.nombreJinete }} {{ binomio.apellidosJinete }}</td>
                      <td>{{ binomio.caballo }}</td>
                    </tr>
                  </tbody>
                </table-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
