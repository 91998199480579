import { Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service'
import { UntypedFormControl, Validators } from '@angular/forms';
declare var jquery:any;
declare var $ : any;

@Component({
  selector: 'gestion-usuarios-inscripciones',
  templateUrl: './gestion-usuarios-inscripciones.component.html',
  styleUrls: ['./gestion-usuarios-inscripciones.component.css']
})
export class GestionUsuariosInscripcionesComponent implements OnInit {

  public idConcurso: string | boolean;
  public nombreConcurso: string;
  public usuariosOnlineActivos: any;
  public privilegios: number;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public loaded: boolean;
  public tableLoaded: boolean;
  public responsables: any;
  public nombreResponsable: UntypedFormControl;
  public usuarioResponsable: UntypedFormControl;
  public passwordResponsable: UntypedFormControl;
  public idResponsableActual: number;
  public nombreResponsableActual: string;
  public usuarioResponsableActual: string;

  constructor(
    private route: ActivatedRoute,
    private inscripcionesRemotasService: InscripcionesRemotasService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router
  ) {
    this.idConcurso = '';
    this.token = '';
    this.idUsuario = '';
    this.sessionData = {};
    this.usuariosOnlineActivos = [];
    this.responsables = [];
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.loaded = false;
    this.tableLoaded = false;
    this.nombreResponsable = new UntypedFormControl('',[Validators.required]);
    this.usuarioResponsable = new UntypedFormControl('',[Validators.required]);
    this.passwordResponsable = new UntypedFormControl('',[Validators.required]);
    this.idResponsableActual = 0;
    this.nombreResponsableActual = '';
    this.usuarioResponsableActual = '';
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 300){
      this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    $('#loader').show();
    this.getUsuariosActivos(false);
  }

  /**
   * El objeto "things" se actualiza cada que detecta un cambio en
   * el elemento 'allTheseThings'.
   */
  @ViewChildren('allTheseThings') things: QueryList<any>;

  ngAfterViewInit() {
    this.things.changes.subscribe(t => {
      this.dataTables();
    })
  }

  /**
   * Inicializa la función de dataTables.
   * >Se invoca cuando la directiva ngFor termina de renderear la
   *  tabla con los datos de los usuarios activos.
   */
  public dataTables(){
    if(!this.loaded){
      $('#users-responsables-table').DataTable({
        keys: true,
        "language": {
            "paginate": {
                "previous": "<i class='mdi mdi-chevron-left'>",
                "next": "<i class='mdi mdi-chevron-right'>"
            }
        },
        "drawCallback": function () {
            $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
        }
      });
      this.loaded = true;
      $('#loader').fadeOut(1000);
    }
  }

  /**
   * @param event (KeyUp) Regresa el valor del INPUT de responsables.
   * @param index Indice del array solicitudesSinResponsableUsuarioOnline[]
   *              En caso de que el evento regrese vacio o nulo, reseteara los campos
   *              del índice.
   *
   * Obtiene los registros de la tabla responsables donde el campo 'fullname' sea similar
   * a los valores capturados por el usuario y que el responsable no tenga una cuenta activa.
   */
  public getResponsables(event: any){
    let nombre = event.target.value;
    let accion = 'filtrarResponsables';
    let correo = '';
    if(nombre.length > 3){
      this.inscripcionesRemotasService.getInformacionResponsables(nombre,correo,accion).subscribe(
        response => {
          this.responsables = response;
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  /**
   * @param val Objeto con los valores del responsable.
   *
   * Regresa a la vista el nombre del responsable
   */
  public displayResponsableCuenta(val): string {
    return val ? val.fullname: val;
  }

  /**
   *  @param needAnimation Notifica si necesita o no animación.
   *  Obtiene los registros en la tabla "users_responsables" y el nombre del responsable
   *  vinculado que no se encuentren eliminados.
   */
  public getUsuariosActivos(needAnimation:boolean){
    this.inscripcionesRemotasService.getUsuariosActivos().subscribe(
      response => {
        this.loaded = false; //Se geneera como falso para inicializar de nuevo DataTables().
        this.usuariosOnlineActivos = response;
        this.tableLoaded = true; //'Agrega' la tabla a la vista
        if(needAnimation){
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  /**
   * #OPTIMIZAR
   * Envía nuevos datos del usuario
   */
  public editarUsuarioActivo(){
    let accion = "actualizarUsuario";
    this.usuariosOnlineActivos.forEach(usuario => {
      usuario.boton_acciones = 0;
    });
    this.inscripcionesRemotasService.accionesUsuarioActivo(
      this.idResponsableActual,
      this.nombreResponsableActual,
      this.usuarioResponsableActual,
      this.nombreResponsable.value.id,
      this.nombreResponsable.value.nombre,
      this.usuarioResponsable.value,
      accion).subscribe(
        response => {
          if(!response.error){
            $.NotificationApp.send("Success", 'The profile of '+this.nombreResponsableActual+' has been updated.', 'bottom-right', '#06d5a1', 'success');
            $('#loader').show();
            this.tableLoaded = false;
            this.usuariosOnlineActivos = [];
            this.getUsuariosActivos(true);
          }else{
            $.NotificationApp.send("ERROR",response.error, 'bottom-right', '#fa5c7c', 'error');
          }
        },
        error => {
          $.NotificationApp.send("ERROR",error, 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
    );
  }


  /**
   * @param index Indice del arreglo solicitudesSinResponsableUsuarioOnline en el que se mostrarán las opciones.
   * @param accion Parametro que define opción visible:
   *                0 = Botones originales (editar - eliminar).
   *                1 = Botones confirmación (rojo-cancelar | Verde actualizar) para editar usuario.
   *                2 = Botones confirmacion (rojo-eliminar | verde-cancelar) para borrar usuario.
   *
   * Solo permite habilitar los botones (1,2) para un usuario.
   */
  public areYouSure(index, accion){
    console.log(index, accion);
    //Resetea todos los indices del arreglo.
    this.usuariosOnlineActivos.forEach(usuario => {
      usuario.boton_acciones = 0;
    });
    //Asigna al indice indicado la accion.
    this.usuariosOnlineActivos[index].boton_acciones = accion;
    if(accion == 1){
      this.idResponsableActual = this.usuariosOnlineActivos[index].id_responsable;
      this.nombreResponsableActual = this.usuariosOnlineActivos[index].fullname;
      this.usuarioResponsableActual = this.usuariosOnlineActivos[index].usuario;
      this.nombreResponsable.setValue({
        nombre: this.usuariosOnlineActivos[index].fullname,
        id: this.usuariosOnlineActivos[index].id_responsable
      });
      this.usuarioResponsable.setValue(this.usuariosOnlineActivos[index].usuario);
    }
  }

  /**
   * @param id ID del responsable a vincular en la cuenta.
   * @param index Indice del array solicitudesSinResponsableUsuarioOnline[] donde se insertarán
   *              los datos temporalmente.
   * @param responsable Nombre del responsable para mostrarlo en la vista.
   *
   * Almacena el nuevo ID y Nombre a asignar.
   */
  public setIdUsuarioOnline(id,index,responsable){
    this.nombreResponsable.setValue({
      nombre: responsable,
      id: id
    });
    this.responsables = [];
  }

  /**
   * @param id ID del responsable al cual se le retirará el perfil.
   * @param usuario Usuario que será deshabilitado.
   *
   * Desvincula al usuario de un responsable. Para eliminarlo es necesario acceder a otro componente
   */
  public eliminarUsuarioActivo(id,usuario){
    let accion = "inhabilitarUsuario"
    this.usuariosOnlineActivos.forEach(usuario => {
      usuario.boton_acciones = 0;
    });
    this.inscripcionesRemotasService.accionesUsuarioActivo(id,'',usuario,'','','',accion).subscribe(
        response => {
          if(!response.error){
            $.NotificationApp.send("Success", 'The profile has been deleted.', 'bottom-right', '#06d5a1', 'success');
            $('#loader').show();
            this.tableLoaded = false; // "Elimina" la tabla de la vista
            this.usuariosOnlineActivos = [];
            this.getUsuariosActivos(true);
          }else{
            $.NotificationApp.send("ERROR",response.error, 'bottom-right', '#fa5c7c', 'error');
          }
        },
        error => {
          $.NotificationApp.send("ERROR",error, 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
    );
  }
}
