<title>Merge horses</title>
<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>

<div class="wrapper">
    <menu-lateral class="left-side-menu active"></menu-lateral>
    <div class="content-page">
        <div class="content"><!-- Topbar Start -->
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
            <div class="row justify-content-center mt-3">
                <div class="col-8">
                    <div class="card">
                        <div class="card-body">
                            <form class="needs-validation" (submit)="mergeHorsesManual()" [formGroup]="form" novalidate>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <div class="form-group">
                                            <input type="text" class="form-control" (keyup)="findHorses($event)" [matAutocomplete]="horse1" formControlName="horse1" required>
                                            <mat-autocomplete #horse1="matAutocomplete" [displayWith]="displayHorse.bind(this)" >
                                                <mat-option *ngFor="let horse of filtroCaballos()" [value]="horse.id_caballo" class="fila-autocomplete">
                                                    {{ horse.fullname ? horse.fullname : '-' }}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </div>
                                    </div>
                                    <div class="col-1 text-center">
                                        <i class="mdi mdi-arrow-left" style="font-size: 1.5rem;"></i>
                                    </div>
                                    <div class="col-5">
                                        <div class="form-group">
                                            <input type="text" class="form-control" (keyup)="findHorses($event)" [matAutocomplete]="horse2" formControlName="horse2" required>
                                            <mat-autocomplete #horse2="matAutocomplete" [displayWith]="displayHorse.bind(this)" >
                                                <mat-option *ngFor="let horse of filtroCaballos()" [value]="horse.id_caballo" class="fila-autocomplete">
                                                    {{ horse.fullname ? horse.fullname : '-' }}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </div>
                                    </div>
                                    <div class="col-11">
                                        <div class="form-group text-right">
                                            <button type="submit" class="btn btn-success" [disabled]="form.disabled">
                                                <div *ngIf="form.disabled" class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                Merge
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
