<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page p-print-0">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
            <div class="row w-100 mx-0 mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <form (ngSubmit)="copiar()" [formGroup]="form">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label>From</label>
                                            <select name="show" id="show" class="form-control" required formControlName="from">
                                                <option value=""></option>
                                                <option [value]="show.id" *ngFor="let show of fromShows">{{ show.nombre }} - {{show.inicio | date:'y'}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label>To</label>
                                            <select name="show" id="show" class="form-control" required formControlName="to">
                                                <option value=""></option>
                                                <option [value]="show.id" *ngFor="let show of toShows">{{ show.nombre }} - {{ show.inicio | date:'y'}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Items to copy</label>
                                            <div class="row" style="padding-right: 15px; padding-left: 15px;">
                                                <input type="checkbox" name="classes" id="classes" class="d-none" formControlName="classes">
                                                <label for="classes" class="col text-center m-0 p-0">
                                                    <div class="item">Classes</div>
                                                </label>
                                                <input type="checkbox" name="divisions" id="divisions" class="d-none" formControlName="divisions">
                                                <label for="divisions" class="col text-center m-0 p-0">
                                                    <div class="item">Divisions</div>
                                                </label>
                                                <input type="checkbox" name="prizeMoneyDistributions" id="prizeMoneyDistributions" class="d-none" formControlName="prizeMoneyDistributions">
                                                <label for="prizeMoneyDistributions" class="col text-center m-0 p-0">
                                                    <div class="item">PM Distributions</div>
                                                </label>
                                                <input type="checkbox" name="billingItems" id="billingItems" class="d-none" formControlName="billingItems">
                                                <label for="billingItems" class="col text-center m-0 p-0">
                                                    <div class="item">Billing items</div>
                                                </label>
                                                <input type="checkbox" name="showSetting" id="showSetting" class="d-none" formControlName="showSetting">
                                                <label for="showSetting" class="col text-center m-0 p-0">
                                                    <div class="item">Show Setting</div>
                                                </label>
                                            </div>
                                        </div>
                                        <!--ul class="list-group">
                                            <li class="list-group-item list-group-item-primary">Classes</li>
                                            <li class="list-group-item list-group-item-secondary">Billing items</li>
                                            <li class="list-group-item list-group-item-success">Show Setting</li>
                                        </ul-->
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-success" type="submit">Accept</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
