import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConcursoService } from '../services/concurso.service';
import { PruebaService } from '../services/prueba.service';
declare var $: any;

@Component({
  selector: 'app-fei-show',
  templateUrl: './fei-show.component.html',
  styleUrls: ['./fei-show.component.css']
})
export class FeiShowComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public concursos = [];
  public show: any;
  public pruebas: any[];
  public classes: boolean = false;
  public saving: boolean = false;
  public error: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private concursoService: ConcursoService,
    private pruebaService: PruebaService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.concursos = [];
    this.show = {};
    this.pruebas = [];
    this.error = false;
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getShowInfo();
  }

  ngAfterViewInit(): void {
    $("#progressbarwizard").bootstrapWizard({
      onTabShow:function(t,r,a){
        var o=(a+1)/r.find("li").length*100;
        $("#progressbarwizard").find(".bar").css({width:o+"%"})
      }
    });
  }

  private getShowInfo(): void{
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          if(response.concurso.idOficial){
            this.getPruebasFei(response.concurso.idOficial);


            // const index = this.concursos.findIndex(c => c.show_code == response.concurso.idOficial);
            // if(index > -1){
            //   this.show = this.concursos[index];
            //   this.getPruebasFei(response.concurso.idOficial);
            //   this.concursoService.saveFeiShow(this.show, this.idConcurso).toPromise().catch(reason => {
            //     $.NotificationApp.send("Error", reason.error, 'bottom-right', '#fa5c7c', 'error');
            //     console.log(reason.error);
            //     $('#loader-fei').hide();
            //   });
            // } else{
            //   this.error = true;
            //   $('#loader-fei').hide();
            // }
          } else{
            this.error = true;
            $('#loader-fei').hide();
          }
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader-fei').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the show info.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  // private getConcursosFei(): void{
  //   $('#loader-fei').show();
  //   this.concursoService.getConcursosFei().subscribe(
  //     response => {
  //       this.concursos = response;
  //       this.getShowInfo();
  //     },
  //     error => {
  //       console.log(error);
  //       $.NotificationApp.send("Error", 'It was not possible to consult the shows list.', 'bottom-right', '#fa5c7c', 'error');
  //       $('#loader-fei').hide();
  //     }
  //   );
  // }

  public getPruebasFei(idConcurso): void{
    console.log('Loading Classes...');
    this.classes = true;
    this.pruebaService.getPruebasFei(idConcurso).subscribe(
      response => {
        this.pruebas = [];
        for(const pruebas of response){
          this.pruebas = this.pruebas.concat(pruebas);
        }
        let element: HTMLElement = document.getElementById('step2') as HTMLElement;
        if(element) element.click();
        $('#loader-fei').hide();

      },
      error => {
        $('#loader-fei').hide();
        $.NotificationApp.send("Error", 'It was not possible to consult the classes list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public saveClasses(): void{
    this.saving = true;
    $('#loader').show();
    this.pruebaService.saveFeiClasses(this.pruebas, this.idConcurso).subscribe(
      response => {
        if(!response.error){
          let element: HTMLElement = document.getElementById('step3') as HTMLElement;
          element.click();
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to import the classes.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
  }
}
