export const traducciones = {
    '"Posicion"': "Rank",
    '"Cucarda"': '"Horse Number"',
    '"FEM"': '"Usef id"',
    '"Jinete"': '"Rider"',
    '"Caballo"': '"Horse"',
    '"Propietario"': '"Owner"',
    '"Entrenador"': '"Trainer"',
    '"No Entrada"': '"Entry"',
    '"Faltas"': '"Faults"',
    '"Tiempo"': '"Time"',
    '"Premio"': '"Prize"'
}