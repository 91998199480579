<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
	<div class="sk-cube-grid ed">
		<div class="sk-cube sk-cube1"></div>
		<div class="sk-cube sk-cube2"></div>
		<div class="sk-cube sk-cube3"></div>
		<div class="sk-cube sk-cube4"></div>
		<div class="sk-cube sk-cube5"></div>
		<div class="sk-cube sk-cube6"></div>
		<div class="sk-cube sk-cube7"></div>
		<div class="sk-cube sk-cube8"></div>
		<div class="sk-cube sk-cube9"></div>
	</div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <div class="row mt-4 justify-content-center m-print-0">
          <div class="col-10 p-print-0">
            <div class="card m-print-0">
              <div class="card-body p-print-0">
                <div class="row">
                  <div class="col-8">
                    <div class="form-group m-0">
                      <select class="form-control" (change)="seleccionarPrueba($event)">
                        <option value="" selected disabled>Select a Class</option>
                        <option *ngFor="let prueba of pruebas" [value]="prueba.ipc">{{ prueba.numero }} - {{ prueba.nombre }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-4">
                    <button type="button" class="btn btn-info" [disabled]="!pruebaSeleccionada" (click)="guardarFirebase()">Sync to App</button>
                  </div>
                  <div class="col-12 mt-3">                                           
                    <form class="row row-cols-lg-auto g-3 align-items-center" [formGroup]="form">
                      <div class="col-2">
                        <input type="number" class="form-control" placeholder="Entry 1" required formControlName="entry1">
                      </div>
                      <div class="col-2">
                        <input type="number" class="form-control" placeholder="Entry 2"  required formControlName="entry2">
                      </div>
                      <div class="col-1">
                          <button type="button" class="btn btn-primary" (click)="orderSwap()">Swap orders</button>
                      </div>
                    </form>
                  </div>
                  <div class="col-12 mt-2" *ngIf="binomios.length == 0 && pruebaSeleccionada">
                    <div class="alert alert-success" role="alert">
                        <strong>Success - </strong> All entries have an assigned entry number!
                    </div>
                  </div>
                  <div class="col-12 mt-2" *ngIf="binomios.length > 0">
                    <button type="button" style="padding: .25rem .45rem; font-size: .65rem;" class="btn btn-primary btn-rounded mt-1 mr-2" *ngFor="let binomio of binomios">{{ binomio.cucarda }} {{ binomio.caballo }} - {{ binomio.nombreJinete }} {{ binomio.apellidosJinete }}</button>
                    <!--span class="badge badge-primary mt-1 mr-2" *ngFor="let binomio of binomios">{{ binomio.cucarda }} {{ binomio.caballo }} - {{ binomio.nombreJinete }} {{ binomio.apellidosJinete }}</span-->
                  </div>
                  <div class="col-12 mt-2" *ngIf="binomiosAsignados.length > 0">
                    <table class="table table-sm m-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Entry</th>
                          <th>Horse</th>
                          <th>Rider</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let binomio of binomiosAsignados; let i = index;">
                          <td>{{ binomio.entrada }}</td>
                          <td><input type="number" id="entry-{{ i }}" class="form-control" (change)="asignarEntry($event, i)"></td>
                          <td>{{ binomio.horse }}</td>
                          <td>{{ binomio.rider }} {{ binomio.lastname }}</td>
                          <td class="table-action">
                            <a href="javascript: void(0);" class="action-icon" *ngIf="binomio.ibpc" (click)="quitarEntry(i)">
                              <i class="mdi mdi-delete"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>