import { Component, OnInit } from '@angular/core';
import { PrintService } from '../services/print.service';
import { ActivatedRoute } from '@angular/router';
import { EntriesService } from '../services/entries.service';
import * as converter  from 'number-to-words';

@Component({
  selector: 'app-print-checks-format',
  templateUrl: './print-checks-format.component.html',
  styleUrls: ['./print-checks-format.component.css']
})
export class PrintChecksFormatComponent implements OnInit {
  private idConcurso: string;
  private entries: string[];
  public checks: any[];

  constructor(private printService: PrintService, private route: ActivatedRoute, private entriesService: EntriesService) {
    this.idConcurso = this.route.snapshot.paramMap.get('idConcurso');
    this.entries = this.route.snapshot.paramMap.get('entries').split(',');
  }

  ngOnInit() {
    this.getChecks();
  }

  private async getChecks(){
    if(this.route.snapshot.paramMap.get('entries') == 'all'){
      const checks = await this.entriesService.getCheck(this.idConcurso, 'all').toPromise();
      this.checks = checks.cheque;
      setTimeout(() => { this.printService.onDataReady() }, 500)
    } else{
      const checks = this.entries.map(e => this.entriesService.getCheck(this.idConcurso, e).toPromise());
      Promise.all(checks).then(v => {
        this.checks = v.map(c => c.cheque);
        setTimeout(() => { this.printService.onDataReady() }, 500);
      });
    }
  }

  public toWords(n: string): string{
    const decimals: string = n.replace(/.+\./,'');
    const number: number = parseFloat(n.replace(/,/g,''));
    return `${converter.toWords(number)} and ${decimals}/100`;
  }
}
