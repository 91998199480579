<div class="card">
  <div class="card-body p-2">
    <ng-container *ngIf="!loading;else cardPlaceholder">
      <h4 class="header-title">Pay with</h4>
      <ul class="list-group mb-3">
        <ng-container *ngFor="let customer of customers">
          <ng-container *ngIf="customer?.paymentMethods.length">
            <!-- Stripe Customer Name -->
            <li class="list-group-item px-2 py-1 active" *ngIf="customer?.name">{{ customer?.name }}</li>
            <ng-container *ngFor="let pm of customer?.paymentMethods">
              <li class="list-group-item d-flex px-2 py-1">
                <input class="form-check-input me-1" type="radio" [disabled]="processingPayment" name="pm-group" [value]="pm?.id" [id]="pm?.id" (change)="paymentMethodSelected(pm?.id, customer?.id)">
                <label class="form-check-label d-flex" [for]="pm?.id">
                  <div class="mr-1 me-1" [ngSwitch]="pm?.card?.brand">
                    <ng-container *ngSwitchCase="'amex'"><img src="assets/images/stripe/amex.png" alt="amex" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchCase="'discover'"><img src="assets/images/stripe/discover.png" alt="discover" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchCase="'jcb'"><img src="assets/images/stripe/jcb.png" alt="jcb" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchCase="'mastercard_debit'"><img src="assets/images/stripe/mastercard_debit.png" alt="mastercard_debit" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchCase="'mastercard_prepaid'"><img src="assets/images/stripe/mastercard_prepaid.png" alt="mastercard_prepaid" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchCase="'mastercard'"><img src="assets/images/stripe/mastercard.png" alt="mastercard" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchCase="'unionpay'"><img src="assets/images/stripe/unionpay.png" alt="unionpay" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchCase="'visa_debit'"><img src="assets/images/stripe/visa_debit.png" alt="visa_debit" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchCase="'visa'"><img src="assets/images/stripe/visa.png" alt="visa" style="max-width: 1.5rem; max-height: 1.5rem;"></ng-container>
                    <ng-container *ngSwitchDefault><i class="mdi mdi-credit-card"></i></ng-container>
                  </div>
                  <span class="mr-1 me-1">•⁠ ⁠•⁠ ⁠•⁠ ⁠•⁠ </span>
                  <span class="mr-3 me-3">{{ pm?.card?.last4 }}</span>
                  <span class="mr-3 me-3" style="min-width: 200px;">{{ pm?.billing_details?.name||'' }}</span>
                  <span class="mr-3 me-3" style="text-transform: capitalize;">{{ pm?.card?.funding }} Card</span>
                  <div class="mr-3 me-3">{{ pm?.card?.exp_month }}/{{ pm?.card?.exp_year }}</div>
                </label>
              </li>
            </ng-container>
          </ng-container>
        </ng-container>
        <li class="list-group-item d-flex px-2 py-1" *ngIf="reining">
          <input class="form-check-input me-1" type="radio" [disabled]="processingPayment" name="pm-group" value="ach" id="pm_ach" (change)="paymentMethodSelected('ach')">
          <label class="form-check-label d-flex" for="pm_ach">
            <i class="mdi mdi-bank mr-1 me-1"></i> ACH Direct Debit
          </label>
        </li>
        <li class="list-group-item d-flex px-2 py-1">
          <input class="form-check-input me-1" type="radio" [disabled]="processingPayment" name="pm-group" value="new_card" id="pm_new_card" (change)="paymentMethodSelected('new_card')">
          <label class="form-check-label d-flex" for="pm_new_card">
            <i class="mdi mdi-credit-card-plus-outline mr-1 me-1"></i> Add new card
          </label>
        </li>
      </ul>
      <!--Stripe.js injects the Payment Element-->
      <!-- Agregar tarjeta nueva -->
      <div class="row" [hidden]="!paymentMethod || (paymentMethod && paymentMethod != 'ach' && paymentMethod != 'new_card')">
        <div class="col-12" [hidden]="customerSelected">
          <div class="form-group">
            <label for="name" class="Label">Stripe Customer</label>
            <select type="text" class="form-control Input" id="customer-selected" name="customer-selected" style="padding: .45rem .9rem;" (change)="selectStripeCustomer($any($event).target.value)" required>
              <option value="">Select a customer</option>
              <option *ngFor="let customer of customers" [value]="customer.id">{{ customer.name }}</option>
            </select>
          </div>
        </div>
        <div class="col-12" [hidden]="!customerSelected">
          <form id="payment-form" (ngSubmit)="submitPayment($event)" >
            <div class="form-group" *ngIf="paymentMethod == 'new_card'">
              <label for="name" class="Label">Carholder name</label>
              <input type="text" class="form-control Input" id="carholder-name" name="cardholder-name" placeholder="Carholder name" [(ngModel)]="cardHolderName" required>
            </div>
            <div id="payment-element" class="mb-3">
            </div>
            <div id="payment-message" class="hidden text-danger mb-3"></div>
            <div class="mb-3 text-center">
              <button type="submit" id="submit" class="btn btn-primary" [disabled]="(!paymentMethod || (paymentMethod && paymentMethod != 'ach' && paymentMethod != 'new_card')) || !validPaymentElement || processingPayment || !cardHolderName">
                <span class="loader mr-1 me-1" *ngIf="processingPayment"></span>
                Pay {{ total | currency }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Mostrar mientras no exista un formulario de payment intent -->
      <ng-container *ngIf="!paymentMethod || (paymentMethod && paymentMethod != 'ach' && paymentMethod != 'new_card')">
        <div id="payment-message-pm" class="hidden text-danger mb-3"></div>
        <p class="text-center mt-3">
          <button type="button" class="btn btn-primary" [disabled]="!paymentMethod || total == 0 || processingPayment" (click)="paymentIntentSelectedMethod()">
            <span class="loader mr-1 me-1" *ngIf="processingPayment"></span>
            Pay {{ total | currency }}
          </button>
        </p>
      </ng-container>
    </ng-container>
  </div>
</div>
<!--  <div *ngIf="!paymentMethod || paymentMethod != 'ach'">Card Payments include a 3% processing fee.</div> -->
<ng-template #cardPlaceholder>
  <h5 class="card-title placeholder-glow">
    <span class="placeholder col-6"></span>
  </h5>
  <p class="card-text placeholder-glow">
    <span class="placeholder col-7"></span>
    <span class="placeholder col-4"></span>
    <span class="placeholder col-4"></span>
    <span class="placeholder col-6"></span>
    <span class="placeholder col-8"></span>
  </p>
  <p class="text-center mt-3">
    <a href="javascript: void(0);" tabindex="-1" class="btn btn-primary disabled placeholder col-2"></a>
  </p>
  <div class="loader-container">
    <div class="paymentLoader"></div>
  </div>
</ng-template>
