import { Component, OnInit, Directive, QueryList, ViewChildren, ViewChild} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import {FormControl, Validators, Form,FormBuilder, FormGroup} from '@angular/forms';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service'


declare var jquery:any;
declare var $ : any;

@Component({
  selector: 'crear-usuario-inscripciones',
  templateUrl: './crear-usuario-inscripciones.component.html',
  styleUrls: ['./crear-usuario-inscripciones.component.css']
})
export class CrearUsuarioInscripcionesComponent implements OnInit {

  public idConcurso: string | boolean;
  public nombreConcurso: string;
  public solicitudesUsuarioOnline: any;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public loaded: boolean;
  public privilegios: number;

  constructor(
    private route: ActivatedRoute,
    private inscripcionesRemotasService: InscripcionesRemotasService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router
  ) {
    this.idConcurso = '';
    this.token = '';
    this.loaded = false;
    this.idUsuario = '';
    this.sessionData = {};
    this.solicitudesUsuarioOnline = [];
    this.nombreConcurso = this.authService.getNombreConcurso();
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso() && this.privilegios >= 300){
      this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    $('#loader').show();
    this.getUsuariosOnline();
  }

  public getUsuariosOnline(){
    this.inscripcionesRemotasService.getUsuariosOnline().subscribe(
      response => {
        this.solicitudesUsuarioOnline = response;
        this.loaded = true;
        $('#loader').hide();
        if(this.solicitudesUsuarioOnline.length == 0){
          $('#pendientes-0').fadeIn(1000);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  public accionesUsuarioOnline(id,nombre,correo){
    let accion = 'registrarUsuario';
    this.inscripcionesRemotasService.accionesUsuarioOnline(id,nombre,correo,accion).subscribe(
      response => {
        if(!response.error){
          $.NotificationApp.send("Success", 'The profile of '+nombre+' has been enabled. The payee was notified :).', 'bottom-right', '#06d5a1', 'success');
          this.getUsuariosOnline();
        };
      },
      error => {
        console.log(error);
      }
    );
  }

}
