<div class="row mt-3">
    <div class="col-6" *ngFor="let prueba of pruebas">
        <div class="card">
            <h5 class="card-header"><b>{{ prueba.numero }}</b> - {{ prueba.nombre }} ({{ prueba.ipc }})</h5>
            <div class="card-body">
                <p>InscritosFS: {{ prueba.inscritosFS }}</p>
                <p>InscritosMySQL: {{ prueba.inscritosSQL }}</p>
                <p>Diferencia: {{ prueba.diferencia }}</p>
                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Entry</th>
                            <th>Rider</th>
                            <th>Horse</th>
                            <th>Result1</th>
                            <th>Result1</th>
                            <th>Prize</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let diferencia of prueba.diferencias">
                            <td>{{ diferencia.posicion }}</td>
                            <td>{{ diferencia.entry }}</td>
                            <td>{{ diferencia.jinete }}</td>
                            <td>{{ diferencia.horse }}</td>
                            <td>{{ diferencia.result1 }}</td>
                            <td>{{ diferencia.result2 }}</td>
                            <td>{{ diferencia.premio||'' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>