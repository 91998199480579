import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ReportesService } from '../services/reportes.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var jquery:any;
declare var $: any;


@Component({
    selector: 'resumen-ejecutivo',
    templateUrl: 'resumen-ejecutivo.component.html',
    styleUrls: ['resumen-ejecutivo.component.css']
})

export class ResumenEjecutivoComponent implements OnInit {
    public idConcurso: string | boolean;
    public nombreConcurso: string;
    public jinetesInternacionales: any;
    public jinetesNacionales: any;
    public totalJinetes: any;
    public caballosCsi: any;
    public caballosJovenes: any;
    public caballosInternacionales: any;
    public caballosNacionales: any;
    public totalGlobal: any;
    public participantes: any;

    constructor(
        private authService: AuthService,
        private reporteService: ReportesService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.idConcurso = '';
        this.nombreConcurso = this.authService.getNombreConcurso();
        this.jinetesInternacionales = {};
        this.jinetesNacionales = {};
        this.totalJinetes = {};
        this.caballosCsi = {};
        this.caballosJovenes = {};
        this.caballosInternacionales = {};
        this.caballosNacionales = {};
        this.totalGlobal = {};
        this.participantes = {};
    }

    ngOnInit() {
        if(!this.authService.isLoggedIn()){
            this.authService.logOut();
            return;
        }
        if(this.authService.validarConcurso()){
            this.idConcurso = this.authService.validarConcurso();
        } else{
            this.router.navigate(['']);
            return;
        }
        this.getResumenEjecutivo();
    }

    public getResumenEjecutivo(){
        this.reporteService.getResumenEjecutivo(this.idConcurso).subscribe(
            response => {
                if(!response.error){
                    this.jinetesInternacionales = response.jinetesInternacionales;
                    this.jinetesNacionales = response.jinetesNacionales;
                    this.totalJinetes = response.totalJinetes;
                    this.caballosCsi = response.caballosCsi;
                    this.caballosJovenes = response.caballosJovenes;
                    this.caballosInternacionales = response.caballosInternacionales;
                    this.caballosNacionales = response.caballosNacionales;
                    this.totalGlobal = response.totalGlobal;
                    this.participantes = response.participantes;
                  } else{
                    $.gritter.add({title: "Error", text: response.message, class_name: "color danger"});
                    console.log(response.message);
                  }
            },
            error => {
                $.gritter.add({title: "Error", text: "No ha sido posible obtener la informacion del resumen.", class_name: "color danger"});
                console.log(error);
            }
        );
    }
}