<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid">
        <div class="row margin-top-35">
          <div class="col-12 padding-0">
            <div class="card">
              <div class="card-body padding-print">
                <!--h4 class="header-title mb-3 hidden-print"> Caja</h4-->
                <div id="basicwizard">
                  <!-- Pestañas (inicio) -->
                  <ul class="nav nav-pills bg-light nav-justified form-wizard-header mb-4 hidden-print">
                    <li class="nav-item">
                      <a href="#basictab1" id="sheetCharges" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2">
                        <i class="mdi mdi-cash-multiple mr-1"></i>
                        <span class="d-none d-sm-inline">Charges</span>
                      </a>
                    </li>
                    <li class="nav-item" (click)="limpiarAutobilling()">
                      <a href="#basictab2" id="sheetBillingMatrix" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2 active show">
                        <i class="mdi mdi-matrix mr-1"></i>
                        <span class="d-none d-sm-inline">Billing Matrix</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#billingMultipleEntries" id="sheetBillingMatrix" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2">
                        <i class="mdi mdi-format-list-bulleted-square mr-1"></i>
                        <span class="d-none d-sm-inline">Add Billing Item to Multiple Entries</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#basictab3" id="sheetBillingMatrix" data-toggle="tab" class="nav-link rounded-0 pt-2 pb-2">
                        <i class="mdi mdi-robot mr-1"></i>
                        <span class="d-none d-sm-inline">Mass Billing</span>
                      </a>
                    </li>
                  </ul>
                  <!-- Pestañas (fin) -->

                  <div class="tab-content b-0 mb-0">
                    <div class="tab-pane" id="basictab1">
                      <!--div class="col-12 hidden-print" style="margin-top:-30px;margin-bottom:10px;">
                        <button onclick="window.print()" class="btn btn-dark btn-rounded float-right">
                          <i class="mdi mdi-printer"></i>
                        </button>
                      </div-->
                      <div class="tab row">
                        <div class="col-lg-6 col-md-12 col-sm-12 hidden-print table-responsive" style="border-right: 1px solid #ddd;">
                          <table class="table table-hover table-sm">
                            <thead>
                              <tr>
                                <th><b>Concept</b></th>
                                <th><b>Unit Cost</b></th>
                                <th>Online</th>
                                <th>AutoBilling</th>
                                <th>Feed</th>
                                <th>Reservation</th>
                                <th>Sales Tax</th>
                                <th colspan="2"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="bg-dark" [formGroup]="formConcepto">
                                <td>
                                  <input type="text" class="form-control input-s" formControlName="nombre"
                                    [ngClass]="{'no-valido': formConcepto.get('nombre').invalid && (formConcepto.get('nombre').touched || formConcepto.get('nombre').dirty)}">
                                </td>
                                <td>
                                  <input type="number" class="form-control input-s" formControlName="monto"
                                    [ngClass]="{'no-valido': formConcepto.get('monto').invalid && (formConcepto.get('monto').touched || formConcepto.get('monto').dirty)}">
                                </td>
                                <td>
                                  <div class="custom-control custom-checkbox d-flex">
                                    <input id="online" type="checkbox" class="custom-control-input"
                                      formControlName="online">
                                    <label class="custom-control-label" for="online"></label>
                                  </div>
                                </td>
                                <td>
                                  <div class="custom-control custom-checkbox d-flex">
                                    <input id="autobilling" type="checkbox" class="custom-control-input"
                                      formControlName="autobilling">
                                    <label class="custom-control-label" for="autobilling"></label>
                                  </div>
                                </td>
                                <td>
                                  <div class="custom-control custom-checkbox d-flex">
                                    <input id="feed" type="checkbox" class="custom-control-input"
                                      formControlName="feed">
                                    <label class="custom-control-label" for="feed"></label>
                                  </div>
                                </td>
                                <td>
                                  <div class="custom-control custom-checkbox d-flex">
                                    <input id="reservation" type="checkbox" class="custom-control-input"
                                      formControlName="reservation">
                                    <label class="custom-control-label" for="reservation"></label>
                                  </div>
                                </td>
                                <td>
                                  <div class="custom-control custom-checkbox d-flex">
                                    <input id="salesTax" type="checkbox" class="custom-control-input"
                                      formControlName="salesTax">
                                    <label class="custom-control-label" for="salesTax"></label>
                                  </div>
                                </td>
                                <td colspan="2" class="table-action text-center">
                                  <a href="javascript: void(0);" class="action-icon" (click)="agregarConcepto()"> <i class="mdi mdi-plus-circle text-white"></i></a>
                                </td>
                              </tr>
                              <tr *ngFor="let concepto of conceptos;let index = index;" [ngClass]="{'warning': concepto.id == idConceptoSeleccionado}">
                                <td *ngIf="!concepto.editar" style="cursor: pointer;" (click)="detalleConcepto(concepto.id, concepto.nombre)">
                                  {{ concepto.nombre }}
                                </td>
                                <td *ngIf="concepto.editar" style="cursor: pointer;">
                                  <input type="text" placeholder="Ingrese el nuevo nombre del concepto"id="nuevoconcepto" class="form-control">
                                </td>
                                <td *ngIf="!concepto.editar" style="cursor: pointer;" (click)="detalleConcepto(concepto.id, concepto.nombre)">
                                  {{ concepto.monto | currency }}
                                </td>
                                <td *ngIf="concepto.editar" style="cursor: pointer;">
                                  <input type="number" class="form-control" [value]="concepto.monto" id="editar-monto">
                                </td>
                                <td *ngIf="!concepto.editar" >
                                  <mat-icon *ngIf="concepto.online">language</mat-icon>
                                </td>
                                <td *ngIf="concepto.editar">
                                  <div class="custom-control custom-checkbox d-flex">
                                      <input type="checkbox" class="custom-control-input" id="editar-online" [checked]="concepto.online">
                                      <label class="custom-control-label" for="editar-online"></label>
                                  </div>
                                </td>
                                <td *ngIf="!concepto.editar">
                                  <i *ngIf="concepto.autobilling" class="mdi mdi-robot"></i>
                                </td>
                                <td *ngIf="concepto.editar">
                                  <div class="custom-control custom-checkbox d-flex">
                                      <input type="checkbox" class="custom-control-input" id="editar-auto" [checked]="concepto.autobilling">
                                      <label class="custom-control-label" for="editar-auto"></label>
                                  </div>
                                </td>
                                <td *ngIf="!concepto.editar">
                                  <i *ngIf="concepto.feed" class="mdi mdi-food-apple"></i>
                                </td>
                                <td *ngIf="concepto.editar">
                                  <div class="custom-control custom-checkbox d-flex">
                                      <input type="checkbox" class="custom-control-input" id="editar-feed" [checked]="concepto.feed">
                                      <label class="custom-control-label" for="editar-feed"></label>
                                  </div>
                                </td>
                                <td *ngIf="!concepto.editar">
                                  <i *ngIf="concepto.reservation" class="mdi mdi-account"></i>
                                </td>
                                <td *ngIf="concepto.editar">
                                  <div class="custom-control custom-checkbox d-flex">
                                      <input type="checkbox" class="custom-control-input" id="editar-reservation" [checked]="concepto.reservation">
                                      <label class="custom-control-label" for="editar-reservation"></label>
                                  </div>
                                </td>
                                <td *ngIf="!concepto.editar">
                                  <i *ngIf="concepto.salesTax" class="mdi mdi-cash"></i>
                                </td>
                                <td *ngIf="concepto.editar">
                                  <div class="custom-control custom-checkbox d-flex">
                                      <input type="checkbox" class="custom-control-input" id="editar-salesTax" [checked]="concepto.salesTax">
                                      <label class="custom-control-label" for="editar-salesTax"></label>
                                  </div>
                                </td>
                                <td class="table-action">
                                  <a href="javascript: void(0);" class="action-icon" *ngIf="concepto.editar == 0" (click)="abrirFormularioEditarConcepto(index, 1)"> <i class="mdi mdi-pencil"></i></a>
                                  <a href="javascript: void(0);" class="action-icon" *ngIf="concepto.editar == 1" (click)="editarConcepto(concepto.id)"> <i class="mdi mdi-content-save text-success"></i></a>
                                </td>
                                <td class="table-action">
                                  <a href="javascript: void(0);" class="action-icon" *ngIf="concepto.editar == 0 && privilegios >= 300" (click)="eliminarConcepto(concepto.id, concepto.monto, concepto.nombre, concepto.id_concepto)"> <i class="mdi mdi-delete"></i></a>
                                  <a href="javascript: void(0);" class="action-icon" *ngIf="concepto.editar == 1" (click)="abrirFormularioEditarConcepto(index, 0)"> <i class="mdi mdi-close-circle text-danger"></i></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12"  [hidden]="!idConceptoSeleccionado">
                          <table class="table table-hover w-100" id="detalles">
                            <caption id="tablecaption"></caption>
                            <thead>
                              <tr>
                                <th colspan="6" class="text-center">
                                  <b id="tableTitle"><span>Details</span>: {{ nombreConceptoSeleccionado }}</b>
                                </th>
                              </tr>
                              <tr class="text-center">
                                <th><b>Entry</b></th>
                                <th><b>Trainer</b></th>
                                <th><b>Horse</b></th>
                                <th><b>Notes</b></th>
                                <!--<th><b>Created</b></th>-->
                                <th><b>Qty</b></th>
                                <th><b>Amount</b></th>
                              </tr>
                            </thead>
                            <tbody>

                            </tbody>
                            <tfoot>
                              <tr>
                                <td colspan="4" class="text-right">TOTAL:</td>
                                <td>{{ totalUnidadesConcepto }}</td>
                                <td>{{ totalMontoConcepto | currency }}</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane active show" id="basictab2">
                      <div class="row">
                        <div class="tab">
                          <div>
                            <select class="form-control hidden-print m-0" style="max-width: 40%; margin-bottom: 2%;margin-top: 15px;" [(ngModel)]="entrenadorSeleccionado" [ngModelOptions]="{standalone: true}" (change)="cambioEntrenador()">
                              <option value="" selected>All</option>
                              <option *ngFor="let entrenador of entrenadores" [value]="entrenador.idEntrenador">
                                {{ entrenador.nombre }}</option>
                            </select>
                            <div class="card mt-3" *ngIf="entriesBilling.length > 0">
                              <div class="card-body">
                                <div class="w-100 p-2 d-none d-print-flex mb-2" style="justify-content: space-between;">
                                  <div style="flex: 1;" class="text-left">
                                    <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                                  </div>
                                  <div>
                                    <h2>{{ nombreConcurso }}</h2>
                                    <h4 style="text-align:center">Billing Matrix</h4>
                                  </div>
                                  <div style="flex: 1;" class="text-right">
                                    <img [src]="header2" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                                  </div>
                                </div>
                                <h3 class="mb-2 d-none d-print-block">{{ nombreEntrenadorBillingMatrix }}</h3>
                                <div class="row d-print-none">
                                  <div class="col-12 mb-2">
                                    <button [ngClass]="{'active': filtro}" class="btn btn-outline-info m-1" (click)="filtrarBillingMatrix()">Filter</button>
                                  </div>
                                  <div class="col-12 mb-2">
                                    <button *ngFor="let concepto of conceptosBillingMatrix()" [ngClass]="{'active': filtroConceptosEntrenador[concepto.id]}" (click)="getConceptoMatrix(concepto.id);" type="button" class="btn btn-outline-primary m-1">{{ concepto.nombre }}</button>
                                  </div>
                                </div>
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th style="vertical-align: middle;">Horse</th>
                                      <ng-container *ngFor="let concepto of conceptos">
                                        <th style="vertical-align: middle;" *ngIf="filtroConceptosEntrenador[concepto.id]">{{ concepto.nombre }}</th>
                                      </ng-container>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let entry of filtroEntriesEntrenador()">
                                      <td style="vertical-align: middle;">
                                        <span *ngIf="!isTrainer(entry.entry);else enlaceEntrenador">{{ entry.entry }} - {{ entry.caballo }}</span>
                                        <ng-template #enlaceEntrenador>
                                          <span><a href="javascript:void(0)" [routerLink]="['/trainer-account', entry.idEntrenador]">{{ entry.entry }} - {{ entry.caballo }}</a></span>
                                        </ng-template>
                                        <span [ngSwitch]="entry.ambito" >
                                          <span *ngSwitchCase="'fei'"><img style="max-width: 30px;" src="assets/images/icons/fei.svg" alt="FEI"></span>
                                          <span *ngSwitchCase="'usef'"><img style="max-width: 20px;" src="assets/images/icons/usef.svg" alt="Usef"></span>
                                          <span *ngSwitchDefault></span>
                                        </span>
                                      </td>
                                      <ng-container *ngFor="let concepto of conceptos">
                                        <td style="vertical-align: middle;" *ngIf="filtroConceptosEntrenador[concepto.id]">
                                          <div class="input-group group-matrix">
                                            <div class="input-group-prepend">
                                              <button title="Charged to Horse" class="btn btn-light" type="button" tabindex="-1">
                                                <!--span class="d-none d-print-inline">P</span--> {{ entry.conceptos[concepto.id]?.qtyEntry | number : '1.0-2' }}</button>
                                            </div>
                                            <span class="mr-2 d-none d-print-block"></span>
                                            <input class="form-control text-center d-print-none" type="text" (keypress)="validate($event)" (change)="agregarCargoMatrix($event, concepto.id, entry.entry, entry.idEntrenador)">
                                            <!--div class="input-group-append">
                                              <button title="Charged to Trainer" class="btn btn-light" type="button" tabindex="-1">T
                                                {{ entry.conceptos[concepto.id].qtyEntrenador }}</button>
                                            </div-->
                                          </div>
                                        </td>
                                      </ng-container>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th style="vertical-align: middle;">Total</th>
                                      <ng-container *ngFor="let concepto of conceptos">
                                        <th *ngIf="filtroConceptosEntrenador[concepto.id]">
                                          <div class="input-group group-matrix">
                                            <div class="input-group-prepend">
                                              <button title="Charged to Horse" class="btn btn-light" type="button" tabindex="-1"><!--span class="d-none d-print-inline">P</span--> {{ totalConcepto(concepto.id) | number : '1.0-2' }}</button>
                                            </div>
                                            <span class="mr-2 d-none d-print-block"></span>
                                            <input type="text" class="form-control d-print-none" disabled style="opacity: 0;">
                                            <!--div class="input-group-append">
                                              <button title="Charged to Trainer" class="btn btn-light" type="button" tabindex="-1">T
                                                {{ totalConceptoEntrenador(concepto.id) }}</button>
                                            </div-->
                                          </div>
                                        </th>
                                      </ng-container>
                                    </tr>
                                  </tfoot>
                                </table>
                                <div class="row d-none d-print-flex mb-2 mt-3">
                                  <div class="col-12 text-center">
                                    <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'" onload="this.style.display='-webkit-inline-box'">
                                  </div>
                                </div>
                                <div class="poweredBy d-none d-print-block">Powered by Equestrian Digital <img src="assets/images/logo.png"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane" id="billingMultipleEntries">
                      <div class="row">
                        <div class="col-2">
                          <div class="form-group">
                            <select class="form-control form-control-sm" [(ngModel)]="selectedConcept" [disabled]="saving">
                              <option value="">Select billing item</option>
                              <option [value]="concepto?.id" *ngFor="let concepto of conceptos">{{ concepto?.nombre }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="form-group">
                            <input type="text" class="form-control form-control-sm" id="multipleEntries-entry" placeholder="Type entry" (change)="addEntry($event)" [disabled]="saving">
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <input type="text" class="form-control form-control-sm" placeholder="Add note (to all)" [(ngModel)]="chargeNotes" [disabled]="saving">
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="form-group">
                            <button type="button" class="btn btn-sm btn-success" [disabled]="!selectedConcept || selectedEntries.length == 0 || saving" (click)="addBillingItemToEntries()">
                              <span class="loader" *ngIf="saving"></span>
                              Save
                            </button>
                          </div>
                        </div>

                        <div class="col-12">
                          <ul class="selectedEntries">
                            <li class="list-group-item d-flex justify-content-between" *ngFor="let entry of selectedEntries;let i = index;">
                              <span>
                                <b [ngClass]="{'text-success': isDuplicatedEntry(entry?.entry)}">{{ entry?.entry }}</b> - {{ entry?.caballo }}
                              </span>
                              <a href="javascript:;" (click)="removeEntry(i)">
                                <i class="mdi mdi-close"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane" id="basictab3">
                      <div class="row pt-1">
                        <div class="col-12">
                          <form [formGroup]="formAutobilling" id="formAutobilling" class="needs-validation" novalidate>
                            <div class="row">
                              <div class="panel" [ngClass]="{'col-6': this.formAutobilling.get('tipo').value == 'all', 'col-4': this.formAutobilling.get('tipo').value == 'class'}">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="form-group">
                                      <label>Charge</label>
                                      <select class="form-control" formControlName="concepto" (change)="filtroEntriesAutobilling()">
                                        <option value="">Select a charge</option>
                                        <option [value]="concepto.id" *ngFor="let concepto of conceptosBillingMatrix()">{{ concepto.nombre }}</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="panel" [ngClass]="{'col-6': this.formAutobilling.get('tipo').value == 'all', 'col-4': this.formAutobilling.get('tipo').value == 'class'}">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-6 text-center">
                                        <input class="d-none" type="radio" name="tipo" value="all" formControlName="tipo">
                                        <div class="autobiling-option" (click)="seleccionarTipoAutobilling('all')">
                                          All horses in show
                                        </div>
                                      </div>
                                      <div class="col-6 text-center">
                                        <input class="d-none" type="radio" name="tipo" value="class" formControlName="tipo">
                                        <div class="autobiling-option" (click)="seleccionarTipoAutobilling('class')">
                                          All horses in a class
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="panel" [ngClass]="{'col-0': this.formAutobilling.get('tipo').value == 'all', 'col-4': this.formAutobilling.get('tipo').value == 'class'}">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="form-group">
                                      <label>Class</label>
                                      <select class="form-control" formControlName="ipc" (change)="filtroEntriesAutobilling()">
                                        <option value="">Select a class</option>
                                        <option [value]="prueba.ipc" *ngFor="let prueba of pruebasAutobilling">{{ prueba.numero }} - {{ prueba.nombre }}</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="card">
                                  <div class="card-body">
                                    <button type="button" class="btn btn-success mb-3" (click)="applyAutobillingCharges()" *ngIf="entriesAutobilling.length > 0">Apply charge</button>
                                    <table class="table table-striped table-hover mb-0" *ngIf="entriesAutobilling.length > 0; else no_entries">
                                      <thead>
                                        <th>Entry</th>
                                        <th>Horse</th>
                                        <th>Current Qty</th>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let entry of entriesAutobilling">
                                          <td>{{ entry.entry }}</td><!-- applyCharge -->
                                          <td>{{ entry.horse }}</td>
                                          <td>{{ entry.qty }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <ng-template #no_entries>
                                      <h3 class="m-0 text-center">No entries for this selection</h3>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div> <!-- tab-content -->
                </div> <!-- end #basicwizard-->
              </div> <!-- end card-body -->
            </div> <!-- end card-->
          </div> <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</div>
