import { Component, NgModule, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { EntriesService } from '../services/entries.service';
import { PrintService } from '../services/print.service';
import { PruebaService } from '../services/prueba.service';
import { CurrencyPipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
declare let $: any;

@Component({
  selector: 'app-print-checks',
  templateUrl: './print-checks.component.html',
  styleUrls: ['./print-checks.component.css'],
  providers: [CurrencyPipe]
})
export class PrintChecksComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public checks: any[];
  public total: any;

  constructor(private router: Router, private authService: AuthService, private entriesService: EntriesService, private printService: PrintService, private pruebaService: PruebaService, private cp: CurrencyPipe) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.checks = [];
    this.total = 0;
  }

  ngOnInit() {
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    $('#loader').show();
    this.getAllChecks();
  }

  private async getAllChecks(){
    await this.entriesService.getAllChecks(this.idConcurso).toPromise().then(
      response => {
        if(!response.error){
          this.checks = response.cheques;
          console.log(this.checks);
          this.total = this.checks.reduce((a, b) => {
            return a + parseFloat(b.amount.replace(/,/g, ''));
          }, 0).toFixed(2);
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the checks list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );

  }

  public printCheck(entry): void{
    this.printService.printChecks(this.idConcurso, [entry]);
  }

  public printAllChecks(): void{
    //const entries = this.checks.map(c => c.entry);
    this.printService.printChecks(this.idConcurso, ['all']/*, entries*/);
  }

  public exportCsv(){
    let csv = `"Entry", "Horse", "Payee", "Amount", "#"`+"\r\n";
    csv += this.checks.map(c => {
      return `"${ c.entry }", "${c.horse}", "${c.payee}", "${this.cp.transform(this.toNumber(c.amount))}", "${c.number}"`;
    }).join("\r\n");
    var blob = new Blob([csv],{type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "Checks.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }
  public exportExcel() {
    $('#loader').show();
    //this.mostrarReporteSeleccionado('getPremiosExcel');
        //this.filasReporte = this.checks;
        var wb = { SheetNames: [], Sheets: {} };
        let printCheques = this.checks;//.splice(0, 1);
        console.log(this.checks);
        printCheques =this.checks.map(({ id, ...item }) => item);
        let totalObj = {payee:'',horse:'Total',amount:this.total,number:''};
        printCheques.push(totalObj);
        var ws = XLSX.utils.json_to_sheet(printCheques);

        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Checks_" + this.nombreConcurso + ".xlsx");
        $('#loader').hide();
  }
  public s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  public toNumber(n): number{
    return parseFloat(n.replace(/,/g,''));
  }

  public guardarNumeroCheque(e): void{
    const value = parseInt(e.target.value);
    if(!isNaN(value)){
      $('#loader').show();
      const promesas: Promise<any>[] = this.checks.map((c, i) => this.pruebaService.guardarNumeroCheque(this.idConcurso, c.entry, value+i, c.amount, this.idUsuario).toPromise().then(response => {
        if(response.error){
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        console.log(response);
        return response;
      }));
      Promise.all(promesas).then(() => {
        $('#loader').hide();
        this.checks.map((c, i) => {
          c.number = value+i;
          return c;
        });
      });
    }
  }

  public cambiarNumeroCheque(e, id, entry, amount): void{
    const value = parseInt(e.target.value);
    if(!isNaN(value)){
      $('#loader').show();
      this.pruebaService.cambiarNumeroCheque(id, entry, amount, this.idConcurso, value, this.idUsuario).subscribe(
        response => {
          if(!response.error){
            $('#loader').hide();
            $.NotificationApp.send("Success", 'The check number has been updated successfully', 'bottom-right', '#3c763d', 'success');
            console.log(response);
          } else{
            $('#loader').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader').hide();
          $.NotificationApp.send("Error", 'It was not possible to change the check number, please try again', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    }
  }
}
