import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-judge-cards',
  templateUrl: './judge-cards.component.html',
  styleUrls: ['./judge-cards.component.css']
})
export class JudgeCardsComponent implements OnInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public dias: string[];
  public pistas: any[];
  public pruebas: any[];
  public diaSeleccionado: string;
  public pistaSeleccionada: string;
  public repeats: any[];

  constructor(private authService: AuthService, private router: Router, private pruebaService: PruebaService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.dias = [];
    this.pistas = [];
    this.pruebas = [];
    this.diaSeleccionado = '';
    this.pistaSeleccionada = '';
    this.repeats = Array(20).fill(0).map((x, i) => i);
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
  }

  public async getPruebas() {
    const response = await this.pruebaService.getPruebas(this.idConcurso, true).toPromise().then(r => r, e => ({ error: true, message: e }));
    if (response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    for (const dia in response) {
      if (!dia) continue;
      this.dias.push(dia);
      for (const prueba of response[dia]) {
        if (this.pistas.indexOf(prueba.pista) == -1) this.pistas.push(prueba.pista);
        this.pruebas.push(prueba);
      }
    }
  }
}
