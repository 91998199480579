<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu class="navbar-custom"></menu>
      <div class="container-fluid margin-top-35">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-xs-12">
            <div class="card panel-borders">
              <div class="card-header bg-dark text-white">
                <h3>
                  <b>Fill in the following fields</b>
                </h3>
              </div>
              <div class="card-body">
                <form class="form-horizontal" [formGroup]="form" (submit)="guardarConcurso()">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" formControlName="nombre"
                          [ngClass]="{'input-error': form.get('nombre').invalid && (form.get('nombre').touched || form.get('nombre').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Type</label>
                        <input type="text" class="form-control" formControlName="tipo"
                          [ngClass]="{'input-error': form.get('tipo').invalid && (form.get('tipo').touched || form.get('tipo').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>FEI Show ID</label>
                        <input type="text" class="form-control" formControlName="idOficial"
                          [ngClass]="{'input-error': form.get('idOficial').invalid && (form.get('idOficial').touched || form.get('idOficial').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Organizer</label>
                        <input type="text" class="form-control" [matAutocomplete]="autocompleteOrganizador"
                          formControlName="organizador"
                          [ngClass]="{'input-error': form.get('organizador').invalid && (form.get('organizador').touched || form.get('organizador').dirty)}">
                        <mat-autocomplete #autocompleteOrganizador="matAutocomplete" [displayWith]="displayOrganizador">
                          <mat-option *ngFor="let club of clubs | filterClub : queryClub"
                            [value]="{id_club: club.id_club, club: club.club}">
                            <img src="{{club.logo_url}}" aria-hidden height="25" style="vertical-align:middle;"
                              onerror="this.src='assets/images/icons/sin-club.png'">
                            <span>{{ (club.club) ? club.club : '-' }}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Venue</label>
                        <input type="text" class="form-control" formControlName="lugar"
                          [ngClass]="{'input-error': form.get('lugar').invalid && (form.get('lugar').touched || form.get('lugar').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group ui-fluid">
                        <label>Start</label>
                        <input type="text" class="form-control date" data-toggle="date-picker" data-single-date-picker="true" formControlName="inicio" onchange="window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunctionInicio(this.value); });">
                        <!--input type="text" class="form-control date" data-toggle="date-picker" data-single-date-picker="true" formControlName="fin" onchange="window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunctionInicioChange(this.value); });">
                        <--p-calendar formControlName="inicio" showTime="true" hourFormat="24" dateFormat="dd-mm-yy"
                          [locale]="es" [showIcon]="false" readonlyInput="true"></p-calendar--->
                        <span class="font-13 text-muted">e.g "MM/DD/YYYY"</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group ui-fluid">
                        <label>End</label>
                        <input type="text" class="form-control date" data-toggle="date-picker" data-single-date-picker="true" formControlName="fin" onchange="window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunctionFin(this.value); });">
                        <!--p-calendar formControlName="fin" showTime="true" hourFormat="24" dateFormat="dd-mm-yy"
                          [locale]="es" [showIcon]="false" readonlyInput="true"></p-calendar-->
                        <span class="font-13 text-muted">e.g "MM/DD/YYYY"</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Nomination Fee</label>
                        <input type="number" min="0" class="form-control" formControlName="costoNominacion"
                          [ngClass]="{'input-error': form.get('costoNominacion').invalid && (form.get('costoNominacion').touched || form.get('costoNominacion').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>% to Deduct</label>
                        <input type="number" min="0" class="form-control" formControlName="porcentajeADeducir"
                          [ngClass]="{'input-error': form.get('porcentajeADeducir').invalid && (form.get('porcentajeADeducir').touched || form.get('porcentajeADeducir').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Scratch Fee < $50K</label>
                            <input type="number" min="0" class="form-control" formControlName="desinscripcion1"
                              [ngClass]="{'input-error': form.get('desinscripcion1').invalid && (form.get('desinscripcion1').touched || form.get('desinscripcion1').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Scratch Fee >= $50K</label>
                        <input type="number" min="0" class="form-control" formControlName="desinscripcion2"
                          [ngClass]="{'input-error': form.get('desinscripcion2').invalid && (form.get('desinscripcion2').touched || form.get('desinscripcion2').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Sales Tax %</label>
                        <input type="number" min="0" class="form-control" formControlName="porcentajeSalesTax"
                          [ngClass]="{'input-error': form.get('porcentajeSalesTax').invalid && (form.get('porcentajeSalesTax').touched || form.get('porcentajeSalesTax').dirty)}">
                      </div>
                    </div>
                    <div class="col-6">

                    </div>
                    <div class="col-6">
                      <div class="am-checkbox">
                        <div class="am-checkbox">
                          <input id="publico" type="checkbox" class="needsclick" formControlName="publico">
                          <label for="publico">Publish</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="am-checkbox">
                        <div class="am-checkbox">
                          <input id="ed" type="checkbox" class="needsclick" formControlName="ed">
                          <label for="ed">in ED</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="am-checkbox">
                        <div class="am-checkbox">
                          <input id="salesTax" type="checkbox" class="needsclick" formControlName="salesTax">
                          <label for="salesTax">Sales Tax</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="am-checkbox">
                        <div class="am-checkbox">
                          <input id="feedOrderSalesTax" type="checkbox" class="needsclick"
                            formControlName="feedOrderSalesTax">
                          <label for="feedOrderSalesTax">Feed Orders Sales Tax</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="am-checkbox">
                        <div class="am-checkbox">
                          <input id="merchSalesTax" type="checkbox" class="needsclick" formControlName="merchSalesTax">
                          <label for="merchSalesTax">Merch Sales Tax</label>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-12" style="text-align: center; margin-top: 30px;">
                      <div class="form-group">
                        <button type="submit" mat-raised-button
                          style="background: #7a98bf; color: white;font-family: 'Open Sans',sans-serif;">Accept</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
