<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid">
        <div class="row justify-content-center w-100">
          <div class="col-10 mt-3">
            <div class="card">
              <div class="card-body">
                <ul class="nav nav-pills bg-light nav-justified mb-3">
                  <li class="nav-item">
                      <a href="#classes" data-toggle="tab" aria-expanded="true" class="nav-link rounded-0 active">
                          <i class="mdi mdi-account-circle mr-1"></i>
                          <span class="d-none d-sm-inline">Classes</span>
                      </a>
                  </li>
                  <li class="nav-item">
                      <a href="#unapplied-prizes" data-toggle="tab" aria-expanded="false" class="nav-link rounded-0">
                          <i class="mdi mdi-face-profile mr-1"></i>
                          <span class="d-none d-sm-inline">See all Unapplied</span>
                      </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane show active" id="classes">
                      <div class="row">
                        <div class="col-12">
                        </div>
                        <div class="col-5">
                          <select class="form-control" (change)="seleccionarPrueba($event)">
                            <option value="" disabled selected>Select a class</option>
                            <option [value]="prueba.ipc" *ngFor="let prueba of pruebas">{{ prueba.numero }} - {{ prueba.nombre }}</option>
                          </select>
                        </div>
                        {{ ipc }}
                        <div class="col-12 mt-3" *ngIf="ipc && prize_distribution.length > 0">
                          <div class="btn-group mb-1">
                            <button type="button" class="btn btn-light" (click)="plantillaPremios()">Apply distribution {{ name_prize_distribution }}</button>
                          </div>
                          <button type="button" class="btn btn-outline-success mb-1 ml-3" *ngIf="mostrarConfirmarTodo()" (click)="confirmAllPrizes()">Confirm all</button>
                        </div>
                        <div class="col-12 mt-2" *ngIf="ipc">
                          <b>{{nombreConcurso}}</b><br>
                          <b>Class</b> {{ nombre }}<br>
                          <b>Height</b> {{ altura }}<br>
                          <b>Table / Article</b> {{ modalidad }}<br>
                          <b>Date</b> {{ fecha }}<br>
                          <b>Prize</b> {{ premio | currency}}<br>
                          <b>Assigned</b> {{ totalAsignado() | currency}}<br>
                        </div>
                        <div class="col-12 mt-2" *ngIf="ipc">
                          <table class="table table-fw-widget table-sm">
                            <thead>
                              <tr>
                                <th>Rank</th>
                                <th>Rider</th>
                                <th>Horse</th>
                                <th>UnApply</th>
                                <th>Notes</th>
                                <th>Amount</th>
                                <th>Deduction %</th>
                                <th>Payable</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let premio of premios; let index = index" [ngClass]="{'bg-danger text-white': premio.estatus != '0'}">
                                <td>
                                  <span>{{ premio.posicion }}</span>
                                </td>
                                <td>{{ premio.jinete }}</td>
                                <td>{{ premio.caballo }}</td>
                                <td>
                                  <div class="custom-control custom-checkbox">
                                    <input id="check{{index}}" type="checkbox" class="custom-control-input" [(ngModel)]="premio.pagarAparte" [attr.checked]="premio.pagarAparte ? 'checked' : null" (change)="guardarPremio(premio, index)">
                                    <label for="check{{index}}" class="custom-control-label" style="margin-bottom: 15px !important;"></label>
                                  </div>
                                </td>
                                <td><input type="text" class="form-control" [value]="premio.referencia" [(ngModel)]="premio.referencia" (change)="guardarPremio(premio, index)"></td>
                                <td><input type="number" class="form-control" onclick="this.select();" [(ngModel)]="premio.monto" (change)="guardarPremio(premio, index)"></td>
                                <td><input type="number" class="form-control" onclick="this.select();" [(ngModel)]="premio.deduccion" (change)="guardarPremio(premio, index)"></td>
                                <td>{{ (premio.montoAPagar ? premio.montoAPagar : '') | currency }}</td>
                                <td>
                                  <button type="button" class="btn btn-outline-success" *ngIf="premio.plantillaPremio" (click)="guardarPremio(premio, index)">Confirm</button>
                                </td>
                                <td>
                                  <button type="button" *ngIf="premio.idPremio" mat-icon-button (click)="eliminarPremio(premio.idPremio)">
                                    <mat-icon>clear</mat-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                  </div>
                  <div class="tab-pane" id="unapplied-prizes">
                      <table class="table table-fw-widget table-sm">
                        <thead>
                          <tr>
                            <th><b>Class</b></th>
                            <th><b>Rank</b></th>
                            <th><b>Amount</b></th>
                            <th><b>Payable</b></th>
                            <th><b>Rider</b></th>
                            <th><b>Notes</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let premio of premiosPagarAparte">
                              <td>{{ premio.numeroPrueba }} - {{ premio.nombre }}</td>
                              <td>{{ premio.premio }}</td>
                              <td>{{ premio.cantidad | currency }}</td>
                              <td>{{ premio.cantidadAPagar | currency }}</td>
                              <td>{{ premio.jinete }}</td>
                              <td>
                                <input type="text" class="form-control" (change)="guardarReferenciaPremio(premio.idPremio, $event)" [value]="premio.referencia">
                              </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
