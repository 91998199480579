<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
    <div class="sk-cube-grid ed">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>
<div class="wrapper">
    <menu-lateral class="left-side-menu"></menu-lateral>
    <div class="content-page">
        <div class="content">
            <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso"  class="navbar-custom"></menu>
            <div class="container-fluid">
                <!-- Modal agregar usuario -->
                <div id="signup-modal" class="modal fade" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header modal-colored-header bg-primary">
                                <h4 class="modal-title" id="primary-header-modalLabel">Crear usuario</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="modal-body">
                                <form class="pl-3 pr-3" [formGroup]="formUsuario" (submit)="guardarUsuario()">

                                    <div class="form-group">
                                        <label>Usuario</label>
                                        <input class="form-control" type="text" formControlName="usuario" required="" placeholder="Usuario" [ngClass]="{'input-error': formUsuario.get('usuario').invalid && (formUsuario.get('usuario').touched || formUsuario.get('usuario').dirty)}">
                                    </div>

                                    <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" type="text" formControlName="nombre" required="" placeholder="Nombre" [ngClass]="{'input-error': formUsuario.get('nombre').invalid && (formUsuario.get('nombre').touched || formUsuario.get('nombre').dirty)}">
                                    </div>

                                    <div class="form-group">
                                        <label>Apellidos</label>
                                        <input class="form-control" type="text" formControlName="apellidos" required="" placeholder="Apellidos" [ngClass]="{'input-error': formUsuario.get('apellidos').invalid && (formUsuario.get('apellidos').touched || formUsuario.get('apellidos').dirty)}">
                                    </div>

                                    <div class="form-group">
                                        <label>Tipo de usuario</label>
                                        <select class="form-control" required="" formControlName="privilegios" [ngClass]="{'input-error': formUsuario.get('privilegios').invalid && (formUsuario.get('privilegios').touched || formUsuario.get('privilegios').dirty)}">
                                            <option [ngValue]="0" disabled>User Type</option>
                                            <!-- <option [ngValue]="50">Entrenador</option> -->
                                            <option [ngValue]="50">Ingate</option>
                                            <option [ngValue]="100">Judge</option>
                                            <option [ngValue]="150">Office</option>
                                            <option [ngValue]="200">Office Leader</option>
                                            <option [ngValue]="250">Accounting</option>
                                            <option [ngValue]="300">Desarrollador</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label>OC</label>
                                        <select class="form-control" formControlName="idClub">
                                            <option value="" disabled>Seleciona un club</option>
                                            <option [value]="club.id_club" *ngFor="let club of clubs">{{ club.club }}</option>
                                        </select>
                                    </div>

                                    <!--div class="form-group" *ngIf="formUsuario.get('privilegios').value === 100">
                                        <label>Juez</label>
                                        <select class="form-control" formControlName="idJuez" [ngClass]="{'input-error': !formUsuario.get('idJuez').value && (formUsuario.get('idJuez').touched || formUsuario.get('idJuez').dirty)}">
                                            <option value="" disabled>Seleciona un juez</option>
                                            <option [value]="juez.id" *ngFor="let juez of jueces">{{ juez.nombre }}</option>
                                        </select>
                                    </div-->

                                    <div class="form-group">
                                        <label>Contraseña</label>
                                        <input class="form-control" type="password" formControlName="password" placeholder="Contraseña" [ngClass]="{'input-error': !formUsuario.get('idUsuario').value && !formUsuario.get('password').value && (formUsuario.get('password').touched || formUsuario.get('password').dirty)}">
                                    </div>

                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="activo" formControlName="activo">
                                            <label class="custom-control-label" for="activo">Activo</label>
                                        </div>
                                    </div>

                                    <div class="form-group text-right">
                                        <button type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">Cancelar</button>
                                        <button type="submit" class="btn btn-outline-success">Aceptar</button>
                                    </div>

                                </form>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- Fin modal agregar usuario -->
                <div class="row mt-4 m-print-0 justify-content-center">
                    <div class="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12">
                        <div class="card w-100">
                            <div class="card-body">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="form-group mb-0 ml-auto">
                                            <button type="button" (click)="modalCrear()" class="btn btn-outline-info btn-rounded"><i class="mdi mdi-account-plus"></i> Crear</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-4">
                                    <div class="row">
                                        <table class="table" id="basic-datatable">
                                            <thead>
                                                <tr>
                                                    <th>Usuario</th>
                                                    <th>Nombre</th>
                                                    <th>Privilegios</th>
                                                    <th>Club</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let usuario of usuarios">
                                                    <td>{{ usuario.usuario | lowercase }}</td>
                                                    <td>{{ usuario.nombre }} {{ usuario.apellidos }}</td>
                                                    <td>{{ privilegiosToText(usuario.privilegios) }}</td>
                                                    <td>{{ usuario.club }}</td>
                                                    <td class="table-action">
                                                        <a href="javascript: void(0);" class="action-icon" (click)="toggleUsuario(usuario)" [ngClass]="{'text-success': usuario.activo}">
                                                            <i class="mdi mdi-power"></i>
                                                        </a>
                                                    </td>
                                                    <td class="table-action">
                                                        <a href="javascript: void(0);" class="action-icon" (click)="modalActualizar(usuario)">
                                                            <i class="mdi mdi-pencil"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
