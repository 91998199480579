import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { ClassListService } from '../services/class-list.service';
import { ResultadoService } from '../services/resultado.service';
import {MatLegacyChipInputEvent as MatChipInputEvent} from '@angular/material/legacy-chips';
import { PrintService } from '../services/print.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
declare var jquery: any;
declare var $: any;


@Component({
  selector: 'app-class-sheets',
  templateUrl: './class-sheets.component.html',
  styleUrls: ['./class-sheets.component.css']
})
export class ClassSheetsComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public pruebas: Array<any>;
  public ipc: string;
  public prueba: any;
  public binomios: Array<any>;
  public fecha: Date;
  public jineteSeleccionado: string;
  public baseUrl: string;
  public mostrarInfoCaballo: boolean;
  public mostrarPaisJinete: boolean;
  public classes: any[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private router: Router,
    private authService: AuthService,
    private pruebaService: PruebaService,
    private classListService: ClassListService,
    private ngZone: NgZone,
    private printService: PrintService
  ) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.pruebas = [];
    this.ipc = '';
    this.prueba = {};
    this.binomios = [];
    this.fecha = new Date();
    this.baseUrl = window.location.hostname;
    this.mostrarInfoCaballo = false;
    this.mostrarPaisJinete = false;
    this.classes = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
  }

  private getPruebas() {
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pruebas = response.pruebas;
          console.log(this.pruebas);
          if (this.ipc)
            this.seleccionarPrueba({}, this.ipc);
          else
            $('#loader').hide();
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to query the classes list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public seleccionarPrueba(select, ipc: boolean | string = false) {
    this.binomios = [];
    $('#loader').show();
    this.ipc = ipc ? ipc : select.currentTarget.value;
    const index = this.pruebas.findIndex(prueba => {
      return prueba.ipc == this.ipc;
    })
    this.prueba = this.pruebas[index];

    this.classListService.getBinomios(this.ipc).subscribe(
      response => {
        if (!response.error) {
          this.binomios = response.binomios;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        $('#loader').hide();
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'Classes not retrieved.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public export_csv(){
    let headers = `"Horse Number","Horse Name","Rider Name","Owner(s)","Rider Country"\n`;
    let txt = this.binomios.map(binomio => `"${binomio.cucarda}","${binomio.caballo}","${binomio.nombreJinete.toUpperCase() + ' ' + binomio.apellidosJinete.toUpperCase()}","${binomio.owner.toUpperCase()}","${binomio.country.toUpperCase()}"`).join('\n');
    txt = headers + txt;
    const csvFile = new Blob([txt], {type: 'text/csv'});

    let a = document.createElement('a');
    a.download = this.prueba.numero + ' '+this.prueba.nombre + '.csv';
    a.href = URL.createObjectURL(csvFile);
    a.style.visibility = 'hidden';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public infoCaballo(binomio): string {
    let datosCaballo = [];
    if (binomio.dateOfBirth) {//Calcular la edad en años
      datosCaballo.push(binomio.dateOfBirth);
    }
    if (binomio.gender)
      datosCaballo.push(binomio.gender);
    if (binomio.colorCode)
      datosCaballo.push(binomio.colorCode);
    if (binomio.colorComplement)
      datosCaballo.push(binomio.colorComplement);
    if (binomio.countryOfBirth)
      datosCaballo.push(binomio.countryOfBirth);
    if (binomio.breed)
      datosCaballo.push(binomio.breed);
    if (binomio.sire)
      datosCaballo.push(binomio.sire);
    if (binomio.dam)
      datosCaballo.push(binomio.dam + " x " + binomio.sireOfDam);

    return datosCaballo.join(' / ');
  }
  public showInfoCaballo(e) {
      if (e.target.checked) {
          this.mostrarInfoCaballo = true;
      } else {
        this.mostrarInfoCaballo = false;
      }
  }
  public showPaisJinete(e) {
      if (e.target.checked) {
          this.mostrarPaisJinete = true;
      } else {
        this.mostrarPaisJinete = false;
      }
  }

  public add(event: MatChipInputEvent): void{
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
        //Consultar el id de la prueba con el numero que el usuario nos esta dando
        const index = this.pruebas.findIndex(p => p.numero == value)
        if(index > -1){
            this.classes.push({ipc: this.pruebas[index].ipc, name: value.trim()});
        }
    }

    if (input) {
        input.value = '';
    }
  }

  public remove(ipc): void {
    const index = this.classes.findIndex(c => c.ipc == ipc);

    if (index >= 0) {
      this.classes.splice(index, 1);
    }
  }

  public printClasses(): void{
    this.printService.printClassSheets(this.idConcurso, this.classes.map(c => c.ipc), this.mostrarInfoCaballo, this.mostrarPaisJinete);
  }
}
