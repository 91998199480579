import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ConcursoService } from '../services/concurso.service';
import { ReportesService } from '../services/reportes.service';
declare var $ :any;

@Component({
  selector: 'app-master-list',
  templateUrl: './master-list.component.html',
  styleUrls: ['./master-list.component.css']
})
export class MasterListComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string;
  public privilegios: number;
  public logoClub: string;
  public header: string;
  public header2: string;
  public footer: string;
  public eventos: any[];
  public horses: any[];
  public eventoSeleccionado: string;

  constructor(private router: Router, private authService: AuthService, private concursoService: ConcursoService, private reportesService: ReportesService){
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.logoClub = '';
    this.header = '';
    this.header2 = '';
    this.footer = '';
    this.eventos = [];
    this.eventoSeleccionado = 'All';
  }

  ngOnInit(){
    if(!this.authService.isLoggedIn()){
      this.authService.logOut();
      return;
    } else{
        this.token = this.authService.getAuthorizationToken();
        this.sessionData = this.authService.getSessionData(this.token);
        this.idUsuario = String(this.sessionData['idUsuario']);
        this.privilegios = this.sessionData.privilegios;
    }
    if(this.authService.validarConcurso()){
        this.idConcurso = this.authService.validarConcurso();
    } else{
        this.router.navigate(['']);
        return;
    }
    this.getConcurso();
  }

  public getConcurso(){
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.logoClub = response.concurso.logoClub;
          this.header = response.concurso.header;
          this.header2 = response.concurso.header2;
          this.footer = response.concurso.footer;
          this.eventos = response.concurso.eventos;
          this.eventos.unshift('All');
          this.getMasterList();
        } else{
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
  }

  private getMasterList(evento = ''): void{
    $('#loader').show();
    this.horses = [];
    this.eventoSeleccionado = evento || 'All';
    if(evento == 'All') evento = '';
    this.reportesService.getMasterList(this.idConcurso, evento).subscribe(
      response => {
        this.horses = response.horses;
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.",  'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
  }
}
